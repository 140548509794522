
import { useContext, useEffect, useState } from 'react';
import {ButtomMenu, ButtonSide, ContainerMobileHide, ContainerMobileOverflow, ContainerMobileShow, GlobalContainer, Header, HeaderMobile, ImgChanpionship, LogoMobile, MainContainer, SideBarMobile, Sidebar} from './style';
import { BsList, BsSpeedometer2, BsGraphUp, BsPlayCircle, BsXLg, BsTags } from 'react-icons/bs';
import { useNavigate } from "react-router-dom";
import { ButtonStyled, Container, ContainerLink, ImageLogo, Text } from '../componets/styles';
import AuthContext from '../context/AuthContext';
import { formatarData } from '../share/functions';
import { ImageOffers } from './style';
import axios from 'axios';
import { API_URL, URL_IMAGE } from '../config';


export default function Offers()
{
    const navigate = useNavigate();

    const {verifieldLogin, user, logout} = useContext(AuthContext);

    const [isSideOpen, setIsSideOpen] = useState(false);

    const [sideBarMobile, setSideBarMobile] = useState(false);

    const [offersList, setOffersList] = useState([]);

    async function handleverifieldLogin()
    {
        const status = await verifieldLogin();

        if(status === 'Inativo')
        {
          navigate("/payment");
        }
    }

    async function handleOffers()
    {
        const response = await axios.get(`${API_URL}/offers`);

        setOffersList(response.data)

    }



    useEffect(()=>{
        handleverifieldLogin();
        handleOffers();
    },[])


    return(
        <GlobalContainer>

            <ContainerMobileHide>

                <Header>

                    <Container w='100px'>
                        <ImageLogo src='./images/logo.png'/>
                    </Container>

                    <Container w='auto' display='flex' mr="20px" items='center'>
                        <Container w='auto' display='flex' mr="20px" >
                            <Text col='#022c22' fs='20px' fw='bold'>{`Bem vindo! ${user.name} |`}</Text><Text ml='10px' col='#e11d48' fs='20px' fw='bold'>{ `${formatarData(user.validation)}`}</Text>
                        </Container>


                        <ButtonStyled onClick={logout} w='150px' mr='30px' bg='transparent' border='1px solid #fff'>
                            <Text col="#fff" fw='bold' fs='20px'>Sair</Text>
                        </ButtonStyled>
                    </Container>



                </Header>

                <Sidebar open={isSideOpen}>

                    <ButtonSide open={isSideOpen} onClick={()=>{setIsSideOpen(!isSideOpen)}}>
                        <BsList col='#fff' size={45}/>
                        {isSideOpen && <Text col="#fff" fw="bold" ml="10px">Menu</Text>}
                    </ButtonSide>

                    <ButtonSide open={isSideOpen} mt="50px" onClick={()=>{navigate("/home")}}>
                        <BsSpeedometer2 col='#fff' size={35}/>
                        {isSideOpen && <Text col="#fff" fw="bold" ml="10px">Painel</Text>}
                        
                    </ButtonSide>

                    <ButtonSide open={isSideOpen} mt="30px" onClick={()=>{navigate("/analysis")}}>
                        <BsGraphUp col='#fff' size={33}/>
                        {isSideOpen && <Text col="#fff" fw="bold" ml="10px">Análise</Text>}
                    </ButtonSide>

                    <ButtonSide open={isSideOpen} mt="30px" onClick={()=>{navigate("/videos")}}>
                        <BsPlayCircle  col='#fff' size={35}/>
                        {isSideOpen && <Text col="#fff" fw="bold" ml="10px">Vídeos</Text>}
                    </ButtonSide>
{/** 
                    <ButtonSide open={isSideOpen} bg="#707070" mt="30px" onClick={()=>{navigate("/robos")}}>
                        <BsTags  col='#fff' size={35}/>
                        {isSideOpen && <Text col="#fff" fw="bold" ml="10px">Robôs</Text>}
                    </ButtonSide>
*/}
                </Sidebar>

                <MainContainer>
                    <Container  display='flex' wp='wrap'>
                    {
                            offersList.map((item, index)=>{
                                return(

                                    <ContainerLink href={item.link} key={index} w='400px' ml='20px'>
                                        <Container w='100%' display='flex' jc='center' mt='20px'>
                                            <Text col='#7dd3fc' fw='bold' fs='20px'>{item.title}</Text>
                                        </Container>

                                        <Container w='100%' display='flex' jc='center' mt='20px'>
                                            <ImageOffers src={`${URL_IMAGE}/imagens/${item.image}`}/>
                                        </Container>

                                        <Container w='100%' display='flex' jc='center' mt='20px'>
                                            <Text col='#fff' fw='bold' fs='15px'>{item.description}</Text>
                                        </Container>
                                    </ContainerLink>

                                    )
                            })
                        }             
                    </Container>
                </MainContainer>

            </ContainerMobileHide>

           <ContainerMobileShow>

           <HeaderMobile>

                <ButtonStyled onClick={()=>{setSideBarMobile(true)}} bg='transparent' w='auto'>
                    <BsList color='#fff' size={30}/>
                </ButtonStyled>

                <Container w='auto' display='flex' >
                <Text ml='5px' col='#e11d48' fs='15px' fw='bold'>{ `${formatarData(user.validation)}`}</Text>
                </Container>

                <LogoMobile src='./images/logo.png'/>

            </HeaderMobile>

                <SideBarMobile open={sideBarMobile}>

                    <Container w='100%' display='flex' jc='flex-end'>
                        <ButtonStyled onClick={()=>{setSideBarMobile(false)}} bg='transparent' w='auto' mr='20px'>
                            <BsXLg color='#fff' size={30}/>
                        </ButtonStyled>
                    </Container>

                    <Container w='100%' mt='40px'>

                        <ButtomMenu onClick={()=>{navigate("/home")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Dashboard</Text>
                        </ButtomMenu>
                        <ButtomMenu onClick={()=>{navigate("/analysis")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Análises</Text>
                        </ButtomMenu>
                        <ButtomMenu onClick={()=>{navigate("/videos")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Videos</Text>
                        </ButtomMenu>
                        <ButtomMenu onClick={()=>{navigate("/robos")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Robôs</Text>
                        </ButtomMenu>

                    </Container>

                </SideBarMobile>

                <ContainerMobileOverflow>

                    <Container display='flex' wp='wrap'>
                    {
                            offersList.map((item,index)=>{
                                return(

                                    <Container key={index} w='400px' ml='20px'>
                                        <Container w='100%' display='flex' jc='center' mt='20px'>
                                            <Text col='#7dd3fc' fw='bold' fs='20px'>{item.title}</Text>
                                        </Container>

                                        <Container w='100%' display='flex' jc='center' mt='20px'>
                                            <ImageOffers src={item.link}/>
                                        </Container>

                                        <Container w='100%' display='flex' jc='center' mt='20px'>
                                            <Text col='#fff' fw='bold' fs='15px'>{item.description}</Text>
                                        </Container>
                                    </Container>

                                    )
                            })
                        }             
                    </Container>

                </ContainerMobileOverflow>

            </ContainerMobileShow>

        </GlobalContainer>
    )
}