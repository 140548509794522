import { BsArrowClockwise, BsGear, BsList, BsToggleOff, BsToggleOn, BsXLg } from "react-icons/bs";
import { ButtonStyled, Container, Text } from "../../../componets/styles";
import { BoxTable, BtnChanpionship, ButtomHour, ButtomMenu, ButtonSettings, ButtonUpdate, ButtonUpdateMobile, ContainerGoalsVertical, ContainerGreenPercentegeVertical, ContainerGreenVertical, ContainerHorasJogos, ContainerHours, ContainerMinutes, ContainerOpacity, ContainerSettings, GlobalContainer, Header, HeaderMobile, ItemGame, ItemGameMobile, ItemGloalsVertical, ItemGloalsVerticalLg, ItemGloalsVerticalLgMobile, ItemGloalsVerticalMobile, ItemGreenPercentegeVertical, ItemGreenPercentegeVerticalLg, ItemGreenPercentegeVerticalLgMobile, ItemGreenPercentegeVerticalMobile, ItemGreenVertical, ItemGreenVerticalLg, ItemGreenVerticalLgMobile, ItemGreenVerticalMobile, ItemHours, ItemHoursMobile, ItemMinutes, ItemMinutesLg, ItemMinutesLgMobile, ItemMinutesMobile, LogoMobile, SideBarBoxFlexBetween, SideBarIconCheck, SideBarItemStrategy, SideBarMobile, SideBarTitle, StrategyButtonMobile, StrategyMobile, TextTitleGreen, TextTitleGreenMobile, TextTitleItemSettings, TextTitleItemSettingsMobile, TextTitleRed, TextTitleRedMobile, TextTitleSettings, TextTitleSettingsMobile } from "../styled";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { STRATEGY } from "../../MultiHour/constants";
import { STRATEGYS } from "../constants";
import ParamContext from "../../../context/ParamContext";
import { getExpressCup } from "../requests/GET";
import HeaderTableAssemble from "../lib/HeaderTableAssemble";
import TableAssemble from "../lib/TableAssemble";
import { GiSoccerBall } from "react-icons/gi";
import Reload from "../../../componets/reload";

export default function TemplateMobile({handlePage})
{

    const [sideBarMobile, setSideBarMobile] = useState(false);
    const [openStrategy, setOpenStrategy] = useState(false);
    const [refresh, setRefersh] = useState(false);
    const [reload, setReload] = useState(false);

    const [rendered, setRendered] = useState({
        verticalData: [],
        hours: [],
        strategy: 'OVER 1.5',
        lasthours: '24',
        green: '0%',
        red: '0%'
    })

    const {
        strategy, 
        setStrategy, 
        hourData, 
        setHourData, 
        showData, 
        setShowData,
        selectedScores, 
        setSelectedScores
    } = useContext(ParamContext);

    const navigate = useNavigate();


    const handleItemClick = (scoreboard) => {
        setSelectedScores(prev => 
            prev.includes(scoreboard) ? prev.filter(score => score !== scoreboard) : [...prev, scoreboard]
        );
    };

    const getBgSelectColor = (scoreboard) => {
        const colors = [    
            '#0000FF', // Azul
            '#FFFF00', // Amarelo
            '#800080', // Roxo
            '#FFC0CB', // Rosa
            '#4B0082', // Índigo
            '#008080', // Verde-azulado
            '#FFA500', // Laranja
            '#00FFFF'  // Ciano
            ];
        const index = selectedScores.indexOf(scoreboard);
        return index !== -1 ? colors[index % colors.length] : null;
    };

    function changeStrategy(id, name)
    {
        setStrategy(id)

        setRendered((prev) => ({
            ...prev,
            strategy: name,
        }));
    }

    async function handleExpressData(param)
    {
        setReload(true)
        const response = await getExpressCup(param);

        const dataHeaderTable = HeaderTableAssemble.create(response, strategy);

        setRendered((prev) => ({
            ...prev,
            green: dataHeaderTable.greensPercentage,
            red: dataHeaderTable.redsPercentage
        }));

        const tableAssemble = TableAssemble.create(response, strategy);

        setRendered((prev => ({
            ...prev,
            verticalData: dataHeaderTable.resultsByMinute, 
            hours: tableAssemble
        })))

        setReload(false)
    }

    useEffect(()=>{
        handleExpressData(hourData);
    }, [strategy, showData, refresh, hourData])
    

    return(

        <GlobalContainer>

            {reload === true && <Reload bg='#000'/>} 

            <HeaderMobile>

                <ButtonStyled onClick={()=>{setSideBarMobile(true)}} bg='transparent' w='auto'>
                    <BsList color='#fff' size={30}/>
                </ButtonStyled>

                <LogoMobile src='./images/logo.png'/>

            </HeaderMobile>

            <SideBarMobile open={sideBarMobile}>

                <Container w='100%' display='flex' jc='flex-end'>
                    <ButtonStyled onClick={()=>{setSideBarMobile(false)}} bg='transparent' w='auto' mr='20px'>
                        <BsXLg color='#fff' size={30}/>
                    </ButtonStyled>
                </Container>

                <Container w='100%' mt='40px'>

                    <ButtomMenu onClick={()=>{navigate("/home")}}>
                        <Text col='#fff' fw='bold' fs='20px'>Dashboard</Text>
                    </ButtomMenu>
                    <ButtomMenu onClick={()=>{handlePage("maximims")}}>
                        <Text col='#fff' fw='bold' fs='20px'>Máximas</Text>
                    </ButtomMenu>
                    <ButtomMenu onClick={()=>{handlePage("hour")}}>
                        <Text col='#fff' fw='bold' fs='20px'>Horários</Text>
                    </ButtomMenu>
                    <ButtomMenu onClick={()=>{handlePage("multihour")}}>
                        <Text col='#fff' fw='bold' fs='20px'>Multihorários</Text>
                    </ButtomMenu>
                    <ButtomMenu onClick={()=>{handlePage("confrontations")}}>
                        <Text col='#fff' fw='bold' fs='20px'>Confrontos</Text>
                    </ButtomMenu>
                    <ButtomMenu onClick={()=>{handlePage("ranking")}}>
                        <Text col='#fff' fw='bold' fs='20px'>Ranking</Text>
                    </ButtomMenu>
                    <ButtomMenu onClick={()=>{navigate("/videos")}}>
                        <Text col='#fff' fw='bold' fs='20px'>Videos</Text>
                    </ButtomMenu>
                        {/**
                         *                         <ButtomMenu onClick={()=>{navigate("/robos")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Robôs</Text>
                        </ButtomMenu>
                         * 
                         */}

                    </Container>

                </SideBarMobile>

                <StrategyMobile openStrategy={openStrategy} onClick={()=>{setOpenStrategy(false)}}>

                    {openStrategy &&
                        <SideBarTitle>
                            Horas de jogos
                        </SideBarTitle>
                    }

                    {openStrategy &&

                        <ContainerHorasJogos>
                            <ButtomHour onClick={() => {
                                setHourData(6);
                                setRendered((prev) => ({ ...prev, lasthours: 6 }));
                            }} active={hourData === 6 ? true : false}>6</ButtomHour>

                            <ButtomHour onClick={() => {
                                setHourData(12);
                                setRendered((prev) => ({ ...prev, lasthours: 12 }));
                            }} active={hourData === 12 ? true : false}>12</ButtomHour>

                            <ButtomHour onClick={() => {
                                setHourData(23);
                                setRendered((prev) => ({ ...prev, lasthours: 24 }));
                            }} active={hourData === 23 ? true : false}>24</ButtomHour>
                        </ContainerHorasJogos>
                    }

                    {openStrategy && 
                        <SideBarTitle>
                            Estratégias
                        </SideBarTitle>
                    }

                    {STRATEGYS.map((item, index) => {
                        if(openStrategy) {
                            return (
                                <SideBarBoxFlexBetween key={index}>

                                    <SideBarItemStrategy key={index}>
                                        {item.name}
                                    </SideBarItemStrategy>

                                    <SideBarIconCheck onClick={()=>{changeStrategy(item.id, item.name)}}>
                                        {strategy === item.id ? <BsToggleOn size={30} color={"#000"} /> : <BsToggleOff size={30} color={"#000"} />}
                                    </SideBarIconCheck>
                                        
                                </SideBarBoxFlexBetween>
                            );
                        } else {
                            return null; // Ou return <></> para um fragmento vazio
                        }
                    })}
  
                  
            </StrategyMobile>

            <ContainerSettings>
                
                {/*<TextTitleSettings>MOSTRAR: <TextTitleItemSettings>FINAL DE JOGO</TextTitleItemSettings></TextTitleSettings>*/}

                {/*<TextTitleSettings>ESTRATÉGIA: <TextTitleItemSettings>{rendered.strategy}</TextTitleItemSettings></TextTitleSettings>*/}

                <TextTitleSettingsMobile>ÚLTIMAS HORAS: <TextTitleItemSettingsMobile>{rendered.lasthours}H</TextTitleItemSettingsMobile></TextTitleSettingsMobile>

                <TextTitleGreenMobile>GREEN: {rendered.green}%</TextTitleGreenMobile>

                <TextTitleRedMobile>RED: {rendered.red}%</TextTitleRedMobile>

            </ContainerSettings>

            <BoxTable>
                
                <ContainerGoalsVertical>

                    <ItemGloalsVerticalLgMobile>G</ItemGloalsVerticalLgMobile>

                    {rendered.verticalData.map((item, index) => {
                        return(
                            < ItemGloalsVerticalMobile key={index} >{item.totalGoals}</ItemGloalsVerticalMobile>
                        )
                    })}

                    <ItemGloalsVerticalLgMobile></ItemGloalsVerticalLgMobile>
                    <ItemGloalsVerticalLgMobile></ItemGloalsVerticalLgMobile>

                </ContainerGoalsVertical>

                <ContainerGreenVertical>

                    <ItemGreenVerticalLgMobile></ItemGreenVerticalLgMobile>

                    {rendered.verticalData.map((item, index) => {
                        return(
                            <ItemGreenVerticalMobile value={item.greensPercentage} key={index}>{item.totalGreens}</ItemGreenVerticalMobile>
                        )
                    })}

                    <ItemGreenVerticalLgMobile></ItemGreenVerticalLgMobile>
                    <ItemGreenVerticalLgMobile></ItemGreenVerticalLgMobile>

                </ContainerGreenVertical>

                
                <ContainerGreenPercentegeVertical>

                    <ItemGreenPercentegeVerticalLgMobile></ItemGreenPercentegeVerticalLgMobile>

                    {rendered.verticalData.map((item, index) => {
                        return(
                            <ItemGreenPercentegeVerticalMobile value={item.greensPercentage} key={index}>{item.greensPercentage}%</ItemGreenPercentegeVerticalMobile>
                        )
                    })}
                    
                    <ItemGreenPercentegeVerticalLgMobile></ItemGreenPercentegeVerticalLgMobile>
                    <ItemGreenPercentegeVerticalLgMobile></ItemGreenPercentegeVerticalLgMobile>

                </ContainerGreenPercentegeVertical>

                <ContainerOpacity>

                    <ContainerMinutes>

                        <ItemMinutesLgMobile>Hora</ItemMinutesLgMobile>

                        {rendered.verticalData.map((item, index) => {
                            return(
                                <ItemMinutesMobile key={index}>{item.minute}</ItemMinutesMobile>
                            )
                        })}

                        <ItemMinutesLgMobile>Dados</ItemMinutesLgMobile>

                        <ItemMinutesLgMobile>
                            <GiSoccerBall/>
                        </ItemMinutesLgMobile>

                    </ContainerMinutes>

                    {rendered.hours.map((item, index) =>{
                        return(
                            <ContainerHours key={index}>
                                <ItemHoursMobile>{item.hour}</ItemHoursMobile>
                                {
                                    item.games.map((element, i)=>{
                                        if(element.code !== "summary")
                                        {
                                            return(
                                                <ItemGameMobile 

                                                    key={i}
                                                    color={element.color}
                                                    bgSelect={getBgSelectColor(element.scoreboard)}
                                                    onClick={() => handleItemClick(element.scoreboard)}
                                                
                                                >
                                                    {element.scoreboard}
                                                </ItemGameMobile>
                                            )
                                        }

                                    })
                                }
                                
                                <ItemGreenPercentegeVerticalLgMobile value={item.greensPercentage}>{item.greenPercentage}%</ItemGreenPercentegeVerticalLgMobile>
                                <ItemGreenPercentegeVerticalLgMobile value={item.greensPercentage}>{item.totalGoals}</ItemGreenPercentegeVerticalLgMobile>

                            </ContainerHours>
                        )
                    })}

                </ContainerOpacity>                

            </BoxTable>

                <StrategyButtonMobile onClick={()=>{setOpenStrategy(true)}}>
                    {rendered.strategy}
                </StrategyButtonMobile>

                <ButtonUpdateMobile onClick={()=>{setRefersh(!refresh)}}>
                    <BsArrowClockwise size={40} color={'#fff'} />
                </ButtonUpdateMobile>

        </GlobalContainer>
    )
}