import styled from 'styled-components';


export const GlobalContainer = styled.div`

    width: 100%;
    height: 100vh;
    background-color: ${props => props.bg || '#fff'};
    overflow: hidden;
    background-color: #000;
    
`;

export const Header = styled.div`

    width: calc(100% - 70px);
    height: 70px;
    background: linear-gradient(to right, #000,#77F355);
    padding-left: 70px;
    display: flex;
    justify-content: space-between;
    align-items:center;
   
`

export const Sidebar = styled.div`

  width: ${props => (props.open ? '300px' : '70px')};
  height: 100vh; // altura total da viewport
  background-color: #484D45;
  transition: width 0.5s ease-in-out; 
  position:absolute;
  z-index:999;
  top:0

`;

export const ButtonSide = styled.div` 

    width: ${props => (props.open ? 'calc (100% - 20px)' : '100%')};
    height: 50px;
    background-color: ${props=>props.bg || 'transparent'};
    border: none;
    display: flex;
    align-items:center;
    justify-content: ${props => (props.open ? 'start' : 'center')};
    cursor:pointer;
    margin-top: ${props=>props.mt || "10px"};
    padding-left: ${props => (props.open ? '20px' : '0px')};

    &:hover {

        background-color: #707070;
    }

`
export const MainContainer = styled.div`

    width: calc(100% - 70px);
    height: auto;
    max-height: calc(100vh - 70px);
    background-color: transparent;
    margin-left: 70px;
    overflow:auto;
    margin-top: 80px;

`

export const BoxChampionship  = styled.button`

    width: 100%;
    height: 55px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    padding-left: 10px;
    padding-right: 10px;

`

export const BoxChampionshipHeader  = styled.button`

    width: 300px;
    height: 55px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    margin-left: 150px;

    @media (max-width: 900px) {
        width: 100%;
        margin-left: 0px;
    }

`

export const BoxDropDown = styled.div`

    width: ${props => props.dp || '0px'};
    height: 280px;
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    top:10px;
    transition: height 0.5s ease-in-out;
    z-index: 1;
`

export const BtnChanpionship = styled.button`

    width: 95%;
    height: 50px;
    background-color: transparent;
    border-radius: 5px;
    &:hover{
        background-color: #ccc;
    }
    display: flex;
    justify-content:center;
    align-items:center;
    cursor: pointer;
    margin-left: 2.5%;
    mergin-top: 10px;

`

export const ImgChanpionship = styled.img`

    width: 25px;
    height: 25px;
    margin-right: 10px;

`
export const BoxHeaderGradient = styled.div`

    width: ${props=>props.w || "100%"};
    height: ${props=>props.h || "70px"};
    margin-top: ${props=>props.mt || "opx"};
    background: linear-gradient(to bottom, #fff, #77F355);
    display:flex;
    justify-content:center;
    align-items:center;

`

export const BtnHourFilter = styled.button`
    width: 35px;
    height: 35px;
    margin-left: ${props=>props.ml || "0px"};
    background-color: ${props=>props.bg || "#fff"};
    font-weight: bold;
    color: ${props=>props.color || "#000"};
    cursor: pointer;

`

export const BtnOdds = styled.button`
    width: 60px;
    height: 30px;
    border-left-style: solid;
    border-left-width: 5px;
    border-left-color: ${props=>props.bblColor || "#77F355"};
    border-radius: 5px;
    margin-left: ${props=>props.ml || "0px"};

`

export const ImageBandeira = styled.img`
    width: ${props=>props.w || '50px'};
    height: ${props=>props.h || '50px'};
`

export const BtnNextGames = styled.button`

    width: 170px;
    height: 50px;
    background-color: ${props=>props.bg || '#316227'};
    border-radius: 10px;
    margin-top: ${props=>props.mt || '0px'};
`

export const ContainerCalc = styled.div`

    width: calc(100% - ${props=>props.wc || '0px'});
    background-color: ${props => props.bg || '#fff'};  
    margin-top: 20px;
    padding: 16px;  
    
`;

export const BtnAmountGames = styled.button`

    width: 350px;
    height: 50px;
    background-color: rgba(240,240,240, 0.8);
    border-radius: 5px;
`

export const BtnGeralStatus = styled.button`

    width: 350px;
    height: 50px;
    background-color: rgba(240,240,240, 0.8);
    border-radius: 5px;


`

export const BtnStatusAway = styled.button`

    width: 350px;
    height: 50px;
    background-color: rgba(240,240,240, 0.8);
    border-radius: 5px;
`
export const Box = styled.div`
    width: 30px;
    height: 30px;
    border: 2px solid ${props=>props.bbbc || '#ccc'};
    margin-right: 15px;
    display:flex;
    justify-content: center;
    align-items:center;

`

export const InputSearch = styled.input`
    width: ${props=>props.w || '400px'};
    height: ${props=>props.h || '70px'};
    border-radius: 5px;
    padding-left: 10px;
    font-size: 20px;


`

export const ContainerMobileHide = styled.div`

    width: 100%;

    @media (max-width: 768px) {
        display: none;
    }

`

export const ContainerMobileShow = styled.div`

    width: 100%;
    max-height: calc(100vh - 60px);
    overflow: auto;
    display: none;
    margin-top: 20px;

    @media (max-width: 768px) {
        display: block;
    }

`

export const HeaderMobile = styled.div`

    width: calc(100% - 30px);
    height: 70px;
    background: linear-gradient(to right, #000,#77F355);
    padding-left: 30px;
    display: flex;
    justify-content: space-between;
    align-items:center;
   
`

export const SideBarMobile = styled.div`
    width: ${props => (props.open ? '100%' : '0%')};
    transition: width 0.5s ease-in-out; 
    height: 100vh;
    background-color: #484D45;
    position: absolute;
    z-index: 999;
    top:0;
    left:0;
    
`

export const ButtomMenu = styled.div`

    width: 95%;
    height: 40px;
    margin-left: 2.5%;
    border: 2px solid #77F355;
    border-radius: 5px;
    display: flex;
    justify-content:center;
    align-items:center;
    margin-top: 10px;

    &:hover{
        background-color: #000;
    }

`

export const ContainerMobileOverflow = styled.div`
    width: 95%;
    overflow: auto;
    max-width: 100%;
    margin-top: 20px;
    margin-left: 2.5%;
   
`

export const LogoMobile = styled.img`

    width: 50px;
    height: 50px;
    margin-right: 30px;

`

export const BtnNextGamesMobile = styled.button`

    width: 130px;
    height: 30px;
    background-color: ${props=>props.bg || '#316227'};
    border-radius: 5px;
    margin-top: ${props=>props.mt || '0px'};
    margin-left: 5px;
`

