import { useState } from "react";
import { BtnIndicators, Tooltip } from "../style";
import { Text } from "../../../componets/styles";

export default function TooltipComponent({item, arrayColorSelect, setArrayColorSelect, updateColors, arrayMinutesColorSelect})
{
    const [showTooltip, setShowTooltip] = useState(false);

    const predefinedColors = ['#0000ff', '#ff00ff', '#ffff00', '#00ffff', '#808080', '#800080', '#008080'];

    function generateUniqueColor(arr) {
        let color;
        const existingColors = arr.map(obj => obj.color);
      
        do {
          // Selecionar uma cor aleatória da lista predefinida
          color = predefinedColors[Math.floor(Math.random() * predefinedColors.length)];
        } while (existingColors.includes(color));
      
        return color;
    }

    const findColorByItem = (arr, item) => {
        const found = arr.find(obj => obj.item === item);
        return found ? found.color : "transparent";
    };

    function onArrayColorSelect(item) {
        // Fazendo uma cópia profunda do array para evitar mutações diretas no estado
        const arr = JSON.parse(JSON.stringify(arrayColorSelect));
      
        // Procurando o índice do item
        const index = arr.findIndex(obj => obj.item === item);
      
        if (index === -1) {
          // Item não encontrado, adicioná-lo
          const color = generateUniqueColor(arr);  // Gere uma cor única
          arr.push({item, color});
        } else {
          // Item encontrado, retirá-lo
          arr.splice(index, 1);
        }
      
        // Atualizando o estado e as cores
        setArrayColorSelect(arr);
        updateColors();
    }

    function onVerifikedMinutesArray(item)
    {

        const arr = JSON.parse(JSON.stringify(arrayMinutesColorSelect));

        if(arr.length === 0){
            return true
        } 

        const index = arr.indexOf(item);


        if (index === -1) {

            return false;

          } else {
            return true;
          }
    }
      



    return(

        <>
            <BtnIndicators 

                onMouseOver={()=>{setShowTooltip(true)}} 

                onMouseOut={()=>{setShowTooltip(false)}}

                bgselect={findColorByItem(arrayColorSelect, item.scoreboard)}

                onClick={()=>{onArrayColorSelect(item.scoreboard)}}  

                w="4.34%" bg={onVerifikedMinutesArray(item.minutes) ? item.color: "#707070"}

                
            
            >
 
                <div>
                    <Text fs="15px" fw="bold" col="#fff">
                        {item.scoreboard ? item.scoreboard : item.odds}
                    </Text>

                    {showTooltip &&
            
                        <Tooltip w="200px" h="40px"

                            onMouseOver={()=>{setShowTooltip(true)}} 

                            onMouseOut={()=>{setShowTooltip(false)}}

                            onClick={()=>{onArrayColorSelect(item.scoreboard)}} 
                        >

                            <Text fs="14px" col="#fff">{item.game}</Text>

                        </Tooltip>
                    }
                </div>

            </BtnIndicators>

        </>
        
    )
}