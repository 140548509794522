import { useContext } from "react";
import { Container, Text } from "../componets/styles";
import { WHATS_APP_NUMBER } from "../config";
import { BtnLogout, GlobalContainer, ImgLogo } from "./styled";
import QRCode from 'qrcode.react';
import { IoLogoWhatsapp } from 'react-icons/io';
import AuthContext from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

export default function Payment()
{
    const {user, logout} = useContext(AuthContext);
    const navigate = useNavigate();

    function handleLogout()
    {
        logout();
        navigate("/");
    }

    return(
        <GlobalContainer>

            <Container w='100%' display='flex' jc='end'>
                <BtnLogout onClick={handleLogout}>
                    <Text col='#fff' fs='20px' fw='bold'>SAIR</Text>
                </BtnLogout>
            </Container>

            <Container w='100%' display='flex' jc='center'>
                <ImgLogo src='./images/logo.png'/>
            </Container>

            <Container  w='100%' display='flex' jc='center'>
                <Text col='#fff' fw='bold' fs='50px'>Você ainda não está ativo!</Text>
            </Container>
{/**
 * 
 *          <Container w='100%' display='flex' jc='center' mt='50px'>
                <QRCode value={`https://api.whatsapp.com/send?phone=${WHATS_APP_NUMBER}&text=${encodeURIComponent(`Olá meu nome é ${user.name} gostaria de saber como ativar minha conta na plataforma meu email é: ${user.email}`)}`} size={300}/>
            </Container>
 * 
 * 
 */}


            <Container  w='100%' display='flex' jc='center' items='center' mt='50px'>
                <Text mr='20px' col='#fff' fw='bold' fs='20px'>Para me chamar no whats pelo celular app ou clique aqui para web {'>>'}.</Text>
                <a target="blank_" href={`https://wa.me/${WHATS_APP_NUMBER}?text=${encodeURIComponent(`Olá meu nome é ${user.name} gostaria de saber como ativar minha conta na plataforma meu email é: ${user.email}`)}`}>
                    <IoLogoWhatsapp color="#25D366" size={50}/>
                </a>
                
            </Container>

        </GlobalContainer>
    )
}