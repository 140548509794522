
import * as React from "react";
import {createBrowserRouter,RouterProvider,} from "react-router-dom";

import Auth from "../auth";
import Login from "../auth/login";
import Register from "../auth/register";
import Loading from "../loading";
import Redefine from "../auth/redefine";
import NewPassword from "../auth/newpassword";

const router = createBrowserRouter([

  {
    path: "/",
    element: <Auth/>,
    children: [
      { path: "/", element: <Login/>},
      { path: "register", element: <Register />},
      { path: "redefine", element: <Redefine />},
      { path: "newpassword", element: <NewPassword />},
      { path: "payment", element: <Loading />},
      { path: "analysis", element: <Loading />},
      { path: "home", element: <Loading />},
      { path: "videos", element: <Loading />},
      { path: "robos", element: <Loading />},
    ],
  },
]);


export default function AuthRouter()
{
    return(

        <React.StrictMode>
            <RouterProvider router={router} />
        </React.StrictMode>
    )
      
}