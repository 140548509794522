
import { useContext, useEffect, useState } from 'react';
import {ButtomMenu, ButtonSide, ContainerMobileHide, ContainerMobileOverflow, ContainerMobileShow, GlobalContainer, Header, HeaderMobile, ImgChanpionship, LogoMobile, MainContainer, SideBarMobile, Sidebar} from './style';
import { BsList, BsSpeedometer2, BsGraphUp, BsPlayCircle, BsXLg, BsTags } from 'react-icons/bs';
import { useNavigate } from "react-router-dom";
import { ButtonStyled, Container, ImageLogo, Text } from '../componets/styles';
import AuthContext from '../context/AuthContext';
import { formatarData } from '../share/functions';
import axios from 'axios';
import { API_URL } from '../config';


export default function Videos()
{
    const navigate = useNavigate();

    const {verifieldLogin, user, logout} = useContext(AuthContext);

    const [isSideOpen, setIsSideOpen] = useState(false);

    const [videosList, setVideosList] = useState([]);

    const [isBoxDropDown, setIsBoxDropDown] = useState(false);

    const [selectChampions, setSelectChampions] = useState({id:1, name: 'Copa do mundo', img:'./images/copa-do-mundo.png', margin:'25px'})
   
    const [sideBarMobile, setSideBarMobile] = useState(false);

    const CHAMPIONS_SHIP = [

        {id:1, name: 'Copa do mundo', img:'./images/copa-do-mundo.png', margin:'25px'},
        {id:2, name: 'Euro copa', img:'./images/euro-copa.png', margin:'10px'},
        {id:3, name: 'Super Liga Super Americana', img:'./images/conmebol-sul-americana.png', margin:'10px'},
        {id:4, name: 'Premier league', img:'./images/premier-league.png', margin:'10px'}
    ]

    function handleChampionsShip(item)
    {
        
        setSelectChampions(item)
        setIsBoxDropDown(false);

    }

    function visibleDrop() {
        setIsBoxDropDown(prev => !prev);
    }

    async function handleverifieldLogin()
    {
        const status = await verifieldLogin();

        console.log(status)

        if(status === 'Inativo')
        {
          navigate("/payment");
        }
    }

    async function handleVideos()
    {
        const response = await axios.get(`${API_URL}/videos`);
        setVideosList(response.data)
        console.log(response);
    }

    useEffect(()=>{
        handleverifieldLogin();
        handleVideos();
    },[])


    function limitCharacters(input, maxLength) {
        if (input.length <= maxLength) {
          return input;
        }
        return input.substring(0, maxLength) + "...";
    }


    return(
        <GlobalContainer>

            <ContainerMobileHide>

                <Header>

                    <Container w='100px'>
                        <ImageLogo src='./images/logo.png'/>
                    </Container>

                    <Container w='auto' display='flex' mr="20px" items='center'>
                        <Container w='auto' display='flex' mr="20px" >
                            <Text col='#022c22' fs='20px' fw='bold'>{`Bem vindo! ${user.name} |`}</Text><Text ml='10px' col='#e11d48' fs='20px' fw='bold'>{ `${formatarData(user.validation)}`}</Text>
                        </Container>


                        <ButtonStyled onClick={logout} w='150px' mr='30px' bg='transparent' border='1px solid #fff'>
                            <Text col="#fff" fw='bold' fs='20px'>Sair</Text>
                        </ButtonStyled>
                    </Container>

                </Header>

                <Sidebar open={isSideOpen}>

                    <ButtonSide open={isSideOpen} onClick={()=>{setIsSideOpen(!isSideOpen)}}>
                        <BsList col='#fff' size={45}/>
                        {isSideOpen && <Text col="#fff" fw="bold" ml="10px">Menu</Text>}
                    </ButtonSide>

{/** 
                        <ButtonSide open={isSideOpen}  mt="50px" onClick={()=>{navigate("/home")}}>
                            <BsSpeedometer2 col='#fff' size={35}/>
                            {isSideOpen && <Text col="#fff" fw="bold" ml="10px">Painel</Text>}
                            
                        </ButtonSide>
*/}

                    <ButtonSide open={isSideOpen} mt="30px" onClick={()=>{navigate("/analysis")}}>
                        <BsGraphUp col='#fff' size={33}/>
                        {isSideOpen && <Text col="#fff" fw="bold" ml="10px">Análise</Text>}
                    </ButtonSide>

                    <ButtonSide open={isSideOpen} bg="#707070" mt="30px" onClick={()=>{navigate("/videos")}}>
                        <BsPlayCircle  col='#fff' size={35}/>
                        {isSideOpen && <Text col="#fff" fw="bold" ml="10px">Vídeos</Text>}
                    </ButtonSide>
{/**
 * 
 * 
 *                     <ButtonSide open={isSideOpen} mt="30px" onClick={()=>{navigate("/robos")}}>
                        <BsTags  col='#fff' size={35}/>
                        {isSideOpen && <Text col="#fff" fw="bold" ml="10px">Robôs</Text>}
                    </ButtonSide>
 * 
 * 
 */}


                </Sidebar>

                <MainContainer>
                   
                        {
                            videosList.map((item,index)=>{
                                return(
                                    <>
                                    <Container w='100%' display='flex' jc='center' mt='50px'>
                                        <Text col='#7dd3fc' fw='bold' fs='30px'>{item.title}</Text>
                                    </Container>

                                    <Container w='100%' display='flex' jc='center' mt='20px'>
                                        <iframe width="800" height="400" src={item.link} frameborder="0" allowfullscreen></iframe>
                                    </Container>

                                    <Container w='100%' display='flex' jc='center' mt='20px'>
                                        <Container w='50%'>
                                            <Text col='#fff' fw='bold' fs='20px'>{item.description}</Text>
                                        </Container>
                                    </Container>
                                    </>
                                    )
                            })
                        }

                    


                </MainContainer>

            </ContainerMobileHide>

           <ContainerMobileShow>

           <HeaderMobile>

                <ButtonStyled onClick={()=>{setSideBarMobile(true)}} bg='transparent' w='auto'>
                    <BsList color='#fff' size={30}/>
                </ButtonStyled>

                <Container w='auto' display='flex' >
                <Text ml='5px' col='#e11d48' fs='15px' fw='bold'>{ `${formatarData(user.validation)}`}</Text>
                </Container>

                <LogoMobile src='./images/logo.png'/>

            </HeaderMobile>


                <SideBarMobile open={sideBarMobile}>

                    <Container w='100%' display='flex' jc='flex-end'>
                        <ButtonStyled onClick={()=>{setSideBarMobile(false)}} bg='transparent' w='auto' mr='20px'>
                            <BsXLg color='#fff' size={30}/>
                        </ButtonStyled>
                    </Container>

                    <Container w='100%' mt='40px'>

                        <ButtomMenu onClick={()=>{navigate("/home")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Dashboard</Text>
                        </ButtomMenu>
                        <ButtomMenu onClick={()=>{navigate("/analysis")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Análises</Text>
                        </ButtomMenu>
                        <ButtomMenu onClick={()=>{navigate("/videos")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Videos</Text>
                        </ButtomMenu>

                        {/**
                         * 
                                                 <ButtomMenu onClick={()=>{navigate("/offers")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Ofertas</Text>
                        </ButtomMenu>* 
                         * 
                         * 
                         */}


                    </Container>

                </SideBarMobile>

                <ContainerMobileOverflow>

                <Container display='flex' wp='wrap'>
                    {
                            videosList.map((item,index)=>{
                                return(

                                    <>
                                    <Container w='100%' display='flex' jc='center' mt='20px'>
                                        <Text col='#7dd3fc' fw='bold' fs='20px'>{item.title}</Text>
                                    </Container>

                                    <Container w='100%' display='flex' jc='center' mt='20px'>
                                        <iframe width="300" height="150" src={item.link} frameborder="0" allowfullscreen></iframe>
                                    </Container>

                                    <Container w='100%' display='flex' jc='center' mt='20px'>
                                        <Container w='70%'>
                                            <Text col='#fff' fw='bold' fs='15px'>{item.description}</Text>
                                        </Container>
                                    </Container>
                                    </>

                                    )
                            })
                        }             
                    </Container>

                </ContainerMobileOverflow>

            </ContainerMobileShow>

        </GlobalContainer>
    )
}