import styled from 'styled-components';


export const GlobalContainer = styled.div`

    width: 100%;
    height: 100vh;
    background-color: ${props => props.bg || '#fff'};
    overflow: auto;
    background-color: #000;
    
`;

export const Header = styled.div`

    width: calc(100% - 70px);
    height: 70px;
    background: linear-gradient(to right, #000,#77F355);
    padding-left: 70px;
    display: flex;
    justify-content: space-between;
    align-items:center;
   
`

export const Sidebar = styled.div`

  width: ${props => (props.open ? '300px' : '70px')};
  height: 100vh; // altura total da viewport
  background-color: #484D45;
  transition: width 0.5s ease-in-out; 
  position:absolute;
  z-index:999;
  top:0

`;

export const ButtonSide = styled.div` 

    width: ${props => (props.open ? 'calc (100% - 20px)' : '100%')};
    height: 50px;
    background-color: ${props=>props.bg || 'transparent'};
    border: none;
    display: flex;
    align-items:center;
    justify-content: ${props => (props.open ? 'start' : 'center')};
    cursor:pointer;
    margin-top: ${props=>props.mt || "10px"};
    padding-left: ${props => (props.open ? '20px' : '0px')};

    &:hover {

        background-color: #707070;
    }

`

export const MainContainer = styled.div`
    width: calc(100% - 70px);
    height: auto;
    background-color: transparent;
    margin-left: 70px;

`
export const BoxChampionship  = styled.button`
    width: 100%;
    height: 55px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    padding-left: 10px;
    padding-right: 10px;

`
export const BoxDropDown = styled.div`

    width: ${props => props.dp || '0px'};
    height: 280px;
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    top:10px;
    transition: height 0.5s ease-in-out;
    z-index: 1;
`

export const BtnChanpionship = styled.button`
    width: 95%;
    height: 50px;
    background-color: transparent;
    border-radius: 5px;
    &:hover{
        background-color: #ccc;
    }
    display: flex;
    justify-content:center;
    align-items:center;
    cursor: pointer;
    margin-left: 2.5%;
    mergin-top: 10px;

`

export const ImgChanpionship = styled.img`
    width: 30px;
    height: 30px;
    margin-right: 10px;
`

export const ImgLogo = styled.img`
    width: 300px;
    height: 300px;
`
export const BtnLogout = styled.button`
    width: 200px;
    height: 50px;
    background-color: #097469;
    baorder-radius: 10px;
    margin-right: 20px;
    margin-top: 10px;
    

`