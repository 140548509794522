
export const SHOW = [
    {id: 1, name: "Fim do Jogo"},
    {id: 2, name: "Primeiro Tempo"},
]

export const STRATEGYS = [
    {id: 1, name: "Ambas Marcam (SIM)", param: true},
    {id: 2, name: "Ambas Marcam (NÃO)", param: true},
    {id: 3, name: "Mandante Marca", param: false},
    {id: 4, name: "Visitante Marca", param: false},
    {id: 5, name: "Over 0.5", param: false},
    {id: 6, name: "Over 1.5", param: false},
    {id: 7, name: "Over 2.5", param: false},
    {id: 8, name: "Over 3.5", param: false},
    {id: 9, name: "Under 0.5", param: false},
    {id: 10, name: "Under 1.5", param: false},
    {id: 11, name: "Under 2.5", param: false},
    {id: 12, name: "Under 3.5", param: false},
    {id: 13, name: "0 Gols", param: false},
    {id: 14, name: "1 Gols", param: false},
    {id: 15, name: "2 Gols", param: false},
    {id: 16, name: "3 Gols", param: false},
    {id: 17, name: "4 Gols", param: false},
    {id: 18, name: "+5 Gols", param: false},
    {id: 19, name: "Gols Par", param: false},
    {id: 20, name: "Gols Ímpar", param: false},
    {id: 21, name: "Casa 1x0, 2x0, 2x1", param: false},
    {id: 22, name: "Casa 3x0, 3x1, 4x0", param: false},
    {id: 23, name: "Casa qualquer outro", param: false},
    {id: 24, name: "Empate 0x0", param: false},
    {id: 25, name: "Empate 1x1 , 2x2", param: false},
    {id: 26, name: "Empate 3x3, 4x4", param: false},
    {id: 27, name: "Visitante 0x1, 0x2, 1x2", param: false},
    {id: 28, name: "Visitante 0x3, 1x2, 0x4", param: false},
    {id: 29, name: "Visitante qualquer outro", param: false},

]