import styled from 'styled-components';

export const Container = styled.div`

    width: ${props => props.w || '100%'};
    height: ${props => props.h || 'auto'};

    max-height: ${props => props.maxh || 'none'};
    overflow: ${props => props.ovfw || 'hidden'};

    margin-top: ${props => props.mt || '0px'};
    margin-right: ${props => props.mr || '0px'};
    margin-left: ${props => props.ml || '0px'};
    margin-bottom: ${props => props.mb || '0px'};

    padding-left: ${props=> props.pl || '0px'};
    padding-right: ${props=> props.pr || '0px'};
    padding-top: ${props=> props.pt || '0px'};
    padding-bottom: ${props=> props.pb || '0px'};

    display: ${props=> props.display || "block"};
    justify-content: ${props=>props.jc || "start"};
    align-items: ${props => props.items || "start"};

    border-top-width: ${props => props.bbtw || 'none'};
    border-top-style: ${props => props.bbts || 'none'};
    border-top-color: ${props => props.bbtc || 'none'};

    border-left-width: ${props => props.bblw || 'none'};
    border-left-style: ${props => props.bbls || 'none'};
    border-left-color: ${props => props.bblc || 'none'};

    border-right-width: ${props => props.bbrw || 'none'};
    border-right-style: ${props => props.bbrs || 'none'};
    border-right-color: ${props => props.bbrc || 'none'};

    border-bottom-width: ${props => props.bbbw || 'none'};
    border-bottom-style: ${props => props.bbbs || 'none'};
    border-bottom-color: ${props => props.bbbc || 'none'};

    border-radius: ${props => props.rd || '0px'};

    background-color: ${props=> props.bg || 'transparent'};

    flex-direction: ${props=> props.fd || "row"};

    background-color: ${props=>props.bg || "none"};

    position: ${props => props.pos || "static"};

    flex-wrap: ${props=> props.wp || "nowrap"};

`

export const ContainerLink = styled.a`

    width: ${props => props.w || '100%'};
    height: ${props => props.h || 'auto'};

    max-height: ${props => props.maxh || 'none'};
    overflow: ${props => props.ovfw || 'hidden'};

    margin-top: ${props => props.mt || '0px'};
    margin-right: ${props => props.mr || '0px'};
    margin-left: ${props => props.ml || '0px'};
    margin-bottom: ${props => props.mb || '0px'};

    padding-left: ${props=> props.pl || '0px'};
    padding-right: ${props=> props.pl || '0px'};
    padding-top: ${props=> props.pt || '0px'};
    padding-bottom: ${props=> props.pb || '0px'};

    display: ${props=> props.display || "block"};
    justify-content: ${props=>props.jc || "start"};
    align-items: ${props => props.items || "start"};

    border-top-width: ${props => props.bbtw || 'none'};
    border-top-style: ${props => props.bbts || 'none'};
    border-top-color: ${props => props.bbtc || 'none'};

    border-left-width: ${props => props.bblw || 'none'};
    border-left-style: ${props => props.bbls || 'none'};
    border-left-color: ${props => props.bblc || 'none'};

    border-right-width: ${props => props.bbrw || 'none'};
    border-right-style: ${props => props.bbrs || 'none'};
    border-right-color: ${props => props.bbrc || 'none'};

    border-bottom-width: ${props => props.bbbw || 'none'};
    border-bottom-style: ${props => props.bbbs || 'none'};
    border-bottom-color: ${props => props.bbbc || 'none'};

    border-radius: ${props => props.rd || '0px'};

    background-color: ${props=> props.bg || 'transparent'};

    flex-direction: ${props=> props.fd || "row"};

    background-color: ${props=>props.bg || "none"};

    position: ${props => props.pos || "static"};

    flex-wrap: ${props=> props.wp || "nowrap"};

`

export const GlobalModalContainer = styled.div`

    width: ${props => props.w || '100%'};
    height: ${props => props.h || 'auto'};

    max-height: ${props => props.maxh || 'none'};
    overflow: ${props => props.ovfw || 'hidden'};

    margin-top: ${props => props.mt || '0px'};
    margin-right: ${props => props.mr || '0px'};
    margin-left: ${props => props.ml || '0px'};
    margin-bottom: ${props => props.mb || '0px'};

    padding-left: ${props=> props.pl || '0px'};
    padding-right: ${props=> props.pl || '0px'};
    padding-top: ${props=> props.pt || '0px'};
    padding-bottom: ${props=> props.pb || '0px'};

    display: ${props=> props.display || "block"};
    justify-content: ${props=>props.jc || "start"};
    align-items: ${props => props.items || "start"};

    border-top-width: ${props => props.bbtw || 'none'};
    border-top-style: ${props => props.bbts || 'none'};
    border-top-color: ${props => props.bbtc || 'none'};

    border-left-width: ${props => props.bblw || 'none'};
    border-left-style: ${props => props.bbls || 'none'};
    border-left-color: ${props => props.bblc || 'none'};

    border-right-width: ${props => props.bbrw || 'none'};
    border-right-style: ${props => props.bbrs || 'none'};
    border-right-color: ${props => props.bbrc || 'none'};

    border-bottom-width: ${props => props.bbbw || 'none'};
    border-bottom-style: ${props => props.bbbs || 'none'};
    border-bottom-color: ${props => props.bbbc || 'none'};

    border-radius: ${props => props.rd || '0px'};

    background-color: ${props=> props.bg || 'transparent'};

    flex-direction: ${props=> props.fd || "row"};

    position: ${props => props.pos || "static"};

    z-index: ${props => props.ind || "0"};

    background-color: ${props=>props.bg || "none"};

    top: ${props=>props.tp || "0"};

`

export const Text = styled.span`

    font-size: ${props=>props.fs || "16px"};
    color: ${props=>props.col || "#707070"};
    font-weight: ${props=>props.fw || "normal"};
    margin-top: ${props => props.mt || '0px'};
    margin-right: ${props => props.mr || '0px'};
    margin-left: ${props => props.ml || '0px'};
    margin-bottom: ${props => props.mb || '0px'};
    
`

export const ButtonStyled = styled.button`
    width: ${props => props.w || '100%'};
    height: ${props => props.h || '50px'};
    background-color: ${props=>props.bg || "#14b8a6"};
    display: ${props=> props.display || "flex"};
    justify-content: ${props=>props.jc || "center"};
    align-items: ${props => props.items || "center"};
    border:${props=> props.border || 'none'};
    cursor:pointer;
    margin-top: ${props=>props.mt || "0px"};
    margin-left: ${props=>props.ml || "0px"};
    margin-right: ${props=>props.mr || "0px"};
    border-radius: 5px;

`

export const ContainerRelative = styled.div`

    width: 4.34%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
`
export const ImageLogo = styled.img`
    width: 200px;
    height:200px;
    position:absolute;
    top:-20px;
    left: 50px;


`