import { useState } from "react";
import { BtnIndicators, BtnIndicatorsMobile, Tooltip } from "../style";
import { Text } from "../../../componets/styles";

export default function TooltipComponentMobile({item, arrayColorSelect, setArrayColorSelect, updateColors, arrayMinutesColorSelect})
{
    const [showTooltip, setShowTooltip] = useState(false);

    const predefinedColors = ['#5eead4', '#a5f3fc', '#c7d2fe', '#e9d5ff', '#4f46e5', '#d1fae5', '#1e3a8a'];

    function generateUniqueColor(arr) {
        let color;
        const existingColors = arr.map(obj => obj.color);
      
        do {
          // Selecionar uma cor aleatória da lista predefinida
          color = predefinedColors[Math.floor(Math.random() * predefinedColors.length)];
        } while (existingColors.includes(color));
      
        return color;
    }

    const findColorByItem = (arr, item) => {
        const found = arr.find(obj => obj.item === item);
        return found ? found.color : "transparent";
    };

    function onArrayColorSelect(item) {
        // Fazendo uma cópia profunda do array para evitar mutações diretas no estado
        const arr = JSON.parse(JSON.stringify(arrayColorSelect));
      
        // Procurando o índice do item
        const index = arr.findIndex(obj => obj.item === item);
      
        if (index === -1) {
          // Item não encontrado, adicioná-lo
          const color = generateUniqueColor(arr);  // Gere uma cor única
          arr.push({item, color});
        } else {
          // Item encontrado, retirá-lo
          arr.splice(index, 1);
        }
      
        // Atualizando o estado e as cores
        setArrayColorSelect(arr);

        updateColors();
    }

    function onVerifikedMinutesArray(item)
    {

        const arr = JSON.parse(JSON.stringify(arrayMinutesColorSelect));

        if(arr.length === 0){
            return true
        } 

        const index = arr.indexOf(item);


        if (index === -1) {

            return false;

          } else {
            return true;
          }
    }

    function limp(string)
    {
        if(string !== null)
        {
            return string.replace(/\s+/g, '');
        }
        return '';
    }
      



    return(

        <>
            <BtnIndicatorsMobile

                onMouseOver={()=>{setShowTooltip(true)}} 

                onMouseOut={()=>{setShowTooltip(false)}}

                bgselect={findColorByItem(arrayColorSelect, item.scoreboard)}

                bg={onVerifikedMinutesArray(item.minutes) ? item.color: "red"}

                onClick={()=>{onArrayColorSelect(item.scoreboard)}}  

                w="4.34%"

                

                
            
            >
                <div>
                    <Text fs="5px" fw="bold" col="#fff">
                        {item.scoreboard ? item.scoreboard : item.odds}
                    </Text>

                    {showTooltip &&
            
                        <Tooltip w="200px" h="40px"

                            onMouseOver={()=>{setShowTooltip(true)}} 

                            onMouseOut={()=>{setShowTooltip(false)}}

                            onClick={()=>{onArrayColorSelect(item.scoreboard)}} 
                        >

                            <Text fs="14px" col="#fff">{item.game}</Text>

                        </Tooltip>
                    }
                </div>
            </BtnIndicatorsMobile>

        </>
        
    )
}