import styled from 'styled-components';

export const GlobalContainer = styled.div`

    width: 100%;
    height: 100vh;
    background-color: ${props => props.bg || '#fff'};
    overflow: auto;
    background-color: #000;
    
`;
