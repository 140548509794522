
import StrategyEnd from './StrategyEnd';
import StrategyInterval from './StrategyInteval';

import moment from 'moment';


class HourHelp 
{
    static fillMissingMinutes(arr, hour, championship) {
        // Converta os tempos no array fornecido para minutos como números inteiros
        const minutesFromArr = arr.map(item => {
            const timeParts = item.time.split(":");
            return parseInt(timeParts[1]);
        });

        const result = [];

        switch (championship) {
            case "Copa do Mundo":

                for (let i = 1; i <= 59; i+=3) {
                    // Converta o minuto atual em uma string de tempo
                    const timeString = hour.padStart(2, '') + ':' + i.toString().padStart(2, '0');

                    let scoreboard = null;
                    let color = '#707070';
                
                    if (!minutesFromArr.includes(i)) {
                        // Se o minuto atual não estiver no array original, adicione um objeto com dados falsos
                        result.push({
                            id: null,
                            time: timeString,
                            data: false,
                            scoreboard: scoreboard,
                            game: null,
                            goals: null,
                            color: color,
                            minutes: i.toString().padStart(2, '0')
                        });
                    } else {
                        // Caso contrário, adicione o objeto original ao resultado
                        const originalObj = arr.find(item => item.time.endsWith(timeString));
                        if (originalObj) {
                            result.push(originalObj);
                        } else {
                            console.warn("Não foi possível encontrar o item original para", timeString);
                        }
                        
                    }
                }
                
                break;

                case "Euro Cup":

                    for (let i = 2; i <= 59; i+=3) {
                        // Converta o minuto atual em uma string de tempo
                        const timeString = hour.padStart(2, '') + ':' + i.toString().padStart(2, '0');

                        let scoreboard = null;
                        let color = '#707070';



                        if (!minutesFromArr.includes(i)) {
                            
                            // Se o minuto atual não estiver no array original, adicione um objeto com dados falsos
                            result.push({
                                id: null,
                                time: timeString,
                                data: false,
                                scoreboard: scoreboard,
                                game: null,
                                goals: null,
                                color: color,
                                minutes: i.toString().padStart(2, '0')
                            });
                        } else {
                            // Caso contrário, adicione o objeto original ao resultado
                            const originalObj = arr.find(item => item.time.endsWith(timeString));
                            if (originalObj) {
                                result.push(originalObj);
                            } else {
                                console.warn("Não foi possível encontrar o item original para", timeString);
                            }
                            
                        }
                    }
                    
                    break;

                    case "Super Liga Sul-Americana":

                    for (let i = 1; i <= 58; i+=3) {
                        // Converta o minuto atual em uma string de tempo
                        const timeString = hour.padStart(2, '') + ':' + i.toString().padStart(2, '0');

                        let scoreboard = null;
                        let color = '#707070';

                      
                        if (!minutesFromArr.includes(i)) {
                            // Se o minuto atual não estiver no array original, adicione um objeto com dados falsos
                            result.push({
                                id: null,
                                time: timeString,
                                data: false,
                                scoreboard: scoreboard,
                                game: null,
                                goals: null,
                                color: color,
                                minutes: i.toString().padStart(2, '0')
                            });
                        } else {
                            // Caso contrário, adicione o objeto original ao resultado
                            const originalObj = arr.find(item => item.time.endsWith(timeString));
                            if (originalObj) {
                                result.push(originalObj);
                            } else {
                                console.warn("Não foi possível encontrar o item original para", timeString);
                            }
                            
                        }
                    }
                    
                    break;  

                    case "Premier League":

                    for (let i = 0; i <= 57; i+=3) {
                        // Converta o minuto atual em uma string de tempo
                        const timeString = hour.padStart(2, '') + ':' + i.toString().padStart(2, '0');

                        let scoreboard = null;
                        let color = '#707070';
            
                        if (!minutesFromArr.includes(i)) {
                            // Se o minuto atual não estiver no array original, adicione um objeto com dados falsos
                            result.push({
                                id: null,
                                time: timeString,
                                data: false,
                                scoreboard: scoreboard,
                                game: null,
                                goals: null,
                                color: color,
                                minutes: i.toString().padStart(2, '0')
                            });
                        } else {
                            // Caso contrário, adicione o objeto original ao resultado
                            const originalObj = arr.find(item => item.time.endsWith(timeString));
                            if (originalObj) {
                                result.push(originalObj);
                            } else {
                                console.warn("Não foi possível encontrar o item original para", timeString);
                            }
                            
                        }
                    }
                    
                    break;                    


            default:
                break;
        }
    
        return result;
    }

    static onOddsHomeEnd(odds, time, dateTime) {

        const maxDifference = 30;

        const timeSet = new Set(odds.map(item => item.time));

        if (timeSet.has(time)) {
            const found = odds.find(item => {
                const timeDiff = moment.duration(moment(item.date_time).diff(moment(dateTime))).asMinutes();
                return item.time === time && timeDiff <= maxDifference && timeDiff >= 0;
            });

            if (found) {
                return found.odds_home;
            } else {
                return "none";
            }
        }

        return "none";
    }

    static arrMinutesEnd(arrResults, hour , arrayHour, strategy)
    {
        const result = [];

        arrResults.forEach((item) => {
    
            const arrHour = item.time.split(":");
            const currentHour = arrHour[0];

           
    
            if (currentHour === hour) {
    
                let exists = false;

                let color;

                switch (strategy) {

                    case 1:
                        color = StrategyEnd.strategyPar(item)
                    break;
                    case 2:
                        color = StrategyEnd.strategyImpar(item)
                    break;
                    case 4:
                        color = StrategyEnd.strategyFirstMarkedHome(item)
                    break;
                    case 5:
                        color = StrategyEnd.strategyFirstMarkedNone(item)
                    break;
                    case 6:
                        color = StrategyEnd.strategyFirstMarkedAway(item)
                    break;  
                    case 8:
                        color = StrategyEnd.strategyLastMarkedHome(item)
                    break;
                    case 9:
                        color = StrategyEnd.strategyLastMarkedNone(item)
                    break;
                    case 10:
                        color = StrategyEnd.strategyLastMarkedAway(item)
                    break; 
                    case 12:
                        color = StrategyEnd.strategyGroupHomeOne(item)
                    break;  
                    case 13:
                        color = StrategyEnd.strategyGroupHomeTwo(item)
                    break;                                        
                    case 14:
                        color = StrategyEnd.strategyGroupHomeAnyOther(item)
                    break;  
                    case 15:
                        color = StrategyEnd.strategyGroupDrawOne(item)
                    break;                     
                    case 16:
                        color = StrategyEnd.strategyGroupDrawTwo(item)
                    break;                     
                    case 17:
                        color = StrategyEnd.strategyGroupDrawThree(item)
                    break; 
                    case 18:
                        color = StrategyEnd.strategyGroupAwayOne(item)
                    break; 
                    case 19:
                        color = StrategyEnd.strategyGroupAwayTwo(item)
                    break; 
                    case 20:
                        color = StrategyEnd.strategyGroupAwayAnyOther(item)
                    break; 
                    case 22:
                        color = StrategyEnd.strategyTurnsHomeHome(item)
                    break
                    case 23:
                        color = StrategyEnd.strategyTurnsHomeDraw(item)
                    break
                    case 24:
                        color = StrategyEnd.strategyTurnsHomeAway(item)
                    break  
                    case 25:
                        color = StrategyEnd.strategyTurnsDrawHome(item)
                    break
                    case 26:
                        color = StrategyEnd.strategyTurnsDrawDraw(item)
                    break
                    case 27:
                        color = StrategyEnd.strategyTurnsDrawAway(item)
                    break                       
                    case 28:
                        color = StrategyEnd.strategyTurnsAwayHome(item)
                    break
                    case 29:
                        color = StrategyEnd.strategyTurnsAwayDraw(item)
                    break
                    case 30:
                        color = StrategyEnd.strategyTurnsAwayAway(item)
                    break   
                    case 32:
                        color = StrategyEnd.strategyResultEndHome(item)
                    break                   
                    case 33:
                        color = StrategyEnd.strategyResultEndAway(item)
                    break  
                    case 34:
                        color = StrategyEnd.strategyResultEndDraw(item)
                    break  
                    case 36:
                        color = StrategyEnd.strategyResultIntervalHome(item)
                    break                   
                    case 37:
                        color = StrategyEnd.strategyResultIntervalAway(item)
                    break  
                    case 38:
                        color = StrategyEnd.strategyResultIntervalDraw(item)
                    break 

                    case 40:
                        color = StrategyEnd.strategyOver_0_5(item)
                    break                   
                    case 41:
                        color = StrategyEnd.strategyOver_1_5(item)
                    break  
                    case 42:
                        color = StrategyEnd.strategyOver_2_5(item)
                    break  
                    case 43:
                        color = StrategyEnd.strategyOver_3_5(item)
                    break  

                    case 45:
                        color = StrategyEnd.strategyUnder_0_5(item)
                    break  
                    case 46:
                        color = StrategyEnd.strategyUnder_1_5(item)
                    break                     
                    case 47:
                        color = StrategyEnd.strategyUnder_2_5(item)
                    break 
                    case 48:
                        color = StrategyEnd.strategyUnder_3_5(item)
                    break 

                    case 50:
                        color = StrategyEnd.strategyBothScoreYes(item)
                    break 
                    case 51:
                        color = StrategyEnd.strategyBothScoreNo(item)
                    break 

                    case 53:
                        color = StrategyEnd.strategyScoreHomeYes(item)
                    break 
                    case 54:
                        color = StrategyEnd.strategyScoreHomeNo(item)
                    break 

                    case 56:
                        color = StrategyEnd.strategyScoreAwayYes(item)
                    break 
                    case 57:
                        color = StrategyEnd.strategyScoreAwayNo(item)
                    break 

                    case 59:
                        color = StrategyEnd.strategyGolsExats_0_0(item)
                    break 
                    case 60:
                        color = StrategyEnd.strategyGolsExats_1_0(item)
                    break 
                    case 61:
                        color = StrategyEnd.strategyGolsExats_2_0(item)
                    break 
                    case 62:
                        color = StrategyEnd.strategyGolsExats_3_0(item)
                    break
                    case 63:
                        color = StrategyEnd.strategyGolsExats_4_0(item)
                    break 
                    case 64:
                        color = StrategyEnd.strategyGolsExats_5_0(item)
                    break
                    
                    case 66:
                        color = StrategyEnd.strategyNoneScore(item);
                    break 
                    case 67:
                        color = StrategyEnd.strategyCentroAvanteHome(item)
                    break
                    case 68:
                        color = StrategyEnd.strategyPontaDireitaHome(item)
                    break 
                    case 69:
                        color = StrategyEnd.strategyPontaEsquerdaHome(item)
                    break 
                    case 70:
                        color = StrategyEnd.strategyMeiaAtacanteHome(item)
                    break
                    case 71:
                        color = StrategyEnd.strategyQualquerOutroHome(item)
                    break
                    case 72:
                        color = StrategyEnd.strategyCentroAvanteAway(item)
                    break
                    case 73:
                        color = StrategyEnd.strategyPontaDireitaAway(item)
                    break 
                    case 74:
                        color = StrategyEnd.strategyPontaEsquerdaAway(item)
                    break 
                    case 75:
                        color = StrategyEnd.strategyMeiaAtacanteAway(item)
                    break
                    case 76:
                        color = StrategyEnd.strategyQualquerOutroAway(item)
                    break    

                    default:
                        color = "#707070";
                    break;
                }

                const data = {
                    id: item.id,
                    time: item.time,
                    scoreboard: item.scoreboard,
                    data: true,
                    game: `${item.team_home} vs ${item.team_away}`,
                    goals: StrategyEnd.goalsTotal(item),
                    color: color,
                    minutes:arrHour[1]
                };

               

                if (arrayHour.length > 0) {
                    exists = arrayHour.some(entry => entry.minutes && entry.minutes.some(minute => minute.id === item.id));
                }
                
                if (result.length === 0 && !exists) {
                    // Se 'result' está vazio e 'item' não existe em 'arrayHour'
                    result.push(data);
                } else if (result.length > 0) {
                    // Se 'result' não está vazio
                    let idDiff = Math.abs(item.id - result[result.length - 1].id);
                    if (idDiff <= 5 && !exists) {
                        // Se a diferença de ID é menor ou igual a 5 e 'item' não existe em 'arrayHour'
                        result.push(data);
                    }
                }
                
            
            }

        });

        const resultado = this.fillMissingMinutes(result, hour , arrResults[0].championship);

        return resultado;
    }

    static scoreInsterval(item)
    {
        let string = '';
        let scoresStr = '';

        if(item.halfTimeCorrectScore === "Qualquer Outro Resultado")
        {
            return "OUT";
        }
        
        for (let i = 0; i < item.halfTimeCorrectScore.length; i++) {
            if (item.halfTimeCorrectScore[i].match(/\d/)) {
                string = item.halfTimeCorrectScore.substring(0, i - 1);
                scoresStr = item.halfTimeCorrectScore.substring(i);
                break;
            }
        }

        return scoresStr;
    }

    static arrMinutesInterval(arrResults, hour , arrayHour, strategy)
    {
        const result = [];

        arrResults.forEach((item) => {
    
            const arrHour = item.time.split(":");
            const currentHour = arrHour[0];
    
            if (currentHour === hour) {
    
                let exists = false;

                if (arrayHour.length > 0) {
                    exists = arrayHour.some(entry => entry.minutes && entry.minutes.some(minute => minute.id === item.id));
                }

                let color;

                switch (strategy) {
                    case 1:
                        color = StrategyInterval.strategyPar(item);
                        break;
                    case 2:
                        color = StrategyInterval.strategyImpar(item);
                        break;
                    case 4:
                        color = StrategyInterval.strategyFirstMarkedHome(item);
                        break;
                    case 5:
                        color = StrategyInterval.strategyFirstMarkedNone(item);
                        break;
                    case 6:
                        color = StrategyInterval.strategyFirstMarkedAway(item);
                        break;
                    case 8:
                        color = StrategyInterval.strategyLastMarkedHome(item);
                        break;
                    case 9:
                        color = StrategyInterval.strategyLastMarkedNone(item);
                        break;
                    case 10:
                        color = StrategyInterval.strategyLastMarkedAway(item);
                        break;
                    case 12:
                        color = StrategyInterval.strategyGroupHomeOne(item);
                        break;
                    case 13:
                        color = StrategyInterval.strategyGroupHomeTwo(item);
                        break;
                    case 14:
                        color = StrategyInterval.strategyGroupHomeAnyOther(item);
                        break;
                    case 15:
                        color = StrategyInterval.strategyGroupDrawOne(item);
                        break;
                    case 16:
                        color = StrategyInterval.strategyGroupDrawTwo(item);
                        break;
                    case 17:
                        color = StrategyInterval.strategyGroupDrawThree(item);
                        break;
                    case 18:
                        color = StrategyInterval.strategyGroupAwayOne(item);
                        break;
                    case 19:
                        color = StrategyInterval.strategyGroupAwayTwo(item);
                        break;
                    case 20:
                        color = StrategyInterval.strategyGroupAwayAnyOther(item);
                        break;
                    case 22:
                        color = StrategyInterval.strategyTurnsHomeHome(item);
                        break;
                    case 23:
                        color = StrategyInterval.strategyTurnsHomeDraw(item);
                        break;
                    case 24:
                        color = StrategyInterval.strategyTurnsHomeAway(item);
                        break;
                    case 25:
                        color = StrategyInterval.strategyTurnsDrawHome(item);
                        break;
                    case 26:
                        color = StrategyInterval.strategyTurnsDrawDraw(item);
                        break;
                    case 27:
                        color = StrategyInterval.strategyTurnsDrawAway(item);
                        break;
                    case 28:
                        color = StrategyInterval.strategyTurnsAwayHome(item);
                        break;
                    case 29:
                        color = StrategyInterval.strategyTurnsAwayDraw(item);
                        break;
                    case 30:
                        color = StrategyInterval.strategyTurnsAwayAway(item);
                        break;
                    case 32:
                        color = StrategyInterval.strategyResultEndHome(item);
                        break;
                    case 33:
                        color = StrategyInterval.strategyResultEndAway(item);
                        break;
                    case 34:
                        color = StrategyInterval.strategyResultEndDraw(item);
                        break;
                    case 36:
                        color = StrategyInterval.strategyResultIntervalHome(item);
                        break;
                    case 37:
                        color = StrategyInterval.strategyResultIntervalAway(item);
                        break;
                    case 38:
                        color = StrategyInterval.strategyResultIntervalDraw(item);
                        break;
                    case 40:
                        color = StrategyInterval.strategyOver_0_5(item);
                        break;
                    case 41:
                        color = StrategyInterval.strategyOver_1_5(item);
                        break;
                    case 42:
                        color = StrategyInterval.strategyOver_2_5(item);
                        break;
                    case 43:
                        color = StrategyInterval.strategyOver_3_5(item);
                        break;
                    case 45:
                        color = StrategyInterval.strategyUnder_0_5(item);
                        break;
                    case 46:
                        color = StrategyInterval.strategyUnder_1_5(item);
                        break;
                    case 47:
                        color = StrategyInterval.strategyUnder_2_5(item);
                        break;
                    case 48:
                        color = StrategyInterval.strategyUnder_3_5(item);
                        break;
                    case 50:
                        color = StrategyInterval.strategyBothScoreYes(item);
                        break;
                    case 51:
                        color = StrategyInterval.strategyBothScoreNo(item);
                        break;
                    case 53:
                        color = StrategyInterval.strategyScoreHomeYes(item);
                        break;
                    case 54:
                        color = StrategyInterval.strategyScoreHomeNo(item);
                        break;
                    case 56:
                        color = StrategyInterval.strategyScoreAwayYes(item);
                        break;
                    case 57:
                        color = StrategyInterval.strategyScoreAwayNo(item);
                        break;
                    case 59:
                        color = StrategyInterval.strategyGolsExats_0_0(item);
                        break;
                    case 60:
                        color = StrategyInterval.strategyGolsExats_1_0(item);
                        break;
                    case 61:
                        color = StrategyInterval.strategyGolsExats_2_0(item);
                        break;
                    case 62:
                        color = StrategyInterval.strategyGolsExats_3_0(item);
                        break;
                    case 63:
                        color = StrategyInterval.strategyGolsExats_4_0(item);
                        break;
                    case 64:
                        color = StrategyInterval.strategyGolsExats_5_0(item);
                        break;
                    case 66:
                        color = StrategyInterval.strategyNoneScore(item);
                        break;
                    case 67:
                        color = StrategyInterval.strategyCentroAvanteHome(item);
                        break;
                    case 68:
                        color = StrategyInterval.strategyPontaDireitaHome(item);
                        break;
                    case 69:
                        color = StrategyInterval.strategyPontaEsquerdaHome(item);
                        break;
                    case 70:
                        color = StrategyInterval.strategyMeiaAtacanteHome(item);
                        break;
                    case 71:
                        color = StrategyInterval.strategyQualquerOutroHome(item);
                        break;
                    case 72:
                        color = StrategyInterval.strategyCentroAvanteAway(item);
                        break;
                    case 73:
                        color = StrategyInterval.strategyPontaDireitaAway(item);
                        break;
                    case 74:
                        color = StrategyInterval.strategyPontaEsquerdaAway(item);
                        break;
                    case 75:
                        color = StrategyInterval.strategyMeiaAtacanteAway(item);
                        break;
                    case 76:
                        color = StrategyInterval.strategyQualquerOutroAway(item);
                        break;
                    default:
                        color = "#707070";
                        break;
                }
                

                const data = {
                    
                    id: item.id,
                    time: item.time,
                    scoreboard: this.scoreInsterval(item),
                    data: true,
                    game: `${item.team_home} vs ${item.team_away}`,
                    goals: StrategyInterval.goalsTotal(item),
                    color: color,
                    minutes:arrHour[1]
                };

               // result.push(data);

                

            if (arrayHour.length > 0) {
                exists = arrayHour.some(entry => entry.minutes && entry.minutes.some(minute => minute.id === item.id));
            }
            
            if (result.length === 0 && !exists) {
                // Se 'result' está vazio e 'item' não existe em 'arrayHour'
                result.push(data);
            } else if (result.length > 0) {
                // Se 'result' não está vazio
                let idDiff = Math.abs(item.id - result[result.length - 1].id);
                if (idDiff <= 5 && !exists) {
                    // Se a diferença de ID é menor ou igual a 5 e 'item' não existe em 'arrayHour'
                    result.push(data);
                }
            }

                
            }

        });

        const resultado = this.fillMissingMinutes(result, hour , arrResults[0].championship);

        return resultado;
    }

    static analyzeColors(data) {

        return data.map(entry => {
            let greenCount = 0;
            let redCount = 0;
    
            entry.minutes.forEach(minute => {
                if (minute.color === "green") greenCount++;
                if (minute.color === "red") redCount++;
            });
    
            const total = greenCount + redCount;
            const greenPercentage = ((greenCount / total) * 100).toFixed(2);
            const redPercentage = ((redCount / total) * 100).toFixed(2);
    
            return {
                ...entry,
                greenCount,
                redCount,
                greenPercentage: greenPercentage + '%',
                redPercentage: redPercentage + '%'
            };
        });
    }


    static countGoalsInMatches(data) {

        function countTotalGoals(scoreboard) {
            const [goalsTeam1, goalsTeam2] = scoreboard.split(" - ").map(Number);
            return goalsTeam1 + goalsTeam2;
        }
    
        return data.map(entry => {
            const totalGoals = countTotalGoals(entry.scoreboard);
            return {
                ...entry,
                totalGoals
            };
        });
    }
    
    static addTotalGoalsByHour(data) {
        return data.map(hourEntry => {
          let hourTotalGoals = 0;
      
          hourEntry.minutes.forEach(minute => {
            if(minute.goals !== null)
            {
                hourTotalGoals += minute.goals;
            }
            
          });
      
          return {
            ...hourEntry,
            totalGoals: hourTotalGoals,
          };
        });
    }



    static arrHour(arrResults, strategy, resultado, nextGames ) {

        const result = [];

        arrResults.map((item, index) => {

             let hourArr;

             let hour;

             let obj = {};

             switch (resultado) {
                case 1:
                    hourArr = item.time.split(":");

                    hour = hourArr[0].length === 1 ? '0'+ hourArr[0] : hourArr[0];
    
                     obj = {
                        id: index,
                        hour: hour,
                        minutes: this.arrMinutesEnd(arrResults, hourArr[0], result, strategy)
                    }
    
                    break;
                case 2:
                    hourArr = item.time.split(":");

                    hour = hourArr[0].length === 1 ? '0'+ hourArr[0] : hourArr[0];
    
                     obj = {
                        id: index,
                        hour: hour,
                        minutes: this.arrMinutesInterval(arrResults, hourArr[0], result, strategy)
                    }

                    break
                case 4:

                    hourArr = item.time.split(":");
    
                    hour = hourArr[0].length === 1 ? '0'+ hourArr[0] : hourArr[0];
        
                    obj = {
                        id: index,
                        hour: hour,
                        minutes: this.arrMinutesEnd(arrResults, hourArr[0], result, strategy)
                    }

                    break
             
                default:
                    break;
             }

             if (result.length === 0 || result[result.length - 1].hour !== obj.hour) {
                 result.push(obj);
             }
        });

        let data = this.analyzeColors(result);

        data = this.addTotalGoalsByHour(data);

        this.onAddOdds(data[0], nextGames, strategy);

        return data;
     }

     static formatTime(input) { //ADIOCIONADO PARA CORRIGIR FORMATO DO TIME 00:00 PARA 0:00
        // Dividir a string em partes usando ":"
        const parts = input.split(":");
    
        // Verificar se há exatamente duas partes
        if (parts.length === 2) {
            const hours = parseInt(parts[0], 10); // Converter horas para número
            const minutes = parseInt(parts[1], 10); // Converter minutos para número
    
            // Verificar se as partes são numéricas e estão no intervalo correto
            if (!isNaN(hours) && !isNaN(minutes) && hours >= 0 && hours <= 9 && minutes >= 0 && minutes <= 59) {
                // Reconstruir a string no formato desejado (0:00)
                const formattedTime = `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
                return formattedTime;
            }
        }
    
        // Se a string não estiver no formato desejado, retornar a string original
        return input;
    }
    

     static onAddOdds(data, next, strategy) {

        console.log(next);

        if(data && next && strategy && data.minutes.length > 0)
        {


            data.minutes.map(item => {

                const correspondente = next.find(objeto => this.formatTime(objeto.time) === this.formatTime(item.time));
    
                let oddsArr;
                let options;
    
                if(correspondente)
                {
                    switch (strategy) {
                        case 1:
                            if (correspondente) {
                                item.odds = null;
                            }
                            break;
                        case 2:
                            if (correspondente) {
                                item.odds = null;
                            }
                            break;
                        case 4:
        
                           oddsArr = JSON.parse(correspondente.firstTeamMarked);
    
                           options  = oddsArr.find(i => i.team === correspondente.team_home && i.option === 'Primeiro');
    
                            if (options) {
                                item.odds = options.odds;
                            }
        
                            break;                       
                        case 5:
        
                            oddsArr = JSON.parse(correspondente.firstTeamMarked);
    
                            options  = oddsArr.find(i => i.team === 'Sem Gols' && i.option === 'Primeiro');
    
                             if (options) {
                                 item.odds = options.odds;
                             }
         
                             break;
    
                        case 6:
        
                            oddsArr = JSON.parse(correspondente.firstTeamMarked);
     
     
                            options  = oddsArr.find(i => i.team === correspondente.team_visit && i.option === 'Primeiro');
     
     
                            if (options) {
                                  item.odds = options.odds;
                            }
          
                             break; 
                        case 8:

                             oddsArr = JSON.parse(correspondente.firstTeamMarked);
      
                             options  = oddsArr.find(i => i.team === correspondente.team_home && i.option === 'Último');
      
                             if (options) {
                                   item.odds = options.odds;
                             }
                              break;     

                        case 9:

                            oddsArr = JSON.parse(correspondente.firstTeamMarked);
            
                            options  = oddsArr.find(i => i.team === 'Sem Gols' && i.option === 'Último');
            
                            if (options) {
                                item.odds = options.odds;
                            }
                 
                        break;   
    
                        case 12:
    
                            //console.log(correspondente)
              
                            oddsArr = JSON.parse(correspondente.correctResultGroup);
            
                            options  = oddsArr.find(i => i.option === correspondente.team_home && i.goals === '1-0, 2-0, 2-1');
            
                            if (options) {
                                item.odds = options.odds;
                            }
                 
                        break;   
    
                        case 13:
    
                            //console.log(correspondente)
              
                            oddsArr = JSON.parse(correspondente.correctResultGroup);
            
                            options  = oddsArr.find(i => i.option === correspondente.team_home && i.goals === '3-0, 3-1, 4-0');
            
                            if (options) {
                                item.odds = options.odds;
                            }
             
                        break; 
    
                        case 14:
    
                            //console.log(correspondente)
              
                            oddsArr = JSON.parse(correspondente.correctResultGroup);

            
                            options  = oddsArr.find(i => i.option === correspondente.team_home && i.goals === 'Qualquer Outro');
            
                            if (options) {
                                item.odds = options.odds;
                            }
         
                        break; 
    
                        case 15:
    
                            //console.log(correspondente)
              
                            oddsArr = JSON.parse(correspondente.correctResultGroup);
            
                            options  = oddsArr.find(i => i.option === 'Empate' && i.goals === '0-0');
            
                            if (options) {
                                item.odds = options.odds;
                            }
         
                        break;
    
                        case 16:
    
                            //console.log(correspondente)
              
                            oddsArr = JSON.parse(correspondente.correctResultGroup);
            
                            options  = oddsArr.find(i => i.option === 'Empate' && i.goals === '1-1, 2-2');
            
                            if (options) {
                                item.odds = options.odds;
                            }
         
                        break;  
                        
                        case 17:
    
                            //console.log(correspondente)
              
                            oddsArr = JSON.parse(correspondente.correctResultGroup);
            
                            options  = oddsArr.find(i => i.option === "Empate" && i.goals === '3-3, 4-4');
            
                            if (options) {
                                item.odds = options.odds;
                            }
         
                        break;  
    
                        case 18:
    
                            //console.log(correspondente)
              
                            oddsArr = JSON.parse(correspondente.correctResultGroup);

            
                            options  = oddsArr.find(i => i.option === correspondente.team_visit && i.goals === '1-0, 2-0, 2-1');
            
                            if (options) {
                                item.odds = options.odds;
                            }
         
                        break; 
    
                        case 19:
    
                            //console.log(correspondente)
              
                            oddsArr = JSON.parse(correspondente.correctResultGroup);

            
                            options  = oddsArr.find(i => i.option === correspondente.team_visit && i.goals === '3-0, 3-1, 4-0');
            
                            if (options) {
                                item.odds = options.odds;
                            }
         
                        break; 
    
                        case 20:
    
                            //console.log(correspondente)
              
                            oddsArr = JSON.parse(correspondente.correctResultGroup);

                            options  = oddsArr.find(i => i.option === correspondente.team_visit && i.goals === 'Qualquer Outro');
            
                            if (options) {
                                item.odds = options.odds;
                            }
         
                        break;    
                        
                        case 32:

                            item.odds = correspondente.odds_home;

                        break; 

                        case 33:

                            item.odds = correspondente.odds_visit;
 
                        break;   

                        case 34:

                            item.odds = correspondente.odds_draw;
 
                        break;   

                        case 36://resultInterval

                        oddsArr = JSON.parse(correspondente.resultInterval);

                        options  = oddsArr.find(i => i.option === correspondente.team_home);
        
                        if (options) {
                            item.odds = options.odds;
                        }


                        break;     

                        case 37:

                        oddsArr = JSON.parse(correspondente.resultInterval);

                        options  = oddsArr.find(i => i.option === correspondente.team_visit);
        
                        if (options) {
                            item.odds = options.odds;
                        }

 
                        break; 
 
                        case 38:

                        oddsArr = JSON.parse(correspondente.resultInterval);

                        options  = oddsArr.find(i => i.option === 'Empate');
        
                        if (options) {
                            item.odds = options.odds;
                        }
 
                        break;   
                        
                        case 40:

                        oddsArr = JSON.parse(correspondente.underAndOver);

                        options  = oddsArr.find(i => i.goal === 0.5 && i.option === 'Mais de');
        
                        if (options) {
                            item.odds = options.odds;
                        }
 
                        break;   

                        case 41:

                        oddsArr = JSON.parse(correspondente.underAndOver);

                        options  = oddsArr.find(i => i.goal === 1.5 && i.option === 'Mais de');
        
                        if (options) {
                            item.odds = options.odds;
                        }

                        break;   

                        case 42:

                        oddsArr = JSON.parse(correspondente.underAndOver);

                        options  = oddsArr.find(i => i.goal === 2.5 && i.option === 'Mais de');
        
                        if (options) {
                            item.odds = options.odds;
                        }
 
                        break;   

                        case 43:

                        oddsArr = JSON.parse(correspondente.underAndOver);

                        options  = oddsArr.find(i => i.goal === 3.5 && i.option === 'Mais de');
        
                        if (options) {
                            item.odds = options.odds;
                        }
 
                        break;                          

                        case 45:

                        oddsArr = JSON.parse(correspondente.underAndOver);

                        options  = oddsArr.find(i => i.goal === 0.5 && i.option === 'Menos de');
        
                        if (options) {
                            item.odds = options.odds;
                        }
 
                        break;   

                        case 46:

                        oddsArr = JSON.parse(correspondente.underAndOver);

                        options  = oddsArr.find(i => i.goal === 1.5 && i.option === 'Menos de');
        
                        if (options) {
                            item.odds = options.odds;
                        }

                        break;   

                        case 47:

                        oddsArr = JSON.parse(correspondente.underAndOver);

                        options  = oddsArr.find(i => i.goal === 2.5 && i.option === 'Menos de');
        
                        if (options) {
                            item.odds = options.odds;
                        }
 
                        break;   

                        case 48:

                        oddsArr = JSON.parse(correspondente.underAndOver);

                        options  = oddsArr.find(i => i.goal === 3.5 && i.option === 'Menos de');
        
                        if (options) {
                            item.odds = options.odds;
                        }
 
                        break;                          
                        case 50:

                        oddsArr = JSON.parse(correspondente.teamMarked);

                        options  = oddsArr.find(i => i.option === 'Sim' && i.teams === 'Ambos os Times');
        
                        if (options) {
                            item.odds = options.odds;
                        }
 
                        break;                            
 
                        case 51:

                        oddsArr = JSON.parse(correspondente.teamMarked);

                        options  = oddsArr.find(i => i.option === 'Não' && i.teams === 'Ambos os Times');
        
                        if (options) {
                            item.odds = options.odds;
                        }
 
                        break;    

                        case 53:

                        oddsArr = JSON.parse(correspondente.teamMarked);

                        options  = oddsArr.find(i => i.option === 'Sim' && i.teams === correspondente.team_home);
        
                        if (options) {
                            item.odds = options.odds;
                        }
 
                        break; 

                        case 54:

                        oddsArr = JSON.parse(correspondente.teamMarked);

                        options  = oddsArr.find(i => i.option === 'Não' && i.teams === correspondente.team_home);
        
                        if (options) {
                            item.odds = options.odds;
                        }
 
                        break;                          
                        
                        case 56:

                        oddsArr = JSON.parse(correspondente.teamMarked);

                        options  = oddsArr.find(i => i.option === 'Sim' && i.teams === correspondente.team_visit);
        
                        if (options) {
                            item.odds = options.odds;
                        }
 
                        break;   

                        case 57:

                        oddsArr = JSON.parse(correspondente.teamMarked);

                        options  = oddsArr.find(i => i.option === 'Não' && i.teams === correspondente.team_visit);
        
                        if (options) {
                            item.odds = options.odds;
                        }
 
                        break;   

                        case 59:

                        oddsArr = JSON.parse(correspondente.totalGoalsExact);

                        options  = oddsArr.find(i => i.option === '0 Golos');
        
                        if (options) {
                            item.odds = options.odds;
                        }
 
                        break; 

                        case 60:

                        oddsArr = JSON.parse(correspondente.totalGoalsExact);

                        options  = oddsArr.find(i => i.option === '1 Golo');
        
                        if (options) {
                            item.odds = options.odds;
                        }
 
                        break;                          
                        
                        case 61:

                        oddsArr = JSON.parse(correspondente.totalGoalsExact);

                        options  = oddsArr.find(i => i.option === '2 Golos');
        
                        if (options) {
                            item.odds = options.odds;
                        }
 
                        break;  

                        case 62:

                        oddsArr = JSON.parse(correspondente.totalGoalsExact);

                        options  = oddsArr.find(i => i.option === '3 Golos');
        
                        if (options) {
                            item.odds = options.odds;
                        }
 
                        break;   

                        case 63:

                        oddsArr = JSON.parse(correspondente.totalGoalsExact);

                        options  = oddsArr.find(i => i.option === '4 Golos');
        
                        if (options) {
                            item.odds = options.odds;
                        }
 
                        break; 

                        case 64:

                        oddsArr = JSON.parse(correspondente.totalGoalsExact);

                        options  = oddsArr.find(i => i.option === '5+ Golos');
        
                        if (options) {
                            item.odds = options.odds;
                        }
 
                        break;                           
                        


                        default:

                        break;
                    }
                }
    
                return item;
            });
        
        }
    }
    

     static getTotalGoalsForMinute(data, specificMinute) {
        let totalGoals = 0;
      
        data.forEach(hourEntry => {
          // Encontre o jogo para o minuto específico nessa hora
          const minuteEntry = hourEntry.minutes.find(minute => minute.time.endsWith(`:${specificMinute.padStart(2, '0')}`));
      
          if (minuteEntry) {
            if(minuteEntry.goals !== null)
            {
                totalGoals += minuteEntry.goals;
            }
          }
        });
      
        return totalGoals;
    }


    static analyzeGreenForMinute(data, specificMinute) {
        let greenCount = 0;
        let totalCount = 0;
    
        data.forEach(hourEntry => {
            // Encontre o minuto específico nessa hora
            const minuteEntry = hourEntry.minutes.find(minute => {
                return minute.time.endsWith(`:${specificMinute.padStart(2, '0')}`) && minute.id !== null;
            });
    
            if (minuteEntry) {
                totalCount++;
    
                if (minuteEntry.color === "green") {
                    greenCount++;
                }
            }
        });
    
        const greenPercentage = totalCount === 0 ? 0 : ((greenCount / totalCount) * 100).toFixed(2);
    
        return {
            greenCount,
            totalCount,
            greenPercentage: greenPercentage + '%'
        };
    }
    
     
}

export default HourHelp;