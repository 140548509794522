import { BtnHeader, HeaderContainer, Logo, TextLogo } from "../styled";
import { Outlet } from "react-router-dom";
import { Link } from "react-router-dom";

export function BtnLogin()
{
    return(
        <BtnHeader>
            <Link style={{textDecoration:'none', color:'inherit'}} to={"/register"}>REGISTRAR</Link>
        </BtnHeader>
    )
}

export function BtnRegister()
{
    return(
        <BtnHeader >
            <Link style={{textDecoration:'none', color:'inherit'}} to={"/"}>ENTRAR</Link>
        </BtnHeader>
    )
}


export default function AuthHeader()
{
    return(
        <HeaderContainer>
            <Logo src='./images/logo.png'/>
            <Outlet/>
        </HeaderContainer>
    )
}