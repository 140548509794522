import axios from "axios";
import { API_URL_SCRALPER_WORLDCUP_RESULT, API_URL_SCRALPER_WORLD_ODDS_NEXT_GAMES } from "../../config";


export async function getDataWorldCup(lastKnownId)
{
    const nextGames = await axios.get(`${ API_URL_SCRALPER_WORLD_ODDS_NEXT_GAMES}`);

    const gamesResponse = await axios.get(`${API_URL_SCRALPER_WORLDCUP_RESULT}${lastKnownId}`);

    return {nextGames: nextGames, gamesResponse: gamesResponse}
}