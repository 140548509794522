import styled from 'styled-components';

export const GlobalContainer = styled.div`

    width: 100%;
    height: 100vh;
    background-color: ${props => props.bg || '#fff'};
    overflow: hidden;
    background-color: #000;
    
`;

export const ImageLogo = styled.img`
    width: 300px;
    height: 300px;
    margin-top: 50px;

`