import { useState, useContext, useEffect } from "react";
import { Container, Text } from "../componets/styles";
import AuthHeader from "./containers/header";
import { ButtonFormAuth, FormContainer, HeaderMobile, InputFormAuth, LogoMobile } from "./styled";
import axios from "axios";
import { API_TOKEN, API_URL } from "../config";
import AuthContext from "../context/AuthContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SpinnerCircular } from 'spinners-react';

export default function Login()
{
    const navigate = useNavigate();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const queryToken = queryParams.get("token_redefine_password");

    const {login} = useContext(AuthContext);

    const [spinner, setSpiner] = useState(false);

    const [form, setForm] = useState({

        email:'',
        password:''
    })

    const [errors, setErrors] = useState({
        email:'',
        password:''
    });


    useEffect(()=>{
        if(queryToken)
        {
            navigate(`/newpassword?token_redefine_password=${queryToken}`)

        }
    },[])

    async function onSave()
    {
        setSpiner(true);

        const headers = {
            'Authorization': `Bearer ${API_TOKEN}`,
            'Content-Type': 'application/json', 
        };

        try {

            const response = await axios.post(`${API_URL}/login`, form, { headers });

            localStorage.setItem('TOKEN_APP_FUT_REIS_TIPS', response.data.token);

            setErrors({
                ...errors,
                email:'',
                password:'',
                error:''
            });

            setSpiner(false);

            login();
            navigate("/");
            return

        } catch (error) {

            console.log("error");
            console.log(error);

            if(error.response.data.errors)
            {
                setErrors({
                    ...errors,
                    email:error.response.data.errors.email,
                    password:error.response.data.errors.password
                });
                setSpiner(false);
                return
            }

            if(error.response.data.error)
            {
                setErrors({
                    ...errors,
                    error: error.response.data.error
                });
                setSpiner(false);
                return
            }

            setSpiner(false);
        }
    }

    return(

        <Container>

            <AuthHeader/>

            <HeaderMobile>
                <LogoMobile src='./images/logo.png'/>
            </HeaderMobile>

            <Container>

                <FormContainer>

                    <Container display="flex" items="center" jc="center" mt='20px' >
                        <Text col="#fff" fs="30px">Seja bem vindo!</Text>
                    </Container>
                   
                    <InputFormAuth onChange={(e)=>{setForm({...form, email:e.target.value})}} value={form.email} type="text" placeholder="Digite seu email"/>
                    <Text ml='5%' fs='20px' col='#dc2626' fw='bold'>{errors.email}</Text> 
                    <InputFormAuth onChange={(e)=>{setForm({...form, password:e.target.value})}} value={form.password} type="password" placeholder="Digite sua senha"/>
                    <Text ml='5%' fs='20px' col='#dc2626' fw='bold'>{errors.password}</Text> 
                    <Container ml="5%" mt="10px">
                         <Text col="red" fs="20px" fw="bold">{errors.error}</Text>
                    </Container>
                    
                    
                    
                    <ButtonFormAuth onClick={onSave}>
                        {spinner == true
                        
                        ?
                            <SpinnerCircular size={30} color="#fff"/>
                        :
                            <Text col="#fff" fs="20px" fw="bold">ENTRAR</Text>
                        }
                      
                    </ButtonFormAuth>

                    <ButtonFormAuth>
                        <Link style={{textDecoration:'none', color:'inherit'}} to={"/register"}>
                            <Text col="#fff" fs="20px" fw="bold">REGISTRAR</Text>
                        </Link>
                    </ButtonFormAuth>

                    <Container mt="10px">
                        <Text col="#fff" fs="15px" ml="5%">Esqueceu sua senha?</Text>
                    </Container>

                    <ButtonFormAuth >
                        <Link style={{textDecoration:'none', color:'inherit'}} to={"/redefine"}>
                            <Text col="#fff" fs="20px" fw="bold">REDEFINIR SENHA</Text>
                        </Link>
                    </ButtonFormAuth>

                    <Container mt='10px'></Container>

                </FormContainer>

            </Container>

        </Container>
    
    )
}