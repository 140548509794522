
import { useState} from "react";

import  TemplateDesktop  from "./templates/templateDesktop.js";
import useWindowSize from "../../useSize/useWindowSize.js";
import TemplateMobile from "./templates/templateMobile.js";

export default function ExpressCup({handlePage})
{
    const size = useWindowSize();
    const isMobile = size.width <= 768; // Define um limite para considerar mobile

    return (
        <>
            {isMobile ? (
                <TemplateMobile handlePage={handlePage} />
            ) : (
                <TemplateDesktop handlePage={handlePage} />
            )}
        </>
    );
}