class StrategyInterval{
    
    //ESTRATÉGIAS START>>
    static strategyPar(item)
    {
        let string = '';
        let scoresStr = '';
        
        for (let i = 0; i < item.halfTimeCorrectScore.length; i++) {
            if (item.halfTimeCorrectScore[i].match(/\d/)) {
                string = item.halfTimeCorrectScore.substring(0, i - 1);
                scoresStr = item.halfTimeCorrectScore.substring(i);
                break;
            }
        }

        if(scoresStr.length > 0)
        {
        // Remover o sinal "+" dos valores
        const cleanedScoreboard = item.scoreboard.replace(/\+/g, '');

        // Dividir os valores
        const scores = cleanedScoreboard.split("-").map(score => parseInt(score.trim()));

            const total = scores.reduce((acc, score) => acc + score, 0);
    
            return total % 2 === 0 ? "green" : "red";
        }
        return "red";
    }

    static strategyImpar(item)
    {
        // Remover o sinal "+" dos valores
        const cleanedScoreboard = item.scoreboard.replace(/\+/g, '');

        // Dividir os valores
        const scores = cleanedScoreboard.split("-").map(score => parseInt(score.trim()));

        // Somar os valores
        const total = scores.reduce((acc, score) => acc + score, 0);

        // Verificar se é par ou ímpar e retornar a cor correspondente
        return total % 2 === 0 ? "red" : "green";
    }

    static goalsTotal(item)
    {
        // Remover o sinal "+" dos valores
        const cleanedScoreboard = item.scoreboard.replace(/\+/g, '');

        // Dividir os valores
        const scores = cleanedScoreboard.split("-").map(score => parseInt(score.trim()));

        // Somar os valores
        const total = scores.reduce((acc, score) => acc + score, 0);

        // Verificar se é par ou ímpar e retornar a cor correspondente
        return total
    }

    static strategyFirstMarkedHome(item)
    {

        if(item.firstTeamToScore === item.team_home)
        {
            return "green";
        }

        return "red";
    }

    static strategyFirstMarkedNone(item)
    {

        if(item.firstTeamToScore !== item.team_home && item.firstTeamToScore !== item.team_away)
        {
            return "green";
        }

        return "red";
    }

    static strategyFirstMarkedAway(item)
    {

        if(item.firstTeamToScore === item.team_away)
        {
            return "green";
        }

        return "red";
    }

    static strategyLastMarkedHome(item)
    {
        if(item.lastTeamToScore === item.team_home)
        {
            return "green";
        }

        return "red";
    }

    static strategyLastMarkedNone(item)
    {
        if(item.lastTeamToScore !== item.team_home && item.lastTeamToScore !== item.team_away)
        {
            return "green";
        }

        return "red";
    }

    static strategyLastMarkedAway(item)
    {
        if(item.lastTeamToScore === item.team_away)
        {
            return "green";
        }

        return "red";
    }

    static strategyGroupHomeOne(item)
    {
        let country = '';
        let scores = '';
        
        for (let i = 0; i < item.corretScoreGroup.length; i++) {
            if (item.corretScoreGroup[i].match(/\d/)) {
                country = item.corretScoreGroup.substring(0, i - 1);
                scores = item.corretScoreGroup.substring(i);
                break;
            }
        }

        if(country === item.team_home && scores === '1-0, 2-0, 2-1')
        {
            return "green"
        }

        return "red"
    }

    static strategyGroupHomeTwo(item)
    {
        let country = '';
        let scores = '';
        
        for (let i = 0; i < item.corretScoreGroup.length; i++) {
            if (item.corretScoreGroup[i].match(/\d/)) {
                country = item.corretScoreGroup.substring(0, i - 1);
                scores = item.corretScoreGroup.substring(i);
                break;
            }
        }

        if(country === item.team_home && scores === '3-0, 3-1, 4-0')
        {
            return "green"
        }

        return "red"
    }

    static strategyGroupHomeAnyOther(item)
    {
        let country = '';
        let scores = '';
        
        for (let i = 0; i < item.corretScoreGroup.length; i++) {
            if (item.corretScoreGroup[i].match(/\d/)) {
                country = item.corretScoreGroup.substring(0, i - 1);
                scores = item.corretScoreGroup.substring(i);
                break;
            }
        }

        if(country === item.team_home && scores === 'Qualquer Outro')
        {
            return "green"
        }

        return "red"
    }

    static strategyGroupDrawOne(item)
    {
        let country = '';
        let scores = '';
        
        for (let i = 0; i < item.corretScoreGroup.length; i++) {
            if (item.corretScoreGroup[i].match(/\d/)) {
                country = item.corretScoreGroup.substring(0, i - 1);
                scores = item.corretScoreGroup.substring(i);
                break;
            }
        }

        if(country === "Empate" && scores === '0-0')
        {
            return "green"
        }

        return "red"
    }


    static strategyGroupDrawTwo(item)
    {
        let country = '';
        let scores = '';
        
        for (let i = 0; i < item.corretScoreGroup.length; i++) {
            if (item.corretScoreGroup[i].match(/\d/)) {
                country = item.corretScoreGroup.substring(0, i - 1);
                scores = item.corretScoreGroup.substring(i);
                break;
            }
        }

        if(country === "Empate" && scores === '1-1, 2-2')
        {
            return "green"
        }

        return "red"
    }

    static strategyGroupDrawThree(item)
    {
        let country = '';
        let scores = '';
        
        for (let i = 0; i < item.corretScoreGroup.length; i++) {
            if (item.corretScoreGroup[i].match(/\d/)) {
                country = item.corretScoreGroup.substring(0, i - 1);
                scores = item.corretScoreGroup.substring(i);
                break;
            }
        }

        if(country === "Empate" && scores === '3-3, 4-4')
        {
            return "green"
        }

        return "red"
    }

    static strategyGroupAwayOne(item)
    {
        let country = '';
        let scores = '';
        
        for (let i = 0; i < item.corretScoreGroup.length; i++) {
            if (item.corretScoreGroup[i].match(/\d/)) {
                country = item.corretScoreGroup.substring(0, i - 1);
                scores = item.corretScoreGroup.substring(i);
                break;
            }
        }

        if(country === item.team_away && scores === '1-0, 2-0, 2-1')
        {
            return "green"
        }

        return "red"
    }

    static strategyGroupAwayTwo(item)
    {
        let country = '';
        let scores = '';
        
        for (let i = 0; i < item.corretScoreGroup.length; i++) {
            if (item.corretScoreGroup[i].match(/\d/)) {
                country = item.corretScoreGroup.substring(0, i - 1);
                scores = item.corretScoreGroup.substring(i);
                break;
            }
        }

        if(country === item.team_away && scores === '3-0, 3-1, 4-0')
        {
            return "green"
        }

        return "red"
    }

    static strategyGroupAwayAnyOther(item)
    {
        let country = '';
        let scores = '';
        
        for (let i = 0; i < item.corretScoreGroup.length; i++) {
            if (item.corretScoreGroup[i].match(/\d/)) {
                country = item.corretScoreGroup.substring(0, i - 1);
                scores = item.corretScoreGroup.substring(i);
                break;
            }
        }

        if(country === item.team_away && scores === 'Qualquer Outro')
        {
            return "green"
        }

        return "red"
    } 
    
    static strategyTurnsHomeHome(item)
    {
        if(item.intervalResult === item.team_home && item.resultEnd === item.team_home)
        {
            return "green"
        }

        return "red"
    }

    static strategyTurnsHomeDraw(item)
    {
        if(item.intervalResult === item.team_home && item.resultEnd === "Empate")
        {
            return "green"
        }

        return "red"
    }

    static strategyTurnsHomeAway(item)
    {
        if(item.intervalResult === item.team_home && item.resultEnd === item.team_away)
        {
            return "green"
        }

        return "red"
    }

    static strategyTurnsDrawHome(item)
    {
        if(item.intervalResult === "Empate" && item.resultEnd === item.team_home)
        {
            return "green"
        }

        return "red"
    }

    static strategyTurnsDrawDraw(item)
    {
        if(item.intervalResult === "Empate" && item.resultEnd === "Empate")
        {
            return "green"
        }

        return "red"
    }

    static strategyTurnsDrawAway(item)
    {
        if(item.intervalResult === "Empate" && item.resultEnd === item.team_away)
        {
            return "green"
        }

        return "red"
    }

    static strategyTurnsAwayHome(item)
    {
        if(item.intervalResult === item.team_away && item.resultEnd === item.team_home)
        {
            return "green"
        }

        return "red"
    }

    static strategyTurnsAwayDraw(item)
    {
        if(item.intervalResult === item.team_away && item.resultEnd === "Empate")
        {
            return "green"
        }

        return "red"
    }

    static strategyTurnsAwayAway(item)
    {
        if(item.intervalResult === item.team_away && item.resultEnd === item.team_away)
        {
            return "green"
        }

        return "red"
    }

    static strategyResultEndHome(item)
    {
        if(item.resultEnd === item.team_home)
        {
            return "green"
        }

        return "red"
    }

    static strategyResultEndAway(item)
    {
        if(item.resultEnd === item.team_away)
        {
            return "green"
        }

        return "red"
    }

    static strategyResultEndDraw(item)
    {
        if(item.resultEnd === "Empate")
        {
            return "green"
        }

        return "red"
    }  
    
    static strategyResultIntervalHome(item)
    {
        if(item.intervalResult === item.team_home)
        {
            return "green"
        }

        return "red"
    }

    static strategyResultIntervalAway(item)
    {
        if(item.intervalResult === item.team_away)
        {
            return "green"
        }

        return "red"
    }

    static strategyResultIntervalDraw(item)
    {
        if(item.intervalResult === "Empate")
        {
            return "green"
        }

        return "red"
    } 

    static strategyOver_0_5(item)
    {
        // Remover o sinal "+" dos valores
        const cleanedScoreboard = item.scoreboard.replace(/\+/g, '');

        // Dividir os valores
        const scores = cleanedScoreboard.split("-").map(score => parseInt(score.trim()));

        const total = scores.reduce((acc, score) => acc + score, 0);

        if(total > 0)
        {
            return "green";
        }

        return "red";
    }

    static strategyOver_1_5(item)
    {
        // Remover o sinal "+" dos valores
        const cleanedScoreboard = item.scoreboard.replace(/\+/g, '');

        // Dividir os valores
        const scores = cleanedScoreboard.split("-").map(score => parseInt(score.trim()));

        const total = scores.reduce((acc, score) => acc + score, 0);

        if(total > 1)
        {
            return "green";
        }

        return "red";
    }

    static strategyOver_2_5(item)
    {
        // Remover o sinal "+" dos valores
        const cleanedScoreboard = item.scoreboard.replace(/\+/g, '');

        // Dividir os valores
        const scores = cleanedScoreboard.split("-").map(score => parseInt(score.trim()));

        const total = scores.reduce((acc, score) => acc + score, 0);

        if(total > 2)
        {
            return "green";
        }

        return "red";
    }

    static strategyOver_3_5(item)
    {
        // Remover o sinal "+" dos valores
        const cleanedScoreboard = item.scoreboard.replace(/\+/g, '');

        // Dividir os valores
        const scores = cleanedScoreboard.split("-").map(score => parseInt(score.trim()));

        const total = scores.reduce((acc, score) => acc + score, 0);

        if(total > 3)
        {
            return "green";
        }

        return "red";
    }

    static strategyUnder_0_5(item)
    {
        // Remover o sinal "+" dos valores
        const cleanedScoreboard = item.scoreboard.replace(/\+/g, '');

        // Dividir os valores
        const scores = cleanedScoreboard.split("-").map(score => parseInt(score.trim()));

        const total = scores.reduce((acc, score) => acc + score, 0);

        if(total === 0)
        {
            return "green";
        }

        return "red";
    }

    static strategyUnder_1_5(item)
    {
        // Remover o sinal "+" dos valores
        const cleanedScoreboard = item.scoreboard.replace(/\+/g, '');

        // Dividir os valores
        const scores = cleanedScoreboard.split("-").map(score => parseInt(score.trim()));

        const total = scores.reduce((acc, score) => acc + score, 0);

        if(total < 2)
        {
            return "green";
        }

        return "red";
    }

    static strategyUnder_2_5(item)
    {
        // Remover o sinal "+" dos valores
        const cleanedScoreboard = item.scoreboard.replace(/\+/g, '');

        // Dividir os valores
        const scores = cleanedScoreboard.split("-").map(score => parseInt(score.trim()));

        const total = scores.reduce((acc, score) => acc + score, 0);

        if(total < 3)
        {
            return "green";
        }

        return "red";
    }

    static strategyUnder_3_5(item)
    {
        // Remover o sinal "+" dos valores
        const cleanedScoreboard = item.scoreboard.replace(/\+/g, '');

        // Dividir os valores
        const scores = cleanedScoreboard.split("-").map(score => parseInt(score.trim()));

        const total = scores.reduce((acc, score) => acc + score, 0);

        if(total < 4)
        {
            return "green";
        }

        return "red";
    }

    static strategyBothScoreYes(item)
    {
        if(item.bothTeamsToScore === "Sim")
        {
            return "green"
        }

        return "red"
    }

    static strategyBothScoreNo(item)
    {
        if(item.bothTeamsToScore === "Não")
        {
            return "green"
        }

        return "red"
    }

    static strategyScoreHomeYes(item)
    {
        if(item.homeTeamsToScore === "Sim")
        {
            return "green"
        }

        return "red"
    }

    static strategyScoreHomeNo(item)
    {
        if(item.homeTeamsToScore === "Não")
        {
            return "green"
        }

        return "red"
    }
    static strategyScoreAwayYes(item)
    {
        if(item.awayTeamsToScore === "Sim")
        {
            return "green"
        }

        return "red"
    }

    static strategyScoreAwayNo(item)
    {
        if(item.awayTeamsToScore === "Não")
        {
            return "green"
        }

        return "red"
    }

    static strategyGolsExats_0_0(item)
    {
         // Remover o sinal "+" dos valores
         const cleanedScoreboard = item.scoreboard.replace(/\+/g, '');

         // Dividir os valores
         const scores = cleanedScoreboard.split("-").map(score => parseInt(score.trim()));

        const total = scores.reduce((acc, score) => acc + score, 0);

        if(total === 0)
        {
            return "green";
        }

        return "red";
    }

    static strategyGolsExats_1_0(item)
    {
        // Remover o sinal "+" dos valores
        const cleanedScoreboard = item.scoreboard.replace(/\+/g, '');

        // Dividir os valores
        const scores = cleanedScoreboard.split("-").map(score => parseInt(score.trim()));

        const total = scores.reduce((acc, score) => acc + score, 0);

        if(total === 1)
        {
            return "green";
        }

        return "red";
    }
    static strategyGolsExats_2_0(item)
    {
        // Remover o sinal "+" dos valores
        const cleanedScoreboard = item.scoreboard.replace(/\+/g, '');

        // Dividir os valores
        const scores = cleanedScoreboard.split("-").map(score => parseInt(score.trim()));

        const total = scores.reduce((acc, score) => acc + score, 0);

        if(total === 2)
        {
            return "green";
        }

        return "red";
    }
    static strategyGolsExats_3_0(item)
    {
        // Remover o sinal "+" dos valores
        const cleanedScoreboard = item.scoreboard.replace(/\+/g, '');

        // Dividir os valores
        const scores = cleanedScoreboard.split("-").map(score => parseInt(score.trim()));

        const total = scores.reduce((acc, score) => acc + score, 0);

        if(total === 3)
        {
            return "green";
        }

        return "red";
    }
    static strategyGolsExats_4_0(item)
    {
        // Remover o sinal "+" dos valores
        const cleanedScoreboard = item.scoreboard.replace(/\+/g, '');

        // Dividir os valores
        const scores = cleanedScoreboard.split("-").map(score => parseInt(score.trim()));

        const total = scores.reduce((acc, score) => acc + score, 0);

        if(total === 4)
        {
            return "green";
        }

        return "red";
    }
    static strategyGolsExats_5_0(item)
    {
        // Remover o sinal "+" dos valores
        const cleanedScoreboard = item.scoreboard.replace(/\+/g, '');

        // Dividir os valores
        const scores = cleanedScoreboard.split("-").map(score => parseInt(score.trim()));

        const total = scores.reduce((acc, score) => acc + score, 0);

        if(total >= 5)
        {
            return "green";
        }

        return "red";
    }

    static strategyNoneScore(item)
    {
        // Remover o sinal "+" dos valores
        const cleanedScoreboard = item.scoreboard.replace(/\+/g, '');

        // Dividir os valores
        const scores = cleanedScoreboard.split("-").map(score => parseInt(score.trim()));

        const total = scores.reduce((acc, score) => acc + score, 0);

        if(total === 0)
        {
            return "green";
        }

        return "red";
    }

    static strategyCentroAvanteHome(item)
    {
        if(item.asianHandicamp.includes("Centro-avante") && item.asianHandicamp.includes(item.team_home))
        {
            return "green";
        }
        return "red";
    }

    static strategyPontaDireitaHome(item)
    {
        if(item.asianHandicamp.includes("Ponta Direita") && item.asianHandicamp.includes(item.team_home))
        {
            return "green";
        }
        return "red";
    }

    static strategyPontaEsquerdaHome(item)
    {
        if(item.asianHandicamp.includes("Ponta Esquerda") && item.asianHandicamp.includes(item.team_home))
        {
            return "green";
        }
        return "red";
    }

    static strategyMeiaAtacanteHome(item)
    {
        if(item.asianHandicamp.includes("Attacking Midfielder") && item.asianHandicamp.includes(item.team_home))
        {
            return "green";
        }
        return "red";
    }

    static strategyQualquerOutroHome(item)
    {
        if(item.asianHandicamp.includes("Qualquer outro jogador") && item.asianHandicamp.includes(item.team_home))
        {
            return "green";
        }
        return "red";
    }

    static strategyCentroAvanteAway(item)
    {
        if(item.asianHandicamp.includes("Centro-avante") && item.asianHandicamp.includes(item.team_away))
        {
            return "green";
        }
        return "red";
    }

    static strategyPontaDireitaAway(item)
    {
        if(item.asianHandicamp.includes("Ponta Direita") && item.asianHandicamp.includes(item.team_away))
        {
            return "green";
        }
        return "red";
    }

    static strategyPontaEsquerdaAway(item)
    {
        if(item.asianHandicamp.includes("Ponta Esquerda") && item.asianHandicamp.includes(item.team_away))
        {
            return "green";
        }
        return "red";
    }

    static strategyMeiaAtacanteAway(item)
    {
        if(item.asianHandicamp.includes("Attacking Midfielder") && item.asianHandicamp.includes(item.team_away))
        {
            return "green";
        }
        return "red";
    }

    static strategyQualquerOutroAway(item)
    {
        if(item.asianHandicamp.includes("Qualquer outro jogador") && item.asianHandicamp.includes(item.team_away))
        {
            return "green";
        }
        return "red";
    }

    //Qualquer outro jogador
//Attacking Midfielder

//<<ESTRATÉGIAS END
}

export default StrategyInterval;