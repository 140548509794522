
import { useContext, useEffect, useState } from 'react';
import { ButtonSide, GlobalContainer, Header, Sidebar, BoxHeaderGradient, BtnHourFilter, BtnOdds, ImageBandeira, ContainerMobileHide, ContainerMobileShow, ContainerMobileOverflow, SideBarMobile, LogoMobile, HeaderMobile, ButtomMenu, BoxChampionshipHeader}  from './style';
import { BsList, BsSpeedometer2, BsGraphUp, BsPlayCircle, BsXLg, BsTags } from 'react-icons/bs';
import { useNavigate } from "react-router-dom";
import { ButtonStyled, Container, ImageLogo, Text } from '../../componets/styles';
import { MainContainer } from './style';
import { BoxChampionship } from './style';
import { BoxDropDown } from './style';
import { BtnChanpionship } from './style';
import { ImgChanpionship } from './style';
import { 

    API_URL_SCRALPER_EUROCUP_RESULT, 
    API_URL_SCRALPER_PREMIERLEAGUERESULTS_RESULT, 
    API_URL_SCRALPER_SOUTHAMERICANSUPERLEAGUE_RESULT, 
    API_URL_SCRALPER_WORLDCUP_RESULT} from '../../config';

import axios from 'axios';
import Maximums from '../../helpers/Maximums';
import Reload from '../../componets/reload';
import ModalOdds from '../Modals/ModalOdds';
import AuthContext from '../../context/AuthContext';
import { formatarData } from '../../share/functions';



export default function Maximims({handlePage})
{

    const {verifieldLogin, user, logout} = useContext(AuthContext);

    const [isSideOpen, setIsSideOpen] = useState(false);

    const [isBoxDropDown, setIsBoxDropDown] = useState(false);

    const [chanpionsShipComponent, setChanpionsShipComponent] = useState({id:1, name: 'Copa do mundo', img:'./images/copa-do-mundo.png', margin:'25px'})

    const [lastResults, setLastResults] = useState([]);

    const [loop, setloop] = useState(false);

    const [lastKnownId, setLastKnownId] = useState("first");

    const [allResults, setAllResults] = useState([]);

    const [hourSelect, setHourSelect] = useState(24);

    const [reload, setReload] = useState(false);

    const [modalOdds, setModalOdds] = useState(false);

    const [gameOddsSelect, setGameOddsSelect] = useState(null);

    const [dateOddsSelect, setDateOddsSelect] = useState(null);
    
    const [selectChampions, setSelectChampions] = useState({id:1, name: 'Copa do mundo', img:'./images/copa-do-mundo.png', margin:'25px'})

    const [sideBarMobile, setSideBarMobile] = useState(false);

    const [statistics, setStatistics] = useState({

        maxImparStreak: 0,
        maxParStreak: 0,
        lastImparStreak: 0,
        lastParStreak: 0,
        lastStreaksUnderOver:{
            "0.5":{under:0, over:0},
            "1.5":{under:0, over:0},
            "2.5":{under:0, over:0},
            "3.5":{under:0, over:0}
        },
        maxStreaksUnderOver:{
            "0.5":{under:0, over:0},
            "1.5":{under:0, over:0},
            "2.5":{under:0, over:0},
            "3.5":{under:0, over:0}
        },
        lastStreaksExactGoals:{
            "0":0,
            "1":0,
            "2":0,
            "3":0,
            "4":0,
            "+5":0
        },
        maxStreaksExactGoals:{
            "0":0,
            "1":0,
            "2":0,
            "3":0,
            "4":0,
            "+5":0
        },
        lastBothScore:{
            bothScored:0,
            neitherScored:0

        },
        maxBothScore:{
            bothScored:0,
            neitherScored:0
        },
        lastResultIntervalEndGame:{
            awayEnd: 0,
            awayInterval: 0,
            homeEnd:0,
            homeInterval:0,
            drawInterval: 0,
            drawEnd: 0
        },
        maxResultIntervalEndGame:{
            awayEnd: 0,
            awayInterval: 0,
            homeEnd:0,
            homeInterval:0,
            drawInterval: 0,
            drawEnd: 0
        },
        lastExactScoreStreaks:{
            home: {
                "1 - 0":0,
                "2 - 0":0,
                "2 - 1":0,
                "3 - 0":0,
                "3 - 1":0,
                "3 - 2":0,
                "4 - 0":0
            },
            away:{

                "0 - 1":0,
                "0 - 2":0,
                "1 - 2":0,
                "0 - 3":0,
                "1 - 3":0,
                "2 - 3":0,
                "0 - 4":0                
            },
            draw:{
                "0 - 0":0,
                "1 - 1":0,
                "2 - 2":0,
                "3 - 3":0,
                "4 - 4":0
            }
        },
        maxExactScoreStreaks:{
            home: {
                "1 - 0":0,
                "2 - 0":0,
                "2 - 1":0,
                "3 - 0":0,
                "3 - 1":0,
                "3 - 2":0,
                "4 - 0":0
            },
            away:{

                "0 - 1":0,
                "0 - 2":0,
                "1 - 2":0,
                "0 - 3":0,
                "1 - 3":0,
                "2 - 3":0,
                "0 - 4":0                
            },
            draw:{
                "0 - 0":0,
                "1 - 1":0,
                "2 - 2":0,
                "3 - 3":0,
                "4 - 4":0
            }
        },
        lastHalfTimeExactScoreStreaks:{
            home: {
                "1-0":0,
                "2-0":0,
            },
            away:{

                "0-1":0,
                "0-2":0,              
            },
            draw:{
                "0-0":0,
                "1-1":0,

            }
        },
        maxHalfTimeExactScoreStreaks:{
            home: {
                "1-0":0,
                "2-0":0,            },
            away:{

                "0-1":0,
                "0-2":0,
            },
            draw:{
                "0-0":0,
                "1-1":0,
            }
        },
        lastFirstScorePlayers: {
            "team_home": {
                "Right Winger": 0,
                "Left Winger": 0,
                "Centre Forward": 0,
                "Ponta esquerda": 0,
                "Ponta direita": 0,
                "Outro jogador": 0,
                "Centro-avante": 0,
                "Meia Atacante": 0,
                "Sem Marcador de Gol": 0
            },
            "team_away": {
                "Right Winger": 0,
                "Left Winger": 0,
                "Centre Forward": 0,
                "Ponta esquerda": 0,
                "Ponta direita": 0,
                "Outro jogador": 0,
                "Centro-avante": 0,
                "Meia Atacante": 0,
                "Sem Marcador de Gol": 0
            },
            "draw":{

                "Sem Marcador de Gol": 0
            }
        },
        maxFirstScorePlayers: {
            "team_home": {
                "Right Winger": 0,
                "Left Winger": 0,
                "Centre Forward": 0,
                "Ponta esquerda": 0,
                "Ponta direita": 0,
                "Outro jogador": 0,
                "Centro-avante": 0,
                "Meia Atacante": 0,
            },
            "team_away": {
                "Right Winger": 0,
                "Left Winger": 0,
                "Centre Forward": 0,
                "Ponta esquerda": 0,
                "Ponta direita": 0,
                "Outro jogador": 0,
                "Centro-avante": 0,
                "Meia Atacante": 0,
            },
            "draw":{

                "Sem Marcador de Gol": 0
            }
        },

        lastFirstTeamScore:{
            "team_home": 0,
            "team_none":0,
            "team_away": 0
        },
        maxFirstTeamScore:{

            "team_home": 0,
            "team_none":0,
            "team_away": 0
        },
        lastLastTeamScore:{
            "team_home": 0,
            "team_none":0,
            "team_away": 0
        },
        maxLastTeamScore:{

            "team_home": 0,
            "team_none":0,
            "team_away": 0
        },
        lastResultsGroups:{
            "team_home":{
                "1-0, 2-0, 2-1":0,
                "3-0, 3-1, 4-0":0
            },
            "team_away":{
                "1-0, 2-0, 2-1":0,
                "3-0, 3-1, 4-0":0
            },
            "draw":{
                "Empate com goals":0
            },
        },
        maxResultsGroups:{
            "team_home":{
                "1-0, 2-0, 2-1":0,
                "3-0, 3-1, 4-0":0
            },
            "team_away":{
                "1-0, 2-0, 2-1":0,
                "3-0, 3-1, 4-0":0
            },
            "draw":{
                "Empate com goals":0
            },
        },
        mostCommonScores:[]
    })


    function handleGames(URL)
    {
        lastKnownId === "first" &&  setReload(true);

        axios.get(`${URL}${lastKnownId}`).then(response=>{
         
            const copyArray = response.data.slice();

            setAllResults(copyArray);

            setLastResults(copyArray.slice(0,25));

            setLastKnownId(copyArray[0].id);

            const maxAndLastStreakNoOddEvenGoals = Maximums.maxAndLastStreakNoOddEvenGoals(copyArray);

            const underAndOver = Maximums.countUnderOver(copyArray);

            const exactGoals = Maximums.currentStreaksForGoals(copyArray);

            const bothTeamsScoreStreaks = Maximums.bothTeamsScoreStreaks(copyArray);

            const teamNotWinningStreaks = Maximums.teamNotWinningStreaks(copyArray);

            const exactScoreStreaks = Maximums.exactScoreStreaks(copyArray);

            const halfTimeScoreStreaks = Maximums.halfTimeScoreStreaks(copyArray);

            const firstScorePlayers = Maximums.firstScorePlayers(copyArray);

            const firstTeamScore = Maximums.firstTeamScore(copyArray);

            const lastTeamScore = Maximums.lastTeamScore(copyArray);

            const resultGroup = Maximums.resultGroup(copyArray);

            const mostCommonScores = Maximums.mostCommonScores(copyArray);


            setStatistics({

                ...statistics,

                lastImparStreak: maxAndLastStreakNoOddEvenGoals.lastNoStreaks.impar,
                lastParStreak: maxAndLastStreakNoOddEvenGoals.lastNoStreaks.par,

                maxImparStreak: maxAndLastStreakNoOddEvenGoals.maxNoStreaks.impar,
                maxParStreak: maxAndLastStreakNoOddEvenGoals.maxNoStreaks.par,

                lastStreaksUnderOver:underAndOver.currentStreaks,
                maxStreaksUnderOver:underAndOver.maxStreaks,

                lastStreaksExactGoals: exactGoals.current,
                maxStreaksExactGoals: exactGoals.max,

                lastBothScore: bothTeamsScoreStreaks.current,
                maxBothScore: bothTeamsScoreStreaks.max,

                lastResultIntervalEndGame: teamNotWinningStreaks.current,
                maxResultIntervalEndGame: teamNotWinningStreaks.max,

                lastExactScoreStreaks: exactScoreStreaks.current,
                maxExactScoreStreaks: exactScoreStreaks.max,

                lastHalfTimeExactScoreStreaks: halfTimeScoreStreaks.current,
                maxHalfTimeExactScoreStreaks: halfTimeScoreStreaks.max,

                lastFirstScorePlayers: firstScorePlayers.current,
                maxFirstScorePlayers: firstScorePlayers.max,

                lastFirstTeamScore: firstTeamScore.current,
                maxFirstTeamScore: firstTeamScore.max,

                lastLastTeamScore: lastTeamScore.current,
                maxLastTeamScore: lastTeamScore.max,

                lastResultsGroups:resultGroup.current,
                maxResultsGroups:resultGroup.max,

                mostCommonScores: mostCommonScores

            })

            setReload(false);

            setTimeout(() => {
                setloop(!loop);
            }, 10000);

        }).catch(error=>{

            console.log(error);
            setReload(false);
            setTimeout(() => {
                setloop(!loop);
            }, 10000);
        })
      }

      async function handleverifieldLogin()
      {
          const status = await verifieldLogin();
  
          if(status === 'Inativo')
          {
            navigate("/payment");
          }
      }

    useEffect(()=>{

        handleverifieldLogin();

        switch (selectChampions.id) {

            case 1:
                handleGames(API_URL_SCRALPER_WORLDCUP_RESULT );
                break;
            case 2:
                handleGames(API_URL_SCRALPER_EUROCUP_RESULT );
                break;
            case 3:
                handleGames(API_URL_SCRALPER_SOUTHAMERICANSUPERLEAGUE_RESULT );
                break;  
            case 4:
                handleGames(API_URL_SCRALPER_PREMIERLEAGUERESULTS_RESULT );
                break;  
            default:
                break;
        }

    },[loop])


    const CHAMPIONS_SHIP = [

        {id:1, name: 'Copa do mundo', img:'./images/copa-do-mundo.png', margin:'25px'},
        {id:2, name: 'Euro copa', img:'./images/euro-copa.png', margin:'10px'},
        {id:3, name: 'Super Liga Super Americana', img:'./images/conmebol-sul-americana.png', margin:'10px'},
        {id:4, name: 'Premier league', img:'./images/premier-league.png', margin:'10px'}
    ]

    const navigate = useNavigate();

    function handleChampionsShip(item)
    {
        setLastKnownId("first");
        setSelectChampions(item)
        setIsBoxDropDown(false);
        setloop(!loop)
    }

    function visibleDrop() {
        setIsBoxDropDown(prev => !prev);
    }


    function limitCharacters(input, maxLength) {
        if (input.length <= maxLength) {
          return input;
        }
        return input.substring(0, maxLength) + "...";
    }

    function extractScore(string) {
        const match = string.match(/\d+-\d+/);
        if (match) {
            return match[0];
        } else {
            return "Placar não encontrado";
        }
    }

    function calculateProportionalGames(hours) {
        const gamesPerHour = 960 / 48; // Isso é 20 jogos por hora
        return hours * gamesPerHour;
      }

    function handleScore(hour)
    {
        const copyArray = allResults;

        const mostCommonScores = Maximums.mostCommonScores(copyArray.slice(0,calculateProportionalGames(hour)));

        setHourSelect(hour)

        setStatistics({
            ...statistics,
            mostCommonScores: mostCommonScores
        })
    }

    function onGameOddsSelect(code, date)
    {
        setGameOddsSelect(code);
        setDateOddsSelect(date);
        setModalOdds(true);
    }



    return(
        <GlobalContainer>
           {reload === true && <Reload/>} 
           <ContainerMobileHide>
            <Header>

                <Container display={'flex'}  items="center"  w="50%" h="100%" ml="50px">

                    <ImageLogo src='./images/logo.png'/>

                    <BoxChampionshipHeader onClick={visibleDrop}>
                        <Container display={'flex'}  items="center" jc="center">
                            <ImgChanpionship src={selectChampions.img} />
                            <Text fw="bold" fs="20px" col='#fff'>{limitCharacters(selectChampions.name, 15)}</Text>
                        </Container>
                    </BoxChampionshipHeader>


                    <BoxDropDown dp={isBoxDropDown === true ? '300px' : '0px'}>
                        {
                            CHAMPIONS_SHIP.map(item => (
                                isBoxDropDown &&
                                <Container key={item.id} mt={item.margin}>
                                    <BtnChanpionship onClick={() => handleChampionsShip(item)}>
                                        <ImgChanpionship src={item.img} />
                                        {item.name}
                                    </BtnChanpionship>
                                </Container>
                            ))
                        }
                    </BoxDropDown>

                </Container>

                <Container w='auto' display='flex' mr="20px" items='center'>
                        <Container w='auto' display='flex' mr="20px" >
                            <Text col='#022c22' fs='20px' fw='bold'>{`Bem vindo! ${user.name} |`}</Text><Text ml='10px' col='#e11d48' fs='20px' fw='bold'>{ `${formatarData(user.validation)}`}</Text>
                        </Container>


                        <ButtonStyled onClick={logout} w='150px' mr='30px' bg='transparent' border='1px solid #fff'>
                            <Text col="#fff" fw='bold' fs='20px'>Sair</Text>
                        </ButtonStyled>
                    </Container>

                </Header>

                <Sidebar open={isSideOpen}>

                    <ButtonSide open={isSideOpen} onClick={()=>{setIsSideOpen(!isSideOpen)}}>
                        <BsList col='#fff' size={45}/>
                        {isSideOpen && <Text col="#fff" fw="bold" ml="10px">Menu</Text>}
                    </ButtonSide>

{/** 
                        <ButtonSide open={isSideOpen}  mt="50px" onClick={()=>{navigate("/home")}}>
                            <BsSpeedometer2 col='#fff' size={35}/>
                            {isSideOpen && <Text col="#fff" fw="bold" ml="10px">Painel</Text>}
                            
                        </ButtonSide>
*/}

                    <ButtonSide open={isSideOpen} bg="#707070" mt="30px" onClick={()=>{navigate("/analysis")}}>
                        <BsGraphUp col='#fff' size={33}/>
                        {isSideOpen && <Text col="#fff" fw="bold" ml="10px">Análise</Text>}
                    </ButtonSide>


                    <ButtonSide open={isSideOpen} mt="30px" onClick={()=>{navigate('/videos')}}>
                        <BsPlayCircle  col='#fff' size={35}/>
                        {isSideOpen && <Text col="#fff" fw="bold" ml="10px">Vídeos</Text>}
                    </ButtonSide>
{/*
                    <ButtonSide open={isSideOpen} mt="30px" onClick={()=>{navigate('/robos')}}>
                        <BsTags  col='#fff' size={35}/>
                        {isSideOpen && <Text col="#fff" fw="bold" ml="10px" >Robôs</Text>}
                    </ButtonSide>
* */}
                </Sidebar>

                <MainContainer >

                    <Container w="98%" pl="20px" pr="20px" pb="20px" pt="20px" display={"flex"} jc="space-between">
                        
                        <ButtonStyled w="16.2%" bg='#15803d' onClick={()=>{handlePage("maximims")}}>
                            <Text col="#fff" fw="bold" fs="25px">MÁXIMAS</Text>
                        </ButtonStyled>
  
                        <ButtonStyled w="16.2%"  onClick={()=>{handlePage("expressCup")}}>
                            <Text col="#fff" fw="bold" fs="25px">EXPRESS CUP</Text>
                        </ButtonStyled>
  
                        <ButtonStyled w="16.2%"  onClick={()=>{handlePage("hour")}}>
                            <Text col="#fff" fw="bold" fs="25px">HORÁRIOS</Text>
                        </ButtonStyled>

                        <ButtonStyled w="16.2%"  onClick={()=>{handlePage("multihour")}}>
                            <Text col="#fff" fw="bold" fs="25px">MULTI HORÁRIOS</Text>
                        </ButtonStyled>

                        <ButtonStyled w="16.2%"  onClick={()=>{handlePage("confrontations")}}>
                            <Text col="#fff" fw="bold" fs="25px">CONFRONTOS</Text>
                        </ButtonStyled>

                        <ButtonStyled w="16.2%" onClick={()=>{handlePage("ranking")}}>
                            <Text col="#fff" fw="bold" fs="25px">RANKING</Text>
                        </ButtonStyled>                     

                    </Container>

                    <Container w="98%" pl="20px" pr="20px" pb="20px" pt="20px" display="flex">
                        <Container w="50%">

                            <BoxHeaderGradient>
                                <Text fs="30px" fw="bold" col="#fff" >GOLS IMPAR/PAR</Text>
                                <Text col='#fff' ml="50px">{"(Sem sair)"}</Text>
                            </BoxHeaderGradient>

                            <Container w="100%" display="flex" jc="space-between" items="center" mt="10px">
                                <Container w="45%">
                                    <Container w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="30px" fw="bold">IMPAR</Text>
                                    </Container>
                                    <Container w="100%" h="50px" bg='#24AB9D' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="30px" fw="bold">{statistics.lastImparStreak}</Text>
                                    </Container>
                                    <Container w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text  fs="18px" fw="bold" col="#FFE500">Max {statistics.maxImparStreak}</Text>
                                    </Container>
                                </Container>
                                <Container w="45%">
                                    <Container w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="30px" fw="bold">PAR</Text>
                                        <Text col='#fff' ml="50px">{"(Sem sair)"}</Text>
                                    </Container>
                                    <Container w="100%" h="50px" bg='#24AB9D' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="30px" fw="bold">{statistics.lastParStreak}</Text>
                                    </Container>
                                    <Container w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text  fs="18px" fw="bold" col="#FFE500">Max {statistics.maxParStreak}</Text>
                                    </Container>
                                </Container>
                            </Container>

                            <BoxHeaderGradient mt="10px">
                                <Text fs="30px" fw="bold" col="#fff" >OVER GOLS</Text>
                                <Text ml="50px" fs="15px" col="#fff">{"(Jogos em sequência)"}</Text>
                            </BoxHeaderGradient>

                            <Container w="100%" display="flex" jc="space-between" items="center" mt="10px">

                                <Container w="45%" display="flex" jc="space-between" items="center">

                                    <Container w="45%">
                                        <Container w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                                            <Text col='#fff' fs="30px" fw="bold">OVER 0.5</Text>
                                        </Container>
                                        <Container w="100%" h="50px" bg='#24AB9D' display="flex" jc="center" items="center">
                                            <Text col='#fff' fs="30px" fw="bold">{statistics.lastStreaksUnderOver.noUnder05Streak} </Text>
                                        </Container>
                                        <Container w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                                            <Text  fs="18px" fw="bold" col="#FFE500">Max {statistics.maxStreaksUnderOver.maxNoUnder05Streak}</Text>
                                        </Container>
                                    </Container>
                                    <Container w="45%">
                                        <Container w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                                            <Text col='#fff' fs="30px" fw="bold">OVER 1.5</Text>
                                        </Container>
                                        <Container w="100%" h="50px" bg='#24AB9D' display="flex" jc="center" items="center">
                                            <Text col='#fff' fs="30px" fw="bold">{statistics.lastStreaksUnderOver.noUnder15Streak}</Text>
                                        </Container>
                                        <Container w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                                            <Text  fs="18px" fw="bold" col="#FFE500">Max {statistics.maxStreaksUnderOver.maxNoUnder15Streak}</Text>
                                        </Container>
                                    </Container>

                                </Container>

                                <Container w="45%" display="flex" jc="space-between" items="center">

                                    <Container w="45%">
                                        <Container w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                                            <Text col='#fff' fs="30px" fw="bold">OVER 2.5</Text>
                                        </Container>
                                        <Container w="100%" h="50px" bg='#24AB9D' display="flex" jc="center" items="center">
                                            <Text col='#fff' fs="30px" fw="bold">{statistics.lastStreaksUnderOver.noUnder25Streak}</Text>
                                        </Container>
                                        <Container w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                                            <Text  fs="18px" fw="bold" col="#FFE500">Max {statistics.maxStreaksUnderOver.maxNoUnder25Streak}</Text>
                                        </Container>
                                    </Container>
                                    <Container w="45%">
                                        <Container w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                                            <Text col='#fff' fs="30px" fw="bold">OVER 3.5</Text>
                                        </Container>
                                        <Container w="100%" h="50px" bg='#24AB9D' display="flex" jc="center" items="center">
                                            <Text col='#fff' fs="30px" fw="bold">{statistics.lastStreaksUnderOver.noUnder35Streak}</Text>
                                        </Container>
                                        <Container w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                                            <Text  fs="18px" fw="bold" col="#FFE500">Max {statistics.maxStreaksUnderOver.maxNoUnder35Streak}</Text>
                                        </Container>
                                    </Container>

                                </Container>

                            </Container>                                

                            <BoxHeaderGradient mt="10px">
                                <Text fs="30px" fw="bold" col="#fff" >UNDER GOLS</Text>
                                <Text ml="50px" fs="15px" col="#fff">{"(Jogos em sequência)"}</Text>
                            </BoxHeaderGradient>

                            <Container w="100%" display="flex" jc="space-between" items="center" mt="10px">
        
                                <Container w="45%" display="flex" jc="space-between" items="center">

                                    <Container w="45%">
                                        <Container w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                                            <Text col='#fff' fs="30px" fw="bold">UNDER 0.5</Text>
                                        </Container>
                                        <Container w="100%" h="50px" bg='#24AB9D' display="flex" jc="center" items="center">
                                            <Text col='#fff' fs="30px" fw="bold">{statistics.lastStreaksUnderOver.noOver05Streak}</Text>
                                        </Container>
                                        <Container w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                                            <Text  fs="18px" fw="bold" col="#FFE500">Max {statistics.maxStreaksUnderOver.maxNoOver05Streak}</Text>
                                        </Container>
                                    </Container>
                                    <Container w="45%">
                                        <Container w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                                            <Text col='#fff' fs="30px" fw="bold">UNDER 1.5</Text>
                                        </Container>
                                        <Container w="100%" h="50px" bg='#24AB9D' display="flex" jc="center" items="center">
                                            <Text col='#fff' fs="30px" fw="bold">{statistics.lastStreaksUnderOver.noOver15Streak}</Text>
                                        </Container>
                                        <Container w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                                            <Text  fs="18px" fw="bold" col="#FFE500">Max {statistics.maxStreaksUnderOver.maxNoOver15Streak}</Text>
                                        </Container>
                                    </Container>

                                </Container>

                                <Container w="45%" display="flex" jc="space-between" items="center">

                                    <Container w="45%">
                                        <Container w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                                            <Text col='#fff' fs="30px" fw="bold">UNDER 2.5</Text>
                                        </Container>
                                        <Container w="100%" h="50px" bg='#24AB9D' display="flex" jc="center" items="center">
                                            <Text col='#fff' fs="30px" fw="bold">{statistics.lastStreaksUnderOver.noOver25Streak}</Text>
                                        </Container>
                                        <Container w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                                            <Text  fs="18px" fw="bold" col="#FFE500">Max {statistics.maxStreaksUnderOver.maxNoOver25Streak}</Text>
                                        </Container>
                                    </Container>
                                    <Container w="45%">
                                        <Container w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                                            <Text col='#fff' fs="30px" fw="bold">UNDER 3.5</Text>
                                        </Container>
                                        <Container w="100%" h="50px" bg='#24AB9D' display="flex" jc="center" items="center">
                                            <Text col='#fff' fs="30px" fw="bold">{statistics.lastStreaksUnderOver.noOver35Streak}</Text>
                                        </Container>
                                        <Container w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                                            <Text  fs="18px" fw="bold" col="#FFE500">Max {statistics.maxStreaksUnderOver.maxNoOver35Streak}</Text>
                                        </Container>
                                    </Container>

                                </Container>

                            </Container>

                            <BoxHeaderGradient mt="10px">
                                <Text fs="30px" fw="bold" col="#fff" >GOLS EXATOS</Text>
                                <Text ml="50px" fs="15px" col="#fff">{"(Jogos em sequência)"}</Text>
                            </BoxHeaderGradient>

                            <Container w="100%" display="flex">

                                <Container w="16.66%">

                                    <Container w="100%" h="40px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="15px" fw="bold">0 GOLS</Text>
                                    </Container>
                                    <Container w="100%" h="50px" bg='#24AB9D' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="18px" fw="bold">{statistics.lastStreaksExactGoals["0"]}</Text>
                                    </Container>
                                    <Container w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text  fs="15px" fw="bold" col="#FFE500">Max {statistics.maxStreaksExactGoals["0"]}</Text>
                                    </Container>

                                </Container>

                                <Container w="16.66%">

                                    <Container w="100%" h="40px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="15px" fw="bold">1 GOLS</Text>
                                    </Container>
                                    <Container w="100%" h="50px" bg='#24AB9D' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="18px" fw="bold">{statistics.lastStreaksExactGoals["1"]}</Text>
                                    </Container>
                                    <Container w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text  fs="15px" fw="bold" col="#FFE500">Max {statistics.maxStreaksExactGoals["1"]}</Text>
                                    </Container>

                                </Container>

                                <Container w="16.66%">

                                    <Container w="100%" h="40px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="15px" fw="bold">2 GOLS</Text>
                                    </Container>
                                    <Container w="100%" h="50px" bg='#24AB9D' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="18px" fw="bold">{statistics.lastStreaksExactGoals["2"]}</Text>
                                    </Container>
                                    <Container w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text  fs="15px" fw="bold" col="#FFE500">Max {statistics.maxStreaksExactGoals["2"]}</Text>
                                    </Container>

                                </Container>

                                <Container w="16.66%">

                                    <Container w="100%" h="40px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="15px" fw="bold">3 GOLS</Text>
                                    </Container>
                                    <Container w="100%" h="50px" bg='#24AB9D' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="18px" fw="bold">{statistics.lastStreaksExactGoals["3"]}</Text>
                                    </Container>
                                    <Container w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text  fs="15px" fw="bold" col="#FFE500">Max {statistics.maxStreaksExactGoals["3"]}</Text>
                                    </Container>

                                </Container>

                                <Container w="16.66%">

                                    <Container w="100%" h="40px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="15px" fw="bold">4 GOLS</Text>
                                    </Container>
                                    <Container w="100%" h="50px" bg='#24AB9D' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="18px" fw="bold">{statistics.lastStreaksExactGoals["4"]}</Text>
                                    </Container>
                                    <Container w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text  fs="15px" fw="bold" col="#FFE500">Max {statistics.maxStreaksExactGoals["4"]}</Text>
                                    </Container>

                                </Container>

                                <Container w="16.66%">

                                    <Container w="100%" h="40px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="15px" fw="bold">+5 GOLS</Text>
                                    </Container>
                                    <Container w="100%" h="50px" bg='#24AB9D' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="18px" fw="bold">{statistics.lastStreaksExactGoals["+5"]}</Text>
                                    </Container>
                                    <Container w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text  fs="15px" fw="bold" col="#FFE500">Max {statistics.maxStreaksExactGoals["+5"]}</Text>
                                    </Container>

                                </Container>
                        
                            </Container> 

                            <Container display="flex" jc="space-between" items="center">
                                <BoxHeaderGradient w="100%" mt="10px" display="flex" jc="space-between" items="center">
                                    <Container w="100%" h="70px" mt="10px" display="flex" jc="center" items="center"> 
                                        <Text fs="25px" fw="bold" col="#fff" >AMBAS MARCAM</Text>
                                    </Container>
                                </BoxHeaderGradient>   
                            </Container>

                            <Container display="flex" jc="space-between" items="center" w="100%" mt="10px">

                                <Container w="45%">
                                    <Container w="100%" h="40px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="15px" fw="bold">AMBAS MARCAM</Text>
                                    </Container>
                                    <Container w="100%" h="50px" bg='#24AB9D' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="18px" fw="bold">{statistics.lastBothScore.bothScored}</Text>
                                    </Container>
                                    <Container w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text  fs="15px" fw="bold" col="#FFE500">Max {statistics.maxBothScore.bothScored}</Text>
                                    </Container>
                                </Container>
                                <Container w="45%">
                                    <Container w="100%" h="40px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="15px" fw="bold">AMBAS NÃO MARCAM</Text>
                                    </Container>
                                    <Container w="100%" h="50px" bg='#24AB9D' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="18px" fw="bold">{statistics.lastBothScore.neitherScored}</Text>
                                    </Container>
                                    <Container w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text  fs="15px" fw="bold" col="#FFE500">Max {statistics.maxBothScore.neitherScored + 1}</Text>
                                    </Container>
                                </Container>

                            </Container>

                            <Container display="flex" jc="space-between" items="center">
                                <BoxHeaderGradient w="100%" mt="10px" display="flex" jc="space-between" items="center">
                                    <Container w="70%" h="70px" mt="10px" display="flex" jc="center" items="center"> 
                                        <Text fs="25px" fw="bold" col="#fff" >RESULTADO CASA X VISITANTE</Text>
                                    </Container>
                                    <Container w="30%" h="70px" mt="10px" display="flex" jc="center" items="center"> 
                                        <Text fs="18px" fw="bold" col="#fff" >{"(Intervalo)"}</Text>
                                    </Container>
                                </BoxHeaderGradient>   
                            </Container>

                            <Container display="flex" jc="space-between" items="center" w="100%" mt="10px">
                                <Container w="30%">
                                    <Container w="100%" h="40px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="15px" fw="bold">MANDANTE</Text>
                                    </Container>
                                    <Container w="100%" h="50px" bg='#24AB9D' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="18px" fw="bold">{statistics.lastResultIntervalEndGame.homeInterval}</Text>
                                    </Container>
                                    <Container w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text  fs="15px" fw="bold" col="#FFE500">Max {statistics.maxResultIntervalEndGame.homeInterval}</Text>
                                    </Container>
                                </Container>
                                <Container w="30%">
                                    <Container w="100%" h="40px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="15px" fw="bold">EMPATE</Text>
                                    </Container>
                                    <Container w="100%" h="50px" bg='#24AB9D' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="18px" fw="bold">{statistics.lastResultIntervalEndGame.drawInterval}</Text>
                                    </Container>
                                    <Container w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text  fs="15px" fw="bold" col="#FFE500">Max {statistics.maxResultIntervalEndGame.drawInterval}</Text>
                                    </Container>
                                </Container>
                                <Container w="30%">
                                    <Container w="100%" h="40px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="15px" fw="bold">VISITANTE</Text>
                                    </Container>
                                    <Container w="100%" h="50px" bg='#24AB9D' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="18px" fw="bold">{statistics.lastResultIntervalEndGame.awayInterval}</Text>
                                    </Container>
                                    <Container w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text  fs="15px" fw="bold" col="#FFE500">Max {statistics.maxResultIntervalEndGame.awayInterval}</Text>
                                    </Container>
                                </Container>
                            </Container>

                            <Container display="flex" jc="space-between" items="center">
                                <BoxHeaderGradient w="100%" mt="10px" display="flex" jc="center" items="center">
                                    <Container w="70%" h="70px" mt="10px" display="flex" jc="center" items="center"> 
                                        <Text fs="25px" fw="bold" col="#fff" >RESULTADO CASA X VISITANTE</Text>
                                    </Container>
                                </BoxHeaderGradient>   
                            </Container>

                            <Container display="flex" jc="space-between" items="center" w="100%" mt="10px">
                                <Container w="30%">
                                    <Container w="100%" h="40px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="15px" fw="bold">MANDANTE</Text>
                                    </Container>
                                    <Container w="100%" h="50px" bg='#24AB9D' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="18px" fw="bold">{statistics.lastResultIntervalEndGame.homeEnd}</Text>
                                    </Container>
                                    <Container w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text  fs="15px" fw="bold" col="#FFE500">Max {statistics.maxResultIntervalEndGame.homeEnd}</Text>
                                    </Container>
                                </Container>
                                <Container w="30%">
                                    <Container w="100%" h="40px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="15px" fw="bold">EMPATE</Text>
                                    </Container>
                                    <Container w="100%" h="50px" bg='#24AB9D' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="18px" fw="bold">{statistics.lastResultIntervalEndGame.drawEnd}</Text>
                                    </Container>
                                    <Container w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text  fs="15px" fw="bold" col="#FFE500">Max {statistics.maxResultIntervalEndGame.drawEnd}</Text>
                                    </Container>
                                </Container>
                                <Container w="30%">
                                    <Container w="100%" h="40px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="15px" fw="bold">VISITANTE</Text>
                                    </Container>
                                    <Container w="100%" h="50px" bg='#24AB9D' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="18px" fw="bold">{statistics.lastResultIntervalEndGame.awayEnd}</Text>
                                    </Container>
                                    <Container w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text  fs="15px" fw="bold" col="#FFE500">Max {statistics.maxResultIntervalEndGame.awayEnd}</Text>
                                    </Container>
                                </Container>
                            </Container>   

                            <BoxHeaderGradient w="100%" mt="10px" display="flex" jc="center" items="center">
                                <Container w="70%" h="70px" mt="10px" display="flex" jc="center" items="center"> 
                                    <Text fs="25px" fw="bold" col="#fff" >PLACAR EXATO</Text>
                                    <Text ml='20px' fs="20px" fw="bold" col="#fff" >(Sem sair)</Text>
                                </Container>
                            </BoxHeaderGradient>

                            <Container 

                                w="99.5%" 
                                                        
                            >

                                <Container w="100%" display="flex" h="40px">
                                    <Container w="33%" h="40px" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >CASA</Text>
                                    </Container>
                                    <Container w="33%" h="40px" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >EMPATE</Text>
                                    </Container>
                                    <Container w="33%" h="40px" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >FORA</Text>
                                    </Container>
                                </Container>

                                <Container  w="100%" h="20px" display="flex" bbtw="1px" bbts="solid" bbtc="#fff" >

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >1-0</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >0-0</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >0-1</Text>
                                    </Container>

                                </Container> 

                                <Container w="100%" h="20px" display="flex">

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastExactScoreStreaks.home["1-0"]}/{statistics.maxExactScoreStreaks.home["1-0"]}</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastExactScoreStreaks.draw["0-0"]}/{statistics.maxExactScoreStreaks.draw["0-0"]}</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastExactScoreStreaks.away["0-1"]}/{statistics.maxExactScoreStreaks.away["0-1"]}</Text>
                                    </Container>

                                </Container>  

                                <Container  w="100%" h="20px" display="flex" bbtw="1px" bbts="solid" bbtc="#fff" >

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >2-0</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >1-1</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >0-2</Text>
                                    </Container>

                                </Container> 

                                <Container w="100%" h="20px" display="flex">

                                <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastExactScoreStreaks.home["2-0"]}/{statistics.maxExactScoreStreaks.home["2-0"]}</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastExactScoreStreaks.draw["1-1"]}/{statistics.maxExactScoreStreaks.draw["1-1"]}</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastExactScoreStreaks.away["0-2"]}/{statistics.maxExactScoreStreaks.away["0-2"]}</Text>
                                    </Container>

                                </Container>  

                                <Container  w="100%" h="20px" display="flex" bbtw="1px" bbts="solid" bbtc="#fff" >

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >2-1</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >2-2</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >1-2</Text>
                                    </Container>

                                </Container> 

                                <Container w="100%" h="20px" display="flex">

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastExactScoreStreaks.home["2-1"]}/{statistics.maxExactScoreStreaks.home["2-1"]}</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastExactScoreStreaks.draw["2-2"]}/{statistics.maxExactScoreStreaks.draw["2-2"]}</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastExactScoreStreaks.away["1-2"]}/{statistics.maxExactScoreStreaks.away["1-2"]}</Text>
                                    </Container>

                                </Container>  

                                <Container  w="100%" h="20px" display="flex" bbtw="1px" bbts="solid" bbtc="#fff" >

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >3-0</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >3-3</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >0-3</Text>
                                    </Container>

                                </Container> 

                                <Container w="100%" h="20px" display="flex">

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastExactScoreStreaks.home["3-0"]}/{statistics.maxExactScoreStreaks.home["2-1"]}</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastExactScoreStreaks.draw["3-3"]}/{statistics.maxExactScoreStreaks.draw["2-2"]}</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastExactScoreStreaks.away["0-3"]}/{statistics.maxExactScoreStreaks.away["1-2"]}</Text>
                                    </Container>

                                </Container> 

                                <Container  w="100%" h="20px" display="flex" bbtw="1px" bbts="solid" bbtc="#fff" >

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >3-1</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >4-4</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >1-3</Text>
                                    </Container>

                                </Container> 

                                <Container w="100%" h="20px" display="flex">

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastExactScoreStreaks.home["3-1"]}/{statistics.maxExactScoreStreaks.home["3-1"]}</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastExactScoreStreaks.draw["4-4"]}/{statistics.maxExactScoreStreaks.draw["4-4"]}</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastExactScoreStreaks.away["1-3"]}/{statistics.maxExactScoreStreaks.away["1-3"]}</Text>
                                    </Container>

                                </Container>  

                                <Container  w="100%" h="20px" display="flex" bbtw="1px" bbts="solid" bbtc="#fff" >

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >3-2</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" ></Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >2-3</Text>
                                    </Container>

                                </Container> 

                                <Container w="100%" h="20px" display="flex">

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastExactScoreStreaks.home["3-2"]}/{statistics.maxExactScoreStreaks.home["3-2"]}</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" ></Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastExactScoreStreaks.away["2-3"]}/{statistics.maxExactScoreStreaks.away["2-3"]}</Text>
                                    </Container>

                                </Container> 

                                <Container  w="100%" h="20px" display="flex" bbtw="1px" bbts="solid" bbtc="#fff" >

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >4-0</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" ></Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >0-4</Text>
                                    </Container>

                                </Container> 

                                <Container w="100%" h="20px" display="flex">

                                <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastExactScoreStreaks.home["4-0"]}/{statistics.maxExactScoreStreaks.home["4-0"]}</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" ></Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastExactScoreStreaks.away["0-4"]}/{statistics.maxExactScoreStreaks.away["0-4"]}</Text>
                                    </Container>

                                </Container> 

                            </Container>

                            <BoxHeaderGradient w="100%" mt="10px" display="flex" jc="center" items="center">
                                <Container w="70%" h="70px" mt="10px" display="flex" jc="center" items="center"> 
                                    <Text fs="25px" fw="bold" col="#fff" >PLACAR EXATO NO INTERVALO</Text>
                                    <Text ml='20px' fs="20px" fw="bold" col="#fff" >(Sem sair)</Text>
                                </Container>
                            </BoxHeaderGradient>

                            <Container
                                w="99.5%" 
                            >
                                <Container w="100%" display="flex" h="40px">
                                    <Container w="33%" h="40px" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >CASA</Text>
                                    </Container>
                                    <Container w="33%" h="40px" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >EMPATE</Text>
                                    </Container>
                                    <Container w="33%" h="40px" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >FORA</Text>
                                    </Container>
                                </Container>
                                <Container  w="100%" h="20px" display="flex" bbtw="1px" bbts="solid" bbtc="#fff" >

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >1-0</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >0-0</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >0-1</Text>
                                    </Container>

                                </Container>
                                <Container w="100%" h="20px" display="flex">

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastHalfTimeExactScoreStreaks.home["1-0"]}/{statistics.maxHalfTimeExactScoreStreaks.home["1-0"]}</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastHalfTimeExactScoreStreaks.draw["0-0"]}/{statistics.maxHalfTimeExactScoreStreaks.draw["0-0"]}</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastHalfTimeExactScoreStreaks.away["0-1"]}/{statistics.maxHalfTimeExactScoreStreaks.away["0-1"]}</Text>
                                    </Container>

                                </Container>  

                                <Container  w="100%" h="20px" display="flex" bbtw="1px" bbts="solid" bbtc="#fff" >

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >2-0</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >1-1</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >0-2</Text>
                                    </Container>

                                </Container> 

                                <Container w="100%" h="20px" display="flex">

                                <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastHalfTimeExactScoreStreaks.home["2-0"]}/{statistics.maxHalfTimeExactScoreStreaks.home["2-0"]}</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastHalfTimeExactScoreStreaks.draw["1-1"]}/{statistics.maxHalfTimeExactScoreStreaks.draw["1-1"]}</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastHalfTimeExactScoreStreaks.away["0-2"]}/{statistics.maxHalfTimeExactScoreStreaks.away["0-2"]}</Text>
                                    </Container>

                                </Container>  

                            </Container>
{/*
                            <BoxHeaderGradient w="100%" mt="10px" display="flex" jc="center" items="center">
                                <Container w="70%" h="70px" mt="10px" display="flex" jc="center" items="center"> 
                                    <Text fs="25px" fw="bold" col="#fff" >PRIMEIRO A MARCAR</Text>
                                </Container>
                            </BoxHeaderGradient>

                            <Container w="100%"  display="flex" bbbw="1px" bbbs="solid" bbbc="#fff">
                                <Container w="50%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="18px" fw="bold" col="#fff" >CASA</Text>
                                </Container>
                                <Container w="50%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="18px" fw="bold" col="#fff" >FORA</Text>
                                </Container>
                            </Container>
                            
                            <Container w="100%"  >

                                <Container w="100%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="20px" fw="bold" col="#fff" >Sem marcar gol</Text>
                                </Container>

                            </Container>

                            <Container w="100%" display="flex" bbbw="1px" bbbs="solid" bbbc="#fff">

                                <Container w="100%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastFirstScorePlayers["draw"]["Sem Marcador de Gol"]}/{statistics.maxFirstScorePlayers["draw"]["Sem Marcador de Gol"]}</Text>
                                </Container>

                            </Container>

                            <Container w="100%"  display="flex" >
                                <Container w="50%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >Centro avante</Text>
                                </Container>

                                <Container w="50%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >Centro avate</Text>
                                </Container>
                            </Container>

                            <Container w="100%"  display="flex" bbbw="1px" bbbs="solid" bbbc="#fff">
                                <Container w="50%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastFirstScorePlayers["team_home"]["Centro-avante"]}/{statistics.maxFirstScorePlayers["team_home"]["Centro-avante"]}</Text>
                                </Container>

                                <Container w="50%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastFirstScorePlayers["team_away"]["Centro-avante"]}/{statistics.maxFirstScorePlayers["team_away"]["Centro-avante"]}</Text>
                                </Container>
                            </Container>

                            <Container w="100%"  display="flex" >
                                <Container w="50%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >Ponta direita</Text>
                                </Container>

                                <Container w="50%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >Ponta direita</Text>
                                </Container>
                            </Container>
                            
                            <Container w="100%"  display="flex" bbbw="1px" bbbs="solid" bbbc="#fff">
                                <Container w="50%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastFirstScorePlayers["team_home"]["Ponta Direita"]}/{statistics.maxFirstScorePlayers["team_home"]["Ponta Direita"]}</Text>
                                </Container>

                                <Container w="50%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastFirstScorePlayers["team_away"]["Ponta Direita"]}/{statistics.maxFirstScorePlayers["team_away"]["Ponta Direita"]}</Text>
                                </Container>
                            </Container>

                            <Container w="100%"  display="flex" >
                                <Container w="50%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >Ponta esquerda</Text>
                                </Container>

                                <Container w="50%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >Ponta esquerda</Text>
                                </Container>
                            </Container>
                            
                            <Container w="100%"  display="flex" bbbw="1px" bbbs="solid" bbbc="#fff">
                                <Container w="50%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastFirstScorePlayers["team_home"]["Ponta Esquerda"]}/{statistics.maxFirstScorePlayers["team_home"]["Ponta Esquerda"]}</Text>
                                </Container>

                                <Container w="50%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastFirstScorePlayers["team_away"]["Ponta Esquerda"]}/{statistics.maxFirstScorePlayers["team_away"]["Ponta Esquerda"]}</Text>
                                </Container>
                            </Container>

                            <Container w="100%"  display="flex" >
                                <Container w="50%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >Meia atacante</Text>
                                </Container>

                                <Container w="50%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >Meia atacante</Text>
                                </Container>
                            </Container>
                            
                            <Container w="100%"  display="flex" bbbw="1px" bbbs="solid" bbbc="#fff">
                                <Container w="50%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastFirstScorePlayers["team_home"]["Meia Atacante"]}/{statistics.maxFirstScorePlayers["team_home"]["Meia Atacante"]}</Text>
                                </Container>

                                <Container w="50%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastFirstScorePlayers["team_away"]["Meia Atacante"]}/{statistics.maxFirstScorePlayers["team_away"]["Meia Atacante"]}</Text>
                                </Container>
                            </Container>

                            <Container w="100%"  display="flex" >
                                <Container w="50%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >Qualquer outra posição</Text>
                                </Container>

                                <Container w="50%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >Qualquer outra posição</Text>
                                </Container>
                            </Container>
                            
                            <Container w="100%"  display="flex" bbbw="1px" bbbs="solid" bbbc="#fff">
                                <Container w="50%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastFirstScorePlayers["team_home"]["Qualquer outro jogador"]}/{statistics.maxFirstScorePlayers["team_home"]["Qualquer outro jogador"]}</Text>
                                </Container>

                                <Container w="50%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastFirstScorePlayers["team_away"]["Qualquer outro jogador"]}/{statistics.maxFirstScorePlayers["team_away"]["Qualquer outro jogador"]}</Text>
                                </Container>
                            </Container>
*/}
                            <BoxHeaderGradient w="100%" mt="10px" display="flex" jc="center" items="center">
                                <Container w="70%" mt="10px" display="flex" jc="center" items="center"> 
                                    <Text fs="25px" fw="bold" col="#fff" >TIME A MARCAR PRIMEIRO</Text>
                                </Container>
                            </BoxHeaderGradient>

                            <Container w="100%" display="flex" h="40px" bbbw="1px" bbbs="solid" bbbc="#fff">
                                <Container w="33%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >CASA</Text>
                                </Container>
                                <Container w="33%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >NENHUM</Text>
                                </Container>
                                <Container w="33%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >FORA</Text>
                                </Container>
                            </Container>

                            <Container w="100%"  display="flex" bbbw="1px" bbbs="solid" bbbc="#fff">
                                <Container w="33%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastFirstTeamScore["team_home"]}/{statistics.maxFirstTeamScore["team_home"]}</Text>
                                </Container>

                                <Container w="33%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastFirstTeamScore["team_none"]}/{statistics.maxFirstTeamScore["team_none"]}</Text>
                                </Container>

                                <Container w="33%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastFirstTeamScore["team_away"]}/{statistics.maxFirstTeamScore["team_away"]}</Text>
                                </Container>
                            </Container>

                            <BoxHeaderGradient w="100%" mt="10px" display="flex" jc="center" items="center">
                                <Container w="70%" h="70px" mt="10px" display="flex" jc="center" items="center"> 
                                    <Text fs="25px" fw="bold" col="#fff" >TIME A MARCAR POR ÚLTIMO</Text>
                                </Container>
                            </BoxHeaderGradient>

                            <Container w="100%" display="flex" h="40px" bbbw="1px" bbbs="solid" bbbc="#fff">
                                <Container w="33%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >CASA</Text>
                                </Container>
                                <Container w="33%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >NENHUM</Text>
                                </Container>
                                <Container w="33%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >FORA</Text>
                                </Container>
                            </Container>

                            <Container w="100%"  display="flex" bbbw="1px" bbbs="solid" bbbc="#fff">
                                <Container w="33%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastLastTeamScore["team_home"]}/{statistics.maxLastTeamScore["team_home"]}</Text>
                                </Container>

                                <Container w="33%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastLastTeamScore["team_none"]}/{statistics.maxLastTeamScore["team_none"]}</Text>
                                </Container>

                                <Container w="33%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastLastTeamScore["team_away"]}/{statistics.maxLastTeamScore["team_away"]}</Text>
                                </Container>
                            </Container>

                            <BoxHeaderGradient w="100%" mt="10px" display="flex" jc="center" items="center">
                                <Container w="70%" h="70px" mt="10px" display="flex" jc="center" items="center"> 
                                    <Text fs="25px" fw="bold" col="#fff" >RESULTADO CORRETO GRUPO</Text>
                                    <Text ml='20px' fs="16px" fw="normal" col="#fff" >(Sem sair)</Text>
                                </Container>
                            </BoxHeaderGradient>

                            <Container w="100%" display="flex" h="40px" bbbw="1px" bbbs="solid" bbbc="#fff">
                                <Container w="33%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >CASA</Text>
                                </Container>
                                <Container w="33%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >NENHUM</Text>
                                </Container>
                                <Container w="33%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >FORA</Text>
                                </Container>
                            </Container>



                            <Container w="100%"  display="flex" >
                                <Container w="50%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >1-0, 2-0, 2-1</Text>
                                </Container>

                                <Container w="50%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >Empate-com-Gols</Text>
                                </Container>

                                <Container w="50%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >1-0, 2-0, 2-1</Text>
                                </Container>
                            </Container>

                            <Container w="100%"  display="flex" bbbw="1px" bbbs="solid" bbbc="#fff">
                                <Container w="50%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastResultsGroups['team_home']['1-0, 2-0, 2-1']}/{statistics.maxResultsGroups["team_home"]["1-0, 2-0, 2-1"]}</Text>
                                </Container>

                                <Container w="50%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastResultsGroups["draw"]["Empate com goals"]}/{statistics.maxResultsGroups["draw"]["Empate com goals"]}</Text>
                                </Container>

                                <Container w="50%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastResultsGroups["team_away"]["1-0, 2-0, 2-1"]}/{statistics.maxResultsGroups["team_away"]["1-0, 2-0, 2-1"]}</Text>
                                </Container>
                            </Container>

                            <Container w="100%"  display="flex" >
                                <Container w="50%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >3-0, 3-1, 4-0</Text>
                                </Container>

                                <Container w="50%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" ></Text>
                                </Container>

                                <Container w="50%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >3-0, 3-1, 4-0</Text>
                                </Container>
                            </Container>

                            <Container w="100%"  display="flex" bbbw="1px" bbbs="solid" bbbc="#fff">
                                <Container w="50%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastResultsGroups['team_home']["3-0, 3-1, 4-0"]}/{statistics.maxResultsGroups["team_home"]["3-0, 3-1, 4-0"]}</Text>
                                </Container>

                                <Container w="50%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" ></Text>
                                </Container>

                                <Container w="50%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastResultsGroups["team_away"]["3-0, 3-1, 4-0"]}/{statistics.maxResultsGroups["team_away"]["3-0, 3-1, 4-0"]}</Text>
                                </Container>
                            </Container>



                        </Container>



                        <Container w="49%" ml="1%" >
                            
                            <Container w="100%" h="40px" display="flex" jc="center" items="center" bg="#2F2F2F">
                                <Text fs="20px" fw="bold" col="#fff">Placares que mais se repetiram nas últimas horas</Text>
                            </Container>

                            <Container w="100%" display="flex" jc="space-between" bg="#2F2F2F">

                                {statistics.mostCommonScores.map(item=>{
                                    if(item.id < 3)
                                    {
                                        return(
                                        
                                            <Container key={item.id} ml="20px" display="flex" jc="center" items="center" w="30%" h="50px" bblw="5px" bbls="solid" rd="10px" bblc="#24AB9D" mt="10px">
                                                <Container w="50%" h="100%" display="flex" jc="center" items="center" bg="#1C524C">
                                                    <Text fs="20px" fw="bold" col="#fff">{item.scoreboard}</Text>
                                                </Container>
                                                <Container w="50%" h="100%" display="flex" jc="center" items="center" bg="#D9D9D9">
                                                    <Text fs="20px" fw="bold" col="#032E2D">{item.count}</Text>
                                                </Container>
                                            </Container>
                                        )
                                    }
                                })}
                            </Container>

                            <Container w="100%" display="flex" jc="space-between" bg="#2F2F2F">
                                {statistics.mostCommonScores.map(item=>{
                                    if(item.id >= 3 && item.id < 6)
                                    {
                                        return(
                                        
                                            <Container key={item.id} ml="20px" display="flex" jc="center" items="center" w="30%" h="50px" bblw="5px" bbls="solid" rd="10px" bblc="#24AB9D" mt="10px">
                                                <Container w="50%" h="100%" display="flex" jc="center" items="center" bg="#1C524C">
                                                    <Text fs="20px" fw="bold" col="#fff">{item.scoreboard}</Text>
                                                </Container>
                                                <Container w="50%" h="100%" display="flex" jc="center" items="center" bg="#D9D9D9">
                                                    <Text fs="20px" fw="bold" col="#032E2D">{item.count}</Text>
                                                </Container>
                                            </Container>
                                        )
                                    }
                                })}
                            </Container>


                            <Container w="100%" display="flex" jc="space-between" bg="#2F2F2F">
                                {statistics.mostCommonScores.map(item=>{
                                    if(item.id >= 6)
                                    {
                                        return(
                                        
                                            <Container key={item.id} ml="20px" display="flex" jc="center" items="center" w="30%" h="50px" bblw="5px" bbls="solid" rd="10px" bblc="#24AB9D" mt="10px">
                                                <Container w="50%" h="100%" display="flex" jc="center" items="center" bg="#1C524C">
                                                    <Text fs="20px" fw="bold" col="#fff">{item.scoreboard}</Text>
                                                </Container>
                                                <Container w="50%" h="100%" display="flex" jc="center" items="center" bg="#D9D9D9">
                                                    <Text fs="20px" fw="bold" col="#032E2D">{item.count}</Text>
                                                </Container>
                                            </Container>
                                        )
                                    }
                                })}
                            </Container>


                            <Container w="100%" h="60px" bg="#2F2F2F" mt='10px'>
                                <Text ml="20px" fs="15px" col="#fff">Filtro das ultimas horas</Text>
                                <Container w="100%" display="flex" bg="#2F2F2F" mt='10px'>  
                                
                                    {hourSelect === 6
                                    ?<BtnHourFilter onClick={()=>{handleScore(6)}} col="#fff" bg="#2F2F2F" ml="10px">6</BtnHourFilter>
                                    :<BtnHourFilter onClick={()=>{handleScore(6)}}  ml="20px">6</BtnHourFilter>
                                    }
                                    {hourSelect === 12
                                    ?<BtnHourFilter onClick={()=>{handleScore(12)}} col="#fff" bg="#2F2F2F"  ml="10px">12</BtnHourFilter>
                                    :<BtnHourFilter onClick={()=>{handleScore(12)}}  ml="10px">12</BtnHourFilter>
                                    }
                                    {hourSelect === 24
                                    ?<BtnHourFilter onClick={()=>{handleScore(24)}} col="#fff" bg="#2F2F2F"  ml="10px">24</BtnHourFilter>
                                    :<BtnHourFilter onClick={()=>{handleScore(24)}}  ml="10px">24</BtnHourFilter>
                                    }

                                </Container>
                            </Container>

                            <BoxHeaderGradient w="100%" mt="30px" display="flex" jc="center" items="center">
                                <Container w="70%" h="70px" mt="10px" display="flex" jc="center" items="center"> 
                                    <Text fs="25px" fw="bold" col="#fff" >ÚTIMOS RESULTADOS</Text>
                                </Container>
                            </BoxHeaderGradient>

                            <Container w="100%" display="flex" jc="space-between" items="center" mt="10px" bg="#2F2F2F">
                                <Container w="40%" h="40px" display="flex" jc="center" items="center"> 
                                    <Text fs="20px" fw="bold" col='#fff'>Hora</Text>
                                </Container>
                                <Container w="20%" h="40px" display="flex" jc="center" items="center"> 
                                    <Text fs="20px" fw="bold" col='#fff' >Casa</Text>
                                </Container>
                                <Container w="20%" h="40px" display="flex" jc="center" items="center"> 
                                    <Text fs="20px" fw="bold" col="#fff" >Placar</Text>
                                </Container>
                                <Container w="20%" h="40px" display="flex" jc="center" items="center"> 
                                    <Text fs="20px" fw="bold" col='#fff' >Fora</Text>
                                </Container>
                            </Container>

                            {lastResults.map(item=>{
                                return(
                                        <Container key={item.id} w="100%" h="110px" display="flex" jc="space-between" items="center" bg="#2F2F2F" bbbw="1px" bbbs="solid" bbbc="#fff">
                                            <Container w="40%" display="flex" jc="space-between" items="center">
                                                <Container w="33%" display="flex" jc="center" items="center">
                                                    <BtnOdds ml="20px" onClick={()=>{onGameOddsSelect(item.code, item.date_time)}}>
                                                        <Text fw="bold">ODDS</Text>
                                                    </BtnOdds>
                                                </Container>
                                                <Container w="33%" display="flex" jc="center" items="center">
                                                    <Text fs="20px" col="#fff">{item.time}</Text>
                                                </Container>
                                                <Container w="33%" display="flex" jc="center" items="center">
                                                    <Text fs="20px" col="#fff"></Text>
                                                </Container>
                                            </Container>
                
                                            <Container 
                                            w="20%"
                                            display="flex" 
                                            jc="center" 
                                            items="center" 
                                            fd="column"
                                            >
                                                <ImageBandeira src={`./bandeiras/${item.team_home}.png`}/>
                
                                                <Text fs="20px" col="#fff">{item.team_home}</Text>
                                            </Container>
                
                                            <Container 
                                            w="20%"
                                            display="flex" 
                                            jc="center" 
                                            items="center" 
                                            fd="column"
                                            >
                                                <Container w="auto">
                                                    <Text fs="25px" col="#FFE500">{item.scoreboard}</Text>
                                                </Container>                                            
                                                
                                            </Container>
                
                                            <Container 
                                            w="20%"
                                            display="flex" 
                                            jc="center" 
                                            items="center" 
                                            fd="column"
                                            >
                                                <ImageBandeira src={`./bandeiras/${item.team_away}.png`}/>
                
                                                <Text fs="20px" col="#fff">{item.team_away}</Text>
                                            </Container>
                
                
                
                                        </Container>
                                )
                            })}

                            <Container w="100%" bg="#2F2F2F" h="20px"></Container>{/**spacing */}



                        </Container>

                    </Container>
                
                </MainContainer>
            </ContainerMobileHide>


            {/**TEMPLATE MOBILE */}
            <ContainerMobileShow>

                <HeaderMobile>

                    <ButtonStyled onClick={()=>{setSideBarMobile(true)}} bg='transparent' w='auto'>
                        <BsList color='#fff' size={30}/>
                    </ButtonStyled>

                    <Container w='auto' display='flex' >
                       <Text ml='5px' col='#e11d48' fs='15px' fw='bold'>{ `${formatarData(user.validation)}`}</Text>
                    </Container>

                    <LogoMobile src='./images/logo.png'/>
                    
                </HeaderMobile>

                <SideBarMobile open={sideBarMobile}>

                    <Container w='100%' display='flex' jc='flex-end'>
                        <ButtonStyled onClick={()=>{setSideBarMobile(false)}} bg='transparent' w='auto' mr='20px'>
                            <BsXLg color='#fff' size={30}/>
                        </ButtonStyled>
                    </Container>

                    <Container w='100%' mt='40px'>

                        <ButtomMenu onClick={()=>{navigate("/home")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Dashboard</Text>
                        </ButtomMenu>
                        <ButtomMenu onClick={()=>{handlePage("maximims")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Máximas</Text>
                        </ButtomMenu>

                        <ButtomMenu onClick={()=>{handlePage("expressCup")}}>
                            <Text col='#fff' fw='bold' fs='20px'>ExpressCup</Text>
                        </ButtomMenu>
  
                        <ButtomMenu onClick={()=>{handlePage("hour")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Horários</Text>
                        </ButtomMenu>
                        <ButtomMenu onClick={()=>{handlePage("multihour")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Multihorários</Text>
                        </ButtomMenu>
                        <ButtomMenu onClick={()=>{handlePage("confrontations")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Confrontos</Text>
                        </ButtomMenu>
                        <ButtomMenu onClick={()=>{handlePage("ranking")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Ranking</Text>
                        </ButtomMenu>
                        <ButtomMenu onClick={()=>{navigate("/videos")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Videos</Text>
                        </ButtomMenu>
                        {/**
                         * 
                         *                         <ButtomMenu onClick={()=>{navigate("/robos")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Robôs</Text>
                        </ButtomMenu>
                         * 
                         * 
                         */}


                    </Container>

                </SideBarMobile>

                <ContainerMobileOverflow>

                    <Container w="100%">

                        <Container display={'flex'}  items="center"  w="100%" h="100%">

                            <BoxChampionship onClick={visibleDrop}>
                                <Container display={'flex'}  items="center" jc="center">
                                    <ImgChanpionship src={selectChampions.img} />
                                    <Text fw="bold" fs="20px" col='#fff'>{limitCharacters(selectChampions.name, 15)}</Text>
                                </Container>
                            </BoxChampionship>


                            <BoxDropDown dp={isBoxDropDown === true ? '300px' : '0px'}>
                                {
                                    CHAMPIONS_SHIP.map(item => (
                                        isBoxDropDown &&
                                        <Container key={item.id} mt={item.margin}>
                                            <BtnChanpionship onClick={() => handleChampionsShip(item)}>
                                                <ImgChanpionship src={item.img} />
                                                {item.name}
                                            </BtnChanpionship>
                                        </Container>
                                    ))
                                }
                            </BoxDropDown>

                        </Container>

                        <BoxHeaderGradient h='40px' mt='10px'>
                            <Text fs="15px" fw="bold" col="#fff" >GOLS IMPAR/PAR</Text>
                            <Text ml="50px" fs="15px" col="#fff">{"(Sem sair)"}</Text>
                        </BoxHeaderGradient>

                        <Container w="100%" display="flex" jc="space-between" items="center" mt="10px">
                            <Container w="45%">
                                <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                    <Text col='#fff' fs="15px" fw="bold">IMPAR</Text>
                                </Container>
                                <Container w="100%" h="30px" bg='#24AB9D' display="flex" jc="center" items="center">
                                    <Text col='#fff' fs="15px" fw="bold">{statistics.lastImparStreak}</Text>
                                </Container>
                                <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                    <Text  fs="15px" fw="bold" col="#FFE500">Max {statistics.maxImparStreak}</Text>
                                </Container>
                            </Container>
                            <Container w="45%">
                                <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                    <Text col='#fff' fs="15px" fw="bold">PAR</Text>
                                    
                                </Container>
                                <Container w="100%" h="30px" bg='#24AB9D' display="flex" jc="center" items="center">
                                    <Text col='#fff' fs="15px" fw="bold">{statistics.lastParStreak}</Text>
                                </Container>
                                <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                    <Text  fs="15px" fw="bold" col="#FFE500">Max {statistics.maxParStreak}</Text>
                                </Container>
                            </Container>
                        </Container>



                    <BoxHeaderGradient mt="10px"h="40px" >
                        <Text fs="15px" fw="bold" col="#fff" >OVER GOLS</Text>
                        <Text ml="50px" fs="15px" col="#fff">{"(Jogos em sequência)"}</Text>
                    </BoxHeaderGradient>

                    <Container w="100%" display="flex" jc="space-between" items="center" mt="10px">
                        <Container w="45%" display="flex" jc="space-between" items="center">
                            <Container w="45%">
                                <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                    <Text col='#fff' fs="13px" fw="bold" h="30px">OVER 0.5</Text>
                                </Container>
                                <Container w="100%" h="30px" bg='#24AB9D' display="flex" jc="center" items="center">
                                    <Text col='#fff' fs="15px" fw="bold" h="30px">{statistics.lastStreaksUnderOver.noUnder05Streak} </Text>
                                </Container>
                                <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500">Max {statistics.maxStreaksUnderOver.maxNoUnder05Streak}</Text>
                                </Container>
                            </Container>
                            <Container w="45%">
                                <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                    <Text col='#fff' fs="13px" fw="bold" h="30px">OVER 1.5</Text>
                                </Container>
                                <Container w="100%" h="30px" bg='#24AB9D' display="flex" jc="center" items="center">
                                    <Text col='#fff' fs="15px" fw="bold" h="30px">{statistics.lastStreaksUnderOver.noUnder15Streak}</Text>
                                </Container>
                                <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500">Max {statistics.maxStreaksUnderOver.maxNoUnder15Streak}</Text>
                                </Container>
                            </Container>
                        </Container>
                        <Container w="45%" display="flex" jc="space-between" items="center">
                            <Container w="45%">
                                <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                    <Text col='#fff' fs="13px" fw="bold" >OVER 2.5</Text>
                                </Container>
                                <Container w="100%" h="30px" bg='#24AB9D' display="flex" jc="center" items="center">
                                    <Text col='#fff' fs="15px" fw="bold" >{statistics.lastStreaksUnderOver.noUnder25Streak}</Text>
                                </Container>
                                <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500">Max {statistics.maxStreaksUnderOver.maxNoUnder25Streak}</Text>
                                </Container>
                            </Container>
                            <Container w="45%">
                                <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                    <Text col='#fff' fs="13px" fw="bold" h="30px">OVER 3.5</Text>
                                </Container>
                                <Container w="100%" h="30px" bg='#24AB9D' display="flex" jc="center" items="center">
                                    <Text col='#fff' fs="15px" fw="bold" h="30px">{statistics.lastStreaksUnderOver.noUnder35Streak}</Text>
                                </Container>
                                <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500">Max {statistics.maxStreaksUnderOver.maxNoUnder35Streak}</Text>
                                </Container>
                            </Container>
                        </Container>
                    </Container>

                    <BoxHeaderGradient h='40px' mt="10px">
                            <Text fs="15px" fw="bold" col="#fff" >UNDER GOLS</Text>
                            <Text ml="50px" fs="15px" col="#fff">{"(Jogos em sequência)"}</Text>
                        </BoxHeaderGradient>

                        <Container w="100%" display="flex" jc="space-between" items="center" mt="10px">

                            <Container w="45%" display="flex" jc="space-between" items="center">

                                <Container w="45%">
                                    <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="15px" fw="bold">UNDER 0.5</Text>
                                    </Container>
                                    <Container w="100%" h="30px" bg='#24AB9D' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="15px" fw="bold">{statistics.lastStreaksUnderOver.noOver05Streak}</Text>
                                    </Container>
                                    <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text  fs="15px" fw="bold" col="#FFE500">Max {statistics.maxStreaksUnderOver.maxNoOver05Streak}</Text>
                                    </Container>
                                </Container>
                                <Container w="45%">
                                    <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="15px" fw="bold">UNDER 1.5</Text>
                                    </Container>
                                    <Container w="100%" h="30px" bg='#24AB9D' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="15px" fw="bold">{statistics.lastStreaksUnderOver.noOver15Streak}</Text>
                                    </Container>
                                    <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text  fs="15px" fw="bold" col="#FFE500">Max {statistics.maxStreaksUnderOver.maxNoOver15Streak}</Text>
                                    </Container>
                                </Container>

                            </Container>

                            <Container w="45%" display="flex" jc="space-between" items="center">

                                <Container w="45%">
                                    <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="15px" fw="bold">UNDER 2.5</Text>
                                    </Container>
                                    <Container w="100%" h="30px" bg='#24AB9D' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="15px" fw="bold">{statistics.lastStreaksUnderOver.noOver25Streak}</Text>
                                    </Container>
                                    <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text  fs="15px" fw="bold" col="#FFE500">Max {statistics.maxStreaksUnderOver.maxNoOver25Streak}</Text>
                                    </Container>
                                </Container>
                                <Container w="45%">
                                    <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="15px" fw="bold">UNDER 3.5</Text>
                                    </Container>
                                    <Container w="100%" h="30px" bg='#24AB9D' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="15px" fw="bold">{statistics.lastStreaksUnderOver.noOver35Streak}</Text>
                                    </Container>
                                    <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text  fs="15px" fw="bold" col="#FFE500">Max {statistics.maxStreaksUnderOver.maxNoOver35Streak}</Text>
                                    </Container>
                                </Container>

                            </Container>
                        </Container>
                    </Container>                   
  
                    <BoxHeaderGradient mt="10px" h='40px'>
                        <Text fs="12px" fw="bold" col="#fff" >GOLS EXATOS</Text>
                        <Text ml="50px" fs="12px" col="#fff" >{"(Jogos em sequência)"}</Text>
                    </BoxHeaderGradient>

                    <Container w="100%" display="flex">

                        <Container w="16.66%">

                            <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                <Text col='#fff' fs="12px" fw="bold" h="30px">0 GOLS</Text>
                            </Container>
                            <Container w="100%" h="30px" bg='#24AB9D' display="flex" jc="center" items="center">
                                <Text col='#fff' fs="12px" fw="bold" h="30px">{statistics.lastStreaksExactGoals["0"]}</Text>
                            </Container>
                            <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                <Text fs="12px" fw="bold" col="#FFE500" h="30px">Max {statistics.maxStreaksExactGoals["0"]}</Text>
                            </Container>

                        </Container>

                        <Container w="16.66%">

                            <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                <Text col='#fff' fs="12px" fw="bold" h="30px">1 GOLS</Text>
                            </Container>
                            <Container w="100%" h="30px" bg='#24AB9D' display="flex" jc="center" items="center">
                                <Text col='#fff' fs="12px" fw="bold" h="30px">{statistics.lastStreaksExactGoals["1"]}</Text>
                            </Container>
                            <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                <Text fs="12px" fw="bold" col="#FFE500" h="30px">Max {statistics.maxStreaksExactGoals["1"]}</Text>
                            </Container>

                        </Container>

                        <Container w="16.66%">

                            <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                <Text col='#fff' fs="12px" fw="bold" h="30px">2 GOLS</Text>
                            </Container>
                            <Container w="100%" h="30px" bg='#24AB9D' display="flex" jc="center" items="center">
                                <Text col='#fff' fs="12px" fw="bold" h="30px">{statistics.lastStreaksExactGoals["2"]}</Text>
                            </Container>
                            <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                <Text fs="12px" fw="bold" col="#FFE500" h="30px">Max {statistics.maxStreaksExactGoals["2"]}</Text>
                            </Container>

                        </Container>

                        <Container w="16.66%">

                            <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                <Text col='#fff' fs="12px" fw="bold" h="30px">3 GOLS</Text>
                            </Container>
                            <Container w="100%" h="30px" bg='#24AB9D' display="flex" jc="center" items="center">
                                <Text col='#fff' fs="12px" fw="bold" h="30px">{statistics.lastStreaksExactGoals["3"]}</Text>
                            </Container>
                            <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                <Text fs="12px" fw="bold" col="#FFE500" h="30px">Max {statistics.maxStreaksExactGoals["3"]}</Text>
                            </Container>

                        </Container>

                        <Container w="16.66%">

                            <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                <Text col='#fff' fs="12px" fw="bold" h="30px">4 GOLS</Text>
                            </Container>
                            <Container w="100%" h="30px" bg='#24AB9D' display="flex" jc="center" items="center">
                                <Text col='#fff' fs="12px" fw="bold" h="30px">{statistics.lastStreaksExactGoals["4"]}</Text>
                            </Container>
                            <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                <Text fs="12px" fw="bold" col="#FFE500" h="30px">Max {statistics.maxStreaksExactGoals["4"]}</Text>
                            </Container>

                        </Container>

                        <Container w="16.66%">

                            <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                <Text col='#fff' fs="12px" fw="bold" h="30px">+5 GOLS</Text>
                            </Container>
                            <Container w="100%" h="30px" bg='#24AB9D' display="flex" jc="center" items="center">
                                <Text col='#fff' fs="12px" fw="bold" h="30px">{statistics.lastStreaksExactGoals["+5"]}</Text>
                            </Container>
                            <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                <Text fs="12px" fw="bold" col="#FFE500" h="30px">Max {statistics.maxStreaksExactGoals["+5"]}</Text>
                            </Container>

                        </Container>
                    </Container>
                    <Container display="flex" jc="space-between" items="center">
                        <BoxHeaderGradient h='40px' w="100%" mt="10px" display="flex" jc="space-between" items="center">
                            <Container w="100%" h="30px" display="flex" jc="center" items="center"> 
                                <Text fs="15px" fw="bold" col="#fff" >AMBAS MARCAM</Text>
                            </Container>
                        </BoxHeaderGradient>   
                    </Container>

                    <Container display="flex" jc="space-between" items="center" w="100%" mt="10px">

                        <Container w="45%">
                            <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                <Text col='#fff' fs="15px" fw="bold" h="30px">AMBAS MARCAM</Text>
                            </Container>
                            <Container w="100%" h="30px" bg='#24AB9D' display="flex" jc="center" items="center">
                                <Text col='#fff' fs="15px" fw="bold" h="30px">{statistics.lastBothScore.bothScored}</Text>
                            </Container>
                            <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                <Text fs="15px" fw="bold" col="#FFE500" h="30px">Max {statistics.maxBothScore.bothScored}</Text>
                            </Container>
                        </Container>
                        <Container w="45%">
                            <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                <Text col="#fff" fs="15px" fw="bold" h="30px">AMBAS NÃO MARCAM</Text>
                            </Container>
                            <Container w="100%" h="30px" bg='#24AB9D' display="flex" jc="center" items="center">
                                <Text col="#fff" fs="15px" fw="bold" h="30px">{statistics.lastBothScore.neitherScored}</Text>
                            </Container>
                            <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                <Text fs="15px" fw="bold" col="#FFE500" h="30px">Max {statistics.maxBothScore.neitherScored}</Text>
                            </Container>
                        </Container>
                    </Container>

                    <Container display="flex" jc="space-between" items="center">
                        <BoxHeaderGradient h='40px' w="100%" display="flex" jc="space-between" items="center">
                            <Container w="70%" mt="10px" display="flex" jc="center" items="center"> 
                                <Text fs="12px" fw="bold" col="#fff" >RESULTADO CASA X VISITANTE</Text>
                            </Container>
                            <Container w="30%" display="flex" jc="center" items="center"> 
                                <Text fs="12px" fw="bold" col="#fff">{"(Intervalo)"}</Text>
                            </Container>
                        </BoxHeaderGradient>   
                    </Container>

                    <Container display="flex" jc="space-between" items="center" w="100%" mt="10px">
                        <Container w="30%">
                            <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                <Text col="#fff" fs="15px" fw="bold" h="30px">MANDANTE</Text>
                            </Container>
                            <Container w="100%" h="30px" bg='#24AB9D' display="flex" jc="center" items="center">
                                <Text col="#fff" fs="15px" fw="bold" h="30px">{statistics.lastResultIntervalEndGame.homeInterval}</Text>
                            </Container>
                            <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                <Text fs="15px" fw="bold" col="#FFE500" h="30px">Max {statistics.maxResultIntervalEndGame.homeInterval}</Text>
                            </Container>
                        </Container>
                        <Container w="30%">
                            <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                <Text col="#fff" fs="15px" fw="bold" h="30px">EMPATE</Text>
                            </Container>
                            <Container w="100%" h="30px" bg='#24AB9D' display="flex" jc="center" items="center">
                                <Text col="#fff" fs="15px" fw="bold" h="30px">{statistics.lastResultIntervalEndGame.drawInterval}</Text>
                            </Container>
                            <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                <Text fs="15px" fw="bold" col="#FFE500" h="30px">Max {statistics.maxResultIntervalEndGame.drawInterval}</Text>
                            </Container>
                        </Container>
                        <Container w="30%">
                            <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                <Text col="#fff" fs="15px" fw="bold" h="30px">VISITANTE</Text>
                            </Container>
                            <Container w="100%" h="30px" bg='#24AB9D' display="flex" jc="center" items="center">
                                <Text col="#fff" fs="15px" fw="bold" h="30px">{statistics.lastResultIntervalEndGame.awayInterval}</Text>
                            </Container>
                            <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                <Text fs="15px" fw="bold" col="#FFE500" h="30px">Max {statistics.maxResultIntervalEndGame.awayInterval}</Text>
                            </Container>
                        </Container>
                    </Container>

                    <Container display="flex" jc="space-between" items="center">
                        <BoxHeaderGradient h='40px' w="100%"  display="flex" jc="center" items="center">
                            <Container w="70%"  display="flex" jc="center" items="center"> 
                                <Text fs="15px" fw="bold" col="#fff" h="30px">RESULTADO CASA X VISITANTE</Text>
                            </Container>
                        </BoxHeaderGradient>   
                    </Container>


                    <Container display="flex" jc="space-between" items="center" w="100%" mt="10px">
                                <Container w="30%">
                                    <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="15px" fw="bold">MANDANTE</Text>
                                    </Container>
                                    <Container w="100%" h="30px" bg='#24AB9D' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="18px" fw="bold">{statistics.lastResultIntervalEndGame.homeEnd}</Text>
                                    </Container>
                                    <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text  fs="15px" fw="bold" col="#FFE500">Max {statistics.maxResultIntervalEndGame.homeEnd}</Text>
                                    </Container>
                                </Container>
                                <Container w="30%">
                                    <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="15px" fw="bold">EMPATE</Text>
                                    </Container>
                                    <Container w="100%" h="30px" bg='#24AB9D' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="18px" fw="bold">{statistics.lastResultIntervalEndGame.drawEnd}</Text>
                                    </Container>
                                    <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text  fs="15px" fw="bold" col="#FFE500">Max {statistics.maxResultIntervalEndGame.drawEnd}</Text>
                                    </Container>
                                </Container>
                                <Container w="30%">
                                    <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="15px" fw="bold">VISITANTE</Text>
                                    </Container>
                                    <Container w="100%" h="30px" bg='#24AB9D' display="flex" jc="center" items="center">
                                        <Text col='#fff' fs="18px" fw="bold">{statistics.lastResultIntervalEndGame.awayEnd}</Text>
                                    </Container>
                                    <Container w="100%" h="30px" bg='#097469' display="flex" jc="center" items="center">
                                        <Text  fs="15px" fw="bold" col="#FFE500">Max {statistics.maxResultIntervalEndGame.awayEnd}</Text>
                                    </Container>
                                </Container>
                            </Container>   

                            <BoxHeaderGradient h='40px' w="100%" mt="10px" display="flex" jc="center" items="center">
                                <Container w="70%" display="flex" jc="center" items="center"> 
                                    <Text fs="15px" fw="bold" col="#fff" >PLACAR EXATO</Text>
                                    <Text ml='20px' fs="10px" fw="bold" col="#fff" >(Sem sair)</Text>
                                </Container>
                            </BoxHeaderGradient>

                            <Container 

                                w="99.5%" 
                                                        
                            >

                                <Container w="100%" display="flex" h="40px">
                                    <Container w="33%" h="40px" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >CASA</Text>
                                    </Container>
                                    <Container w="33%" h="40px" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >EMPATE</Text>
                                    </Container>
                                    <Container w="33%" h="40px" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >FORA</Text>
                                    </Container>
                                </Container>

                                <Container  w="100%" h="20px" display="flex" bbtw="1px" bbts="solid" bbtc="#fff" >

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >1-0</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >0-0</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >0-1</Text>
                                    </Container>

                                </Container> 

                                <Container w="100%" h="20px" display="flex">

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastExactScoreStreaks.home["1-0"]}/{statistics.maxExactScoreStreaks.home["1-0"]}</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastExactScoreStreaks.draw["0-0"]}/{statistics.maxExactScoreStreaks.draw["0-0"]}</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastExactScoreStreaks.away["0-1"]}/{statistics.maxExactScoreStreaks.away["0-1"]}</Text>
                                    </Container>

                                </Container>  

                                <Container  w="100%" h="20px" display="flex" bbtw="1px" bbts="solid" bbtc="#fff" >

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >2-0</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >1-1</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >0-2</Text>
                                    </Container>

                                </Container> 

                                <Container w="100%" h="20px" display="flex">

                                <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastExactScoreStreaks.home["2-0"]}/{statistics.maxExactScoreStreaks.home["2-0"]}</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastExactScoreStreaks.draw["1-1"]}/{statistics.maxExactScoreStreaks.draw["1-1"]}</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastExactScoreStreaks.away["0-2"]}/{statistics.maxExactScoreStreaks.away["0-2"]}</Text>
                                    </Container>

                                </Container>  

                                <Container  w="100%" h="20px" display="flex" bbtw="1px" bbts="solid" bbtc="#fff" >

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >2-1</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >2-2</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >1-2</Text>
                                    </Container>

                                </Container> 

                                <Container w="100%" h="20px" display="flex">

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastExactScoreStreaks.home["2-1"]}/{statistics.maxExactScoreStreaks.home["2-1"]}</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastExactScoreStreaks.draw["2-2"]}/{statistics.maxExactScoreStreaks.draw["2-2"]}</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastExactScoreStreaks.away["1-2"]}/{statistics.maxExactScoreStreaks.away["1-2"]}</Text>
                                    </Container>

                                </Container>  

                                <Container  w="100%" h="20px" display="flex" bbtw="1px" bbts="solid" bbtc="#fff" >

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >3-0</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >3-3</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >0-3</Text>
                                    </Container>

                                </Container> 

                                <Container w="100%" h="20px" display="flex">

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastExactScoreStreaks.home["3-0"]}/{statistics.maxExactScoreStreaks.home["2-1"]}</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastExactScoreStreaks.draw["3-3"]}/{statistics.maxExactScoreStreaks.draw["2-2"]}</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastExactScoreStreaks.away["0-3"]}/{statistics.maxExactScoreStreaks.away["1-2"]}</Text>
                                    </Container>

                                </Container> 

                                <Container  w="100%" h="20px" display="flex" bbtw="1px" bbts="solid" bbtc="#fff" >

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >3-1</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >4-4</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >1-3</Text>
                                    </Container>

                                </Container> 

                                <Container w="100%" h="20px" display="flex">

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastExactScoreStreaks.home["3-1"]}/{statistics.maxExactScoreStreaks.home["3-1"]}</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastExactScoreStreaks.draw["4-4"]}/{statistics.maxExactScoreStreaks.draw["4-4"]}</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastExactScoreStreaks.away["1-3"]}/{statistics.maxExactScoreStreaks.away["1-3"]}</Text>
                                    </Container>

                                </Container>  

                                <Container  w="100%" h="20px" display="flex" bbtw="1px" bbts="solid" bbtc="#fff" >

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >3-2</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" ></Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >2-3</Text>
                                    </Container>

                                </Container> 

                                <Container w="100%" h="20px" display="flex">

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastExactScoreStreaks.home["3-2"]}/{statistics.maxExactScoreStreaks.home["3-2"]}</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" ></Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastExactScoreStreaks.away["2-3"]}/{statistics.maxExactScoreStreaks.away["2-3"]}</Text>
                                    </Container>

                                </Container> 

                                <Container  w="100%" h="20px" display="flex" bbtw="1px" bbts="solid" bbtc="#fff" >

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >4-0</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" ></Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >0-4</Text>
                                    </Container>

                                </Container> 

                                <Container w="100%" h="20px" display="flex">

                                <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastExactScoreStreaks.home["4-0"]}/{statistics.maxExactScoreStreaks.home["4-0"]}</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" ></Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastExactScoreStreaks.away["0-4"]}/{statistics.maxExactScoreStreaks.away["0-4"]}</Text>
                                    </Container>

                                </Container> 

                            </Container>

                            <BoxHeaderGradient w="100%" mt="10px" display="flex" jc="center" items="center">
                                <Container w="100%" h="40px" mt="10px" display="flex" jc="center" items="center"> 
                                    <Text fs="15px" fw="bold" col="#fff" >PLACAR EXATO NO INTERVALO</Text>
                                    <Text ml='20px' fs="10px" fw="bold" col="#fff" >(Sem sair)</Text>
                                </Container>
                            </BoxHeaderGradient>

                            <Container
                                w="99.5%" 
                            >
                                <Container w="100%" display="flex" h="40px">
                                    <Container w="33%" h="40px" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >CASA</Text>
                                    </Container>
                                    <Container w="33%" h="40px" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >EMPATE</Text>
                                    </Container>
                                    <Container w="33%" h="40px" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >FORA</Text>
                                    </Container>
                                </Container>
                                <Container  w="100%" h="20px" display="flex" bbtw="1px" bbts="solid" bbtc="#fff" >

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >1-0</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >0-0</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >0-1</Text>
                                    </Container>

                                </Container>
                                <Container w="100%" h="20px" display="flex">

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastHalfTimeExactScoreStreaks.home["1-0"]}/{statistics.maxHalfTimeExactScoreStreaks.home["1-0"]}</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastHalfTimeExactScoreStreaks.draw["0-0"]}/{statistics.maxHalfTimeExactScoreStreaks.draw["0-0"]}</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastHalfTimeExactScoreStreaks.away["0-1"]}/{statistics.maxHalfTimeExactScoreStreaks.away["0-1"]}</Text>
                                    </Container>

                                </Container>  

                                <Container  w="100%" h="20px" display="flex" bbtw="1px" bbts="solid" bbtc="#fff" >

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >2-0</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >1-1</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#fff" >0-2</Text>
                                    </Container>

                                </Container> 

                                <Container w="100%" h="20px" display="flex">

                                <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastHalfTimeExactScoreStreaks.home["2-0"]}/{statistics.maxHalfTimeExactScoreStreaks.home["2-0"]}</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastHalfTimeExactScoreStreaks.draw["1-1"]}/{statistics.maxHalfTimeExactScoreStreaks.draw["1-1"]}</Text>
                                    </Container>

                                    <Container w="33%" display="flex" jc="center" items="center">
                                        <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastHalfTimeExactScoreStreaks.away["0-2"]}/{statistics.maxHalfTimeExactScoreStreaks.away["0-2"]}</Text>
                                    </Container>

                                </Container>  

                            </Container>



{/** 

                            <BoxHeaderGradient h='40px' w="100%" mt="10px" display="flex" jc="center" items="center">
                                <Container w="70%" display="flex" jc="center" items="center"> 
                                    <Text fs="15px" fw="bold" col="#fff" >PRIMEIRO A MARCAR</Text>
                                </Container>
                            </BoxHeaderGradient>

                            <Container w="100%"  display="flex" bbbw="1px" bbbs="solid" bbbc="#fff">
                                <Container w="50%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >CASA</Text>
                                </Container>
                                <Container w="50%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >FORA</Text>
                                </Container>
                            </Container>
                            
                            <Container w="100%"  >

                                <Container w="100%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >Sem marcar gol</Text>
                                </Container>

                            </Container>

                            <Container w="100%" display="flex" bbbw="1px" bbbs="solid" bbbc="#fff">

                                <Container w="100%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastFirstScorePlayers["draw"]["Sem Marcador de Gol"]}/{statistics.maxFirstScorePlayers["draw"]["Sem Marcador de Gol"]}</Text>
                                </Container>

                            </Container>

                            <Container w="100%"  display="flex" >
                                <Container w="50%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >Centro avante</Text>
                                </Container>

                                <Container w="50%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >Centro avate</Text>
                                </Container>
                            </Container>

                            <Container w="100%"  display="flex" bbbw="1px" bbbs="solid" bbbc="#fff">
                                <Container w="50%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastFirstScorePlayers["team_home"]["Centro-avante"]}/{statistics.maxFirstScorePlayers["team_home"]["Centro-avante"]}</Text>
                                </Container>

                                <Container w="50%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastFirstScorePlayers["team_away"]["Centro-avante"]}/{statistics.maxFirstScorePlayers["team_away"]["Centro-avante"]}</Text>
                                </Container>
                            </Container>

                            <Container w="100%"  display="flex" >
                                <Container w="50%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >Ponta direita</Text>
                                </Container>

                                <Container w="50%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >Ponta direita</Text>
                                </Container>
                            </Container>
                            
                            <Container w="100%"  display="flex" bbbw="1px" bbbs="solid" bbbc="#fff">
                                <Container w="50%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastFirstScorePlayers["team_home"]["Ponta Direita"]}/{statistics.maxFirstScorePlayers["team_home"]["Ponta Direita"]}</Text>
                                </Container>

                                <Container w="50%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastFirstScorePlayers["team_away"]["Ponta Direita"]}/{statistics.maxFirstScorePlayers["team_away"]["Ponta Direita"]}</Text>
                                </Container>
                            </Container>

                            <Container w="100%"  display="flex" >
                                <Container w="50%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >Ponta esquerda</Text>
                                </Container>

                                <Container w="50%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >Ponta esquerda</Text>
                                </Container>
                            </Container>
                            
                            <Container w="100%"  display="flex" bbbw="1px" bbbs="solid" bbbc="#fff">
                                <Container w="50%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastFirstScorePlayers["team_home"]["Ponta Esquerda"]}/{statistics.maxFirstScorePlayers["team_home"]["Ponta Esquerda"]}</Text>
                                </Container>

                                <Container w="50%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastFirstScorePlayers["team_away"]["Ponta Esquerda"]}/{statistics.maxFirstScorePlayers["team_away"]["Ponta Esquerda"]}</Text>
                                </Container>
                            </Container>

                            <Container w="100%"  display="flex" >
                                <Container w="50%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >Meia atacante</Text>
                                </Container>

                                <Container w="50%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >Meia atacante</Text>
                                </Container>
                            </Container>
                            
                            <Container w="100%"  display="flex" bbbw="1px" bbbs="solid" bbbc="#fff">
                                <Container w="50%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastFirstScorePlayers["team_home"]["Meia Atacante"]}/{statistics.maxFirstScorePlayers["team_home"]["Meia Atacante"]}</Text>
                                </Container>

                                <Container w="50%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastFirstScorePlayers["team_away"]["Meia Atacante"]}/{statistics.maxFirstScorePlayers["team_away"]["Meia Atacante"]}</Text>
                                </Container>
                            </Container>

                            <Container w="100%"  display="flex" >
                                <Container w="50%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >Qualquer outra posição</Text>
                                </Container>

                                <Container w="50%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >Qualquer outra posição</Text>
                                </Container>
                            </Container>
                            
                            <Container w="100%"  display="flex" bbbw="1px" bbbs="solid" bbbc="#fff">
                                <Container w="50%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastFirstScorePlayers["team_home"]["Qualquer outro jogador"]}/{statistics.maxFirstScorePlayers["team_home"]["Qualquer outro jogador"]}</Text>
                                </Container>

                                <Container w="50%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastFirstScorePlayers["team_away"]["Qualquer outro jogador"]}/{statistics.maxFirstScorePlayers["team_away"]["Qualquer outro jogador"]}</Text>
                                </Container>
                            </Container>
*/}
                            <BoxHeaderGradient h='40px' w="100%" mt="10px" display="flex" jc="center" items="center">
                                <Container w="100%"   display="flex" jc="center" items="center"> 
                                    <Text fs="15px" fw="bold" col="#fff" >TIME A MARCAR PRIMEIRO</Text>
                                </Container>
                            </BoxHeaderGradient>

                            <Container w="100%" display="flex" h="40px" bbbw="1px" bbbs="solid" bbbc="#fff">
                                <Container w="33%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >CASA</Text>
                                </Container>
                                <Container w="33%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >NENHUM</Text>
                                </Container>
                                <Container w="33%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >FORA</Text>
                                </Container>
                            </Container>

                            <Container w="100%"  display="flex" bbbw="1px" bbbs="solid" bbbc="#fff">
                                <Container w="33%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastFirstTeamScore["team_home"]}/{statistics.maxFirstTeamScore["team_home"]}</Text>
                                </Container>

                                <Container w="33%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastFirstTeamScore["team_none"]}/{statistics.maxFirstTeamScore["team_none"]}</Text>
                                </Container>

                                <Container w="33%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastFirstTeamScore["team_away"]}/{statistics.maxFirstTeamScore["team_away"]}</Text>
                                </Container>
                            </Container>

                            <BoxHeaderGradient h='40px' w="100%" mt="10px" display="flex" jc="center" items="center">
                                <Container w="100%"   display="flex" jc="center" items="center"> 
                                    <Text fs="15px" fw="bold" col="#fff" >TIME A MARCAR POR ÚLTIMO</Text>
                                </Container>
                            </BoxHeaderGradient>

                            <Container w="100%" display="flex" h="40px" bbbw="1px" bbbs="solid" bbbc="#fff">
                                <Container w="33%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >CASA</Text>
                                </Container>
                                <Container w="33%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >NENHUM</Text>
                                </Container>
                                <Container w="33%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >FORA</Text>
                                </Container>
                            </Container>

                            <Container w="100%"  display="flex" bbbw="1px" bbbs="solid" bbbc="#fff">
                                <Container w="33%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastLastTeamScore["team_home"]}/{statistics.maxLastTeamScore["team_home"]}</Text>
                                </Container>

                                <Container w="33%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastLastTeamScore["team_none"]}/{statistics.maxLastTeamScore["team_none"]}</Text>
                                </Container>

                                <Container w="33%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastLastTeamScore["team_away"]}/{statistics.maxLastTeamScore["team_away"]}</Text>
                                </Container>
                            </Container>                            

                            <BoxHeaderGradient w="100%" mt="10px" display="flex" jc="center" items="center">
                                <Container w="70%" h="40px" display="flex" jc="center" items="center"> 
                                    <Text fs="15px" fw="bold" col="#fff" >RESULTADO CORRETO GRUPO</Text>
                                    <Text ml='20px' fs="10px" fw="bold" col="#fff" >(Sem sair)</Text>
                                </Container>
                            </BoxHeaderGradient>

                            <Container w="100%" display="flex" h="40px" bbbw="1px" bbbs="solid" bbbc="#fff">
                                <Container w="33%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >CASA</Text>
                                </Container>
                                <Container w="33%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >NENHUM</Text>
                                </Container>
                                <Container w="33%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >FORA</Text>
                                </Container>
                            </Container>



                            <Container w="100%"  display="flex" >
                                <Container w="50%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >1-0, 2-0, 2-1</Text>
                                </Container>

                                <Container w="50%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >Empate-com-Gols</Text>
                                </Container>

                                <Container w="50%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >1-0, 2-0, 2-1</Text>
                                </Container>
                            </Container>

                            <Container w="100%"  display="flex" bbbw="1px" bbbs="solid" bbbc="#fff">
                                <Container w="50%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastResultsGroups['team_home']['1-0, 2-0, 2-1']}/{statistics.maxResultsGroups["team_home"]["1-0, 2-0, 2-1"]}</Text>
                                </Container>

                                <Container w="50%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastResultsGroups["draw"]["Empate com goals"]}/{statistics.maxResultsGroups["draw"]["Empate com goals"]}</Text>
                                </Container>

                                <Container w="50%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastResultsGroups["team_away"]["1-0, 2-0, 2-1"]}/{statistics.maxResultsGroups["team_away"]["1-0, 2-0, 2-1"]}</Text>
                                </Container>
                            </Container>

                            <Container w="100%"  display="flex" >
                                <Container w="50%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >3-0, 3-1, 4-0</Text>
                                </Container>

                                <Container w="50%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" ></Text>
                                </Container>

                                <Container w="50%" h="40px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#fff" >3-0, 3-1, 4-0</Text>
                                </Container>
                            </Container>

                            <Container w="100%"  display="flex" bbbw="1px" bbbs="solid" bbbc="#fff">
                                <Container w="50%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastResultsGroups['team_home']["3-0, 3-1, 4-0"]}/{statistics.maxResultsGroups["team_home"]["3-0, 3-1, 4-0"]}</Text>
                                </Container>

                                <Container w="50%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" ></Text>
                                </Container>

                                <Container w="50%" h="20px" display="flex" jc="center" items="center">
                                    <Text fs="15px" fw="bold" col="#FFE500" >Max - {statistics.lastResultsGroups["team_away"]["3-0, 3-1, 4-0"]}/{statistics.maxResultsGroups["team_away"]["3-0, 3-1, 4-0"]}</Text>
                                </Container>
                            </Container>                            



                            <Container w="100%" h="40px" display="flex" jc="center" items="center" bg="#2F2F2F">
                                <Text fs="13px" fw="bold" col="#fff">Placares que mais se repetiram nas últimas horas</Text>
                            </Container>

                            <Container w="100%" display="flex" jc="space-between" bg="#2F2F2F">

                                {statistics.mostCommonScores.map(item=>{
                                    if(item.id < 3)
                                    {
                                        return(
                                        
                                            <Container key={item.id} display="flex" jc="center" items="center" w="25%" h="40px" bblw="5px" bbls="solid" rd="10px" bblc="#24AB9D" mt="10px">
                                                <Container w="50%" h="100%" display="flex" jc="center" items="center" bg="#1C524C">
                                                    <Text fs="15px" fw="bold" col="#fff">{item.scoreboard}</Text>
                                                </Container>
                                                <Container w="50%" h="100%" display="flex" jc="center" items="center" bg="#D9D9D9">
                                                    <Text fs="15px" fw="bold" col="#032E2D">{item.count}</Text>
                                                </Container>
                                            </Container>
                                        )
                                    }
                                })}
                            </Container>

                            <Container w="100%" display="flex" jc="space-between" bg="#2F2F2F">
                                {statistics.mostCommonScores.map(item=>{
                                    if(item.id >= 3 && item.id < 6)
                                    {
                                        return(
                                        
                                            <Container key={item.id} display="flex" jc="center" items="center" w="25%" h="40px" bblw="5px" bbls="solid" rd="10px" bblc="#24AB9D" mt="10px">
                                                <Container w="50%" h="100%" display="flex" jc="center" items="center" bg="#1C524C">
                                                    <Text fs="15px" fw="bold" col="#fff">{item.scoreboard}</Text>
                                                </Container>
                                                <Container w="50%" h="100%" display="flex" jc="center" items="center" bg="#D9D9D9">
                                                    <Text fs="15px" fw="bold" col="#032E2D">{item.count}</Text>
                                                </Container>
                                            </Container>
                                        )
                                    }
                                })}
                            </Container>


                            <Container w="100%" display="flex" jc="space-between" bg="#2F2F2F">
                                {statistics.mostCommonScores.map(item=>{
                                    if(item.id >= 6)
                                    {
                                        return(
                                        
                                            <Container key={item.id} display="flex" jc="center" items="center" w="25%" h="40px" bblw="5px" bbls="solid" rd="10px" bblc="#24AB9D" mt="10px">
                                                <Container w="50%" h="100%" display="flex" jc="center" items="center" bg="#1C524C">
                                                    <Text fs="15px" fw="bold" col="#fff">{item.scoreboard}</Text>
                                                </Container>
                                                <Container w="50%" h="100%" display="flex" jc="center" items="center" bg="#D9D9D9">
                                                    <Text fs="15px" fw="bold" col="#032E2D">{item.count}</Text>
                                                </Container>
                                            </Container>
                                        )
                                    }
                                })}
                            </Container>


                            <Container w="100%" h="60px" bg="#2F2F2F">
                                <Text mt='10px' fs="15px" col="#fff">Filtro das ultimas horas</Text>
                                <Container w="100%" display="flex" bg="#2F2F2F">  
                                
                                    {hourSelect === 6
                                    ?<BtnHourFilter onClick={()=>{handleScore(6)}} col="#fff" bg="#2F2F2F" ml="10px">6</BtnHourFilter>
                                    :<BtnHourFilter onClick={()=>{handleScore(6)}}  ml="10px">6</BtnHourFilter>
                                    }
                                    {hourSelect === 12
                                    ?<BtnHourFilter onClick={()=>{handleScore(12)}} col="#fff" bg="#2F2F2F"  ml="10px">12</BtnHourFilter>
                                    :<BtnHourFilter onClick={()=>{handleScore(12)}}  ml="10px">12</BtnHourFilter>
                                    }
                                    {hourSelect === 24
                                    ?<BtnHourFilter onClick={()=>{handleScore(24)}} col="#fff" bg="#2F2F2F"  ml="10px">24</BtnHourFilter>
                                    :<BtnHourFilter onClick={()=>{handleScore(24)}}  ml="10px">24</BtnHourFilter>
                                    }

                                </Container>
                            </Container>

                            <BoxHeaderGradient h='40px' w="100%" mt="10px" display="flex" jc="center" items="center">
                                <Container w="100%"   display="flex" jc="center" items="center"> 
                                    <Text fs="15px" fw="bold" col="#fff" >ÚLTIMOS RESULTADOS</Text>
                                </Container>
                            </BoxHeaderGradient>

                            <Container w="100%" display="flex" jc="space-between" items="center" mt="10px" bg="#2F2F2F">
                                <Container w="40%" h="40px" display="flex" jc="center" items="center"> 
                                    <Text fs="15px" fw="bold" col='#fff'>Hora</Text>
                                </Container>
                                <Container w="20%" h="40px" display="flex" jc="center" items="center"> 
                                    <Text fs="15px" fw="bold" col='#fff' >Casa</Text>
                                </Container>
                                <Container w="20%" h="40px" display="flex" jc="center" items="center"> 
                                    <Text fs="15px" fw="bold" col="#fff" >Placar</Text>
                                </Container>
                                <Container w="20%" h="40px" display="flex" jc="center" items="center"> 
                                    <Text fs="15px" fw="bold" col='#fff' >Fora</Text>
                                </Container>
                            </Container>

                            {lastResults.map(item=>{
                                return(
                                        <Container key={item.id} w="100%" h="110px" display="flex" jc="space-between" items="center" bg="#2F2F2F" bbbw="1px" bbbs="solid" bbbc="#fff">
                                            <Container w="40%" display="flex" jc="space-between" items="center">
                                                <Container w="33%" display="flex" jc="center" items="center">
                                                    <BtnOdds ml="20px" onClick={()=>{onGameOddsSelect(item.code, item.date_time)}}>
                                                        <Text fs="15px" fw="bold">ODDS</Text>
                                                    </BtnOdds>
                                                </Container>
                                                <Container w="33%" display="flex" jc="center" items="center">
                                                    <Text fs="15px" col="#fff">{item.time}</Text>
                                                </Container>
                                                <Container w="33%" display="flex" jc="center" items="center">
                                                    <Text fs="15px" col="#fff"></Text>
                                                </Container>
                                            </Container>
                
                                            <Container 
                                            w="20%"
                                            display="flex" 
                                            jc="center" 
                                            items="center" 
                                            fd="column"
                                            >
                                                <ImageBandeira src={`./bandeiras/${item.team_home}.png`}/>
                
                                                <Text fs="15px" col="#fff">{item.team_home}</Text>
                                            </Container>
                
                                            <Container 
                                            w="20%"
                                            display="flex" 
                                            jc="center" 
                                            items="center" 
                                            fd="column"
                                            >
                                                <Container w="auto">
                                                    <Text fs="15px" col="#FFE500">{item.scoreboard}</Text>
                                                </Container>                                            
                                                
                                            </Container>
                
                                            <Container 
                                            w="20%"
                                            display="flex" 
                                            jc="center" 
                                            items="center" 
                                            fd="column"
                                            >
                                                <ImageBandeira src={`./bandeiras/${item.team_away}.png`}/>
                
                                                <Text fs="15px" col="#fff">{item.team_away}</Text>
                                            </Container>
                
                
                
                                        </Container>
                                )
                            })}

                            <Container w="100%" bg="#2F2F2F" h="20px"></Container>{/**spacing */}

                </ContainerMobileOverflow>

            </ContainerMobileShow>

            {modalOdds === true && 
            <ModalOdds 
                code={gameOddsSelect} 
                date={dateOddsSelect} 
                setModalOdds={setModalOdds}
                url={selectChampions.id}
            />}
        
        </GlobalContainer>
    )
}

