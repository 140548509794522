import { useState, useContext } from "react";
import { Container, Text } from "../componets/styles";
import AuthHeader from "./containers/header";
import { ButtonFormAuth, FormContainer, HeaderMobile, InputFormAuth, LogoMobile } from "./styled";

import { SpinnerCircular } from 'spinners-react';
import axios from "axios";
import { API_TOKEN, API_URL } from "../config";
import AuthContext from "../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";

export default function Register()
{
    const {login} = useContext(AuthContext);

    const navigate = useNavigate();

    const [spinner, setSpiner] = useState(false);

    const [form, setForm] = useState({

        name:'',
        phone:'',
        email:'',
        password:'',
    })

    const [errors, setErrors] = useState({
        name:'',
        phone:'',
        email:'',
        password:''
    });

    async function onSave()
    {
        setSpiner(true);

        const headers = {
            'Authorization': `Bearer ${API_TOKEN}`,
            'Content-Type': 'application/json', 
        };

        try {

            const response = await axios.post(`${API_URL}/register`, form, { headers });

            localStorage.setItem('TOKEN_APP_FUT_REIS_TIPS', response.data.token);

            setErrors({
                ...errors,
                name:'',
                whats:'',
                email:'',
                password:''
            });

            setSpiner(false);

            login();
            navigate("/");
            return

        } catch (error) {

            console.log("error");
            console.log(error);

            if(error.response.data.errors)
            {
                setErrors({
                    ...errors,
                    name:error.response.data.errors.name,
                    whats:error.response.data.errors.phone,
                    email:error.response.data.errors.email,
                    password:error.response.data.errors.password
                });
            }

            setSpiner(false);
        }
    }

    return(
        
        <Container>

            <AuthHeader/>

            <HeaderMobile>
                <LogoMobile src='./images/logo.png'/>
            </HeaderMobile>

            <Container>

                <FormContainer>

                    <Container display="flex" items="center" jc="center"  >
                        <Text col="#fff" fs="20px">Obrigado por se registrar!</Text>
                    </Container>
                   
                    <InputFormAuth onChange={(e)=>{setForm({...form, name:e.target.value})}} type="text" placeholder="Nome completo"/>
                    <Text ml='5%' fs='20px' col='#dc2626' fw='bold'>{errors.name}</Text> 
                    <InputFormAuth onChange={(e)=>{setForm({...form, phone:e.target.value})}} type="text" placeholder="Whatsapp"/>
                    <Text ml='5%' fs='20px' col='#dc2626' fw='bold'>{errors.whats}</Text>
                    <InputFormAuth onChange={(e)=>{setForm({...form, email:e.target.value})}} type="text" placeholder="Email"/>
                    <Text ml='5%' fs='20px' col='#dc2626' fw='bold'>{errors.email}</Text>
                    <InputFormAuth onChange={(e)=>{setForm({...form, password:e.target.value})}} type="password" placeholder="Digite sua senha"/>
                    <Text ml='5%' fs='20px' col='#dc2626' fw='bold'>{errors.password}</Text>
                    <ButtonFormAuth onClick={onSave}>

                        {spinner == true
                        
                        ?
                            <SpinnerCircular size={30} color="#fff"/>
                        :
                            <Text col="#fff" fs="20px" fw="bold">REGISTRAR</Text>
                        }
                    
                    </ButtonFormAuth>

                    <ButtonFormAuth>
                        <Link style={{textDecoration:'none', color:'inherit'}} to={"/"}>
                            <Text col="#fff" fs="20px" fw="bold">ENTRAR</Text>
                        </Link>
                    </ButtonFormAuth>

                    <Container mt='10px'></Container>
                    
                </FormContainer>

            </Container>  

        </Container>
    )
}