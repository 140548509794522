import styled from 'styled-components';


export const GlobalContainer = styled.div`

    width: 100%;
    height: 100vh;
    background-color: ${props => props.bg || '#fff'};
    overflow: hidden;
    background-color: #000;
    
`;

export const Header = styled.div`

    width: calc(100% - 70px);
    height: 70px;
    background: linear-gradient(to right, #000,#77F355);
    padding-left: 70px;
    display: flex;
    justify-content: space-between;
    align-items:center;
   
`

export const Sidebar = styled.div`

  width: ${props => (props.open ? '300px' : '70px')};
  height: 100vh; // altura total da viewport
  background-color: #484D45;
  transition: width 0.5s ease-in-out; 
  position:absolute;
  z-index:999;
  top:0

`;

export const ButtonSide = styled.div` 

    width: ${props => (props.open ? 'calc (100% - 20px)' : '100%')};
    height: 50px;
    background-color: ${props=>props.bg || 'transparent'};
    border: none;
    display: flex;
    align-items:center;
    justify-content: ${props => (props.open ? 'start' : 'center')};
    cursor:pointer;
    margin-top: ${props=>props.mt || "10px"};
    padding-left: ${props => (props.open ? '20px' : '0px')};

    &:hover {

        background-color: #707070;
    }

`
export const MainContainer = styled.div`

    width: calc(100% - 70px);
    height: auto;
    max-height: calc(100vh - 70px);
    background-color: transparent;
    margin-left: 70px;
    overflow:auto;
    margin-top: 80px;

`

export const BoxChampionship  = styled.button`

    width: 280px;
    height: 55px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    margin-left: 20px;

    @media (max-width: 900px) {
        width: 100%;
        margin-left: 0px;
    }

`

export const BoxChampionshipHeader  = styled.button`

    width: 300px;
    height: 55px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    margin-left: 150px;

    @media (max-width: 900px) {
        width: 100%;
        margin-left: 0px;
    }

`
export const BoxDropDown = styled.div`

    width: ${props => props.dp || '0px'};
    height: auto;
    max-height: 700px;
    overflow: auto;
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    top:10px;
    transition: height 0.5s ease-in-out;
    z-index: 1;
`

export const BtnChanpionship = styled.button`

    width: 95%;
    height: 50px;
    background-color: transparent;
    border-radius: 5px;

    &:hover{
        background-color: #ccc;
    }
    display: flex;
    justify-content:center;
    align-items:center;
    cursor: pointer;
    margin-left: 2.5%;
    mergin-top: 10px;


`

export const ImgChanpionship = styled.img`

    width: ${props=>props.w || '25px'};
    height: ${props=>props.h || '25px'};
    margin-right: 10px;

`
export const BoxHeaderGradient = styled.div`

    width: ${props=>props.w || "100%"};
    height: ${props=>props.h || "70px"};
    margin-top: ${props=>props.mt || "opx"};
    background: linear-gradient(to top, #000, #77F355);
    display:flex;
    justify-content:center;
    align-items:center;

`

export const BtnHourFilter = styled.button`
    width: 35px;
    height: 35px;
    margin-left: ${props=>props.ml || "0px"};
    background-color: ${props=>props.bg || "#fff"};
    font-weight: bold;
    color: ${props=>props.color || "#000"};
    cursor: pointer;

`

export const BtnOdds = styled.button`
    width: 60px;
    height: 30px;
    border-left-style: solid;
    border-left-width: 5px;
    border-left-color: ${props=>props.bblColor || "#77F355"};
    border-radius: 5px;
    margin-left: ${props=>props.ml || "0px"};

`

export const ImageBandeira = styled.img`
    width: 50px;
    height: 50px;
`

export const ContainerGradient = styled.div`

    background: linear-gradient(to top, #000,#77F355);
    width: 100%;
    height: 70px;
    baorder-radius: 10px;
    display:flex;
    justify-content: start;
    align-items: center;
    margin-top: ${props=>props.mt || '0px'}

`

export const ContainerOpacity = styled.div`

    background-color: rgba(250, 250 , 250, 0.3);
    width: 100%;
    height: auto;
    border-radius: 10px;

`

export const BtnMinutes = styled.button`

    width:99.5%;
    height: 25px;
    background-color:#D9D9D9;
    border-radius:5px;

`

export const BtnIndicators = styled.button`
    width: ${props => props.w || '100px'};
    height: ${props => props.h || '40px'};
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props=>props.bg};

    & > div {
        width: 100%;
        height: 100%;
            background: radial-gradient(
                ellipse at center,
                ${props => props.bg} 20%, /* Cor no centro */
                ${props => props.bgselect || "transparent"} 80% /* Cor nas extremidades */
            );
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export const BtnCheckIndicator = styled.button`

    width: ${props=>props.w};
    height: 30px;
    display: flex;
    justify-content:center;
    align-items:center;
    background-color: ${props=>props.bg};

`

export const BoxIndicator = styled.div`
    width: ${props=>props.w};
    height: 30px;
    display: flex;
    justify-content:center;
    align-items:center;
    background-color: ${props=>props.bg};
`

export const Tooltip = styled.div`

    width: ${props=>props.w};
    height: ${props=>props.h};
    background-color: rgba(0,0,0,0.7) ;
    border-radius: 10px; 
    position: absolute; 
    top: 65px;
    z-index: 888;
    cursor: pointer;
    display:flex;
    justify-content:center;
    align-items:center;

`

export const ContainerMobileHide = styled.div`

    width: 100%;

    @media (max-width: 768px) {
        display: none;
    }

`

export const ContainerMobileShow = styled.div`

    width: 100%;
    max-height: calc(100vh - 60px);
    overflow: auto;
    display: none;

    @media (max-width: 768px) {
        display: block;
    }

`

export const HeaderMobile = styled.div`

    width: calc(100% - 30px);
    height: 70px;
    background: linear-gradient(to right, #000,#77F355);
    padding-left: 30px;
    display: flex;
    justify-content: space-between;
    align-items:center;
   
`

export const SideBarMobile = styled.div`
    width: ${props => (props.open ? '100%' : '0%')};
    transition: width 0.5s ease-in-out; 
    height: 100vh;
    background-color: #484D45;
    position: absolute;
    z-index: 999;
    top:0;
    left:0;
    
`

export const ButtomMenu = styled.div`

    width: 95%;
    height: 40px;
    margin-left: 2.5%;
    border: 2px solid #77F355;
    border-radius: 5px;
    display: flex;
    justify-content:center;
    align-items:center;
    margin-top: 10px;

    &:hover{
        background-color: #000;
    }

`

export const ContainerMobileOverflow = styled.div`
    width: 95%;
    overflow: auto;
    max-width: 100%;
    margin-top: 20px;
    margin-left: 2.5%;
   
`

export const LogoMobile = styled.img`

    width: 50px;
    height: 50px;
    margin-right: 30px;

`

export const ContainerGradientMobile = styled.div`

    background: linear-gradient(to top, #000,#77F355);
    width: 100%;
    height: 40px;
    baorder-radius: 10px;
    display:flex;
    justify-content: start;
    align-items: center;
    margin-top: 30px;

`

export const BtnMinutesMobile = styled.button`

    width:100%;
    height: 15px;
    background-color:#D9D9D9;
    font-size: 5px;
  
`

export const BtnCheckIndicatorMobile = styled.button`

    width: ${props=>props.w};
    height: 15px;
    display: flex;
    justify-content:center;
    align-items:center;
    background-color: ${props=>props.bg};

`

export const BoxIndicatorMobile = styled.div`
    width: ${props=>props.w};
    height: 15px;
    display: flex;
    justify-content:center;
    align-items:center;
    background-color: ${props=>props.bg};
`

export const BtnIndicatorsMobile = styled.button`
    width: ${props => props.w || '100px'};
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props=>props.bg};
    font-size: 6px;

    & > div {
        width: 100%;
        height: 100%;
            background: radial-gradient(
                ellipse at center,
                ${props => props.bg} 20%, /* Cor no centro */
                ${props => props.bgselect || "transparent"} 80% /* Cor nas extremidades */
            );
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;