import {useContext, useEffect} from "react";

import AppRouter from "./app.router";
import AuthRouter from "./auth.router";
import AuthContext from "../context/AuthContext";



export default function Router()
{
    const { isAuthenticated} = useContext(AuthContext);



    return(
        
        isAuthenticated ? <AppRouter /> : <AuthRouter />
    )

}