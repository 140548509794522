import { Link } from "react-router-dom";
import { Container, Text } from "../componets/styles";
import AuthHeader from "./containers/header";
import { ButtonFormAuth, FormContainer, HeaderMobile, InputFormAuth, LogoMobile } from "./styled";
import { useState } from "react";
import { SpinnerCircular } from "spinners-react";
import axios from "axios";
import { API_URL } from "../config";

export default function Redefine()
{
    const [form, setForm] = useState({
        email:''
    })
    const [errors, setErrors] = useState({
        email:''
    });

    const [success, setSuccess] = useState('');

    const [spinner, setSpiner] = useState(false);

    async function onRedefine()
    {
        setSpiner(true)
        try {
            const response = await axios.post(`${API_URL}/redefine-send`, form);
            setSpiner(false)
            setSuccess(response.data.success)
            setForm({
                ...form,
                email:''
            })
        } catch (error) {
            if(error.response.data.errors)
            {
                setErrors({
                    ...errors,
                    email:error.response.data.errors.email,
                });
                setSpiner(false);
                return
            }

            if(error.response.data.error)
            {
                console.log(error.response.data.error);
                setSpiner(false);

                return
            }

            setSpiner(false);
        }

    }

    return(


        <Container>

            <AuthHeader/>

            <HeaderMobile>
                <LogoMobile src='./images/logo.png'/>
            </HeaderMobile>

            <Container>

                <FormContainer>

                    <Container display="flex" items="center" jc="center" mt='20px' >
                        <Text col="#fff" fs="30px">Informe seu email.</Text>
                    </Container>
                
                    <InputFormAuth onChange={(e)=>{setForm({...form, email:e.target.value})}} value={form.email} type="text" placeholder="Digite seu email"/>
                    <Text ml='5%' fs='20px' col='#dc2626' fw='bold'>{errors.email}</Text> 
                    
                    <Container ml="5%" mt="10px">
                        <Text col="red" fs="20px" fw="bold">{errors.error}</Text>
                    </Container>
                    
                    <ButtonFormAuth onClick={onRedefine}>
                        {spinner == true
                        
                        ?
                            <SpinnerCircular size={30} color="#fff"/>
                        :
                            <Text col="#fff" fs="20px" fw="bold">REDEFINIR</Text>
                        }
                    
                    </ButtonFormAuth>

                    <ButtonFormAuth>
                        <Link style={{textDecoration:'none', color:'inherit'}} to={"/"}>
                            <Text col="#fff" fs="20px" fw="bold">ENTRAR</Text>
                        </Link>
                    </ButtonFormAuth>

                    <Container display='flex' jc='center' mt='10px'>
                        <Text col='#fff' fs='20' fw='bold'>{success}</Text>
                    </Container>

                    <Container mt='10px'></Container>

                </FormContainer>

            </Container>

        </Container>
    )



}