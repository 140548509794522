export  const STRATEGY = [

    {id:31, name:"Resultado FT Casa x Visitante", type:"text"},
    {id:32, name:"Mandante", type:"button"},
    {id:33, name:"Visitante", type:"button"},
    {id:34, name:"Empate", type:"button"},

    {id:35, name:"Resultado HT Casa x Visitante", type:"text"},
    {id:36, name:"Mandante", type:"button"},
    {id:37, name:"Visitante", type:"button"},
    {id:38, name:"Empate", type:"button"},

    {id:49, name:"Ambas Marcam", type:"text"},
    {id:50, name:"Sim", type:"button", strategy:"Ambas Marcam"},
    {id:51, name:"Não", type:"button", strategy:"Ambas Marcam"},

    {id:52, name:"Mandante Marca", type:"text"},
    {id:53, name:"Sim", type:"button", strategy:"Mandante Marca"},
    {id:54, name:"Não", type:"button", strategy:"Mandante Marca"},

    {id:55, name:"Visitante Marca", type:"text"},
    {id:56, name:"Sim", type:"button", strategy:"Visitante Marca"},
    {id:57, name:"Não", type:"button", strategy:"Visitante Marca"},

    {id:39, name:"Over Gols", type:"text"},
    {id:40, name:"Over 0.5", type:"button"},
    {id:41, name:"Over 1.5", type:"button"},
    {id:42, name:"Over 2.5", type:"button"},
    {id:43, name:"Over 3.5", type:"button"},

    {id:44, name:"Under Gols", type:"text"},
    {id:45, name:"Under 0.5", type:"button"},
    {id:46, name:"Under 1.5", type:"button"},
    {id:47, name:"Under 2.5", type:"button"},
    {id:48, name:"Under 3.5", type:"button"},

    {id:0, name:"Gols PAR / IMPAR", type:"text"},
    {id:1, name:"Par 0, 2 ou 4", type:"button"},
    {id:2, name:"Impar 1, 3", type:"button"},

    {id:3, name:"Time a Marcar Primeiro", type:"text"},
    {id:4, name:"Casa", type:"button"},
    {id:5, name:"Nenhum", type:"button"},
    {id:6, name:"Visitante", type:"button"},

    {id:7, name:"Time a Marcar por Último", type:"text"},
    {id:8, name:"Casa", type:"button"},
    {id:9, name:"Nenhum", type:"button"},
    {id:10, name:"Visitante", type:"button"},

    {id:11, name:"Resultado de Grupo", type:"text"},
    {id:12, name:"Casa 1x0, 2x0, 2x1", type:"button"},
    {id:13, name:"Casa 3x0, 3x1, 4x0", type:"button"},
    {id:14, name:"Casa Qualquer Outro", type:"button"},

    {id:15, name:"Empate 0x0", type:"button"},
    {id:16, name:"Empate 1x1, 2x2", type:"button"},
    {id:17, name:"Empate 3x3, 4x4", type:"button"},
    {id:18, name:"Visitante 1x0, 2x0, 2x1", type:"button"},
    {id:19, name:"Visitante 3x0, 3x1, 4x0", type:"button"},
    {id:20, name:"Visitante Qualquer Outro", type:"button"},

    {id:21, name:"Viradas", type:"text"},
    {id:22, name:"Casa HT - Casa FT", type:"button"},
    {id:23, name:"Casa HT - Empate FT", type:"button"},
    {id:24, name:"Casa HT - Visitante FT", type:"button"},
    {id:25, name:"Empate HT - Casa FT", type:"button"},
    {id:26, name:"Empate HT - Empate FT", type:"button"},
    {id:27, name:"Empate HT - Visitante FT", type:"button"},
    {id:28, name:"Visitante HT - Casa FT", type:"button"},
    {id:29, name:"Visitante HT - Empate FT", type:"button"},
    {id:30, name:"Visitante HT - Visitante FT", type:"button"},

    {id:58, name:"Gols", type:"text"},
    {id:59, name:"0 Gols", type:"Button"},
    {id:60, name:"1 Gol", type:"Button"},
    {id:61, name:"2 Gols", type:"Button"},
    {id:62, name:"3 Gols", type:"Button"},
    {id:63, name:"4 Gols", type:"Button"},
    {id:64, name:"+5 Gols", type:"Button"},

    {id:65, name:"First goalscorer", type:"text"},
    {id:66, name:"Nenhum jogador", type:"Button"},

    {id:67, name:"Centro avante - Casa", type:"Button"},
    {id:68, name:"Ponta direita - Casa", type:"Button"},
    {id:69, name:"Ponta esquerda - Casa", type:"Button"},
    {id:70, name:"Meia atacante - Casa", type:"Button"},
    {id:71, name:"Qualquer outro jogador - Casa", type:"Button"},

    {id:72, name:"Centro avante - Fora", type:"Button"},
    {id:73, name:"Ponta direita - Fora", type:"Button"},
    {id:74, name:"Ponta esquerda - Fora", type:"Button"},
    {id:75, name:"Meia atacante- Fora", type:"Button"},
    {id:76, name:"Qualquer outro jogador - Fora", type:"Button"},

]

export const CHAMPIONS_SHIP = [

    {id:1, name: 'Copa do mundo', value:"Copa do Mundo", img:'./images/copa-do-mundo.png', margin:'25px'},
    {id:2, name: 'Euro copa', value:"Euro Cup", img:'./images/euro-copa.png', margin:'10px'},
    {id:3, name: 'Super Liga Super Americana', value:"Super Liga Sul-Americana",img:'./images/conmebol-sul-americana.png', margin:'10px'},
    {id:4, name: 'Premier league', value:"Premier League",img:'./images/premier-league.png', margin:'10px'}
]

export const SHOW = [

    {id:0, name:"Placar HT e FT", type:"text"},
    {id:1, name:"Resultado FT", type:"button"},
    {id:2, name:"Resultado HT", type:"button"},
/*
    {id:3, name:"Odds Resultado final", type:"text"},
    {id:4, name:"Casa", type:"button"},
    {id:5, name:"Empate", type:"button"},
    {id:6, name:"Visitante", type:"button"},

    {id:7, name:"Odds Dupla Hipótese", type:"text"},
    {id:8, name:"Casa", type:"button"},
    {id:9, name:"Empate", type:"button"},
    {id:10, name:"Visitante", type:"button"},
*/
]

export const HOUR_FILTER = [

    {id:1, name: "6h", value:6},
    {id:2, name: "12h", value:12},
    {id:3, name: "24h", value:24},
]