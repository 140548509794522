

import { useEffect, useState } from "react";
import { Container, Text } from "../../../componets/styles";
import HourHelp from "../../../helpers/HourHelp";
import { BoxIndicator, BtnCheckIndicator, BtnMinutes, ContainerGradient, ContainerOpacity, ImgChanpionship } from "../style";
import {  API_URL_SCRALPER_PREMIERLEAGUERESULTS_ODDS, API_URL_SCRALPER_PREMIERLEAGUERESULTS_RESULT, API_URL_SCRALPER_PREMIER_ODDS_NEXT_GAMES, API_URL_SCRALPER_WORLD_ODDS_NEXT_GAMES } from "../../../config";
import axios from "axios";

import { GiSoccerBall } from "react-icons/gi";
import TooltipComponent from "./tooltip";

export default function PremierLeague({
    hourFilterComponent, 
    strategyComponent, 
    setReload, 
    showComponent,
    arrayColorSelect,
    setArrayColorSelect,

})


{
    const [goalsVertical, setGoalsVertical] = useState([]);
    const [loop, setloop] = useState(false);
    const [lastKnownId, setLastKnownId] = useState("first");
    const [allResults, setAllResults] = useState([]);
    const [percentualVertical, setPercentualVertical] = useState([]);
    const [buttonsMinutesVertical, setButtonsMinutesVertical] = useState([]);
    const [arrayMinutesColorSelect, setMinutesColorSelect] = useState([]);
    const [barraPercentage, setBarraPercentage] = useState(null)
    const [percentage, setPercentege] = useState({
        red: '0,00%',
        green: '0,00%'
    })
    
    const [data, setData] = useState({
        hours:[]
    })

    function calculetePercentege(arr)
    {
        const total = arr.reduce((acc, obj) => {
            acc.greenCount += obj.greenCount;
            acc.redCount += obj.redCount;
            return acc;
        }, { greenCount: 0, redCount: 0 });

        const totalCount = total.greenCount + total.redCount;
        const greenPercentageTotal = ((total.greenCount / totalCount) * 100).toFixed(2) + "%";
        const redPercentageTotal = ((total.redCount / totalCount) * 100).toFixed(2) + "%";

        return {
            greenPercentageTotal:greenPercentageTotal,
            redPercentageTotal:redPercentageTotal
        }
    }

    async function handleGames(URL_RESULTS)
    {
        lastKnownId === "first" &&  setReload(true);

        try {
            const nextGames = await axios.get(`${API_URL_SCRALPER_PREMIER_ODDS_NEXT_GAMES}`);

            const gamesResponse = await axios.get(`${URL_RESULTS}${lastKnownId}`);
    
            const gamesResults = gamesResponse.data
    
            if(gamesResults)
            {
    
                setAllResults(gamesResults);
    
                setLastKnownId(gamesResults[0].id);
    
                const arrHourGames = HourHelp.arrHour(gamesResults, strategyComponent.id, showComponent.id, nextGames.data.data);
            
                const calcPercentege = calculetePercentege(arrHourGames);
        
                setPercentege({
                    ...percentage,
                    green:calcPercentege.greenPercentageTotal,
                    red:calcPercentege.redPercentageTotal
                })
        
                setData({
                    ...data,
                    hours:arrHourGames.slice(0, hourFilterComponent.value)
                })
            }
        } catch (error) {
            console.log(error)
        }



        setTimeout(() => {
            setReload(false);
        }, 3000);

        setTimeout(() => {
            setloop(!loop);
        }, 40000);
    }

    useEffect(()=>{
        handleGames(API_URL_SCRALPER_PREMIERLEAGUERESULTS_RESULT);
    },[loop])


    async function updateColors()
    {
        const nextGames = await axios.get(`${API_URL_SCRALPER_PREMIER_ODDS_NEXT_GAMES}`);

        const arrHourGames = HourHelp.arrHour(allResults, strategyComponent.id, showComponent.id, nextGames.data.data);

        setData({
            ...data,
            hours:arrHourGames.slice(0, hourFilterComponent.value)
        })
    }

    useEffect(()=>{
        updateColors()
    },[hourFilterComponent, strategyComponent, showComponent])



    useEffect(() => {

        const generateArray = (startMinute, step) => {
          return Array.from({ length: 20 }, (_, index) => {
            const minute = startMinute + index * step;
            return {
              id: minute / step + 1,
              goals: 0,
              minutes: minute.toString().padStart(2, '0'),
              width: '4.34%'
            };
          });
        };
      
        let newPercentualVertical = [];
        let newButtonsMinutesVertical = [];
      
        newPercentualVertical = generateArray(0, 3);
        newButtonsMinutesVertical = generateArray(0, 3);
      
        setPercentualVertical(newPercentualVertical);
        setButtonsMinutesVertical(newButtonsMinutesVertical);
        setGoalsVertical(newPercentualVertical)

      }, []);

      function evaluatePercentage(percentageStr) {
        // Removendo o sinal de porcentagem e convertendo para número
        const percentage = parseFloat(percentageStr.replace('%', ''));
    
        if (percentage <= 50) {
            return "#ef4444";
        } else if (percentage > 50 && percentage <= 60) {
            return "#fb923c";
        } else {
            return "#86efac";
        }
    }


    function calculateGreenPercentage(dataArray, targetMinutes) {

        let totalMinutes = 0;
        let greenMinutes = 0;
      
        dataArray.forEach(hourObj => {
          const matchingMinutes = hourObj.minutes.filter(minuteObj => {
            const minutePart = minuteObj.time.split(":")[1];
            return targetMinutes.some(target => target === minutePart) && minuteObj.id !== null;
          });
      
          const greenMatchingMinutes = matchingMinutes.filter(minuteObj => minuteObj.color === "green");
      
          totalMinutes += matchingMinutes.length;
          greenMinutes += greenMatchingMinutes.length;
        });
      
        const greenPercentage = totalMinutes ? (greenMinutes / totalMinutes) * 100 : 0;
      
        // Arredonda para duas casas decimais e adiciona o símbolo de porcentagem
        const formattedGreenPercentage = greenPercentage.toFixed(2) + '%';
      
        return formattedGreenPercentage;
    }


    function onAddMinutesArray(minutes, item) {

        const arr = [...arrayMinutesColorSelect];  
      
        const index = arr.indexOf(minutes);
      
        if (index === -1) {

          arr.push(minutes);

        } else {

          arr.splice(index, 1); 

        }
      
        setMinutesColorSelect(arr); 
        updateColors(); 

        setBarraPercentage(calculateGreenPercentage(data.hours, arr));
    }



    return(
        <>
            <ContainerGradient>

                <Container w="100%" display="flex" jc="space-between" items="center" pl="16px" pr="16px">

                    <Container w="auto" display="flex">
                        <Text col="#CFFFC2" fs="20px" fw="bold">GREEN: {percentage.green}</Text>
                        <Text col="#FF0000" fs="20px" fw="bold" ml="20px" mr="150px">RED: {percentage.red}</Text>
                    </Container>

                    <Container w="auto" display="flex" jc="ceter" items="center">
                        <ImgChanpionship w="50px" h="50px" src="./images/premier-league.png"/>
                        <Text col="#fff" fw="bold">Premier league</Text>
                    </Container>

                </Container>

            </ContainerGradient>

            <Container w="100%" pl="20px" pr="20px"  display="flex" >

                <Container  w={"4.34%"}>
                    <Container w="100%" display="flex" jc="center" items="center">
                        <Text col="#fff">G</Text>
                    </Container>
                </Container>
                        
                {goalsVertical.map(item=>{
                    return(
                        <Container key={item.id} w={item.width}>
                            <Container w="100%" display="flex" jc="center" items="center">
                                <Text col="#fff">{HourHelp.getTotalGoalsForMinute(data.hours, item.minutes)}</Text>
                            </Container>
                        </Container>
                    )
                })}

                <Container  w={"8.68%"}>
                    <Container w="100%" display="flex" jc="center" items="center">
                        <Text col="#fff"></Text>
                    </Container>
                </Container>

            </Container>
            <Container pos={"relative"} >
                    <ContainerOpacity>

                        <Container w="100%" pb="20px" pt="20px"  display="flex" >

                        <Container w={"4.34%"} ></Container>

                        {percentualVertical.map(item=>{
                                return(
                                        <Container key={item.id} w={item.width} >
                                            <Container w="100%">
                                                <Container w="100%" display="flex" jc="center" items="center">
                                                    <Text  fs="14px"col={evaluatePercentage(HourHelp.analyzeGreenForMinute(data.hours, item.minutes).greenPercentage)}>{HourHelp.analyzeGreenForMinute(data.hours, item.minutes).greenCount}</Text>
                                                </Container>
                                                <Container  w="100%" display="flex" jc="center" items="center" mt="5px">
                                                    <Text  fs="12px" col={evaluatePercentage(HourHelp.analyzeGreenForMinute(data.hours, item.minutes).greenPercentage)}>{HourHelp.analyzeGreenForMinute(data.hours, item.minutes).greenPercentage}</Text>
                                                </Container>
                                            </Container>
                                        </Container>
                                )
                            })}
                        </Container>
                        {arrayMinutesColorSelect.length > 0
                            &&
                            <Container h="30px" w="100%" mb="5px">
                                <Container h="100%" w={barraPercentage} bg="#ccc" display="flex" jc="center" items="center">
                                    <Text  fw="bold" col="green">{`${barraPercentage} ${strategyComponent.name}`}</Text>
                                </Container>
                            </Container>
                        }


                        <Container  display="flex">

                            <Container w="4.34%" display="flex" jc="center" items="center">
                                <Text col="#FFFF">Hora</Text>
                            </Container>

                            {buttonsMinutesVertical.map(item=>{
                                return(

                                    <Container key={item.id} w={item.width} display="flex" jc="center" items="center">
                                        <BtnMinutes onClick={()=>{onAddMinutesArray(item.minutes, item)}}>{item.minutes}</BtnMinutes>
                                    </Container>
                                )
                            })}

                            <Container w="4.34%" display="flex" jc="center" items="center">
                                <Text col="#FFFF">Dados</Text>
                            </Container>

                            <Container w="4.34%" display="flex" jc="center" items="center">
                                <Text col="#FFFF"><GiSoccerBall/></Text>
                            </Container>
                    
                        </Container>

                        {data.hours.map((hour, index)=>{

                            return( 
                                <Container key={index} display="flex" >

                                    <BtnCheckIndicator w={"4.34%"} bg="#707070">
                                        {/*hour.marked ? <BsCheckSquare color='#fff'/> : <BsSquareFill color='#fff'/>*/}
                                        <Text ml="5px" col='#fff'>{hour.hour}</Text>
                                    </BtnCheckIndicator>

                                    {hour.minutes.map((item,index)=>{
                                        return(
                                          
                                            <TooltipComponent  key={index}
                                                 item={item} 
                                                 arrayColorSelect={arrayColorSelect} 
                                                 setArrayColorSelect={setArrayColorSelect}
                                                 updateColors={updateColors}
                                                 arrayMinutesColorSelect={arrayMinutesColorSelect}
                                                 setMinutesColorSelect={setMinutesColorSelect}
                                            />
                                          
                                        )
                                    })}
                                    

                                    <BoxIndicator bg={ "#737373"} w="4.34%">
                                        <Text fs="14px" ml="5px" col={evaluatePercentage(hour.greenPercentage)} fw="bold">{hour.greenPercentage}</Text>
                                    </BoxIndicator>

                                    <BoxIndicator  bg={"#a3a3a3"} w="4.34%">
                                        <Text fs="14px" ml="5px" col='#FFE500' fw="bold">{hour.totalGoals}</Text>
                                    </BoxIndicator>
        
                                </Container>
                            )
                        })}



                    </ContainerOpacity>
                </Container>
        </>
    )
}