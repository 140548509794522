import styled from 'styled-components';


export const GlobalContainer = styled.div`

    width: 100%;
    height: 100vh;
    background-color: ${props => props.bg || '#fff'};
    background-image: url('/images/bg-auth.jpg');
    background-size: cover; 
    background-repeat: no-repeat; 
    background-position: center center; 
    overflow: auto;
    
`;

export const HeaderContainer = styled.div`

    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
        display: none;
    }
    

`

export const TextLogo = styled.h1`

    font-size: 20px;
    color: #0d9488;
    margin-left: 20px;

    @media (min-width: 600px) and (max-width: 1200px) {
        font-size: 30px;
    }
    
      @media (min-width: 1201px) {
        font-size: 50px;
    }
`
export const BtnHeader = styled.div`
    width: 120px;
    height: 35px;
    border-radius: 5px;
    background-color: #fff;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #032E2D;
    font-weight: bold;
    cursor: pointer;

    @media (max-width: 768px) {
        display: none;
    }

`

export const FormContainer = styled.div`

    width: 90%;
    margin: 0 auto; 
    min-height: 450px;
    background-color: rgba(7,150,35, 0.7);
    border-radius: 10px;
    padding-top: 50px;
    padding-bottom: 50px;

    @media (min-width: 600px) and (max-width: 1200px) {
        width: 50%;
    }
    
      @media (min-width: 1201px) {
        width: 40%;
    }

`

export const InputFormAuth = styled.input`

    width: calc(90% - 10px);
    height: 50px;
    border-radius: 10px;
    font-size: 18px;
    color: #707070;
    margin-top: 30px;
    margin-left: 5%;
    border: none;
    outline: none;
    padding-left: 10px;
`
export const ButtonFormAuth = styled.button`

    width: 90%;
    height: 50px;
    border-radius: 10px;
    font-size: 18px;
    background-color: #A2EA0B;
    margin-top: 30px;
    margin-left: 5%;
    border: none;
    display:flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover{
        background-color: #032C28;
    }

`
export const Logo = styled.img`
    width: 300px;
    height: 300px;
    margin-top: 200px;

    @media (max-width: 768px) {
        display: none;
    }
`
export const HeaderMobile = styled.div`
    width: 100%;
    height: 150px;
    display: none;

    @media (max-width: 768px) {
        display: flex;
        justify-content:center;
    }

`

export const LogoMobile = styled.img`
    width: 200px;
    height: 200px;

`