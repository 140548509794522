import moment from 'moment';
import Strategys from './Strategys';

class TableAssemble {
    static create(data, strategy) {
        let mount = this.#mount(data, strategy);
        return mount;
    }

    static #mount(data, strategy) {
        const mount = [];
        data.forEach(element => {
            const split = element.time.trim().split(":");
            if (split.length > 0) {

                let formatHour = split[0]

                if(split[0].length === 1)
                {
                    formatHour = '0' + split[0]
                }

                if (!mount.some(obj => obj.hour === formatHour)) {
                    const { games, greenPercentage, totalGoals } = this.#games(data, split[0], strategy);

                    mount.push({
                        hour: formatHour,
                        date_time: element.date_time,
                        games: games,
                        greenPercentage: greenPercentage,
                        totalGoals: totalGoals
                    });
                }
            }
        });

        return mount;
    }

    static #games(data, hour, strategy) {
        const game = [];
        const minutesFound = new Set();
        let totalGames = 0;
        let greenCount = 0;
        let totalGoals = 0;

        // Filtrar e processar os jogos da hora especificada
        data.forEach(element => {
            const split = element.time.trim().split(":");
            if (split.length > 0 && split[0] === hour) {
                const scoreboard = element.scoreboard.trim().split("-");
                const homeScore = parseInt(scoreboard[0], 10);
                const awayScore = parseInt(scoreboard[1], 10);

                const score = `${homeScore}-${awayScore}`
                const color = this.#handleStrategy(strategy, score)
              
                game.push({
                    code: element.code,
                    time: element.time,
                    scoreboard: score,
                    color: color
                });

                totalGames++;
                if (color === 'green') {
                    greenCount++;
                }
                totalGoals += homeScore + awayScore;
                minutesFound.add(split[1]);
            }
        });

        // Preencher minutos faltantes com dados vazios
        for (let minute = 0; minute < 60; minute++) {
            const minuteStr = minute.toString().padStart(2, '0');
            if (!minutesFound.has(minuteStr)) {
                game.push({
                    code: "",
                    time: `${hour}:${minuteStr}`,
                    scoreboard: "",
                    color: "transparent"
                });
            }
        }

        // Ordenar os jogos por tempo
        game.sort((a, b) => {
            const timeA = a.time.split(":").map(Number);
            const timeB = b.time.split(":").map(Number);
            return (timeA[0] * 60 + timeA[1]) - (timeB[0] * 60 + timeB[1]);
        });

        // Calcular percentual de green e quantidade de gols
        const greenPercentage = totalGames > 0 ? (greenCount / totalGames) * 100 : 0;

        return {
            games: game,
            greenPercentage: greenPercentage.toFixed(2),
            totalGoals: totalGoals
        };
    }

    static #handleStrategy(strategy, score)
    {
        switch (strategy) {
            case 1:
                return Strategys.bothScoreYes(score)
                break;
            case 2:
                return Strategys.bothScoreNot(score)
                break;    
            case 3:
                return Strategys.homeMarked(score)
                break;
            case 4:
                return Strategys.awayMarked(score)
                break;                   
            case 5:
                return Strategys.over_0_5(score)
                break;
            case 6:
                return Strategys.over_1_5(score)
                break;    
            case 7:
                return Strategys.over_2_5(score)
                break;
            case 8:
                return Strategys.over_3_5(score)
                break;     
            case 9:
                return Strategys.under_0_5(score)
                break;
            case 10:
                return Strategys.under_1_5(score)
                break;    
            case 11:
                return Strategys.under_2_5(score)
                break;
            case 12:
                return Strategys.under_3_5(score)
                break;                  
            case 13:
                return Strategys.zero_goals(score)
                break;     
            case 14:
                return Strategys.one_goals(score)
                break;
            case 15:
                return Strategys.two_goals(score)
                break;    
            case 16:
                return Strategys.tree_goals(score)
                break;
            case 17:
                return Strategys.fuor_goals(score)
                break;
            case 18:
                return Strategys.more_five_goals(score)
                break;
            case 19:
                return Strategys.par_goals(score)
                break;
            case 20:
                return Strategys.impar_goals(score)
                break;        
                
            case 21:
                return Strategys.isHomeGroup0(score)
                break;
            case 22:
                return Strategys.isHomeGroup1(score)
                break;
            case 23:
                return Strategys.isHomeGroup2(score)
                break;
            case 24:
                return Strategys.isEmpateGrup0(score)
                break;
            case 25:
                return Strategys.isEmpateGroup1(score)
                break;                    
            case 26:
                return Strategys.isEmpateGroup2(score)
                break;
            case 27:
                return Strategys.isAwayGroup0(score)
                break;
            case 28:
                return Strategys.isAwayGroup1(score)
                break;
            case 29:
                return Strategys.isAwayGroup2(score)
                break;
   

            default:
                return '#ccc'
                break;
        }
    }
}

export default TableAssemble;
