import { Container, Text } from "../../componets/styles";
import { GlobalContainer, ImageLogo } from "./styled";

export default function Manutencao()
{
    return(

        <GlobalContainer>

            <Container w='100%' display='flex' jc='center' mt='50px'>

                <Text col='#fff' fs='30px' fw='bold '> Estamos em manutenção previsão de retorno 00:00. </Text>

            </Container>

            <Container w='100%' display='flex' jc='center' mt='50px'>

                <Text col='#fff' fs='30px' fw='bold '></Text>

            </Container>

            <Container w='100%' display='flex' jc='center' mt='50px'>

                <ImageLogo src='./images/logo.png'/>

            </Container>

        </GlobalContainer>
    )
}