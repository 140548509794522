

export const initialStatistics = {

    maxImparStreak: 0,
    maxParStreak: 0,
    lastImparStreak: 0,
    lastParStreak: 0,
    lastStreaksUnderOver:{
        "0.5":{under:0, over:0},
        "1.5":{under:0, over:0},
        "2.5":{under:0, over:0},
        "3.5":{under:0, over:0}
    },
    maxStreaksUnderOver:{
        "0.5":{under:0, over:0},
        "1.5":{under:0, over:0},
        "2.5":{under:0, over:0},
        "3.5":{under:0, over:0}
    },
    lastStreaksExactGoals:{
        "0":0,
        "1":0,
        "2":0,
        "3":0,
        "4":0,
        "+5":0
    },
    maxStreaksExactGoals:{
        "0":0,
        "1":0,
        "2":0,
        "3":0,
        "4":0,
        "+5":0
    },
    lastBothScore:{
        bothScored:0,
        neitherScored:0

    },
    maxBothScore:{
        bothScored:0,
        neitherScored:0
    },
    lastResultIntervalEndGame:{
        awayEnd: 0,
        awayInterval: 0,
        homeEnd:0,
        homeInterval:0,
        drawInterval: 0,
        drawEnd: 0
    },
    maxResultIntervalEndGame:{
        awayEnd: 0,
        awayInterval: 0,
        homeEnd:0,
        homeInterval:0,
        drawInterval: 0,
        drawEnd: 0
    },
    lastExactScoreStreaks:{
        home: {
            "1 - 0":0,
            "2 - 0":0,
            "2 - 1":0,
            "3 - 0":0,
            "3 - 1":0,
            "3 - 2":0,
            "4 - 0":0
        },
        away:{

            "0 - 1":0,
            "0 - 2":0,
            "1 - 2":0,
            "0 - 3":0,
            "1 - 3":0,
            "2 - 3":0,
            "0 - 4":0                
        },
        draw:{
            "0 - 0":0,
            "1 - 1":0,
            "2 - 2":0,
            "3 - 3":0,
            "4 - 4":0
        }
    },
    maxExactScoreStreaks:{
        home: {
            "1 - 0":0,
            "2 - 0":0,
            "2 - 1":0,
            "3 - 0":0,
            "3 - 1":0,
            "3 - 2":0,
            "4 - 0":0
        },
        away:{

            "0 - 1":0,
            "0 - 2":0,
            "1 - 2":0,
            "0 - 3":0,
            "1 - 3":0,
            "2 - 3":0,
            "0 - 4":0                
        },
        draw:{
            "0 - 0":0,
            "1 - 1":0,
            "2 - 2":0,
            "3 - 3":0,
            "4 - 4":0
        }
    },
    lastHalfTimeExactScoreStreaks:{
        home: {
            "1-0":0,
            "2-0":0,
        },
        away:{

            "0-1":0,
            "0-2":0,              
        },
        draw:{
            "0-0":0,
            "1-1":0,

        }
    },
    maxHalfTimeExactScoreStreaks:{
        home: {
            "1-0":0,
            "2-0":0,            },
        away:{

            "0-1":0,
            "0-2":0,
        },
        draw:{
            "0-0":0,
            "1-1":0,
        }
    },
    lastFirstScorePlayers: {
        "team_home": {
            "Right Winger": 0,
            "Left Winger": 0,
            "Centre Forward": 0,
            "Ponta esquerda": 0,
            "Ponta direita": 0,
            "Outro jogador": 0,
            "Centro-avante": 0,
            "Meia Atacante": 0,
            "Sem Marcador de Gol": 0
        },
        "team_away": {
            "Right Winger": 0,
            "Left Winger": 0,
            "Centre Forward": 0,
            "Ponta esquerda": 0,
            "Ponta direita": 0,
            "Outro jogador": 0,
            "Centro-avante": 0,
            "Meia Atacante": 0,
            "Sem Marcador de Gol": 0
        }
    },
    maxFirstScorePlayers: {
        "team_home": {
            "Right Winger": 0,
            "Left Winger": 0,
            "Centre Forward": 0,
            "Ponta esquerda": 0,
            "Ponta direita": 0,
            "Outro jogador": 0,
            "Centro-avante": 0,
            "Meia Atacante": 0,
            "Sem Marcador de Gol": 0
        },
        "team_away": {
            "Right Winger": 0,
            "Left Winger": 0,
            "Centre Forward": 0,
            "Ponta esquerda": 0,
            "Ponta direita": 0,
            "Outro jogador": 0,
            "Centro-avante": 0,
            "Meia Atacante": 0,
            "Sem Marcador de Gol": 0
        }
    },

    lastFirstTeamScore:{
        "team_home": 0,
        "team_none":0,
        "team_away": 0
    },
    maxFirstTeamScore:{

        "team_home": 0,
        "team_none":0,
        "team_away": 0
    },
    lastLastTeamScore:{
        "team_home": 0,
        "team_none":0,
        "team_away": 0
    },
    maxLastTeamScore:{

        "team_home": 0,
        "team_none":0,
        "team_away": 0
    },
    mostCommonScores:[]
}


export const STRATEGY = [

    {id:31, name:"Resultado FT Casa x Visitante", type:"text"},
    {id:32, name:"Mais venceu", type:"button"},
    {id:33, name:"Mais empatou", type:"button"},
    {id:34, name:"Mais perdeu", type:"button"},
    {id:35, name:"Mais pontos", type:"button"},

    {id:36, name:"Resultado HT Casa x Visitante", type:"text"},
    {id:37, name:"Mais venceu", type:"button"},
    {id:38, name:"Mais empatou", type:"button"},
    {id:39, name:"Mais perdeu", type:"button"},
    {id:40, name:"Mais pontos", type:"button"},

    {id:41, name:"Over Gols", type:"text"},
    {id:42, name:"Over 0.5", type:"button"},
    {id:43, name:"Over 1.5", type:"button"},
    {id:44, name:"Over 2.5", type:"button"},
    {id:45, name:"Over 3.5", type:"button"},

    {id:46, name:"Under Gols", type:"text"},
    {id:47, name:"Under 0.5", type:"button"},
    {id:48, name:"Under 1.5", type:"button"},
    {id:49, name:"Under 2.5", type:"button"},
    {id:50, name:"Under 3.5", type:"button"},

    {id:51, name:"Ambas Marcam", type:"text"},
    {id:52, name:"Sim", type:"button"},
    {id:53, name:"Não", type:"button"},

//    {id:52, name:"Mandante Marca", type:"text"},
//     {id:53, name:"Sim", type:"button"},
 //   {id:54, name:"Não", type:"button"},

//    {id:55, name:"Visitante Marca", type:"text"},
//    {id:56, name:"Sim", type:"button"},
 //   {id:57, name:"Não", type:"button"},

]

export const CHAMPIONS_SHIP = [
    {id:1, name: 'Copa do mundo', img:'./images/copa-do-mundo.png', margin:'25px'},
    {id:2, name: 'Euro copa', img:'./images/euro-copa.png', margin:'10px'},
    {id:3, name: 'Super Liga Super Americana', img:'./images/conmebol-sul-americana.png', margin:'10px'},
    {id:4, name: 'Premier league', img:'./images/premier-league.png', margin:'10px'}
];