
import * as React from "react";
import {createBrowserRouter,RouterProvider,} from "react-router-dom";

import Home from '../home';
import Analysis from "../analysis";
import Payment from "../payment";
import Videos from "../videos";
import Offers from "../offers";


const router = createBrowserRouter([
  {
    path: "/",
    element: <Analysis/>,

  },
  {
    path: "/payment",
    element: <Payment/>,

  },
  {
    path: "/register",
    element: <Analysis/>,
    
  },
  {
    path: "/login",
    element: <Analysis/>,
    
  },
  {
    path: "/home",
    element: <Analysis/>,
    
  },
  {
    path: "/analysis",
    element: <Analysis/>,
    
  },
  {
    path: "/videos",
    element: <Videos/>,
    
  },
  {
    path: "/robos",
    element: <Offers/>,
    
  },

]);


export default function AppRouter()
{
    return(

        <React.StrictMode>
            <RouterProvider router={router} />
        </React.StrictMode>
    )
      
}