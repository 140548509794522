export function onLimitCharacters(input, maxLength) {
    if (input.length <= maxLength) {
      return input;
    }
    return input.substring(0, maxLength) + "...";
}

export function formatarData(dataString) {
    const data = new Date(dataString);
    const dia = data.getDate();
    const mes = obterNomeMes(data.getMonth()); // Obter o nome do mês
    const ano = data.getFullYear();
  
    return `Acesso até: ${dia} de ${mes}`;
}
  
 export function obterNomeMes(mes) {
    const meses = [
      "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
      "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
    ];
  
    return meses[mes];
}