import { Outlet } from "react-router-dom";
import { GlobalContainer } from "./styled";

export default function Auth({ children }) {
    
return (

        <GlobalContainer>
            <Outlet/>
        </GlobalContainer>

  );
}