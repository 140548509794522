import styled from 'styled-components';


const FLEX_START = `
    display: flex;
    justify-content: start;
    align-items: center;
`

const FLEX_END= `
    display: flex;
    justify-content: end;
    align-items: center;
`

const FLEX_CENTER = `
    display: flex;
    justify-content: center;
    align-items: center;
`

const FLEX_BETWEEN = `
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const GlobalContainer  = styled.div`
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    overflow: auto;
    background-color: #000;
`

export const Header = styled.div`
    width: 100%;
    height: 70px;
    ${FLEX_BETWEEN}
    background: linear-gradient(to right, #000,#77F355);
    position: relative;

`

export const LogoImageHeader = styled.img`
    width: 200px;
    height: 200px;
    margin-top: 100px;
`

export const Menu = styled.button`
    width: 35px;
    height: 35px;
    position: absolute;
    top: 20px;
    left: 320px;
    cursor: pointer;
    border-radius: 3px;
    background-color: transparent;
    border: none;
    z-index: 999;

`

export const  SiderBarSettings = styled.div`
    width: ${({ sideBarSettings }) => (sideBarSettings ? '400px' : '0px')};
    transition: width 0.3s ease;
    height: 100vh;
    background-color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 999;
    padding-bottom: 50px;
`

export const SpaceSideBar = styled.div`
    width: 100%;
    height: 50px;
`

export const  SiderBarSettingsHeader = styled.div`
    width: 100%;
    height: 70px;
    background: linear-gradient(to right, #77F355, #000);
    ${FLEX_START}
`

export const SiderBarSettingsButtonTransparentHeaderBack = styled.button `
    width: 50px;
    height: 50px;
    border: none;
    background-color: transparent;
    cursor: pointer;

`

export const SiderBarSettingsHeaderText = styled.span`
    font-size: 20px;
    font-weight: bold;
    margin-left: 20px;
    color: #fff;

`

export const SideBar = styled.div`
    width: ${({ sideBar }) => (sideBar ? '300px' : '0px')};
    height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    transition: width 0.3s ease;
    z-index: 1000;
    background-color: white;
`

export const SideBarHeader = styled.div`
    width: ${({ sideBar }) => (sideBar ? '100%' : '0px')};
    height: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(to right, #000,#77F355);
`
export const SideBarContent = styled.div`
    width: 100%;
    height: auto;
    max-height: calc(100vh - 100px);
    overflow: auto;
`

export const SideBarTitle = styled.h3`
    margin-top: 20px;
    margin-left: 10px;
    color: blue;
`

export const SideBarBoxFlexBetween = styled.div`
    width: 100%;
    height: auto;
    ${FLEX_BETWEEN};

`

export const SideBarItemShow = styled.div`
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    width: 45%;
    height: 50px;
    background-color: transparent;
    border-radius: 5px;
    border: 2px solid #000;
    cursor: pointer;
    font-weight: bold;
    font-size: 20px;
    ${FLEX_CENTER}

    &:hover{
        border: none;
        background-color: #77F355;
        color: #fff
    }
`

export const SideBarItemStrategy = styled.span`
    font-size: 15px;
    color: #000c;
    margin-top: 10px;
    margin-left: 10px;
    font-weight: bold;
    
`

export const LogoImageHeaderSideBar = styled.img`
    opacity: ${({ sideBar }) => (sideBar ? '1' : '0')};
    width: 200px;
    height: 200px;
    margin-top: 130px;
    margin-left: 45px;
    transition: opacity 1s ease;
`

export const SideBarIconCheck = styled.div`
    margin-right: 10px;
    cursor: pointer;
`

export const MenuList = styled.ul`

    width: 97%;
    height: auto;
    list-style: none;
    margin-top: 100px;

`

export const MenuItem = styled.li`

    width: 100%;
    height: 40px;
    border-radius: 3px;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 20px;
    margin-left: 1%;
    padding-left: 35px;
    gap: 10px;
    font-size: 15px;
    cursor: pointer;

`

export const TextInfo = styled.span`
    font-size: 15px;
`

export const TextDateValidation = styled.span`
    font-size: 15px;
    color: red;
`
export const ButtonLogOff = styled.button`
    width: 150px;
    height: 40px;
    border: 2px solid #fff;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 20px;
    background-color: transparent;
    font-size: 15px;
    color: #fff;

`

export const ContainerButtonAnalysis = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 90px;
    
`

export const ButtonAnalysis = styled.div`

    width: 16.2%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ active }) => (active ? '#2cf776' : '#15803d')};
    color: ${({ active }) => (active ? '#000' : '#fff')};
    font-size: 1.2vw;
    font-weight: bold;
    cursor: pointer;

    &:hover {
        background-color: #1c9c50; 
        color: white;
    }

`

export const ContainerSettings = styled.div`
    width: 100%;
    height: 70px;
    margin-top: 20px;
    ${FLEX_START};
    gap: 1.2vw;
    background: linear-gradient(to top, #000,#77F355);
    position: relative
`

export const TextTitleSettings = styled.span`
    font-size: 20px;
    color: #fff;
    font-weight: bold;
    margin-left: 20px;
`

export const TextTitleItemSettings = styled.span`
    font-size: 20px;
    color: #000;
    font-weight: bold;
    margin-left: 20px;
`

export const TextTitleGreen = styled.span`
    font-size: 20px;
    color: rgb(115, 238, 115);
    font-weight: bold;
    margin-left: 1.2vw;
`

export const TextTitleRed = styled.span`
    font-size: 20px;
    color: red;
    font-weight: bold;
    margin-left: 1.2vw;
`

export const ButtonSettings = styled.button`
    cursor: pointer;
    width: 50px;
    height: 50px;
    background-color: transparent;
    position: absolute;
    top: 10px;
    right: 20px;
    border: none;
`

export const ButtonUpdate = styled.button`
    cursor: pointer;
    width: 50px;
    height: 50px;
    background-color: transparent;
    position: absolute;
    top: 10px;
    right: 80px;
    border: none;
`

export const ButtonUpdateMobile = styled.button`
    cursor: pointer;
    width: 60px;
    height: 60px;
    background-color: #ccc;
    position: absolute;
    bottom: 100px;
    right: 50px;
    border: none;
    border-radius: 100%;
`

export const BoxTable = styled.div`
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
    height: auto;
    padding-bottom: 50px;

`


export const ContainerGoalsVertical = styled.div`
    width: 100%;
    height: auto;
    margin-top: 20px;
    ${FLEX_BETWEEN}

`

export const ItemGloalsVertical = styled.div`
    min-width: 50px;
    height: 20px;
    ${FLEX_CENTER};
    font-size: 20px;
    color: #fff;
    border: 1px solid #000;

`

export const ItemGloalsVerticalLg = styled.div`
    min-width: 80px;
    height: 20px;
    ${FLEX_CENTER};
    font-size: 20px;
    color: #fff;
    border: 1px solid #000;
`

export const ContainerGreenVertical = styled.div`
    min-width: 80px;
    height: auto;
    margin-top: 5px;
    ${FLEX_BETWEEN}
    border: 1px solid #000;

`

export const ItemGreenVertical = styled.div`
    min-width: 50px;
    height: 20px;
    ${FLEX_CENTER};
    font-size: 20px;
    color: ${({ value }) => 
        value >= 60 ? '#2cf776' : 
        value >= 50 && value < 60 ?  'orange' : 
        value < 50 ? 'red' : '#fff' // Adiciona uma cor padrão caso não seja atendida nenhuma das condições anteriores
    };
    border: 1px solid #000;

`

export const ItemGreenVerticalLg = styled.div`
    min-width: 80px;
    height: 20px;
    ${FLEX_CENTER};
    font-size: 20px;
    color: #fff;
    border: 1px solid #000;
`

export const ContainerGreenPercentegeVertical = styled.div`
    width: 100%;
    height: auto;
    margin-top: 5px;
    ${FLEX_BETWEEN}
    border: 1px solid #000;

`

export const ItemGreenPercentegeVertical = styled.div`
    min-width: 50px;
    height: 20px;
    ${FLEX_CENTER};
    font-size: 20px;
    color: ${({ value }) => 
        value >= 60 ? '#2cf776' : 
        value >= 50 && value < 60 ?  'orange' : 
        value < 50 ? 'red' : '#fff' // Adiciona uma cor padrão caso não seja atendida nenhuma das condições anteriores
    };
    border: 1px solid #000;
`;


export const ItemGreenPercentegeVerticalLg = styled.div`
    min-width: 80px;
    height: 20px;
    ${FLEX_CENTER};
    font-size: 15px;
    color: ${({ value }) => 
        value >= 60 ? '#2cf776' : 
        value >= 50 && value < 60 ?  'orange' : 
        value < 50 ? 'red' : '#fff' // Adiciona uma cor padrão caso não seja atendida nenhuma das condições anteriores
    };
    border: 1px solid #000;
`

export const ContainerOpacity = styled.div`
    width: auto;
    height: auto; 
    padding-top: 10px;
    padding-bottom: 20px;
   

    
`
//background-color: rgba(250, 250 , 250, 0.3);

export const ContainerMinutes = styled.div`
    width: 100%;
    height: auto;
    ${FLEX_BETWEEN}
    margin-bottom: 20px;
    
`

export const ItemMinutes = styled.div`
    min-width: 50px;
    height: 20px;
    ${FLEX_CENTER};
    font-size: 20px;
    color: #fff;
    cursor: pointer;
    border: 1px solid #000;
    
`

export const ItemMinutesLg = styled.div`
    min-width: 80px;
    height: 20px;
    ${FLEX_CENTER};
    font-size: 20px;
    color: #fff;
    border: 1px solid #000;
`

export const ContainerHours = styled.div`
    width: 100%;
    height: 30px;
    font-size: 15px;
    color: #fff;
    font-weight: bold;
    ${FLEX_BETWEEN}
`

export const ItemHours = styled.div`
    min-width: 80px;
    height: 100%;
    background-color: tranparent;
    font-size: 20px;
    ${FLEX_CENTER}

`

export const ItemGame = styled.div`
    min-width: 50px;
    height: 100%;
    background: ${({ color, bgSelect }) => 
        bgSelect ? `linear-gradient(to right, ${bgSelect} 0%, ${color} 50%, ${bgSelect} 100%)` : color};
    ${FLEX_CENTER}
    cursor: pointer;
    font-size: 18px;
    border: 1px solid #000;

`

export const HeaderMobile = styled.div`

    width: calc(100% - 30px);
    height: 70px;
    background: linear-gradient(to right, #000,#77F355);
    padding-left: 30px;
    display: flex;
    justify-content: space-between;
    align-items:center;
   
`

export const LogoMobile = styled.img`

    width: 50px;
    height: 50px;
    margin-right: 30px;

`

export const SideBarMobile = styled.div`
    width: ${props => (props.open ? '100%' : '0%')};
    transition: width 0.5s ease-in-out; 
    height: 100vh;
    background-color: #484D45;
    position: absolute;
    z-index: 999;
    top:0;
    left:0;
    
`

export const ButtomMenu = styled.div`

    width: 95%;
    height: 40px;
    margin-left: 2.5%;
    border: 2px solid #77F355;
    border-radius: 5px;
    display: flex;
    justify-content:center;
    align-items:center;
    margin-top: 10px;

    &:hover{
        background-color: #000;
    }

`

export const StrategyMobile = styled.div`

    width: 100%;
    height: ${props => (props.openStrategy ? 'auto' : '0px')} ;
    transition: height 0.5s ease-in-out; 
    background-color: #fff;
    margin-top: ${props => (props.openStrategy ? '-10px' : '0px')} ;
    padding-top: ${props => (props.openStrategy ? '10px' : '0px')} ;
    padding-bottom: ${props => (props.openStrategy ? '100px' : '0px')} ;

`
export const StrategyButtonMobile = styled.div`
    width: 100%;
    height: 45px;
    background-color: #77F355;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    cursor: pointer;

`

export const BtnChanpionship = styled.button`

    width: 95%;
    height: 50px;
    background-color: transparent;
    border-radius: 5px;

    &:hover{
        background-color: #ccc;
    }
    display: flex;
    justify-content:center;
    align-items:center;
    cursor: pointer;
    margin-left: 2.5%;
    mergin-top: 10px;


`

export const TextTitleSettingsMobile = styled.span`
    font-size: 12px;
    color: #fff;
    font-weight: bold;
    margin-left: 20px;
`

export const TextTitleItemSettingsMobile = styled.span`
    font-size: 12px;
    color: #000;
    font-weight: bold;
    margin-left: 20px;
`

export const TextTitleGreenMobile = styled.span`
    font-size: 12px;
    color: rgb(115, 238, 115);
    font-weight: bold;
    margin-left: 1.2vw;
`

export const TextTitleRedMobile = styled.span`
    font-size: 12px;
    color: red;
    font-weight: bold;
    margin-left: 1.2vw;
`

export const ItemGloalsVerticalLgMobile = styled.div`
    min-width: 60px;
    height: 15px;
    ${FLEX_CENTER};
    font-size: 12px;
    color: #fff;
    border: 1px solid #000;
`

export const ItemGloalsVerticalMobile = styled.div`
    min-width: 40px;
    height: 15px;
    ${FLEX_CENTER};
    font-size: 12px;
    color: #fff;
    border: 1px solid #000;

`

export const ItemGreenVerticalLgMobile = styled.div`
    min-width: 60px;
    height: 15px;
    ${FLEX_CENTER};
    font-size: 12px;
    color: #fff;
    border: 1px solid #000;
`

export const ItemGreenVerticalMobile = styled.div`
    min-width: 40px;
    height: 15px;
    ${FLEX_CENTER};
    font-size: 12px;
    color: ${({ value }) => 
        value >= 60 ? '#2cf776' : 
        value >= 50 && value < 60 ?  'orange' : 
        value < 50 ? 'red' : '#fff' // Adiciona uma cor padrão caso não seja atendida nenhuma das condições anteriores
    };
    border: 1px solid #000;

`

export const ItemGreenPercentegeVerticalLgMobile = styled.div`
    min-width: 60px;
    height: 15px;
    ${FLEX_CENTER};
    font-size: 12px;
    color: ${({ value }) => 
        value >= 60 ? '#2cf776' : 
        value >= 50 && value < 60 ?  'orange' : 
        value < 50 ? 'red' : '#fff' // Adiciona uma cor padrão caso não seja atendida nenhuma das condições anteriores
    };
    border: 1px solid #000;
`

export const ItemGreenPercentegeVerticalMobile = styled.div`
    min-width: 40px;
    height: 15px;
    ${FLEX_CENTER};
    font-size: 12px;
    color: ${({ value }) => 
        value >= 60 ? '#2cf776' : 
        value >= 50 && value < 60 ?  'orange' : 
        value < 50 ? 'red' : '#fff' // Adiciona uma cor padrão caso não seja atendida nenhuma das condições anteriores
    };
    border: 1px solid #000;
`

export const ItemMinutesLgMobile = styled.div`
    min-width: 60px;
    height: 15px;
    ${FLEX_CENTER};
    font-size: 12px;
    color: #fff;
    border: 1px solid #000;
`

export const ItemMinutesMobile = styled.div`
    min-width: 40px;
    height: 15px;
    ${FLEX_CENTER};
    font-size: 12px;
    color: #fff;
    cursor: pointer;
    border: 1px solid #000;
    
`

export const ItemHoursMobile = styled.div`
    min-width: 60px;
    height: 100%;
    background-color: tranparent;
    font-size: 12px;
    ${FLEX_CENTER}

`
export const ItemGameMobile = styled.div`
    min-width: 40px;
    height: 100%;
    background: ${({ color, bgSelect }) => 
        bgSelect ? `linear-gradient(to right, ${bgSelect} 0%, ${color} 50%, ${bgSelect} 100%)` : color};
    ${FLEX_CENTER}
    cursor: pointer;
    font-size: 12px;
    border: 1px solid #000;
`;


export const ContainerHorasJogos = styled.div`

    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

`

export const ButtomHour = styled.button`
    width: 30%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    background: linear-gradient(to top, #000, ${(props) => props.active ? '#77F355' : '#ccc'});
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    border: none;
    cursor: pointer;
`;
