import moment from 'moment';


class Strategys{

    static bothScoreYes(score) {

        const [team1, team2] = score.split('-').map(Number);
        if (team1 > 0 && team2 > 0) {
            return "green";
        } else {
            return "red";
        }
    }

    static bothScoreNot(score) {

        const [team1, team2] = score.split('-').map(Number);
        if (team1 > 0 && team2 > 0) {
            return "red";
        } else {
            return "green";
        }
    }

    static homeMarked(score) {

        const [team1, team2] = score.split('-').map(Number);
        if (team1 > 0) {
            return "green";
        } else {
            return "red";
        }
    }

    static awayMarked(score) {

        const [team1, team2] = score.split('-').map(Number);
        if (team2 > 0) {
            return "green";
        } else {
            return "red";
        }
    }

    static over_0_5(score) {

        const [team1, team2] = score.split('-').map(Number);
        if (team1 + team2 > 0) {
            return "green";
        } else {
            return "red";
        }
    }

    static over_1_5(score) {

        const [team1, team2] = score.split('-').map(Number);
        if (team1 + team2 > 1) {
            return "green";
        } else {
            return "red";
        }
    }

    static over_2_5(score) {

        const [team1, team2] = score.split('-').map(Number);
        if (team1 + team2 > 2) {
            return "green";
        } else {
            return "red";
        }
    }

    static over_3_5(score) {

        const [team1, team2] = score.split('-').map(Number);
        if (team1 + team2 > 3) {
            return "green";
        } else {
            return "red";
        }
    }

    static under_0_5(score) {

        const [team1, team2] = score.split('-').map(Number);
        if (team1 + team2 === 0) {
            return "green";
        } else {
            return "red";
        }
    }

    static under_1_5(score) {

        const [team1, team2] = score.split('-').map(Number);
        if (team1 + team2 < 2) {
            return "green";
        } else {
            return "red";
        }
    }

    static under_2_5(score) {

        const [team1, team2] = score.split('-').map(Number);
        if (team1 + team2 < 3) {
            return "green";
        } else {
            return "red";
        }
    }

    static under_3_5(score) {

        const [team1, team2] = score.split('-').map(Number);
        if (team1 + team2 < 4) {
            return "green";
        } else {
            return "red";
        }
    }

    static zero_goals(score) {

        const [team1, team2] = score.split('-').map(Number);
        if (team1 + team2 === 0) {
            return "green";
        } else {
            return "red";
        }
    }

    static one_goals(score) {

        const [team1, team2] = score.split('-').map(Number);
        if (team1 + team2 === 1) {
            return "green";
        } else {
            return "red";
        }
    }

    static two_goals(score) {

        const [team1, team2] = score.split('-').map(Number);
        if (team1 + team2 === 2) {
            return "green";
        } else {
            return "red";
        }
    }

    static tree_goals(score) {

        const [team1, team2] = score.split('-').map(Number);
        if (team1 + team2 === 3) {
            return "green";
        } else {
            return "red";
        }
    }

    static fuor_goals(score) {

        const [team1, team2] = score.split('-').map(Number);
        if (team1 + team2 === 4) {
            return "green";
        } else {
            return "red";
        }
    }

    static more_five_goals(score) {

        const [team1, team2] = score.split('-').map(Number);
        if (team1 + team2 > 5) {
            return "green";
        } else {
            return "red";
        }
    }

    static impar_goals(score) {
        return this.check_goals(score, false);
    }

    static par_goals(score) {
        return this.check_goals(score, true);
    }

    static check_goals(score, isPar) {
        const [team1, team2] = score.split('-').map(Number);
        const totalGoals = team1 + team2;

        if (isPar) {
            return totalGoals % 2 === 0 ? "green" : "red";
        } else {
            return totalGoals % 2 === 0 ? "red" : "green";
        }
    }

    static isHomeGroup0(score)
    {
        const [team1, team2] = score.split('-').map(Number);

        if(team1 === 1 & team2 === 0 | team1 === 2 & team2 === 0 | team1 === 2 & team2 === 1)
        {
            return "green"

        }else{
            return "red"

        }
    }

    static isHomeGroup1(score)
    {
        const [team1, team2] = score.split('-').map(Number);

        if(team1 === 3 & team2 === 0 | team1 === 3 & team2 === 1 | team1 === 4 & team2 === 0)
        {
            return "green"

        }else{
            return "red"
            
        }
    }

    static isHomeGroup2(score)
    {
        const [team1, team2] = score.split('-').map(Number);

        if(team1 > team2 & this.isHomeGroup0 === "red" & this.isHomeGroup1 === "red")
        {
            return "green"
        }else{
            return "red"
        }
    }    

    static isEmpateGrup0(score)
    {
        const [team1, team2] = score.split('-').map(Number);

        if(team1 + team2 === 0)
        {
            return "green"
        }else{
            return "red"
        }
    }

    static isEmpateGroup1(score)
    {
        const [team1, team2] = score.split('-').map(Number);

        if(team1 === 1 & team2 === 1 | team1 === 2 & team2 === 2)
        {
            return "green"
        }else{
            return "red"
        }
    }

    static isEmpateGroup2(score)
    {
        const [team1, team2] = score.split('-').map(Number);

        if(team1 === 3 & team2 === 3 | team1 === 4 & team2 === 4)
        {
            return "green"
        }else{
            return "red"
        }
    }


    static isAwayGroup0(score)
    {
        const [team1, team2] = score.split('-').map(Number);

        if(team1 === 0 & team2 === 1 | team1 === 0 & team2 === 2 | team1 === 1 & team2 === 2)
        {
            return "green"

        }else{
            return "red"

        }
    }

    static isAwayGroup1(score)
    {
        const [team1, team2] = score.split('-').map(Number);

        if(team1 === 0 & team2 === 3 | team1 === 1 & team2 === 2 | team1 === 0 & team2 === 4)
        {
            return "green"

        }else{
            return "red"
            
        }
    }

    static isAwayGroup2(score)
    {
        const [team1, team2] = score.split('-').map(Number);

        if(team1 < team2 & this.isHomeGroup0 === "red" & this.isHomeGroup1 === "red")
        {
            return "green"
        }else{
            return "red"
        }
    }  


}

export default Strategys;