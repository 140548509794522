export const URL_IMAGE = "https://api.futreistips.com"

export const API_URL        = "https://api.futreistips.com/api";

export const API_URL_BET365 = "https://api.futreistips.com/api/";

export const API_URL_SCRALPER_WORLDCUP_RESULT   = `${API_URL_BET365}worldCupResults?lastKnownId=`;

export const API_URL_SCRALPER_WORLDCUP_ODDS     = `${API_URL_BET365}worldCupOdds`;

export const API_URL_SCRALPER_EUROCUP_RESULT    = `${API_URL_BET365}euroCupResults?lastKnownId=`;

export const API_URL_SCRALPER_EUROCUP_ODDS      = `${API_URL_BET365}euroCupOdds`;

export const API_URL_SCRALPER_SOUTHAMERICANSUPERLEAGUE_RESULT   = `${API_URL_BET365}southAmericanSuperLeagueResults?lastKnownId=`;

export const API_URL_SCRALPER_SOUTHAMERICANSUPERLEAGUE_ODDS     = `${API_URL_BET365}southAmericanSuperLeagueOdds`;

export const API_URL_SCRALPER_PREMIERLEAGUERESULTS_RESULT       = `${API_URL_BET365}premierLeagueResults?lastKnownId=`;

export const API_URL_SCRALPER_PREMIERLEAGUERESULTS_ODDS         = `${API_URL_BET365}premierLeagueOdds`;

export const API_URL_SCRALPER_EXPRESS_RESULT       = `${API_URL_BET365}expressCupResults?lastKnownId=`;

export const API_URL_SCRALPER_EXPRESS_ODDS         = `${API_URL_BET365}expressCupOdds`;

export const API_URL_SCRALPER_WORLD_ODDS_NEXT_GAMES             = `${API_URL_BET365}nextGamesWorld`;

export const API_URL_SCRALPER_EURO_ODDS_NEXT_GAMES      = `${API_URL_BET365}nextGamesEuro`;

export const API_URL_SCRALPER_SOUTH_ODDS_NEXT_GAMES     = `${API_URL_BET365}nextGamesSouth`;

export const API_URL_SCRALPER_PREMIER_ODDS_NEXT_GAMES   = `${API_URL_BET365}nextGamesPremier`;

export const API_URL_SCRALPER_WORLD_CONFRONTATION       = `${API_URL_BET365}confrotationsWorldCupStats`;

export const API_URL_SCRALPER_RANKING = `${API_URL_BET365}ranking`;

export const API_URL_DASHBOARD        = `${API_URL_BET365}lastUnderAndOverIndex?champions=`;

export const API_TOKEN                = 'oR5it8F58tuE94iGRH57dj48euRmRsnw';

export const WHATS_APP_NUMBER = '62995422211';