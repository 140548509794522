import axios from "axios"
import { ButtonStyled, Container, GlobalModalContainer, Text } from "../../componets/styles"
import { useEffect, useState } from "react";
import { BoxHeaderGradient } from "../Maximims/style";

import { 
    API_URL_SCRALPER_EUROCUP_ODDS, 
    API_URL_SCRALPER_PREMIERLEAGUERESULTS_ODDS, 
    API_URL_SCRALPER_SOUTHAMERICANSUPERLEAGUE_ODDS, 
    API_URL_SCRALPER_WORLDCUP_ODDS } from "../../config";


import Reload from "../../componets/reload";

export default function ModalOdds({ code , date, setModalOdds, url})
{

    const [reload, setReload] = useState(false);

    const [data, setData] = useState({

        teamHome:null,
        teamAway:null,
        oddsHome:null,
        oddsAway:null,
        oddsDraw:null,
        dobleHipothesis:[

            {home:'',visit:'',odss:''},
            {home:'',visit:'',odss:''},
            {home:'',visit:'',odss:''}
        ],
        correctResult:[],
        correctResultGroup:[],
        resultBreakAndEnd:[],
        underAndOver:[],
        totalGoalsExact:[],
        firstMarkedGoal:[],
        resultForBothTeamsToScore:[],
        handicapResults:[],
        handicapAsian:[],
        marginOfVictory:[],
        teamMarked:[],
        teamGoals:[],
        firstTeamMarked:[],
        resultInterval:[
            {option:'',odds:''},
            {option:'',odds:''},
            {option:'',odds:''},
        ],
        correctResultInterval:[]



    })

    function filterTeam(teams, team) {
        // Crie um array para armazenar os times da Argentina
        const arr = [];
        // Percorra o array de objetos
        teams.forEach((item) => {
          // Se o time for da Argentina, adicione-o ao array
          if (item.teams === team) {
            arr.push(item);
          }
        });
        // Retorne o array de times da Argentina
        return arr;
    }

    function handleGame(URL)
    {
        setReload(true)

        axios.get(`${URL}`,{
        params: {
            code : code,
            date: date
        }

    }).then(response=>{

            //console.log(JSON.parse(response.data.correctResultInterval));

            //console.log(JSON.parse(response.data.teamMarked));

            //const arrHomeGolsMarked = filterTeam(JSON.parse(response.data.teamMarked), response.data.team_home);

            //console.log(arrHomeGolsMarked);

            //console.log(response.data)

            setData({

                ...data,
                
                teamHome: response.data.team_home,
                teamAway: response.data.team_visit,
                oddsHome: response.data.odds_home,
                oddsAway: response.data.odds_visit,
                oddsDraw: response.data.odds_draw,
                dobleHipothesis:    JSON.parse(response.data.dobleHipothesis),
                correctResult:      JSON.parse(response.data.correctResult),
                correctResultGroup: JSON.parse(response.data.correctResultGroup),
                resultBreakAndEnd:  JSON.parse(response.data.resultBreakAndEnd),
                underAndOver:       JSON.parse(response.data.underAndOver),
                totalGoalsExact:    JSON.parse(response.data.totalGoalsExact),
                firstMarkedGoal:    JSON.parse(response.data.firstMarkedGoal),
                resultForBothTeamsToScore:  JSON.parse(response.data.resultForBothTeamsToScore),
                handicapResults:            JSON.parse(response.data.handicapResults),
                handicapAsian:              JSON.parse(response.data.handicapAsian),
                marginOfVictory:            JSON.parse(response.data.marginOfVictory),
                teamMarked:                 JSON.parse(response.data.teamMarked),
                teamGoals:                  JSON.parse(response.data.teamGoals),
                firstTeamMarked:            JSON.parse(response.data.firstTeamMarked),
                resultInterval:             JSON.parse(response.data.resultInterval),
                correctResultInterval:      JSON.parse(response.data.correctResultInterval)
            })

            setReload(false)

        }).catch(error=>{
            console.log(error);
            setReload(false)
        })
    }

    useEffect(()=>{

        switch (url) {

            case 1:
                handleGame(API_URL_SCRALPER_WORLDCUP_ODDS );
                break;
            case 2:
                handleGame(API_URL_SCRALPER_EUROCUP_ODDS );
                break;
            case 3:
                handleGame(API_URL_SCRALPER_SOUTHAMERICANSUPERLEAGUE_ODDS );
                break;  
            case 4:
                handleGame(API_URL_SCRALPER_PREMIERLEAGUERESULTS_ODDS );
                break;  
            default:
                break;
        }

    },[])



    return(
            <GlobalModalContainer display="flex" jc="center" items="center" w="100%" h="100vh" pos="absolute" ind="999" bg="rgba(250,250,250,0.7)"> 
               {reload === true && <Reload/>}
                <Container w="70%" bg="rgba(0,0,0,0.8)" rd="10px" maxh="100vh" ovfw="auto" mt="20px">

                    <Container w="100%" mt="20px" display="flex" jc="center">
                        <ButtonStyled onClick={()=>{setModalOdds(false)}}>
                            <Text col="#fff" fw="bold" fs="20px">Fechar</Text>
                        </ButtonStyled>
                    </Container>

                    <BoxHeaderGradient w="100%">
                        <Container w="100%" h="70px" mt="10px" display="flex" jc="center" items="center"> 
                            <Text fs="30px" fw="bold" col="#fff" >{data.teamHome} vs {data.teamAway}</Text>
                        </Container>
                    </BoxHeaderGradient>

                    <Container bbbw="2px" bbbs="solid" bbbc="#fff" mt="10px" w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                        <Container w="auto" >
                            <Text col="#fff" fs="25px">Vencedor do jogo</Text>
                        </Container>
                    </Container>

                    <Container w="100%"  display="flex" jc="space-between" items="center" bg='#097469' >
                        <Container w="33%" display="flex">
                            <Container w="70%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                <Text col="#fff" fs="20px">{data.teamHome}</Text>
                            </Container>
                            <Container w="30%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                <Text col="#fff" fs="20px">{data.oddsHome}</Text>
                            </Container>
                        </Container>
                        <Container w="33%" display="flex">
                            <Container w="70%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                <Text col="#fff" fs="20px">Empate</Text>
                            </Container>
                            <Container w="30%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                <Text col="#fff" fs="20px">{data.oddsDraw}</Text>
                            </Container>
                        </Container>
                        <Container w="33%" display="flex">
                            <Container w="70%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                <Text col="#fff" fs="20px">{data.teamAway}</Text>
                            </Container>
                            <Container w="30%" h="50px" display="flex" jc="center" items="center">
                                <Text col="#fff" fs="20px">{data.oddsAway}</Text>
                            </Container>
                        </Container>
                    </Container>

                    <Container bbbw="2px" bbbs="solid" bbbc="#fff" mt="10px" w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                        <Container w="auto" >
                            <Text col="#fff" fs="25px">Dupla hipótese</Text>
                        </Container>
                    </Container>

                    <Container w="100%"  display="flex" jc="space-between" items="center" bg='#097469' >
                        <Container w="33%" display="flex">
                            <Container w="70%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                <Text col="#fff" fs="20px">{data.dobleHipothesis[0].home}</Text>
                            </Container>
                            <Container w="30%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                <Text col="#fff" fs="20px">{data.dobleHipothesis[0].odds}</Text>
                            </Container>
                        </Container>
                        <Container w="33%" display="flex">
                            <Container w="70%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                <Text col="#fff" fs="20px">{data.dobleHipothesis[1].draw}</Text>
                            </Container>
                            <Container w="30%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                <Text col="#fff" fs="20px">{data.dobleHipothesis[1].odds}</Text>
                            </Container>
                        </Container>
                        <Container w="33%" display="flex">
                            <Container w="70%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                <Text col="#fff" fs="20px">{data.dobleHipothesis[2].visit}</Text>
                            </Container>
                            <Container w="30%" h="50px" display="flex" jc="center" items="center">
                                <Text col="#fff" fs="20px">{data.dobleHipothesis[2].odds}</Text>
                            </Container>
                        </Container>
                    </Container>   

                    <Container bbbw="2px" bbbs="solid" bbbc="#fff" mt="10px" w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                        <Container w="auto" >
                            <Text col="#fff" fs="25px">Resultado correto</Text>
                        </Container>
                    </Container>

                    <Container w="100%"  display="flex" jc="space-between" items="center" bg='#097469' >
                        <Container w="33.3333%" display="flex">
                            <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                <Text col="#fff" fs="20px">{data.teamHome}</Text>
                            </Container>
                        </Container>
                        <Container w="33.3333%" display="flex">
                            <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                <Text col="#fff" fs="20px">{'Empate'}</Text>
                            </Container>
                        </Container>
                        <Container w="33.3333%" display="flex">
                            <Container w="100%" h="50px" display="flex" jc="center" items="center">
                                <Text col="#fff" fs="20px">{data.teamAway}</Text>
                            </Container>
                        </Container>
                    </Container>

                    <Container display="flex">
                        <Container w="33.3333%"  bg='#097469' mt="1px">
                            {data.correctResult.map((item, index)=>{
                                if(index < 8)
                                {
                                    return(
                                        <Container key={index} w="100%" display="flex" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)">

                                            <Container w="70%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px">{item.goals}</Text>
                                            </Container>
                                            <Container w="30%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px">{item.odds}</Text>
                                            </Container>
                
                                        </Container>
                                        )
                                }
                            })}
                        </Container>
                        <Container w="33.333%"  bg='#097469' mt="1px">
                            {data.correctResult.map((item, index)=>{
                                if(index > 7 && index < 11)
                                {
                                    return(
                                        <Container key={index} w="100%" display="flex" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)">

                                            <Container w="70%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px">{item.goals}</Text>
                                            </Container>
                                            <Container w="30%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px">{item.odds}</Text>
                                            </Container>
                
                                        </Container>
                                        )
                                }
                            })}

                            <Container w="100%" display="flex" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)">
                                <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" ></Container>
                            </Container>
                            <Container w="100%" display="flex" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)">
                                <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" ></Container>
                            </Container>
                            <Container w="100%" display="flex" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)">
                                <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" ></Container>
                            </Container>
                            <Container w="100%" display="flex" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)">
                                <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" ></Container>
                            </Container>
                            <Container w="100%" display="flex" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)">
                                <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" ></Container>
                            </Container>

                        </Container>
                        <Container w="33.333%"  bg='#097469' mt="1px">
                            {data.correctResult.map((item, index)=>{
                                if(index > 11)
                                {
                                    return(
                                        <Container key={index} w="100%" display="flex" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)">

                                            <Container w="70%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px">{item.goals}</Text>
                                            </Container>
                                            <Container w="30%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px">{item.odds}</Text>
                                            </Container>
                
                                        </Container>
                                        )
                                }
                            })}
                        </Container>   

                    </Container>

                    <Container bbbw="2px" bbbs="solid" bbbc="#fff" mt="10px" w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                        <Container w="auto" >
                            <Text col="#fff" fs="25px">Resultado correto grupo</Text>
                        </Container>
                    </Container>

                    <Container display="flex">
                        <Container w="33.333%"  bg='#097469' mt="1px">
                        {data.correctResultGroup.map((item, index)=>{
                            if(index < 3)
                             {
                                return(
                                    <Container key={index} w="100%" display="flex" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)">
    
                                        <Container w="70%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                            <Text col="#fff" fs="20px">{index !== 2 ? `${item.option} ${item.goals}`: item.goals}</Text>
                                        </Container>
                                        <Container w="30%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                             <Text col="#fff" fs="20px">{item.odds}</Text>
                                        </Container>
                    
                                     </Container>
                                )
                             }
                        })}
                        </Container>

                        <Container w="33.333%"  bg='#097469' mt="1px">
                            {data.correctResultGroup.map((item, index)=>{
                                if(index > 3 && index <= 5)
                                {
                                    return(
                                        <Container key={index} w="100%" display="flex" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)">
        
                                            <Container w="70%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px">{`${item.option} ${item.goals}`}</Text>
                                            </Container>
                                            <Container w="30%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px">{item.odds}</Text>
                                            </Container>
                        
                                        </Container>
                                    )
                                }
                            })}
                                <Container w="100%" display="flex" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)">
                                    <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" ></Container>
                                </Container>
                        </Container>

                        <Container w="33.333%"  bg='#097469' mt="1px">
                        {data.correctResultGroup.map((item, index)=>{
                            if(index > 5)
                             {
                                return(
                                    <Container key={index} w="100%" display="flex" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)">
    
                                        <Container w="70%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                            <Text col="#fff" fs="20px">{index !== 8 ? `${item.option} ${item.goals}`: item.goals}</Text>
                                        </Container>
                                        <Container w="30%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                             <Text col="#fff" fs="20px">{item.odds}</Text>
                                        </Container>
                    
                                     </Container>
                                )
                             }
                        })}
                        </Container>                        

                    </Container>
     
                    <Container bbbw="2px" bbbs="solid" bbbc="#fff" mt="10px" w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                        <Container w="auto" >
                            <Text col="#fff" fs="25px">Intervalo e final do jogo</Text>
                        </Container>
                    </Container>

                    <Container display="flex">
                        <Container w="33.333%"  bg='#097469' mt="1px">
                        {data.resultBreakAndEnd.map((item, index)=>{
                            if(index < 3)
                             {
                                return(
                                    <Container key={index} w="100%" display="flex" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)">
    
                                        <Container w="70%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                            <Text col="#fff" fs="20px">{item.option}</Text>
                                        </Container>
                                        <Container w="30%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                             <Text col="#fff" fs="20px">{item.odds}</Text>
                                        </Container>
                    
                                     </Container>
                                )
                             }
                        })}
                        </Container>

                        <Container w="33.333%"  bg='#097469' mt="1px">
                            {data.resultBreakAndEnd.map((item, index)=>{
                                if(index > 2 && index < 6)
                                {
                                    return(
                                        <Container key={index} w="100%" display="flex" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)">
        
                                         <Container w="70%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                            <Text col="#fff" fs="20px">{item.option}</Text>
                                        </Container>
                                        <Container w="30%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                             <Text col="#fff" fs="20px">{item.odds}</Text>
                                        </Container>
                        
                                        </Container>
                                    )
                                }
                            })}
                        </Container>

                        <Container w="33.333%"  bg='#097469' mt="1px">
                        {data.resultBreakAndEnd.map((item, index)=>{
                            if(index >= 6)
                             {
                                return(
                                    <Container key={index} w="100%" display="flex" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)">
    
                                        <Container w="70%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                            <Text col="#fff" fs="20px">{item.option}</Text>
                                        </Container>
                                        <Container w="30%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                             <Text col="#fff" fs="20px">{item.odds}</Text>
                                        </Container>
                    
                                     </Container>
                                )
                             }
                        })}
                        </Container>                        

                    </Container>  

                    <Container bbbw="2px" bbbs="solid" bbbc="#fff" mt="10px" w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                        <Container w="auto" >
                            <Text col="#fff" fs="25px">Total de gols</Text>
                        </Container>
                    </Container>

                    <Container display="flex">

                        <Container  w="20%" bg='#097469' mt="1px" >
                            <Container w="100%" display="flex" jc="center" items="center">
                                <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                    <Text col="#fff" fs="20px"></Text>
                                </Container>
                            </Container>

                            {data.underAndOver.map((item,index)=>{
                                if( index < 4)
                                {
                                    return(
                                        <Container key={index} w="100%" display="flex" jc="center" items="center">
                                            <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px">{item.goal}</Text>
                                            </Container>
                                        </Container>
                                    )
                                }
                            })}

 

                        </Container>

                        <Container  w="40%" bg='#097469' mt="1px">
                            <Container w="100%" display="flex" jc="center" items="center" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)">
                                <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                    <Text col="#fff" fs="20px">Mais de</Text>
                                </Container>
                            </Container>
                            {data.underAndOver.map((item,index)=>{
                                if( index < 4)
                                {
                                    return(
                                        <Container key={index} w="100%" display="flex" jc="center" items="center">
                                            <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px">{item.odds}</Text>
                                            </Container>
                                        </Container>
                                    )
                                }
                            })}                            
                        </Container>

                        <Container  w="40%" bg='#097469' mt="1px" >
                            <Container w="100%" display="flex" jc="center" items="center" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)">
                                <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                    <Text col="#fff" fs="20px">Menos de</Text>
                                </Container>
                            </Container>
                            {data.underAndOver.map((item,index)=>{
                                if( index >= 4)
                                {
                                    return(
                                        <Container key={index} w="100%" display="flex" jc="center" items="center">
                                            <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px">{item.odds}</Text>
                                            </Container>
                                        </Container>
                                    )
                                }
                            })}                             
                        </Container>

                    </Container>

                    <Container bbbw="2px" bbbs="solid" bbbc="#fff" mt="10px" w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                        <Container w="auto" >
                            <Text col="#fff" fs="25px">Número de gols exatos</Text>
                        </Container>
                    </Container>  

                    <Container display="flex">
                        <Container w="33.333%" bg='#097469' mt="1px" >
                            {data.totalGoalsExact.map((item, index)=>{
                                if(index < 2)
                                {
                                    return(
                                        <Container key={index} w="100%" display="flex" jc="center" items="center">
                                            <Container w="50%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px">{item.option}</Text>
                                            </Container>
                                            <Container w="50%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px">{item.odds}</Text>
                                            </Container>                                            
                                        </Container>
                                    ) 
                                }
                            })}
                        </Container>
                        <Container w="33.333%" bg='#097469' mt="1px" >
                            {data.totalGoalsExact.map((item, index)=>{
                                    if(index >= 2 && index < 4)
                                    {
                                        return(
                                            <Container key={index} w="100%" display="flex" jc="center" items="center">
                                                <Container w="50%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                    <Text col="#fff" fs="20px">{item.option}</Text>
                                                </Container>
                                                <Container w="50%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                    <Text col="#fff" fs="20px">{item.odds}</Text>
                                                </Container>                                            
                                            </Container>
                                        ) 
                                    }
                            })}
                        </Container>
                        <Container w="33.333%" bg='#097469' mt="1px" >
                            {data.totalGoalsExact.map((item, index)=>{
                                    if(index >= 4)
                                    {
                                        return(
                                            <Container key={index} w="100%" display="flex" jc="center" items="center">
                                                <Container w="50%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                    <Text col="#fff" fs="20px">{item.option}</Text>
                                                </Container>
                                                <Container w="50%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                    <Text col="#fff" fs="20px">{item.odds}</Text>
                                                </Container>                                            
                                            </Container>
                                        ) 
                                    }
                            })}
                        </Container>                        
                    </Container>

                    <Container bbbw="2px" bbbs="solid" bbbc="#fff" mt="10px" w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                        <Container w="auto" >
                            <Text col="#fff" fs="25px">Primeiro a marcar o gol</Text>
                        </Container>
                    </Container> 

                    <Container display="flex">
                        <Container w="33.333%" bg='#097469' mt="1px" >
                            {data.firstMarkedGoal.map((item, index)=>{

                                if(index < 4)
                                {
                                    return(

                                        <Container key={index} w="100%" display="flex" jc="center" items="center">
                                            <Container w="70%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="15px">{item.option}</Text>
                                            </Container>
                                            <Container w="30%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px">{item.odds}</Text>
                                            </Container>                                            
                                        </Container>                                    
                                    )
                                }

                            })}
                        </Container>

                        <Container w="33.333%" bg='#097469' mt="1px" >
                            {data.firstMarkedGoal.map((item, index)=>{

                                if(index >= 4 && index < 8)
                                {
                                    return(

                                        <Container key={index} w="100%" display="flex" jc="center" items="center">
                                            <Container w="70%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="15px">{item.option}</Text>
                                            </Container>
                                            <Container w="30%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px">{item.odds}</Text>
                                            </Container>                                            
                                        </Container>                                    
                                    )
                                }

                            })}
                        </Container>

                        <Container w="33.333%" bg='#097469' mt="1px" >
                            {data.firstMarkedGoal.map((item, index)=>{

                                if(index >= 8)
                                {
                                    return(

                                        <Container key={index} w="100%" display="flex" jc="center" items="center">
                                            <Container w="70%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="15px">{item.option}</Text>
                                            </Container>
                                            <Container w="30%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px">{item.odds}</Text>
                                            </Container>                                            
                                        </Container>                                    
                                    )
                                }

                            })}
                        </Container>                                                
                    </Container>

                    <Container bbbw="2px" bbbs="solid" bbbc="#fff" mt="10px" w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                        <Container w="auto" >
                            <Text col="#fff" fs="25px">Resultado/Para ambos os Times Marcarem</Text>
                        </Container>
                    </Container>  

                    <Container display="flex">
                        <Container w="33.333%" bg='#097469' mt="1px" >
                            <Container w="100%" display="flex" jc="center" items="center" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)">
                                <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                    <Text col="#fff" fs="20px"></Text>
                                </Container>
                            </Container>                            
                            {data.resultForBothTeamsToScore.map((item, index)=>{

                                if(index < 3)
                                {
                                    return(

                                        <Container key={index} w="100%" display="flex" jc="center" items="center">
                                            <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="15px">{item.team}</Text>
                                            </Container>                                           
                                        </Container>                                    
                                    )
                                }

                            })}
                        </Container>

                        <Container w="33.333%" bg='#097469' mt="1px" >
                            <Container w="100%" display="flex" jc="center" items="center" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)">
                                <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                    <Text col="#fff" fs="20px">Sim</Text>
                                </Container>
                            </Container>
                            {data.resultForBothTeamsToScore.map((item, index)=>{

                                if(index < 3)
                                {
                                    return(

                                        <Container key={index} w="100%" display="flex" jc="center" items="center">
                                            <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px">{item.odds}</Text>
                                            </Container>                                            
                                        </Container>                                    
                                    )
                                }

                            })}
                        </Container>

                        <Container w="33.333%" bg='#097469' mt="1px" >
                            <Container w="100%" display="flex" jc="center" items="center" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)">
                                <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                    <Text col="#fff" fs="20px">Não</Text>
                                </Container>
                            </Container>                            
                            {data.resultForBothTeamsToScore.map((item, index)=>{

                                if(index >= 3)
                                {
                                    return(

                                        <Container key={index} w="100%" display="flex" jc="center" items="center">
                                            <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px">{item.odds}</Text>
                                            </Container>                                            
                                        </Container>                                    
                                    )
                                }

                            })}
                        </Container>                                                
                    </Container> 

                    <Container bbbw="2px" bbbs="solid" bbbc="#fff" mt="10px" w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                        <Container w="auto" >
                            <Text col="#fff" fs="25px">Handicap - Resultado</Text>
                        </Container>
                    </Container>  

                    <Container display="flex" >

                        <Container w="33.333%" bg='#097469' mt="1px">

                            {data.handicapResults.map((item, index)=>{
                                if(index < 11)
                                {
                                    return(
                                        
                                        <Container key={index} w="100%" display="flex" jc="center" items="center">
                                            <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px">{item.option}</Text>
                                            </Container>                                            
                                        </Container>                                     
                                    )
                                }
                            })}

                        </Container>

                        <Container w="33.333%" bg='#097469' mt="1px">

                            {data.handicapResults.map((item, index)=>{
                                if(index < 11)
                                {
                                    return(
                                        <Container key={index} w="100%" display="flex" jc="center" items="center">
                                            <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px">{item.team}</Text>
                                            </Container>                                            
                                        </Container>                                     
                                    )
                                }
                            })}

                        </Container>  

                        <Container w="33.333%" bg='#097469' mt="1px">

                            {data.handicapResults.map((item, index)=>{
                                if(index < 11)
                                {
                                    return(
                                        <Container key={index} w="100%" display="flex" jc="center" items="center">
                                            <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px">{item.odds}</Text>
                                            </Container>                                            
                                        </Container>                                     
                                    )
                                }
                            })}

                        </Container>                          

                    </Container> 

                    <Container bbbw="2px" bbbs="solid" bbbc="#fff" mt="10px" w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                        <Container w="auto" >
                            <Text col="#fff" fs="25px">Handicap - Asiático</Text>
                        </Container>
                    </Container>                                                                                                

                    <Container display="flex" >

                        <Container w="33.333%" bg='#097469' mt="1px">

                            {data.handicapAsian.map((item, index)=>{
                                if(index < 11)
                                {
                                    return(
                                        
                                        <Container key={index} w="100%" display="flex" jc="center" items="center">
                                            <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px">{item.option}</Text>
                                            </Container>                                            
                                        </Container>                                     
                                    )
                                }
                            })}

                        </Container>

                        <Container w="33.333%" bg='#097469' mt="1px">

                            {data.handicapAsian.map((item, index)=>{
                                if(index < 11)
                                {
                                    return(
                                        <Container key={index} w="100%" display="flex" jc="center" items="center">
                                            <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px">{item.team}</Text>
                                            </Container>                                            
                                        </Container>                                     
                                    )
                                }
                            })}

                        </Container>  

                        <Container w="33.333%" bg='#097469' mt="1px">

                            {data.handicapAsian.map((item, index)=>{
                                if(index < 11)
                                {
                                    return(
                                        <Container key={index} w="100%" display="flex" jc="center" items="center">
                                            <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px">{item.odds}</Text>
                                            </Container>                                            
                                        </Container>                                     
                                    )
                                }
                            })}

                        </Container>                          

                    </Container> 

                    <Container bbbw="2px" bbbs="solid" bbbc="#fff" mt="10px" w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                        <Container w="auto" >
                            <Text col="#fff" fs="25px">Margem de vitória</Text>
                        </Container>
                    </Container> 

                    <Container display="flex" >

                        <Container w="33.333%" bg='#097469' mt="1px">

                            {data.marginOfVictory.map((item, index)=>{
                                if(index < 11)
                                {
                                    return(
                                        
                                        <Container key={index} w="100%" display="flex" jc="center" items="center">
                                            <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px">{item.option}</Text>
                                            </Container>                                            
                                        </Container>                                     
                                    )
                                }
                            })}

                        </Container>

                        <Container w="33.333%" bg='#097469' mt="1px">

                            {data.marginOfVictory.map((item, index)=>{
                                if(index < 11)
                                {
                                    return(
                                        <Container key={index} w="100%" display="flex" jc="center" items="center">
                                            <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px">{item.team}</Text>
                                            </Container>                                            
                                        </Container>                                     
                                    )
                                }
                            })}

                        </Container>  

                        <Container w="33.333%" bg='#097469' mt="1px">

                            {data.marginOfVictory.map((item, index)=>{
                                if(index < 11)
                                {
                                    return(
                                        <Container key={index} w="100%" display="flex" jc="center" items="center">
                                            <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px">{item.odds}</Text>
                                            </Container>                                            
                                        </Container>                                     
                                    )
                                }
                            })}

                        </Container>                          

                    </Container> 

                    <Container bbbw="2px" bbbs="solid" bbbc="#fff" mt="10px" w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                        <Container w="auto" >
                            <Text col="#fff" fs="25px">Para o time marcar Sim/Não</Text>
                        </Container>
                    </Container>


                    <Container display="flex">
                        <Container w="33.333%" bg='#097469' mt="1px" >
                            <Container w="100%" display="flex" jc="center" items="center" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)">
                                <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                    <Text col="#fff" fs="20px"></Text>
                                </Container>
                            </Container>                            
                            {data.teamMarked.map((item, index)=>{

                                if(index < 3)
                                {
                                    return(

                                        <Container key={index} w="100%" display="flex" jc="center" items="center">
                                            <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="15px">{item.teams}</Text>
                                            </Container>                                           
                                        </Container>                                    
                                    )
                                }

                            })}
                        </Container>

                        <Container w="33.333%" bg='#097469' mt="1px" >
                            <Container w="100%" display="flex" jc="center" items="center" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)">
                                <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                    <Text col="#fff" fs="20px">Sim</Text>
                                </Container>
                            </Container>
                            {data.teamMarked.map((item, index)=>{

                                if(index < 3)
                                {
                                    return(

                                        <Container key={index} w="100%" display="flex" jc="center" items="center">
                                            <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px">{item.odds}</Text>
                                            </Container>                                            
                                        </Container>                                    
                                    )
                                }

                            })}
                        </Container>

                        <Container w="33.333%" bg='#097469' mt="1px" >
                            <Container w="100%" display="flex" jc="center" items="center" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)">
                                <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                    <Text col="#fff" fs="20px">Não</Text>
                                </Container>
                            </Container>                            
                            {data.teamMarked.map((item, index)=>{

                                if(index >= 3)
                                {
                                    return(

                                        <Container key={index} w="100%" display="flex" jc="center" items="center">
                                            <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px">{item.odds}</Text>
                                            </Container>                                            
                                        </Container>                                    
                                    )
                                }

                            })}
                        </Container>                                                
                    </Container> 

                    <Container bbbw="2px" bbbs="solid" bbbc="#fff" mt="10px" w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                        <Container w="auto" >
                            <Text col="#fff" fs="25px">Times - Gols</Text>
                        </Container>
                    </Container>

                    <Container display="flex">
                        <Container w="33.333%" bg='#097469' mt="1px" >
                            <Container w="100%" display="flex" jc="center" items="center" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)">
                                <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                    <Text col="#fff" fs="20px"></Text>
                                </Container>
                            </Container>                            
                            {data.teamGoals.map((item, index)=>{

                                if(index < 6)
                                {
                                    return(

                                        <Container key={index} w="100%" display="flex" jc="center" items="center">
                                            <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="15px">{item.option}</Text>
                                            </Container>                                           
                                        </Container>                                    
                                    )
                                }

                            })}
                        </Container>

                        <Container w="33.333%" bg='#097469' mt="1px" >
                            <Container w="100%" display="flex" jc="center" items="center" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)">
                                <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                    <Text col="#fff" fs="20px">{data.teamHome}</Text>
                                </Container>
                            </Container>
                            {data.teamGoals.map((item, index)=>{

                                if(index < 6)
                                {
                                    return(

                                        <Container key={index} w="100%" display="flex" jc="center" items="center">
                                            <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px">{item.odds}</Text>
                                            </Container>                                            
                                        </Container>                                    
                                    )
                                }

                            })}
                        </Container>

                        <Container w="33.333%" bg='#097469' mt="1px" >
                            <Container w="100%" display="flex" jc="center" items="center" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)">
                                <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                    <Text col="#fff" fs="20px">{data.teamAway}</Text>
                                </Container>
                            </Container>                            
                            {data.teamGoals.map((item, index)=>{

                                if(index >= 6)
                                {
                                    return(

                                        <Container key={index} w="100%" display="flex" jc="center" items="center">
                                            <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px">{item.odds}</Text>
                                            </Container>                                            
                                        </Container>                                    
                                    )
                                }

                            })}
                        </Container>                                                
                    </Container> 

                    <Container bbbw="2px" bbbs="solid" bbbc="#fff" mt="10px" w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                        <Container w="auto" >
                            <Text col="#fff" fs="25px">Primeiro time a marcar</Text>
                        </Container>
                    </Container>

                    <Container display="flex">
                        <Container w="33.333%" bg='#097469' mt="1px" >
                            <Container w="100%" display="flex" jc="center" items="center" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)">
                                <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                    <Text col="#fff" fs="20px"></Text>
                                </Container>
                            </Container>                            
                            {data.firstTeamMarked.map((item, index)=>{

                                if(index < 3)
                                {
                                    return(

                                        <Container key={index} w="100%" display="flex" jc="center" items="center">
                                            <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="15px">{item.team}</Text>
                                            </Container>                                           
                                        </Container>                                    
                                    )
                                }

                            })}
                        </Container>

                        <Container w="33.333%" bg='#097469' mt="1px" >
                            <Container w="100%" display="flex" jc="center" items="center" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)">
                                <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                    <Text col="#fff" fs="20px">Primeiro</Text>
                                </Container>
                            </Container>
                            {data.firstTeamMarked.map((item, index)=>{

                                if(index < 3)
                                {
                                    return(

                                        <Container key={index} w="100%" display="flex" jc="center" items="center">
                                            <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px">{item.odds}</Text>
                                            </Container>                                            
                                        </Container>                                    
                                    )
                                }

                            })}
                        </Container>

                        <Container w="33.333%" bg='#097469' mt="1px" >
                            <Container w="100%" display="flex" jc="center" items="center" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)">
                                <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                    <Text col="#fff" fs="20px">Último</Text>
                                </Container>
                            </Container>                            
                            {data.firstTeamMarked.map((item, index)=>{

                                if(index >= 3)
                                {
                                    return(

                                        <Container key={index} w="100%" display="flex" jc="center" items="center">
                                            <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="20px">{item.odds}</Text>
                                            </Container>                                            
                                        </Container>                                    
                                    )
                                }

                            })}
                        </Container>                                                
                    </Container> 

                    <Container bbbw="2px" bbbs="solid" bbbc="#fff" mt="10px" w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                        <Container w="auto" >
                            <Text col="#fff" fs="25px">Intervalo resultado</Text>
                        </Container>
                    </Container>

                    <Container w="100%"  display="flex" jc="space-between" items="center" bg='#097469' >
                        <Container w="33%" display="flex">
                            <Container w="70%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                <Text col="#fff" fs="20px">{data.resultInterval[0].option}</Text>
                            </Container>
                            <Container w="30%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                <Text col="#fff" fs="20px">{data.resultInterval[0].odds}</Text>
                            </Container>
                        </Container>
                        <Container w="33%" display="flex">
                            <Container w="70%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                <Text col="#fff" fs="20px">{data.resultInterval[1].option}</Text>
                            </Container>
                            <Container w="30%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                <Text col="#fff" fs="20px">{data.resultInterval[1].odds}</Text>
                            </Container>
                        </Container>
                        <Container w="33%" display="flex">
                            <Container w="70%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                <Text col="#fff" fs="20px">{data.resultInterval[2].option}</Text>
                            </Container>
                            <Container w="30%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                <Text col="#fff" fs="20px">{data.resultInterval[2].odds}</Text>
                            </Container>
                        </Container>
                    </Container>

                    <Container bbbw="2px" bbbs="solid" bbbc="#fff" mt="10px" w="100%" h="50px" bg='#097469' display="flex" jc="center" items="center">
                        <Container w="auto" >
                            <Text col="#fff" fs="25px">Intervalo - Resultado Correto</Text>
                        </Container>
                    </Container>


                    <Container display="flex">
                        <Container w="33.333%" bg='#097469' mt="1px" >
                            <Container w="100%" display="flex" jc="center" items="center" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)">
                                <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                    <Text col="#fff" fs="20px">{data.teamHome}</Text>
                                </Container>
                            </Container>                            
                            {data.correctResultInterval.map((item, index)=>{

                                if(index < 2)
                                {
                                    return(

                                        <Container key={index} w="100%" display="flex" jc="center" items="center">
                                            <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="15px">{item.goals}</Text>
                                            </Container> 
                                            <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="15px">{item.odds}</Text>
                                            </Container>                                           
                                        </Container>                                    
                                    )
                                }

                            })}
                        </Container>

                        <Container w="33.333%" bg='#097469' mt="1px" >
                            <Container w="100%" display="flex" jc="center" items="center" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)">
                                <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                    <Text col="#fff" fs="20px">Empate</Text>
                                </Container>
                            </Container>
                            {data.correctResultInterval.map((item, index)=>{

                                if(index > 2 && index < 5)
                                {
                                    return(

                                        <Container key={index} w="100%" display="flex" jc="center" items="center">
                                            <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="15px">{index === 4 ? item.option : item.goals}</Text>
                                            </Container> 
                                            <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="15px">{item.odds}</Text>
                                            </Container>                                            
                                        </Container>                                    
                                    )
                                }

                            })}
                        </Container>

                        <Container w="33.333%" bg='#097469' mt="1px" >
                            <Container w="100%" display="flex" jc="center" items="center" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)">
                                <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                    <Text col="#fff" fs="20px">{data.teamAway}</Text>
                                </Container>
                            </Container>                            
                            {data.correctResultInterval.map((item, index)=>{

                                if(index >= 5)
                                {
                                    return(

                                        <Container key={index} w="100%" display="flex" jc="center" items="center">
                                            <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="15px">{item.goals}</Text>
                                            </Container> 
                                            <Container w="100%" h="50px" bbrw="1px" bbrs="solid" bbrc="rgba(0,0,0,0.8)" bbbw="1px" bbbs="solid" bbbc="rgba(0,0,0,0.8)" display="flex" jc="center" items="center">
                                                <Text col="#fff" fs="15px">{item.odds}</Text>
                                            </Container>                                             
                                        </Container>                                    
                                    )
                                }

                            })}
                        </Container>                                                
                    </Container> 

                    <Container w="100%" mb="20px" display="flex" jc="center">
                        <ButtonStyled onClick={()=>{setModalOdds(false)}}>
                            <Text col="#fff" fw="bold" fs="20px">Fechar</Text>
                        </ButtonStyled>
                    </Container>

                </Container>

            </GlobalModalContainer>
    )
}