class Maximums {

    //ESTRATÉGIAS START>>
    static maxAndLastStreakNoOddEvenGoals(arrayOfObjects) {
        const types = ["impar", "par"];
        const currentNoStreaks = {
            "impar": 0,
            "par": 0
        };
        const maxNoStreaks = {
            "impar": 0,
            "par": 0
        };
        const lastNoStreaks = {
            "impar": 0,
            "par": 0
        };

        for (let obj of arrayOfObjects) {
            if (obj.scoreboard) {
                // Remove o sinal "+" se estiver presente na string
                const cleanedScoreboard = obj.scoreboard.replace(/\+/g, '');
                const [score1, score2] = cleanedScoreboard.split(' - ').map(Number);
                const totalGoals = score1 + score2;
                const currentType = totalGoals % 2 === 0 ? "par" : "impar";

                types.forEach(type => {
                    if (type !== currentType) {
                        currentNoStreaks[type]++;
                        if (currentNoStreaks[type] > maxNoStreaks[type]) {
                            maxNoStreaks[type] = currentNoStreaks[type];
                        }
                    } else {
                        currentNoStreaks[type] = 0;
                    }
                });
            }
        }

        // Reset current streaks
        types.forEach(type => (currentNoStreaks[type] = 0));

        // For the last streak, we reverse the array and count until we break the streak
        for (let obj of arrayOfObjects.reverse()) {
            if (obj.scoreboard) {
                // Remove o sinal "+" se estiver presente na string
                const cleanedScoreboard = obj.scoreboard.replace(/\+/g, '');
                const [score1, score2] = cleanedScoreboard.split(' - ').map(Number);
                const totalGoals = score1 + score2;
                const currentType = totalGoals % 2 === 0 ? "par" : "impar";

                types.forEach(type => {
                    if (type !== currentType) {
                        currentNoStreaks[type]++;
                        lastNoStreaks[type] = currentNoStreaks[type];
                    } else {
                        currentNoStreaks[type] = 0;
                        lastNoStreaks[type] = 0; // Reset streak for exact type
                    }
                });
            }
        }

        return {
            maxNoStreaks: maxNoStreaks,
            lastNoStreaks: lastNoStreaks
        };
    }

   static countUnderOver(arrayOfObjects) {

        const goalLimits = ["0.5", "1.5", "2.5", "3.5"];
        let streaks = {};

        // Initialize streaks object
        for (let limit of goalLimits) {
            streaks[limit] = {
                noUnder: 0,
                maxNoUnder: 0,
                noOver: 0,
                maxNoOver: 0
            };
        }

        for (let obj of arrayOfObjects) {
            // Remove o sinal "+" se estiver presente na string
            const cleanedScoreboard = obj.scoreboard.replace(/\+/g, '');
            const [score1, score2] = cleanedScoreboard.split(' - ').map(Number);
            const totalGoals = score1 + score2;

            for (let limit of goalLimits) {
                const floatLimit = parseFloat(limit);

                // Logic for under
                if (totalGoals <= floatLimit) {
                    streaks[limit].noUnder = 0;
                    streaks[limit].noOver++;
                    streaks[limit].maxNoOver = Math.max(streaks[limit].maxNoOver, streaks[limit].noOver);
                } else {
                    streaks[limit].noOver = 0;
                    streaks[limit].noUnder++;
                    streaks[limit].maxNoUnder = Math.max(streaks[limit].maxNoUnder, streaks[limit].noUnder);
                }
            }
        }

        // Structuring the result for better readability
        let result = {
            currentStreaks: {},
            maxStreaks: {}
        };

        for (let limit of goalLimits) {
            result.currentStreaks[`noUnder${limit.replace('.', '')}Streak`] = streaks[limit].noUnder;
            result.currentStreaks[`noOver${limit.replace('.', '')}Streak`] = streaks[limit].noOver;
            result.maxStreaks[`maxNoUnder${limit.replace('.', '')}Streak`] = streaks[limit].maxNoUnder;
            result.maxStreaks[`maxNoOver${limit.replace('.', '')}Streak`] = streaks[limit].maxNoOver;
        }

        return result;
    }
    



    static maxAndLastStreakNoOverUnderGoals(arrayOfObjects) {

       

        const goalLimits = ["0.5", "1.5", "2.5", "3.5"];
        
        // Object to store current streaks
        const currentNoStreaks = {};
        
        // Object to store the maximum no-streaks encountered
        const maxNoStreaks = {};
        
        // Object to store the last streaks encountered
        const lastNoStreaks = {};

        // Initialize objects
        goalLimits.forEach(limit => {

            currentNoStreaks[limit] = { over: 0, under: 0 };
            maxNoStreaks[limit] = { over: 0, under: 0 };
            lastNoStreaks[limit] = { over: 0, under: 0 };
        });

      //  console.log(currentNoStreaks);
      //  console.log(maxNoStreaks);
      //  console.log(lastNoStreaks);

        // Calculate streaks
        for (let obj of arrayOfObjects) {
            if (obj.scoreboard) {

                // Remove o sinal "+" se estiver presente na string
                const cleanedScoreboard = obj.scoreboard.replace(/\+/g, '');
                const [score1, score2] = cleanedScoreboard.split(' - ').map(Number);

                const totalGoals = score1 + score2;

                goalLimits.forEach(limit => {
                    const floatLimit = parseFloat(limit);
                    if (totalGoals > floatLimit) {
                        currentNoStreaks[limit].under = 0;
                        currentNoStreaks[limit].over++;

                        maxNoStreaks[limit].over = Math.max(maxNoStreaks[limit].over, currentNoStreaks[limit].over);
                    } else {
                        currentNoStreaks[limit].over = 0;
                        currentNoStreaks[limit].under++;

                        maxNoStreaks[limit].under = Math.max(maxNoStreaks[limit].under, currentNoStreaks[limit].under);
                    }
                });
            }
        }

        for (let obj of arrayOfObjects) {
            // Remove o sinal "+" se estiver presente na string
            const cleanedScoreboard = obj.scoreboard.replace(/\+/g, '');
            const [score1, score2] = cleanedScoreboard.split(' - ').map(Number);
            const totalGoals = score1 + score2;
        
            let allGoalsChecked = true;
        
            goalLimits.forEach(limit => {
                const floatLimit = parseFloat(limit);
                
                if (totalGoals > floatLimit) {
                    currentNoStreaks[limit].under = 0;
                    currentNoStreaks[limit].over++;
                } else {
                    currentNoStreaks[limit].over = 0;
                    currentNoStreaks[limit].under++;
                }
        
                if (currentNoStreaks[limit].over === 0 && currentNoStreaks[limit].under === 0) {
                    allGoalsChecked = false;
                } else {
                    lastNoStreaks[limit].over = currentNoStreaks[limit].over;
                    lastNoStreaks[limit].under = currentNoStreaks[limit].under;
                }
            });
        
            if (allGoalsChecked) break;
        }

        

        return {
            maxNoStreaks,
            lastNoStreaks
        };
    }


    static calculateNoStreaksFor05(arrayOfObjects) {
        let noUnder05Streak = 0;
        let noOver05Streak = 0;
    
        for (let obj of arrayOfObjects) {
            // Remove o sinal "+" se estiver presente na string
            const cleanedScoreboard = obj.scoreboard.replace(/\+/g, '');
            const [score1, score2] = cleanedScoreboard.split(' - ').map(Number);

            const totalGoals = score1 + score2;
    
            if (totalGoals === 0) {
                noUnder05Streak = 0;   // Se tem 0 gols, é under 0.5
                noOver05Streak++;
            } else {
                noOver05Streak = 0;    // Se tem pelo menos 1 gol, é over 0.5
                noUnder05Streak++;
            }
        }
    
        return {
            noUnder05Streak,
            noOver05Streak
        };
    }
    
  
    static maxConsecutiveCounts(arrayOfObjects) {
        let currentImparStreak = 0;
        let currentParStreak = 0;

        let maxImparStreak = 0;
        let maxParStreak = 0;

        let lastImparStreak = 0;
        let lastParStreak = 0;

        arrayOfObjects.forEach(obj => {
            if (obj.scoreboard) {
                // Remove o sinal "+" se estiver presente na string
                const cleanedScoreboard = obj.scoreboard.replace(/\+/g, '');
                const [score1, score2] = cleanedScoreboard.split(' - ').map(Number);
                const total = score1 + score2;

                if (total % 2 === 0) {  // Par
                    currentParStreak++;
                    currentImparStreak = 0;
                    if (currentParStreak > maxParStreak) maxParStreak = currentParStreak;
                } else {  // Ímpar
                    currentImparStreak++;
                    currentParStreak = 0;
                    if (currentImparStreak > maxImparStreak) maxImparStreak = currentImparStreak;
                }
            }
        });

        // Determine the last streak starting from the beginning of the array, since it's in descending order
        for (let i = 0; i < arrayOfObjects.length; i++) {
            const obj = arrayOfObjects[i];
            if (obj.scoreboard) {
                // Remove o sinal "+" se estiver presente na string
                const cleanedScoreboard = obj.scoreboard.replace(/\+/g, '');
                const [score1, score2] = cleanedScoreboard.split(' - ').map(Number);
                const total = score1 + score2;

                if (total % 2 === 0) {  // Par
                    if (lastImparStreak > 0) break; // End when different streak is found
                    lastParStreak++;
                } else {  // Ímpar
                    if (lastParStreak > 0) break;  // End when different streak is found
                    lastImparStreak++;
                }
            }
        }

        return {
            maxImparStreak: maxImparStreak,
            maxParStreak: maxParStreak,
            lastImparStreak: lastImparStreak,
            lastParStreak: lastParStreak
        };
    }


    static maxConsecutiveOverUnder(arrayOfObjects) {
        const limits = [0.5, 1.5, 2.5, 3.5];
        const currentStreaks = {};
        const maxStreaksUnderOver = {};
        const lastStreaksUnderOver = {};
        
        // Inicializando os objetos de controle
        limits.forEach(limit => {
            currentStreaks[limit] = { over: 0, under: 0 };
            maxStreaksUnderOver[limit] = { over: 0, under: 0 };
            lastStreaksUnderOver[limit] = { over: 0, under: 0 };
        });

        arrayOfObjects.forEach(obj => {
            if (obj.scoreboard) {
                // Remove o sinal "+" se estiver presente na string
                const cleanedScoreboard = obj.scoreboard.replace(/\+/g, '');
                const [score1, score2] = cleanedScoreboard.split(' - ').map(Number);
                const totalGoals = score1 + score2;

                limits.forEach(limit => {
                    if (totalGoals > limit) {  // Over
                        currentStreaks[limit].over++;
                        currentStreaks[limit].under = 0;
                        if (currentStreaks[limit].over > maxStreaksUnderOver[limit].over) {
                            maxStreaksUnderOver[limit].over = currentStreaks[limit].over;
                        }
                    } else {  // Under
                        currentStreaks[limit].under++;
                        currentStreaks[limit].over = 0;
                        if (currentStreaks[limit].under > maxStreaksUnderOver[limit].under) {
                            maxStreaksUnderOver[limit].under = currentStreaks[limit].under;
                        }
                    }
                });
            }
        });

        limits.forEach(limit => {
            for (let i = 0; i < arrayOfObjects.length; i++) {
                const obj = arrayOfObjects[i];
                if (obj.scoreboard) {
                // Remove o sinal "+" se estiver presente na string
                const cleanedScoreboard = obj.scoreboard.replace(/\+/g, '');
                const [score1, score2] = cleanedScoreboard.split(' - ').map(Number);
                    const totalGoals = score1 + score2;

                    if (totalGoals > limit) {  // Over
                        if (lastStreaksUnderOver[limit].under > 0) break;
                        lastStreaksUnderOver[limit].over++;
                    } else {  // Under
                        if (lastStreaksUnderOver[limit].over > 0) break;
                        lastStreaksUnderOver[limit].under++;
                    }
                }
            }
        });

        return {
            maxStreaksUnderOver: maxStreaksUnderOver,
            lastStreaksUnderOver: lastStreaksUnderOver
        };
    }


    static currentStreaksForGoals(array) {
        let streaks = {
            "0": 0,
            "1": 0,
            "2": 0,
            "3": 0,
            "4": 0,
            "+5": 0
        };
    
        let maxStreaks = {
            "0": 0,
            "1": 0,
            "2": 0,
            "3": 0,
            "4": 0,
            "+5": 0
        };
    
        for (let item of array) {
            if (item.scoreboard) {
                // Remove o sinal "+" se estiver presente na string
                const cleanedScoreboard = item.scoreboard.replace(/\+/g, '');

                const [score1, score2] = cleanedScoreboard.split(' - ').map(Number);

                const sumGoals = score1 + score2;
        
                Object.keys(streaks).forEach(category => {
                    if ((category === "+5" && sumGoals >= 5) || (sumGoals === Number(category))) {
                        // Se a sequência atual é maior que a sequência máxima, atualize a sequência máxima
                        if (streaks[category] > maxStreaks[category]) {
                            maxStreaks[category] = streaks[category];
                        }
                        streaks[category] = 0; // reiniciar a sequência
                    } else {
                        streaks[category]++;
                    }
                });
            }
        }
    
        // Uma verificação final após o loop para atualizar o maxStreaks, caso o array termine com uma sequência ativa
        Object.keys(streaks).forEach(category => {
            if (streaks[category] > maxStreaks[category]) {
                maxStreaks[category] = streaks[category];
            }
        });
    
        return { current: streaks, max: maxStreaks };
    }
    
    static bothTeamsScoreStreaks(array) {
        // Inicializa as variáveis de sequência e sequência máxima
        let streaks = {
            "bothScored": 0,
            "neitherScored": 0
        };
    
        let maxStreaks = {
            "bothScored": 0,
            "neitherScored": 0
        };
    
        // Loop através do array de partidas
        for (let item of array) {
            if (item.scoreboard) {
                // Remove o sinal "+" se estiver presente na string
                const cleanedScoreboard = item.scoreboard.replace(/\+/g, '').replace(/\s+/g, '');
    
                // Divide a string do scoreboard em números para representar os gols de ambos os times
                const [goalsHome, goalsAway] = cleanedScoreboard.split("-").map(Number);

                // Ambos marcaram
                if (goalsHome > 0 && goalsAway > 0){

                    streaks["bothScored"]++;
                    
                    if (streaks["bothScored"] > maxStreaks["bothScored"]){
                        maxStreaks["bothScored"] = streaks["bothScored"];
                    }

                    streaks["neitherScored"] = 0;
                } 
                // Nenhum marcou
                else if (goalsHome === 0 && goalsAway === 0){

                    streaks["neitherScored"]++;
                    streaks["bothScored"] = 0;
                } 
                // Apenas um dos times marcou
                else {

                    streaks["neitherScored"]++;

                    if (streaks["neitherScored"] > maxStreaks["neitherScored"]){
                        maxStreaks["neitherScored"] = streaks["neitherScored"];
                    }

                    streaks["bothScored"] = 0;
                }
            }
        }
 /*   
        // Verificar no final do loop para atualizar maxStreaks se o array termina com uma sequência ativa
        Object.keys(streaks).forEach(category => {
            if (streaks[category] > maxStreaks[category]) {
                maxStreaks[category] = streaks[category];
            }
        });
*/
        // Retorna as sequências atuais e máximas
        return { current: streaks, max: maxStreaks };
    }
    

    static teamNotWinningStreaks(games) {
        let streaks = {
            "homeInterval": 0,
            "awayInterval": 0,
            "drawInterval": 0,
            "homeEnd": 0,
            "awayEnd": 0,
            "drawEnd": 0
        };
    
        let maxStreaks = {
            "homeInterval": 0,
            "awayInterval": 0,
            "drawInterval": 0,
            "homeEnd": 0,
            "awayEnd": 0,
            "drawEnd": 0
        };
    
        for (let game of games) {

            const intervalAndEnd = game.intervalEndGame.replace(/\+/g, '').replace(/\s+/g, '');

            const home = game.team_home;

            const away = game.team_away;

            const [intervalWinner, endGameWinner] = intervalAndEnd.split("-");

            // Para o resultado do Intervalo
            if (intervalWinner === home) {
                streaks["awayInterval"] = 0;
                streaks["drawInterval"] = 0;
                streaks["homeInterval"]++;
                if(streaks["homeInterval"] > maxStreaks["homeInterval"]){
                    maxStreaks["homeInterval"] = streaks["homeInterval"];
                }
            } else if (intervalWinner === away) {
                streaks["homeInterval"] = 0;
                streaks["drawInterval"] = 0;
                streaks["awayInterval"]++;
                if(streaks["awayInterval"] > maxStreaks["awayInterval"]){
                    maxStreaks["awayInterval"] = streaks["awayInterval"];
                }
            } else { // Empate no Intervalo
                streaks["drawInterval"]++;
                streaks["homeInterval"] = 0;
                streaks["awayInterval"] = 0;
                if(streaks["drawInterval"] > maxStreaks["drawInterval"]){
                    maxStreaks["drawInterval"] = streaks["drawInterval"];
                }
            }
    
            // Para o resultado Final do Jogo
            if (endGameWinner === home) {
                streaks["homeEnd"]++;
                streaks["awayEnd"] = 0;
                streaks["drawEnd"] = 0;
                if(streaks["homeEnd"] > maxStreaks["homeEnd"]){
                    maxStreaks["homeEnd"] = streaks["homeEnd"];
                }
            } else if (endGameWinner === away) {
                streaks["awayEnd"]++;
                streaks["homeEnd"] = 0;
                streaks["drawEnd"] = 0;
                if(streaks["awayEnd"] > maxStreaks["awayEnd"]){
                    maxStreaks["awayEnd"] = streaks["awayEnd"];
                }
            } else { // Empate no Final do Jogo
                streaks["drawEnd"]++;
                streaks["homeEnd"] = 0;
                streaks["awayEnd"] = 0;
                if(streaks["drawEnd"] > maxStreaks["drawEnd"]){
                    maxStreaks["drawEnd"] = streaks["drawEnd"];
                }
            }
        }
   /* 
        // Verificar no final do loop para atualizar maxStreaks se a sequência está ativa
        Object.keys(streaks).forEach(key => {
            maxStreaks[key] = Math.max(streaks[key], maxStreaks[key]);
        });
*/
        const response = { current: streaks, max: maxStreaks };
    
        return response;
    }

    static exactScoreStreaks(games) {
   
        let streaks = {
            "home": {
                "1-0": 0, "2-0": 0, "2-1": 0, "3-0": 0, "3-1": 0, "3-2": 0, "4-0": 0
            },
            "away": {
                "0-1": 0, "0-2": 0, "1-2": 0, "0-3": 0, "1-3": 0, "2-3": 0, "0-4": 0
            },
            "draw": {
                "0-0": 0, "1-1": 0, "2-2": 0, "3-3": 0, "4-4": 0
            }
        };
    
        let maxStreaks = JSON.parse(JSON.stringify(streaks)); // clone the structure
    
        for (let game of games) {

  
            const score = game.scoreboard.replace(/\+/g, '').replace(/\s+/g, '');
    
            ["home", "away", "draw"].forEach(type => {

                Object.keys(streaks[type]).forEach(exactScore => {

                    if (score === exactScore) {
                        streaks[type][exactScore] = 0;
                    } else {
                        streaks[type][exactScore]++;
                        if (streaks[type][exactScore] > maxStreaks[type][exactScore]) {
                            maxStreaks[type][exactScore] = streaks[type][exactScore];
                        }
                    }
                });
            });
        }

        const response = { current: streaks, max: maxStreaks };
    
        return response;
    }
    
    static halfTimeScoreStreaks(games) {

        let streaks = {
            "home": {
                "1-0": 0, "2-0": 0
            },
            "away": {
                "0-1": 0, "0-2": 0
            },
            "draw": {
                "0-0": 0, "1-1": 0
            }
        };
    
        let maxStreaks = JSON.parse(JSON.stringify(streaks));
        let currentStreaks = JSON.parse(JSON.stringify(streaks));
    
        for (let game of games) {

            const [result, score] = game.halfTimeCorrectScore.split(" ");

            for (let type of ["home", "away", "draw"]) {
                for (let exactScore in streaks[type]) {
                    if (score === exactScore) {
                        currentStreaks[type][exactScore] = 0;
                    } else {
                    
                        currentStreaks[type][exactScore]++;
                        if (currentStreaks[type][exactScore] > maxStreaks[type][exactScore]) {
                            maxStreaks[type][exactScore] = currentStreaks[type][exactScore];
                        }
                    }
                }
            }
        }

        const response = { current: currentStreaks, max: maxStreaks };
    
        return response;
    }
    
    static firstScorePlayers(games) {

        let streaks = {

            "team_home": {
                "Right Winger": 0,
                "Left Winger": 0,
                "Centre Forward": 0,
                "Ponta Esquerda": 0,
                "Ponta Direita": 0,
                "Qualquer outro jogador": 0,
                "Centro-avante": 0,
                "Meia Atacante": 0,
            },
            "team_away": {
                "Right Winger": 0,
                "Left Winger": 0,
                "Centre Forward": 0,
                "Ponta Esquerda": 0,
                "Ponta Direita": 0,
                "Qualquer outro jogador": 0,
                "Centro-avante": 0,
                "Meia Atacante": 0,
                
            },
            "draw":{

                "Sem Marcador de Gol": 0
            }
        };
    
        let maxStreaks = {
            "team_home": { ...streaks["team_home"] },
            "team_away": { ...streaks["team_away"] },
            "draw": {...streaks["draw"]}
        };
    
        let currentStreaks = {
            "team_home": { ...streaks["team_home"] },
            "team_away": { ...streaks["team_away"] },
            "draw": {...streaks["draw"]}
        };
    
        for (let game of games) {

            let home = game.team_home.replace(/\s+/g, '');
            let away = game.team_away.replace(/\s+/g, '');
    
            for (let index in streaks["team_home"]) {

                if ((game.teamScorecast.includes(index) || game.asianHandicamp.includes(index)) &&
                    (game.teamScorecast.includes(home) || game.asianHandicamp.includes(home))) {

                    currentStreaks["team_home"][index]++;

                    if(currentStreaks["team_home"][index] > maxStreaks["team_home"][index]){
                        maxStreaks["team_home"][index] = currentStreaks["team_home"][index]
                    }

                    currentStreaks["team_home"]["Sem Marcador de Gol"] = 0;

                } else {
                    currentStreaks["team_home"][index] = 0; 
                }
    
                if ((game.teamScorecast.includes(index) || game.asianHandicamp.includes(index)) &&
                    (game.teamScorecast.includes(away) || game.asianHandicamp.includes(away))) {

                    currentStreaks["team_away"][index]++;

                    if(currentStreaks["team_away"][index] > maxStreaks["team_away"][index]){
                        maxStreaks["team_away"][index] = currentStreaks["team_away"][index]
                    }

                    currentStreaks["team_away"]["Sem Marcador de Gol"] = 0;

                } else {
                    currentStreaks["team_away"][index] = 0;
                }
            }

            if((game.teamScorecast.includes("Sem Marcador de Gol") || game.asianHandicamp.includes("Sem Marcador de Gol")))
            {
                currentStreaks["draw"]["Sem Marcador de Gol"]++;

                if(currentStreaks["draw"]["Sem Marcador de Gol"] > maxStreaks["draw"]["Sem Marcador de Gol"]){
                    maxStreaks["draw"]["Sem Marcador de Gol"] = currentStreaks["draw"]["Sem Marcador de Gol"]
                }
            }else{
                currentStreaks["draw"]["Sem Marcador de Gol"] = 0;
            }
        }

        const response = { current: currentStreaks, max: maxStreaks };
    
        return response;
    }

    static firstTeamScore(games) {
    
        let maxStreaks = {
            "team_home": 0,
            "team_none": 0,
            "team_away": 0
        };
    
        let currentStreaks = {
            "team_home": 0,
            "team_none": 0,
            "team_away": 0
        };
    
        for (let game of games) {
            let home = game.team_home.replace(/\s+/g, '');
            let away = game.team_away.replace(/\s+/g, '');
    
            if (game.firstTeamToScore.includes(home)) {
                currentStreaks["team_home"]++;
                if (maxStreaks["team_home"] < currentStreaks["team_home"]) {
                    maxStreaks["team_home"] = currentStreaks["team_home"];
                }
            } else {
                currentStreaks["team_home"] = 0;
            }
    
            if (game.firstTeamToScore.includes(away)) {

                currentStreaks["team_away"]++;

                if (maxStreaks["team_away"] < currentStreaks["team_away"]) {
                    maxStreaks["team_away"] = currentStreaks["team_away"];
                }
                
            } else {
                currentStreaks["team_away"] = 0;
            }
    
            if (game.lastTeamToScore === "Sem Gols") {
                currentStreaks["team_none"]++;
                if (maxStreaks["team_none"] < currentStreaks["team_none"]) {
                    maxStreaks["team_none"] = currentStreaks["team_none"];
                }
            } else {
                currentStreaks["team_none"] = 0;
            }
        }

        const response = { current: currentStreaks, max: maxStreaks };
    
        return response;
    }

   static lastTeamScore(games)
   {
  

        let maxStreaks = {
            "team_home": 0,
            "team_none": 0,
            "team_away": 0
        };

        let currentStreaks = {
            "team_home": 0,
            "team_none": 0,
            "team_away": 0
        };

        for (let game of games) {

            let home = game.team_home.replace(/\s+/g, '');
            let away = game.team_away.replace(/\s+/g, '');
    
            if (game.lastTeamToScore.replace(/\s+/g, '') === home) {

                currentStreaks["team_home"]++;
    
                if (maxStreaks["team_home"] < currentStreaks["team_home"]) {
                    maxStreaks["team_home"] = currentStreaks["team_home"];
                }
                
            } else {
                currentStreaks["team_home"] = 0;
            }
    
            if (game.lastTeamToScore.replace(/\s+/g, '') === away) {

                currentStreaks["team_away"]++;
    
                if (maxStreaks["team_away"] < currentStreaks["team_away"]) {
                    maxStreaks["team_away"] = currentStreaks["team_away"];
                }
            
            } else {
                currentStreaks["team_away"] = 0;
            }
    
            if (game.lastTeamToScore === "Sem Gols") {
                currentStreaks["team_none"]++;
    
                if (maxStreaks["team_none"] < currentStreaks["team_none"]) {
                    maxStreaks["team_none"] = currentStreaks["team_none"];
                }

            } else {
                currentStreaks["team_none"] = 0;
            }
        }

        const response = { current: currentStreaks, max: maxStreaks };
    
        return response;
   }

   static resultGroup(games)
   {
        let lastResultsGroups = {
            "team_home":{
                "1-0, 2-0, 2-1":0,
                "3-0, 3-1, 4-0":0
            },
            "team_away":{
                "1-0, 2-0, 2-1":0,
                "3-0, 3-1, 4-0":0
            },
            "draw":{
                "Empate com goals":0
            },
        }
        let maxResultsGroups = {
            "team_home":{
                "1-0, 2-0, 2-1":0,
                "3-0, 3-1, 4-0":0
            },
            "team_away":{
                "1-0, 2-0, 2-1":0,
                "3-0, 3-1, 4-0":0
            },
            "draw":{
                "Empate com goals":0
            },
        };

        for(let game of games)
        {
            let home = game.team_home
            let away = game.team_away

            for (let index in lastResultsGroups["team_home"]) {

                if(game.corretScoreGroup === `${home} ${index}`)
                {
                    lastResultsGroups['team_home'][index] = 0;
                }else{

                    lastResultsGroups['team_home'][index]++;

                    if(lastResultsGroups['team_home'][index] > maxResultsGroups['team_home'][index]){
                        maxResultsGroups['team_home'][index] = lastResultsGroups['team_home'][index]
                    }
                }

                if(game.corretScoreGroup === `${away} ${index}`)
                {
                    lastResultsGroups['team_away'][index] = 0;
                }else{
               
                    lastResultsGroups['team_away'][index]++;

                    if(lastResultsGroups['team_away'][index] > maxResultsGroups['team_away'][index]){
                        maxResultsGroups['team_away'][index] = lastResultsGroups['team_away'][index]
                    }
                }

                if(game.corretScoreGroup.includes("Empate") && !game.corretScoreGroup.includes("Empate 0-0"))
                {
                    lastResultsGroups['draw']['Empate com goals'] = 0;
                }else{

                    lastResultsGroups['draw']['Empate com goals']++;

                    if(lastResultsGroups['draw']['Empate com goals'] > maxResultsGroups['draw']['Empate com goals']){
                        maxResultsGroups['draw']['Empate com goals'] = lastResultsGroups['draw']['Empate com goals']
                    }
                }
            }
        }

        const response  = { current: lastResultsGroups, max: maxResultsGroups };

        return response
   }

   static mostCommonScores(games) {
        const frequencies = {};

        // Counting the frequency of each score
        games.forEach(game => {
        if (!frequencies[game.scoreboard]) {
            frequencies[game.scoreboard] = 0;
        }
        frequencies[game.scoreboard]++;
        });
    
        // Converting the frequency object into an array and sorting by frequency
        const sortedScores = Object.entries(frequencies).sort((a, b) => b[1] - a[1]);
        let count = 0;
        // Taking the top 6 (most frequent) scores
        const top6 = sortedScores.slice(0, 9).map(item => ({
        id: count++,
        scoreboard: item[0],
        count: item[1]
        }));
    
        return top6;
  }
    
    
}

export default Maximums;
