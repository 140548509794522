
import { useContext, useEffect, useState } from 'react';
import {ButtonSide, GlobalContainer, Header, Sidebar, ContainerGradient, ContainerOpacity, BtnMinutes, BtnCheckIndicator, BoxIndicator, ContainerMobileHide, ContainerMobileShow, HeaderMobile, LogoMobile, SideBarMobile, ButtomMenu, ContainerMobileOverflow, BtnCheckIndicatorMobile, BoxIndicatorMobile, BtnMinutesMobile, ContainerGradientMobile, BoxChampionshipHeader, ContainerExpress, ContainerExpressGoals, BoxExpressGoals}  from './style';
import { BsList, BsSpeedometer2, BsGraphUp, BsPlayCircle, BsXLg, BsTags } from 'react-icons/bs';
import { useNavigate } from "react-router-dom";
import { ButtonStyled, Container, ImageLogo, Text } from '../../componets/styles';
import { MainContainer } from './style';
import { BoxChampionship } from './style';
import { BoxDropDown } from './style';
import { BtnChanpionship } from './style';
import { ImgChanpionship } from './style';
import { GiSoccerBall } from 'react-icons/gi';
import { 
    API_URL_SCRALPER_EUROCUP_ODDS,
    API_URL_SCRALPER_EUROCUP_RESULT, 
    API_URL_SCRALPER_EURO_ODDS_NEXT_GAMES, 
    API_URL_SCRALPER_EXPRESS_ODDS, 
    API_URL_SCRALPER_EXPRESS_RESULT, 
    API_URL_SCRALPER_PREMIERLEAGUERESULTS_ODDS, 
    API_URL_SCRALPER_PREMIERLEAGUERESULTS_RESULT, 
    API_URL_SCRALPER_PREMIER_ODDS_NEXT_GAMES, 
    API_URL_SCRALPER_SOUTHAMERICANSUPERLEAGUE_ODDS, 
    API_URL_SCRALPER_SOUTHAMERICANSUPERLEAGUE_RESULT, 
    API_URL_SCRALPER_SOUTH_ODDS_NEXT_GAMES, 
    API_URL_SCRALPER_WORLDCUP_ODDS, 
    API_URL_SCRALPER_WORLDCUP_RESULT,
    API_URL_SCRALPER_WORLD_ODDS_NEXT_GAMES} from '../../config';

import axios from 'axios';
import Reload from '../../componets/reload';
import ModalOdds from '../Modals/ModalOdds';
import HourHelp from '../../helpers/HourHelp';
import TooltipComponent from './containers/tooltip';
import AuthContext from '../../context/AuthContext';
import { formatarData } from '../../share/functions';
import TooltipComponentMobile from './containers/tooltipMobile';



export default function Hour({handlePage})
{

    const {verifieldLogin, user, logout} = useContext(AuthContext);

    const [isSideOpen, setIsSideOpen] = useState(false);

    const [isBoxDropDownChanpionsShip, setIsBoxDropDownChanpionsShip] = useState(false);

    const [isBoxDropDownShow, setIsBoxDropShow] = useState(false);

    const [isBoxDropDownStrategy, setIsBoxDropStrategy] = useState(false);

    const [isBoxDropDownHourFilter, setIsBoxDropDownHourFilter] = useState(false);
    
    const [showComponent, setShowComponent] = useState({id:1, name:"Resultado FT"});

    const [strategyComponent, setStrategyComponent] = useState({id:50, name:"Sim", type:"button", strategy:"Ambas Marcam"});

    const [hourFilterComponent, setHourFilterCoponent] = useState({id:2, name: "12h", value:12})

    const [lastResults, setLastResults] = useState([]);

    const [loop, setloop] = useState(false);

    const [lastKnownId, setLastKnownId] = useState("first");

    const [allResults, setAllResults] = useState([]);

    const [allOdds, setAllOdds] = useState([]);

    const [hourSelect, setHourSelect] = useState(48);

    const [reload, setReload] = useState(false);

    const [modalOdds, setModalOdds] = useState(false);

    const [gameOddsSelect, setGameOddsSelect] = useState(null);

    const [dateOddsSelect, setDateOddsSelect] = useState(null);

    const [arrayColorSelect, setArrayColorSelect] = useState([{}]);

    const [arrayMinutesColorSelect, setMinutesColorSelect] = useState([]);

    const [barraPercentage, setBarraPercentage] = useState(null)

    const [percentualVertical, setPercentualVertical] = useState([]);

    const [buttonsMinutesVertical, setButtonsMinutesVertical] = useState([]);

    const [goalsVertical, setGoalsVertical] = useState([]);

    const [selectChampions, setSelectChampions] = useState({id:1, name: 'Copa do mundo', img:'./images/copa-do-mundo.png', margin:'25px', value:"Copa do Mundo"})

    const [isBoxDropDown, setIsBoxDropDown] = useState(false);

    const [sideBarMobile, setSideBarMobile] = useState(false);

    const [expressCup, setExpressCup] = useState(false);

    const [percentage, setPercentege] = useState({
        red: '0,00%',
        green: '0,00%'
    })

    const [data, setData] = useState({
        hours:[]
    })

    function calculetePercentege(arr)
    {
        const total = arr.reduce((acc, obj) => {
            acc.greenCount += obj.greenCount;
            acc.redCount += obj.redCount;
            return acc;
        }, { greenCount: 0, redCount: 0 });

        const totalCount = total.greenCount + total.redCount;
        const greenPercentageTotal = ((total.greenCount / totalCount) * 100).toFixed(2) + "%";
        const redPercentageTotal = ((total.redCount / totalCount) * 100).toFixed(2) + "%";

        return {
            greenPercentageTotal:greenPercentageTotal,
            redPercentageTotal:redPercentageTotal
        }
    }

    async function handleGames(URL_RESULTS, URL_NEXT_GAMES)
    {
        lastKnownId === "first" &&  setReload(true);

        const nextGames = await axios.get(`${URL_NEXT_GAMES}`);

        axios.get(`${URL_RESULTS}${lastKnownId}`).then(response=>{
         
            const copyArrayResuts = response.data.slice();

            setAllResults(copyArrayResuts);

            setLastKnownId(copyArrayResuts[0].id);

            const arrHourGames = HourHelp.arrHour(copyArrayResuts, strategyComponent.id, showComponent.id, nextGames.data.data);

            const calcPercentege = calculetePercentege(arrHourGames);

            setPercentege({
                ...percentage,
                green:calcPercentege.greenPercentageTotal,
                red:calcPercentege.redPercentageTotal
            })

            setData({
                ...data,
                hours:arrHourGames.slice(0, hourFilterComponent.value)
            })

            setReload(false);
            
            setTimeout(() => {
                setloop(!loop);
            }, 10000);

        }).catch(error=>{

            setReload(false);
            setTimeout(() => {
                setloop(!loop);
            }, 10000);
        })
      }
      async function handleverifieldLogin()
      {
          const status = await verifieldLogin();
  
          if(status === 'Inativo')
          {
            navigate("/payment");
          }
      }
    useEffect(()=>{

        handleverifieldLogin()
        
        switch (selectChampions.id) {

            case 1:
                handleGames(API_URL_SCRALPER_WORLDCUP_RESULT,  API_URL_SCRALPER_WORLD_ODDS_NEXT_GAMES );
                break;
            case 2:
                handleGames(API_URL_SCRALPER_EUROCUP_RESULT,  API_URL_SCRALPER_EURO_ODDS_NEXT_GAMES );
                break;
            case 3:
                handleGames(API_URL_SCRALPER_SOUTHAMERICANSUPERLEAGUE_RESULT,  API_URL_SCRALPER_SOUTH_ODDS_NEXT_GAMES );
                break;  
            case 4:
                handleGames(API_URL_SCRALPER_PREMIERLEAGUERESULTS_RESULT,  API_URL_SCRALPER_PREMIER_ODDS_NEXT_GAMES );
                break;  
            case 5:
                handleGames(API_URL_SCRALPER_EXPRESS_RESULT,  API_URL_SCRALPER_EXPRESS_ODDS );
                break;                 
            default:
                break;
        }

    },[loop])


    const CHAMPIONS_SHIP = [

        {id:1, name: 'Copa do mundo', value:"Copa do Mundo", img:'./images/copa-do-mundo.png', margin:'25px'},
        {id:2, name: 'Euro copa', value:"Euro Cup", img:'./images/euro-copa.png', margin:'10px'},
        {id:3, name: 'Super Liga Sul-Americana', value:"Super Liga Sul-Americana",img:'./images/conmebol-sul-americana.png', margin:'10px'},
        {id:4, name: 'Premier league', value:"Premier League",img:'./images/premier-league.png', margin:'10px'},
        {id:5, name: 'Express Cup', value:"Express Cup",img:'./images/expresscup.png', margin:'10px'}
    ]

    const SHOW = [

        {id:0, name:"Placar HT e FT", type:"text"},
        {id:1, name:"Resultado FT", type:"button"},
        {id:2, name:"Resultado HT", type:"button"},
/*
        {id:3, name:"Odds Resultado final", type:"text"},
        {id:4, name:"Casa", type:"button"},
        {id:5, name:"Empate", type:"button"},
        {id:6, name:"Visitante", type:"button"},

        {id:7, name:"Odds Dupla Hipótese", type:"text"},
        {id:8, name:"Casa", type:"button"},
        {id:9, name:"Empate", type:"button"},
        {id:10, name:"Visitante", type:"button"},
*/
    ]

    const STRATEGY = [

        {id:31, name:"Resultado FT Casa x Visitante", type:"text"},
        {id:32, name:"Mandante", type:"button"},
        {id:33, name:"Visitante", type:"button"},
        {id:34, name:"Empate", type:"button"},

        {id:35, name:"Resultado HT Casa x Visitante", type:"text"},
        {id:36, name:"Mandante", type:"button"},
        {id:37, name:"Visitante", type:"button"},
        {id:38, name:"Empate", type:"button"},

        {id:49, name:"Ambas Marcam", type:"text"},
        {id:50, name:"Sim", type:"button", strategy:"Ambas Marcam"},
        {id:51, name:"Não", type:"button", strategy:"Ambas Marcam"},

        {id:52, name:"Mandante Marca", type:"text"},
        {id:53, name:"Sim", type:"button", strategy:"Mandante Marca"},
        {id:54, name:"Não", type:"button", strategy:"Mandante Marca"},

        {id:55, name:"Visitante Marca", type:"text"},
        {id:56, name:"Sim", type:"button", strategy:"Visitante Marca"},
        {id:57, name:"Não", type:"button", strategy:"Visitante Marca"},

        {id:39, name:"Over Gols", type:"text"},
        {id:40, name:"Over 0.5", type:"button"},
        {id:41, name:"Over 1.5", type:"button"},
        {id:42, name:"Over 2.5", type:"button"},
        {id:43, name:"Over 3.5", type:"button"},

        {id:44, name:"Under Gols", type:"text"},
        {id:45, name:"Under 0.5", type:"button"},
        {id:46, name:"Under 1.5", type:"button"},
        {id:47, name:"Under 2.5", type:"button"},
        {id:48, name:"Under 3.5", type:"button"},

        {id:0, name:"Gols PAR / IMPAR", type:"text"},
        {id:1, name:"Par 0, 2 ou 4", type:"button"},
        {id:2, name:"Impar 1, 3", type:"button"},

        {id:3, name:"Time a Marcar Primeiro", type:"text"},
        {id:4, name:"Casa", type:"button"},
        {id:5, name:"Nenhum", type:"button"},
        {id:6, name:"Visitante", type:"button"},

        {id:7, name:"Time a Marcar por Último", type:"text"},
        {id:8, name:"Casa", type:"button"},
        {id:9, name:"Nenhum", type:"button"},
        {id:10, name:"Visitante", type:"button"},

        {id:11, name:"Resultado de Grupo", type:"text"},
        {id:12, name:"Casa 1x0, 2x0, 2x1", type:"button"},
        {id:13, name:"Casa 3x0, 3x1, 4x0", type:"button"},
        {id:14, name:"Casa Qualquer Outro", type:"button"},

        {id:15, name:"Empate 0x0", type:"button"},
        {id:16, name:"Empate 1x1, 2x2", type:"button"},
        {id:17, name:"Empate 3x3, 4x4", type:"button"},
        {id:18, name:"Visitante 1x0, 2x0, 2x1", type:"button"},
        {id:19, name:"Visitante 3x0, 3x1, 4x0", type:"button"},
        {id:20, name:"Visitante Qualquer Outro", type:"button"},

        {id:21, name:"Viradas", type:"text"},
        {id:22, name:"Casa HT - Casa FT", type:"button"},
        {id:23, name:"Casa HT - Empate FT", type:"button"},
        {id:24, name:"Casa HT - Visitante FT", type:"button"},
        {id:25, name:"Empate HT - Casa FT", type:"button"},
        {id:26, name:"Empate HT - Empate FT", type:"button"},
        {id:27, name:"Empate HT - Visitante FT", type:"button"},
        {id:28, name:"Visitante HT - Casa FT", type:"button"},
        {id:29, name:"Visitante HT - Empate FT", type:"button"},
        {id:30, name:"Visitante HT - Visitante FT", type:"button"},

        {id:58, name:"Gols", type:"text"},
        {id:59, name:"0 Gols", type:"Button"},
        {id:60, name:"1 Gol", type:"Button"},
        {id:61, name:"2 Gols", type:"Button"},
        {id:62, name:"3 Gols", type:"Button"},
        {id:63, name:"4 Gols", type:"Button"},
        {id:64, name:"+5 Gols", type:"Button"},
/*
        {id:65, name:"First goalscorer", type:"text"},
        {id:66, name:"Nenhum jogador", type:"Button"},

        {id:67, name:"Centro avante - Casa", type:"Button"},
        {id:68, name:"Ponta direita - Casa", type:"Button"},
        {id:69, name:"Ponta esquerda - Casa", type:"Button"},
        {id:70, name:"Meia atacante - Casa", type:"Button"},
        {id:71, name:"Qualquer outro jogador - Casa", type:"Button"},

        {id:72, name:"Centro avante - Fora", type:"Button"},
        {id:73, name:"Ponta direita - Fora", type:"Button"},
        {id:74, name:"Ponta esquerda - Fora", type:"Button"},
        {id:75, name:"Meia atacante- Fora", type:"Button"},
        {id:76, name:"Qualquer outro jogador - Fora", type:"Button"},
*/
    ]

    const HOUR_FILTER = [

        {id:1, name: "6h", value:6},
        {id:2, name: "12h", value:12},
        {id:3, name: "24h", value:24},
    ]


    useEffect(() => {

        const generateArray = (startMinute, step, length, width) => {
          return Array.from({ length: length }, (_, index) => {
            const minute = startMinute + index * step;
            return {
              id: minute / step + 1,
              goals: 0,
              minutes: minute.toString().padStart(2, '0'),
              width: width
            };
          });
        };
      
        let newPercentualVertical = [];
        let newButtonsMinutesVertical = [];

      
        switch (selectChampions.value) {
          case 'Copa do Mundo':
            newPercentualVertical = generateArray(1, 3, 20, '4.34%');
            newButtonsMinutesVertical = generateArray(1, 3, 20, '4.34%');
            setExpressCup(false);
            break;

          case 'Euro Cup':
            newPercentualVertical = generateArray(2, 3, 20, '4.34%');
            newButtonsMinutesVertical = generateArray(2, 3, 20, '4.34%');
            setExpressCup(false);
            break;

          case 'Super Liga Sul-Americana':
            newPercentualVertical = generateArray(1, 3, 20, '4.34%');
            newButtonsMinutesVertical = generateArray(1, 3, 20,'4.34%');
            setExpressCup(false);
            break;

          case 'Premier League':
            newPercentualVertical = generateArray(0, 3, 20, '4.34%');
            newButtonsMinutesVertical = generateArray(0, 3, 20, '4.34%');
            setExpressCup(false);
            break;

          case 'Express Cup':
            newPercentualVertical = generateArray(0, 1, 60, '1.66%');
            newButtonsMinutesVertical = generateArray(0, 1, 60, '1.66%');
            setExpressCup(true);
            break;   

          default:
            break;
        }
      
        setPercentualVertical(newPercentualVertical);
        setButtonsMinutesVertical(newButtonsMinutesVertical);
        setGoalsVertical(newPercentualVertical)

      }, [selectChampions]);
      
    const navigate = useNavigate();

    function handleChampionsShip(item)
    {
        setLastKnownId("first");
        setSelectChampions(item)
        setIsBoxDropDown(false);
        setloop(!loop)
    }

    async function handleShow(item)
    {
        setShowComponent(item)
        setIsBoxDropShow(false);

        const nextGames = await axios.get(`${ API_URL_SCRALPER_WORLD_ODDS_NEXT_GAMES}`);

        const arrHourGames = HourHelp.arrHour(allResults, strategyComponent.id, item.id, nextGames.data.data);

        setData({
            ...data,
            hours:arrHourGames.slice(0, hourFilterComponent.value)
        })
    }

    async function handleStrategy(item)
    {
        setStrategyComponent(item)
        setIsBoxDropStrategy(false);

        const nextGames = await axios.get(`${ API_URL_SCRALPER_WORLD_ODDS_NEXT_GAMES}`);

        const arrHourGames = HourHelp.arrHour(allResults, item.id, showComponent.id, nextGames.data.data);

        setData({
            ...data,
            hours:arrHourGames.slice(0, hourFilterComponent.value)
        })
    }



    async function updateColors()
    {
        const nextGames = await axios.get(`${ API_URL_SCRALPER_WORLD_ODDS_NEXT_GAMES}`);

        const arrHourGames = HourHelp.arrHour(allResults, strategyComponent.id, showComponent.id, nextGames.data.data);

        setData({
            ...data,
            hours:arrHourGames.slice(0, hourFilterComponent.value)
        })
    }

    async function handleHourFilter(item)
    {
        setHourFilterCoponent(item)
        setIsBoxDropDownHourFilter(false);

        const nextGames = await axios.get(`${ API_URL_SCRALPER_WORLD_ODDS_NEXT_GAMES}`);

        const arrHourGames = HourHelp.arrHour(allResults, strategyComponent.id, showComponent.id, nextGames.data.data);

        

        setData({
            ...data,
            hours:arrHourGames.slice(0, item.value)
        })
    }

    function visibleDropDownChanpionsShip() {
        setIsBoxDropDownChanpionsShip(prev => !prev);
    }

    function visibleDropDownShow() {
        setIsBoxDropShow(prev => !prev);
    }

    function visibleDropDownStrategy() {
        setIsBoxDropStrategy(prev => !prev);
    }


    function visibleDropDownHourFilter() {
        setIsBoxDropDownHourFilter(prev => !prev);
    }



    function limitCharacters(input, maxLength) {
        if (input.length <= maxLength) {
          return input;
        }
        return input.substring(0, maxLength) + "...";
    }


    function evaluatePercentage(percentageStr) {
        // Removendo o sinal de porcentagem e convertendo para número
        const percentage = parseFloat(percentageStr.replace('%', ''));
    
        if (percentage <= 50) {
            return "#ef4444";
        } else if (percentage > 50 && percentage <= 60) {
            return "#fb923c";
        } else {
            return "#86efac";
        }
    }

    function calculateGreenPercentage(dataArray, targetMinutes) {

        let totalMinutes = 0;
        let greenMinutes = 0;
      
        dataArray.forEach(hourObj => {
          const matchingMinutes = hourObj.minutes.filter(minuteObj => {
            const minutePart = minuteObj.time.split(":")[1];
            return targetMinutes.some(target => target === minutePart) && minuteObj.id !== null;
          });
      
          const greenMatchingMinutes = matchingMinutes.filter(minuteObj => minuteObj.color === "green");
      
          totalMinutes += matchingMinutes.length;
          greenMinutes += greenMatchingMinutes.length;
        });
      
        const greenPercentage = totalMinutes ? (greenMinutes / totalMinutes) * 100 : 0;
      
        // Arredonda para duas casas decimais e adiciona o símbolo de porcentagem
        const formattedGreenPercentage = greenPercentage.toFixed(2) + '%';
      
        return formattedGreenPercentage;
    }

    function onAddMinutesArray(minutes, item) {

        const arr = [...arrayMinutesColorSelect];  
      
        const index = arr.indexOf(minutes);
      
        if (index === -1) {

          arr.push(minutes);

        } else {

          arr.splice(index, 1); 

        }
      
        setMinutesColorSelect(arr); 
        updateColors(); 

        setBarraPercentage(calculateGreenPercentage(data.hours, arr));
    }


    function visibleDrop() {
        setIsBoxDropDown(prev => !prev);
    }
      
    

    return(
        <GlobalContainer>
           {reload === true && <Reload/>} 

{expressCup === false &&
    <>           
           <ContainerMobileHide>
            
            <Header>

                <Container display={'flex'}  items="center"  w="50%" h="100%" ml="50px">

                    <ImageLogo src='./images/logo.png'/>

                    <BoxChampionshipHeader onClick={visibleDrop}>
                        <Container display={'flex'}  items="center" jc="center">
                            <ImgChanpionship src={selectChampions.img} />
                            <Text fw="bold" fs="20px" col='#fff'>{limitCharacters(selectChampions.name, 15)}</Text>
                        </Container>
                    </BoxChampionshipHeader>


                    <BoxDropDown dp={isBoxDropDown === true ? '300px' : '0px'}>
                        {
                            CHAMPIONS_SHIP.map(item => (
                                isBoxDropDown &&
                                <Container key={item.id} mt={item.margin}>
                                    <BtnChanpionship onClick={() => handleChampionsShip(item)}>
                                        <ImgChanpionship src={item.img} />
                                        {item.name}
                                    </BtnChanpionship>
                                </Container>
                            ))
                        }
                        <Container mt='20px'></Container>
                    </BoxDropDown>
                    
                </Container>

                <Container w='auto' display='flex' mr="20px" items='center'>
                        <Container w='auto' display='flex' mr="20px" >
                            <Text col='#022c22' fs='20px' fw='bold'>{`Bem vindo! ${user.name} |`}</Text><Text ml='10px' col='#e11d48' fs='20px' fw='bold'>{`${formatarData(user.validation)}`}</Text>
                        </Container>


                        <ButtonStyled onClick={logout} w='150px' mr='30px' bg='transparent' border='1px solid #fff'>
                            <Text col="#fff" fw='bold' fs='20px'>Sair</Text>
                        </ButtonStyled>
                    </Container>

                </Header>

                <Sidebar open={isSideOpen}>

                

                    <ButtonSide open={isSideOpen} onClick={()=>{setIsSideOpen(!isSideOpen)}}>
                        <BsList col='#fff' size={45}/>
                        {isSideOpen && <Text col="#fff" fw="bold" ml="10px">Menu</Text>}
                    </ButtonSide>

{/** 
                        <ButtonSide open={isSideOpen}  mt="50px" onClick={()=>{navigate("/home")}}>
                            <BsSpeedometer2 col='#fff' size={35}/>
                            {isSideOpen && <Text col="#fff" fw="bold" ml="10px">Painel</Text>}
                            
                        </ButtonSide>
*/}

                    <ButtonSide open={isSideOpen} bg="#707070" mt="30px" onClick={()=>{navigate("/analysis")}}>
                        <BsGraphUp col='#fff' size={33}/>
                        {isSideOpen && <Text col="#fff" fw="bold" ml="10px">Análise</Text>}
                    </ButtonSide>


                    <ButtonSide open={isSideOpen} mt="30px" onClick={()=>{navigate('/videos')}}>
                        <BsPlayCircle  col='#fff' size={35}/>
                        {isSideOpen && <Text col="#fff" fw="bold" ml="10px">Vídeos</Text>}
                    </ButtonSide>
{/**
                    <ButtonSide open={isSideOpen} mt="30px" onClick={()=>{navigate('/robos')}}>
                        <BsTags  col='#fff' size={35}/>
                        {isSideOpen && <Text col="#fff" fw="bold" ml="10px" >Robôs</Text>}
                    </ButtonSide>
 */}
                </Sidebar>

                <MainContainer >

                <Container w="98%" pl="20px" pr="20px" pb="20px" pt="20px" display={"flex"} jc="space-between">
                        
                        <ButtonStyled w="16.2%" bg='#15803d' onClick={()=>{handlePage("maximims")}}>
                            <Text col="#fff" fw="bold" fs="25px">MÁXIMAS</Text>
                        </ButtonStyled>
  
                        <ButtonStyled w="16.2%"  onClick={()=>{handlePage("expressCup")}}>
                            <Text col="#fff" fw="bold" fs="25px">EXPRESS CUP</Text>
                        </ButtonStyled>
  
                        <ButtonStyled w="16.2%"  onClick={()=>{handlePage("hour")}}>
                            <Text col="#fff" fw="bold" fs="25px">HORÁRIOS</Text>
                        </ButtonStyled>

                        <ButtonStyled w="16.2%"  onClick={()=>{handlePage("multihour")}}>
                            <Text col="#fff" fw="bold" fs="25px">MULTI HORÁRIOS</Text>
                        </ButtonStyled>

                        <ButtonStyled w="16.2%"  onClick={()=>{handlePage("confrontations")}}>
                            <Text col="#fff" fw="bold" fs="25px">CONFRONTOS</Text>
                        </ButtonStyled>

                        <ButtonStyled w="16.2%" onClick={()=>{handlePage("ranking")}}>
                            <Text col="#fff" fw="bold" fs="25px">RANKING</Text>
                        </ButtonStyled>                     

                    </Container>

                    <Container w="98%" pl="20px" pr="20px" pb="20px" pt="20px" display="flex">

                        <ContainerGradient>

                            <Container w="70%" display="flex" items="center">


                                <Text fs="25px" col="#fff" fw="bold" ml="10px"> Mostrar:</Text>

                                <BoxChampionship onClick={visibleDropDownShow} ml="30px">
                                    <Container display={'flex'}  items="center" jc="center">
                                        <Text fs="20px" col='#fff'>{limitCharacters(showComponent.name, 15)}</Text>
                                    </Container>
                                </BoxChampionship>

                                <BoxDropDown h="auto" dp={isBoxDropDownShow === true ? '350px' : '0px'}>
                                    {
                                        SHOW.map(item => (

                                            isBoxDropDownShow &&

                                            <Container key={item.id} mt={"10px"}>

                                                {item.type === "text"
                                                ?
                                                    <Container w="100%" display="flex" jc="center" items="center">
                                                        <Text fw="bold" col="blue" fs="16px">{item.name}</Text>
                                                    </Container>
                                                :

                                                    <BtnChanpionship onClick={() => handleShow(item)} disabled={false}>
                                                        <Text fw="bold" col="#000"  fs="16px">{item.name}</Text>
                                                    </BtnChanpionship>
                                                }
                                                
                                            </Container>
                                        ))
                                    }
                                    <Container mt="20px"></Container>
                                </BoxDropDown>

                                <Text fs="25px" col="#fff" fw="bold" ml="10px"> Estratégia:</Text>

                                <BoxChampionship onClick={visibleDropDownStrategy} ml="30px">
                                    <Container display={'flex'}  items="center" jc="center">
                                        <Text fs="20px"  col='#fff'>{limitCharacters(`${strategyComponent.strategy ? strategyComponent.strategy : ''} (${strategyComponent.name})`, 15)}</Text>
                                    </Container>
                                </BoxChampionship>

                                <BoxDropDown dp={isBoxDropDownStrategy === true ? '300px' : '0px'}>
                                    {
                                        STRATEGY.map((item, index )=> (

                                            isBoxDropDownStrategy &&
                                            <Container key={item.id} mt={"10px"} >
                                                {item.type === "text"
                                                ?
                                                    <Container w="100%" display="flex" jc="center" items="center">
                                                        <Text fw="bold" col="blue">{item.name}</Text>
                                                    </Container>
                                                :

                                                    <BtnChanpionship onClick={() => handleStrategy(item)}>
                                                        <Text fw="bold" col="#000">{item.name}</Text>
                                                    </BtnChanpionship>
                                                }
                                            </Container>
                                        ))
                                    }
                                    <Container mt="20px"></Container>
                                </BoxDropDown>

                                <Text fs="25px" col="#fff" fw="bold" ml="10px"> Últimas horas:</Text>

                                <BoxChampionship onClick={visibleDropDownHourFilter} ml="30px">
                                    <Container display={'flex'}  items="center" jc="center">
                                        <Text fs="20px" col='#fff'>{limitCharacters(hourFilterComponent.name, 15)}</Text>
                                    </Container>
                                </BoxChampionship>

                                <BoxDropDown h="320px" dp={isBoxDropDownHourFilter === true ? '300px' : '0px'}>
                                    {
                                        HOUR_FILTER.map(item => (
                                            isBoxDropDownHourFilter &&
                                            <Container key={item.id} mt={"10px"}>
                                                <BtnChanpionship onClick={() => handleHourFilter(item)}>
                                                    {item.name}
                                                </BtnChanpionship>
                                            </Container>
                                        ))
                                    }
                                </BoxDropDown>

                            </Container>

                            <Container w="30%" display="flex" jc="end" >

                                <Container w="auto">
                                    <Text col="#CFFFC2" fs="30px" fw="bold">GREEN: {percentage.green}</Text>
                                </Container>
                                    
                                <Container w="auto">
                                    <Text col="#FF0000" fs="30px" fw="bold" ml="20px" mr="150px">RED: {percentage.red}</Text>
                                </Container>

                            </Container>

                        </ContainerGradient>
                    </Container>

                    <Container w="98%" pl="20px" pr="20px"  display="flex" >

                        <Container  w={"4.34%"}>
                            <Container w="100%" display="flex" jc="center" items="center">
                                <Text col="#fff">G</Text>
                            </Container>
                        </Container>
                            
                        {goalsVertical.map(item=>{
                            return(
                                <Container key={item.id} w={item.width}>
                                    <Container w="100%" display="flex" jc="center" items="center">
                                        <Text col="#fff">{HourHelp.getTotalGoalsForMinute(data.hours, item.minutes)}</Text>
                                    </Container>
                                </Container>
                            )
                        })}

                        <Container  w={"8.68%"}>
                            <Container w="100%" display="flex" jc="center" items="center">
                                <Text col="#fff"></Text>
                            </Container>
                        </Container>

                    </Container>                    
                    
                    <Container w="98%" pl="20px" pr="20px" pos={"relative"} >

                        <ContainerOpacity>

                            <Container w="100%" pb="20px" pt="20px"  display="flex" >

                            <Container w={"4.34%"} ></Container>

                            {percentualVertical.map(item=>{
                                    return(
                                            <Container key={item.id} w={item.width} >
                                                <Container w="100%">
                                                    <Container w="100%" display="flex" jc="center" items="center">
                                                        <Text fs='20px' col={evaluatePercentage(HourHelp.analyzeGreenForMinute(data.hours, item.minutes).greenPercentage)}>{HourHelp.analyzeGreenForMinute(data.hours, item.minutes).greenCount}</Text>
                                                    </Container>
                                                    <Container  w="100%" display="flex" jc="center" items="center" mt="5px">
                                                        <Text fs='20px' col={evaluatePercentage(HourHelp.analyzeGreenForMinute(data.hours, item.minutes).greenPercentage)}>{HourHelp.analyzeGreenForMinute(data.hours, item.minutes).greenPercentage}</Text>
                                                    </Container>
                                                </Container>
                                            </Container>
                                    )
                                })}
                            </Container>
                            {arrayMinutesColorSelect.length > 0
                                &&
                                <Container h="30px" w="100%" mb="5px">
                                    <Container h="100%" w={barraPercentage} bg="#ccc" display="flex" jc="center" items="center">
                                        <Text fw="bold" col="green">{`${barraPercentage} ${strategyComponent.name}`}</Text>
                                    </Container>
                                </Container>
                            }


                            <Container  display="flex">

                                <Container w="4.34%" display="flex" jc="center" items="center">
                                    <Text col="#FFFF">Hora</Text>
                                </Container>

                                {buttonsMinutesVertical.map(item=>{
                                    return(

                                        <Container key={item.id} w={item.width} display="flex" jc="center" items="center">
                                            <BtnMinutes onClick={()=>{onAddMinutesArray(item.minutes, item)}}>{item.minutes}</BtnMinutes>
                                        </Container>
                                    )
                                })}

                                <Container w="4.34%" display="flex" jc="center" items="center">
                                    <Text col="#FFFF">Dados</Text>
                                </Container>

                                <Container w="4.34%" display="flex" jc="center" items="center">
                                    <Text col="#FFFF"><GiSoccerBall/></Text>
                                </Container>
                        
                            </Container>

                            {data.hours.map((hour, index)=>{

                                return( 
                                    <Container key={index} display="flex" >

                                        <BtnCheckIndicator w={"4.34%"} bg="#707070">
                                            {/*hour.marked ? <BsCheckSquare color='#fff'/> : <BsSquareFill color='#fff'/>*/}
                                            <Text ml="5px" col='#fff'>{hour.hour}</Text>
                                        </BtnCheckIndicator>

                                        {hour.minutes.map((item,index)=>{
                                            return(
                                            
                                                <TooltipComponent  key={index}
                                                    item={item} 
                                                    arrayColorSelect={arrayColorSelect} 
                                                    setArrayColorSelect={setArrayColorSelect}
                                                    updateColors={updateColors}
                                                    arrayMinutesColorSelect={arrayMinutesColorSelect}
                                                    setMinutesColorSelect={setMinutesColorSelect}
                                                />
                                            
                                            )
                                        })}
                                        

                                        <BoxIndicator bg={ "#737373"} w="4.34%">
                                            <Text ml="5px" col={evaluatePercentage(hour.greenPercentage)} fw="bold">{hour.greenPercentage}</Text>
                                        </BoxIndicator>

                                        <BoxIndicator  bg={"#a3a3a3"} w="4.34%">
                                            <Text ml="5px" col='#FFE500' fw="bold">{hour.totalGoals}</Text>
                                        </BoxIndicator>
            
                                    </Container>
                                )
                            })}

                        </ContainerOpacity>
                    </Container>                   
                </MainContainer>

            </ContainerMobileHide>

            {/**TEMPLATE MOBILE */}
            <ContainerMobileShow>

                <HeaderMobile>

                    <ButtonStyled onClick={()=>{setSideBarMobile(true)}} bg='transparent' w='auto'>
                        <BsList color='#fff' size={30}/>
                    </ButtonStyled>

                    <Container w='auto' display='flex' >
                       <Text ml='5px' col='#e11d48' fs='15px' fw='bold'>{ `${formatarData(user.validation)}`}</Text>
                    </Container>

                    <LogoMobile src='./images/logo.png'/>
                    
                </HeaderMobile>

                <SideBarMobile open={sideBarMobile}>

                    <Container w='100%' display='flex' jc='flex-end'>
                        <ButtonStyled onClick={()=>{setSideBarMobile(false)}} bg='transparent' w='auto' mr='20px'>
                            <BsXLg color='#fff' size={30}/>
                        </ButtonStyled>
                    </Container>

                    <Container w='100%' mt='40px'>

                        <ButtomMenu onClick={()=>{navigate("/home")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Dashboard</Text>
                        </ButtomMenu>

                        <ButtomMenu onClick={()=>{handlePage("maximims")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Máximas</Text>
                        </ButtomMenu>
                        
                        <ButtomMenu onClick={()=>{handlePage("expressCup")}}>
                            <Text col='#fff' fw='bold' fs='20px'>ExpressCup</Text>
                        </ButtomMenu>

                        <ButtomMenu onClick={()=>{handlePage("hour")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Horários</Text>
                        </ButtomMenu>
                        <ButtomMenu onClick={()=>{handlePage("multihour")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Multihorários</Text>
                        </ButtomMenu>
                        <ButtomMenu onClick={()=>{handlePage("confrontations")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Confrontos</Text>
                        </ButtomMenu>
                        <ButtomMenu onClick={()=>{handlePage("ranking")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Ranking</Text>
                        </ButtomMenu>
                        <ButtomMenu onClick={()=>{navigate("/videos")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Videos</Text>
                        </ButtomMenu>
                        {/**
                         * 
                         *                         <ButtomMenu onClick={()=>{navigate("/robos")}}>
                            <Text col='#fff' fw='bold' fs='20px'>Robôs</Text>
                        </ButtomMenu>
                         * 
                         */}


                    </Container>

                </SideBarMobile>

                <Container display={'block'}  items="center"  w="100%" h="100%">
                    <Container mt='20px'></Container>

                    <BoxChampionship ml='2.5%' w='95%' onClick={visibleDrop}>
                        <Container display={'flex'}  items="center" jc="center">
                            <ImgChanpionship src={selectChampions.img} />
                            <Text fw="bold" fs="20px" col='#fff'>{limitCharacters(selectChampions.name, 15)}</Text>
                        </Container>
                    </BoxChampionship>


                    <BoxDropDown dp={isBoxDropDown === true ? '100%' : '0px'}>
                        {
                            CHAMPIONS_SHIP.map(item => (
                                isBoxDropDown &&
                                <Container key={item.id} mt={item.margin}>
                                    <BtnChanpionship onClick={() => handleChampionsShip(item)}>
                                        <ImgChanpionship src={item.img} />
                                        {item.name}
                                    </BtnChanpionship>
                                </Container>
                            ))
                        }
                       <Container mt='20px'></Container>
                    </BoxDropDown>

                  

                </Container>  

                <Container w="100%" display="block">

                    <BoxChampionship onClick={visibleDropDownShow} w='95%' ml="2.5%" mt='5px'>
                        <Container display={'flex'}  items="center" jc="center">
                            <Text  fs="20px" col='#fff'>{limitCharacters(showComponent.name, 15)}</Text>
                        </Container>
                    </BoxChampionship>

                    <BoxDropDown dp={isBoxDropDownShow === true ? '300px' : '0px'}>
                            {
                                SHOW.map(item => (
                                isBoxDropDownShow &&
                                <Container key={item.id} mt={"10px"}>
                                    {item.type === "text"
                                        ?
                                        <Container w="100%" display="flex" jc="center" items="center">
                                            <Text fw="bold" col="blue">{item.name}</Text>
                                        </Container>
                                        :

                                        <BtnChanpionship onClick={() => handleShow(item)} disabled={false}>
                                            <Text fw="bold" col="#000">{item.name}</Text>
                                        </BtnChanpionship>
                                    }
                                </Container>
                            ))
                        }
                        <Container mt="20px"></Container>
                    </BoxDropDown>

                    <BoxChampionship onClick={visibleDropDownStrategy} w='95%' ml="2.5%" mt='5px'>
                        <Container display={'flex'}  items="center" jc="center">
                            <Text fs="20px" col='#fff'>{limitCharacters(strategyComponent.name, 15)}</Text>
                        </Container>
                    </BoxChampionship>

                    <BoxDropDown dp={isBoxDropDownStrategy === true ? '300px' : '0px'}>
                        {
                            STRATEGY.map((item, index )=> (

                                isBoxDropDownStrategy &&
                                    <Container key={item.id} mt={"10px"} >
                                    {item.type === "text"
                                    ?
                                        <Container w="100%" display="flex" jc="center" items="center">
                                            <Text fw="bold" col="blue">{item.name}</Text>
                                        </Container>
                                        :

                                        <BtnChanpionship onClick={() => handleStrategy(item)}>
                                            <Text fw="bold" col="#000">{item.name}</Text>
                                        </BtnChanpionship>
                                    }
                                    </Container>
                                ))
                        }
                        <Container mt="20px"></Container>
                    </BoxDropDown>

                    <BoxChampionship onClick={visibleDropDownHourFilter} w='95%' ml="2.5%" mt='5px'>
                        <Container display={'flex'}  items="center" jc="center">
                            <Text  fs="20px" col='#fff'>{limitCharacters(hourFilterComponent.name, 15)}</Text>
                        </Container>
                    </BoxChampionship>

                    <BoxDropDown h="320px" dp={isBoxDropDownHourFilter === true ? '300px' : '0px'}>
                        {
                            HOUR_FILTER.map(item => (
                                isBoxDropDownHourFilter &&
                                <Container key={item.id} mt={"10px"}>
                                    <BtnChanpionship onClick={() => handleHourFilter(item)}>
                                        <Text fs="20px" fw="bold">{item.name}</Text>
                                    </BtnChanpionship>
                                </Container>
                            ))
                        }
                        <Container h="10px"></Container>
                    </BoxDropDown>
                </Container>

                <ContainerMobileOverflow>

                    
                    <Container w='100%'>

                        {/**INICIO */}

                        <Container w="100%" display="flex">

                        <ContainerGradientMobile>

                            <Container w="100%" display="flex" jc="center" >

                                <Text col="#CFFFC2" fs="13px" fw="bold">GREEN: {percentage.green}</Text>
                                        
                                <Text col="#FF0000" fs="13px" fw="bold" ml="20px" >RED: {percentage.red}</Text>

                            </Container>

                        </ContainerGradientMobile>
                        
                        </Container>

                        <Container w="98%" pl='3px' display="flex" >

                        <Container  w={"4.35%"}>
                            <Container w="100%" display="flex" jc="center" items="center">
                                <Text fs='5px' col="#fff">G</Text>
                            </Container>
                        </Container>

                        {goalsVertical.map(item=>{
                            return(
                                <Container key={item.id} w={"4.8%"}>
                                    <Container w="100%" display="flex" jc="center" items="center">
                                        <Text fs='5px' col="#fff">{HourHelp.getTotalGoalsForMinute(data.hours,item.minutes)}</Text>
                                    </Container>
                                </Container>
                            )
                        })}

                        <Container  w={"8.68%"}>
                            <Container w="100%" display="flex" jc="center" items="center">
                                <Text col="#fff"></Text>
                            </Container>
                        </Container>

                        </Container>

                        <Container w="100%" pos={"relative"} >

                        <ContainerOpacity>

                            <Container w="100%"  display="flex" >

                                <Container w={"4.34%"} h='10px' ></Container>

                                {percentualVertical.map(item=>{
                                        return(
                                        <Container key={item.id} w={item.width} >
                                            <Container w="100%">
                                                <Container w="100%" display="flex" jc="center" items="center">
                                                    <Text fs='5px' mt='5px' col={evaluatePercentage(HourHelp.analyzeGreenForMinute(data.hours, item.minutes).greenPercentage)}>{HourHelp.analyzeGreenForMinute(data.hours, item.minutes).greenCount}</Text>
                                                </Container>
                                                <Container  w="100%" display="flex" jc="center" items="center" mt="5px">
                                                    <Text fs='4px' col={evaluatePercentage(HourHelp.analyzeGreenForMinute(data.hours, item.minutes).greenPercentage)}>{HourHelp.analyzeGreenForMinute(data.hours, item.minutes).greenPercentage}</Text>
                                                </Container>
                                            </Container>
                                        </Container>
                                        )
                                    })}
                                </Container>

                                {arrayMinutesColorSelect.length > 0
                                    &&
                                    <Container h="15px" w="100%" mb="5px">
                                        <Container h="100%" w={barraPercentage} bg="#ccc" display="flex" jc="center" items="center">
                                            <Text fs='5px' fw="bold" col="green">{`${barraPercentage} ${strategyComponent.name}`}</Text>
                                        </Container>
                                    </Container>
                                }

                                <Container  display="flex">

                                    <Container h='100%' w="4.34%" display="flex" jc="center" items="center">
                                        <Text fs='5px' col="#FFFF">Hora</Text>
                                    </Container>

                                    {buttonsMinutesVertical.map(item=>{
                                        return(

                                            <Container key={item.id} w={item.width} display="flex" jc="center" items="center">
                                                <BtnMinutesMobile onClick={()=>{onAddMinutesArray(item.minutes, item)}}>
                                                    {item.minutes}
                                                </BtnMinutesMobile>
                                            </Container>
                                        )
                                    })}

                                    <Container w="4.34%" display="flex" jc="center" items="center">
                                        <Text fs='5px' col="#FFFF">Dados</Text>
                                    </Container>

                                    <Container w="4.34%" display="flex" jc="center" items="center">
                                        <Text fs='5px' col="#FFFF"><GiSoccerBall/></Text>
                                    </Container>

                                </Container>

                                {data.hours.map((hour, index)=>{

                                    return( 
                                        <Container h='15px' key={index} display="flex" >

                                            <BtnCheckIndicatorMobile w={"4.34%"} bg="#707070">
                                                {/*hour.marked ? <BsCheckSquare color='#fff'/> : <BsSquareFill color='#fff'/>*/}
                                                <Text fs='5px' ml="5px" col='#fff'>{hour.hour}</Text>
                                            </BtnCheckIndicatorMobile>

                                            {hour.minutes.map((item,index)=>{
                                                return(

                                                    <TooltipComponentMobile  
                                                        key={index}
                                                        item={item} 
                                                        arrayColorSelect={arrayColorSelect} 
                                                        setArrayColorSelect={setArrayColorSelect}
                                                        updateColors={updateColors}
                                                        arrayMinutesColorSelect={arrayMinutesColorSelect}
                                                        setMinutesColorSelect={setMinutesColorSelect}
                                                    />
                                                )
                                            })}
                                            
                                            <BoxIndicatorMobile bg={ "#737373"} w="4.34%">

                                                <Text 
                                                    fs='5px' 
                                                    ml="5px" 
                                                    col={evaluatePercentage(hour.greenPercentage)} 
                                                    fw="bold">{hour.greenPercentage}
                                                </Text>

                                            </BoxIndicatorMobile>

                                            <BoxIndicatorMobile  bg={"#a3a3a3"} w="4.34%">
                                                <Text fs='5px' ml="5px" col='#FFE500' fw="bold">{hour.totalGoals}</Text>
                                            </BoxIndicatorMobile>

                                        </Container>
                                    )
                                })}

                        </ContainerOpacity>
                    </Container>

                    {/**FIM */}

                    </Container>

                    <Container mt='50px'></Container>

                </ContainerMobileOverflow>                

            </ContainerMobileShow>
        </>
    }

        {modalOdds === true && 
            <ModalOdds 
                game={gameOddsSelect} 
                date={dateOddsSelect} 
                setModalOdds={setModalOdds}
                url={selectChampions.id}
            />
        }

        </GlobalContainer>
    )
}

