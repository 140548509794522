import Reload from "../componets/reload";
import { GlobalContainer } from "./styled";

export default function Loading()
{
    return(

        <GlobalContainer>
            <Reload bg='transparent'/>
        </GlobalContainer>
    )
}