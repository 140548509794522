import { useLocation, useNavigate} from "react-router-dom";
import { Container, Text } from "../componets/styles";
import AuthHeader from "./containers/header";
import { ButtonFormAuth, FormContainer, HeaderMobile, InputFormAuth, LogoMobile } from "./styled";
import { useEffect, useState } from "react";
import { SpinnerCircular } from "spinners-react";
import axios from "axios";
import { API_URL } from "../config";
import Reload from "../componets/reload";

export default function NewPassword()
{
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const queryToken = queryParams.get("token_redefine_password");

    const navigate = useNavigate();

    const [form, setForm] = useState({

        password:'',
        repeat:'',
        token:''
    })
    const [errors, setErrors] = useState({
        password:'',
        repeat:'',
        token:''
    });

    const [success, setSuccess] = useState('');

    const [spinner, setSpiner] = useState(false);

    async function verifieldToken()
    {
        const response = await axios.get(`${API_URL}/redefine?token=${queryToken}`);

        if(response.data.token)
        {
            setForm({
                ...form,
                token: response.data.token
            })
        }

    }

    useEffect(()=>{
        verifieldToken()
    },[])

    async function onRedefine()
    {
        setSpiner(true)

        try {
            const response = await axios.post(`${API_URL}/redefine`, form);

            console.log(response)
            setSpiner(false)
            setSuccess(response.data.success)
            setForm({
                ...form,
                password:'',
                repeat:'',
                token:''
            })
            navigate('/')
        } catch (error) {

            console.log(error)

            if(error.response.data.errors)
            {
                setErrors({
                    ...errors,
                    password:error.response.data.errors.password,
                    repeat:error.response.data.errors.repeat,
                });
                setSpiner(false);
                return
            }

            if(error.response.data.error)
            {
                console.log(error.response.data.error);
                setSpiner(false);

                return
            }

            setSpiner(false);
        }

    }

    if(!form.token)
    {
        return(
            <Reload/>
        )
        
    }else{

        return(
            <Container>
    
                <AuthHeader/>
    
                <HeaderMobile>
                    <LogoMobile src='./images/logo.png'/>
                </HeaderMobile>
    
                <Container>
    
                    <FormContainer>
    
                        <Container display="flex" items="center" jc="center" mt='20px' >
                            <Text col="#fff" fs="30px">Informe a nova senha.</Text>
                        </Container>
                    
                        <InputFormAuth onChange={(e)=>{setForm({...form, password:e.target.value})}} value={form.password} type="password" placeholder="Digite a nova senha"/>
                        <Text ml='5%' fs='20px' col='#dc2626' fw='bold'>{errors.password}</Text> 
    
                        <InputFormAuth onChange={(e)=>{setForm({...form, repeat:e.target.value})}} value={form.repeat} type="password" placeholder="Repita a nova senha"/>
                        <Text ml='5%' fs='20px' col='#dc2626' fw='bold'>{errors.repeat}</Text> 
                        
                        <Container ml="5%" mt="10px">
                            <Text col="red" fs="20px" fw="bold">{errors.error}</Text>
                        </Container>
                        
                        <ButtonFormAuth onClick={onRedefine}>
                            {spinner == true
                            
                            ?
                                <SpinnerCircular size={30} color="#fff"/>
                            :
                                <Text col="#fff" fs="20px" fw="bold">SALVAR</Text>
                            }
                        
                        </ButtonFormAuth>
    
                        <Container display='flex' jc='center' mt='10px'>
                            <Text col='#fff' fs='20' fw='bold'>{success}</Text>
                        </Container>
    
                        <Container mt='10px'></Container>
    
                    </FormContainer>
    
                </Container>
    
            </Container>
        ) 
    }





}